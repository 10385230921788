export default {
  trigger: {
    name: "Task Schedule Name",
    preScheduleTime: "Last Run Time",
    nextScheduleTime: "Next Run Time",
    triggerConfig: "Configure Task Schedule Details",
    executionTargetAndArguments: "Confirm Execution Targets and Parameters",
    triggerTime: "Schedule Time",
    setBeginTime: "Set start time for task schedule",
    setEndTime: "Set end time for task schedule",
    jobSetting: "Task Settings",
    enableSuccessfully: "Started",
    disabledSuccessfully: "Ended",
    executeSuccessfully: "Executed",
    status: {
      all: "All status",
      enabled: "Enabled",
      disabled: "Disable",
    },
    enableHandle: {
      enabled: "Enable",
      disabled: "Disable",
    },
    validate: {
      endTimeNotBeforeBeginTime: "End time cannot be earlier than start time.",
      endTimeNotBeforeCurrentTime: "End time cannot be earlier than current time.",
      pleaseInputRightcron: "Please enter a correct cron expression",
    },
    timeType: {
      minute: "By minute",
      hour: "By hour",
      day: "By day",
      week: "By week",
      month: "By month",
      cron: "By expression",
    },
    log: {
      operation: {
        createdTrigger: "A cron job has been created.",
        createdJob: "A task has been created.",
        stopedTrigger: "End Cron Job",
        startedTrigger: "Start Cron Job",
        editedTrigger: "Edit Cron Job",
      },
      title: "Task Schedule Log",
      logName: "Task Schedule Name",
      logTime: "Log Time",
      content: "Log Content",
      operationName: "Operator",
    },
  },
};
