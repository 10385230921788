export default {
  runInstance: {
    viewLog: "View Log",
    logInfo: "Log Information",
    logTime: "Log Time",
    logTypeList: {
      systemLog: "System Log",
      defLog: "Custom Log",
    },
    allLog: "All",
    errLog: "Error",
    infoLog: "Info",
    debugLog: "Debug",
    logRank: "Log Level",
    logType: "Log Type",
    logContent: "Log Content",
    screenshotShown: "Screenshot Display",
    runinstanceNoVideoTip: "No video record of the current task.",
    videoShow: "Video Display",
    noScreenshotShown: "No screenshot.",
  },
  execution: {
    runOrder: "Execution Order",
    executionRobot: "Execution Robot",
    log: "Log",
    priority: "Priority Execution",
    restart: "Restart",
    termination: "Termination",
    expansion: "Show All",
    taskSource: "Task Source",
    status: {
      create: "Create",
      running: "Running",
      failed: "Failed",
      cancelled: "Cancel",
      succeeded: "Succeeded",
      cancelling: "Canceling",
    },
  },
};
