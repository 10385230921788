import {
  AppsServiceClient,
  AssetServiceClient,
  AuditlogServiceClient,
  DashboardServiceClient,
  DataPermissionServiceClient,
  DataTableServiceClient,
  HeaderMiddleware,
  HttpClient,
  InstallationServiceClient,
  JobServiceClient,
  LicenseBindingsServiceClient,
  PackageServiceClient,
  PermissionServiceClient,
  Profile,
  QueueServiceClient,
  RequestQueue,
  ResourceGroupServiceClient,
  RobotServiceClient,
  RoleAssignmentServiceClient,
  RoleServiceClient,
  RuninstanceServiceClient,
  TenantServiceClient,
  UserServiceClient,
  WorkflowServiceClient
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { History } from "history";
import {
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol
} from "src/dependencies/identifiers";
import developmentContainer from "src/dependencies/_development";
import productionContainer from "src/dependencies/_production";
import testContainer from "src/dependencies/_test";
import ApplicationInsightsService from "src/services/ApplicationInsightsService";
import { EnvironmentServiceClient } from "src/services/environmentService";
import { FileServiceClient } from "src/services/FileServiceClient";
import { LocaleService } from "src/services/LocaleService";
import { ReduxService } from "src/services/ReduxService";
import { SystemSettingsServiceClient } from "src/services/SystemSettings";
import { ThemeService } from "src/services/ThemeService";
import { UnderstandingSeviceClient } from "src/services/UnderstandingSeviceClient";
import { ErrorMiddleware } from "src/store/models/ui/error/error";

const container =
  process.env.NODE_ENV === "production"
    ? productionContainer
    : process.env.NODE_ENV === "test"
      ? testContainer
      : developmentContainer;

export interface Dependencies {
  userManagerSettings: Oidc.UserManagerSettings;
  history: History;
  locale: LocaleService;
  http: HttpClient;
  redux: ReduxService;
  theme: ThemeService;
  oidcClient: EncooOidcClient;
  profile: Profile;
  applicationInsights: ApplicationInsightsService;

  httpMiddleware: {
    tenantHeader: HeaderMiddleware;
    requestQueue: RequestQueue;
    errorHandle: ErrorMiddleware;
  };

  serviceClient: {
    auditlog: AuditlogServiceClient;
    user: UserServiceClient;
    resourceGroup: ResourceGroupServiceClient;
    package: PackageServiceClient;
    workflow: WorkflowServiceClient;
    robot: RobotServiceClient;
    queue: QueueServiceClient;
    role: RoleServiceClient;
    roleAssignment: RoleAssignmentServiceClient;
    apps: AppsServiceClient;
    permission: PermissionServiceClient;
    licenseBindings: LicenseBindingsServiceClient;
    runInstance: RuninstanceServiceClient;
    job: JobServiceClient;
    understanding: UnderstandingSeviceClient;
    installation: InstallationServiceClient;
    dashboard: DashboardServiceClient;
    environment: EnvironmentServiceClient;
    asset: AssetServiceClient;
    tenant: TenantServiceClient;
    file: FileServiceClient;
    dataPermission: DataPermissionServiceClient;
    dataTable: DataTableServiceClient;
    systemSettings: SystemSettingsServiceClient;
  };
}

const dependencies: Dependencies = {
  get userManagerSettings() {
    return container.get<Oidc.UserManagerSettings>(UserManagerSettingsSymbol);
  },

  get http() {
    return container.get<HttpClient>(HttpClientSymbol);
  },

  get history() {
    return container.get<History>(HistorySymbol);
  },

  get locale() {
    return container.get(LocaleService);
  },
  get redux() {
    return container.get(ReduxService);
  },
  get theme() {
    return container.get(ThemeService);
  },

  get oidcClient() {
    return container.get(EncooOidcClient);
  },

  get profile() {
    return container.get<Profile>(ProfileSymbol);
  },

  get applicationInsights() {
    return container.get(ApplicationInsightsService);
  },

  httpMiddleware: {
    get tenantHeader() {
      return container.get<HeaderMiddleware>(TenantHeaderMiddlewareSymbol);
    },
    get requestQueue() {
      return container.get<RequestQueue>(RequestQueueMiddlewareSymbol);
    },
    get errorHandle() {
      return container.get<ErrorMiddleware>(ErrorMiddlewareSymbol);
    },
  },

  serviceClient: {
    get auditlog() {
      return container.get(AuditlogServiceClient);
    },
    get resourceGroup() {
      return container.get(ResourceGroupServiceClient);
    },
    get package() {
      return container.get(PackageServiceClient);
    },
    get workflow() {
      return container.get(WorkflowServiceClient);
    },
    get robot() {
      return container.get(RobotServiceClient);
    },
    get user() {
      return container.get(UserServiceClient);
    },
    get queue() {
      return container.get(QueueServiceClient);
    },
    get role() {
      return container.get(RoleServiceClient);
    },
    get roleAssignment() {
      return container.get(RoleAssignmentServiceClient);
    },
    get apps() {
      return container.get(AppsServiceClient);
    },
    get permission() {
      return container.get(PermissionServiceClient);
    },
    get licenseBindings() {
      return container.get(LicenseBindingsServiceClient);
    },
    get runInstance() {
      return container.get(RuninstanceServiceClient);
    },
    get job() {
      return container.get(JobServiceClient);
    },
    get understanding() {
      return container.get(UnderstandingSeviceClient);
    },
    get installation() {
      return container.get(InstallationServiceClient);
    },
    get dashboard() {
      return container.get(DashboardServiceClient);
    },
    get environment() {
      return container.get(EnvironmentServiceClient);
    },
    get asset() {
      return container.get(AssetServiceClient);
    },
    get tenant() {
      return container.get(TenantServiceClient);
    },
    get file() {
      return container.get(FileServiceClient);
    },
    get dataPermission() {
      return container.get(DataPermissionServiceClient);
    },
    get dataTable() {
      return container.get(DataTableServiceClient);
    },
    get systemSettings() {
      return container.get(SystemSettingsServiceClient);
    },
  },
};

export function getDependencies() {
  return dependencies;
}
