import {
  Role,
  RoleCreationData,
  RoleUpdateData,
} from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { RoleEntityModel } from "src/store/models/entity/role/entity";
import { RoleListModel } from "src/store/models/entity/role/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const RoleHelperModel = createModel(
  class extends createHelperModel<Role>({
    setItems: (getContainer, items) =>
      getContainer(RoleEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RoleEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(RoleEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(RoleListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RoleEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readAll: async (payload: { force?: boolean }) => {
          const { force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.role.getAll();
            },
            getEntityParentId: (entity: Role) => entity.id,
            force,
          });
        },
        getById: async (payload: { entityId: string }) => {
          const { entityId } = payload;
          return this._readById({
            id: entityId,
            getByIdAction: () => {
              return this.dependencies.serviceClient.role.getById(entityId);
            },
          });
        },
        create: async (payload: { role: RoleCreationData }) => {
          const { role } = payload;
          return this._create({
            createAction: () => {
              return this.dependencies.serviceClient.role.create(role);
            },
          });
        },
        update: async (payload: { role: Role; updateData: RoleUpdateData }) => {
          const { role, updateData } = payload;
          return this._update({
            id: role.id,
            updateAction: () => {
              return this.dependencies.serviceClient.role.update(
                role,
                updateData as Record<string, unknown>
              );
            },
          });
        },
        delete: async (payload: { roleId: string }) => {
          const { roleId } = payload;
          return this._delete({
            id: roleId,
            deleteAction: () => {
              return this.dependencies.serviceClient.role.delete(roleId);
            },
          });
        },
        deletes: async (payload: {
          resourceGroupId: string;
          roleId: string;
        }) => {
          const { roleId } = payload;
          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [roleId],
          });
          try {
            await this.dependencies.serviceClient.role.delete(roleId);
            await this.actions._rw.endWrite.dispatch({
              items: [roleId],
              timestamp,
            });
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
