import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import { EnvironmentEdition, SystemInfo } from "src/models/environment";

export const ENVIRONMENT_SERVICE_NAME = "environment";

export class EnvironmentServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, ENVIRONMENT_SERVICE_NAME);
  }

  /**
   * @deprecated use getSystemInfo api instead to get environment edition
   */
  async getEdition(): Promise<EnvironmentEdition> {
    const req = this.buildRequest({
      url: `/v1/environments/edition`,
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      return res.body;
    });
  }

  async getSystemInfo(): Promise<SystemInfo> {
    const req = this.buildRequest({
      url: `/v1/environments/systemInfo`,
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      return res.body;
    });
  }

  async getUniqueCode(): Promise<string> {
    const req = this.buildRequest({
      url: `/v1/environments/uniqueCode`,
      method: "GET",
    });
    return await this.sendRequest(req).then((res) => {
      return res.body;
    });
  }
}
