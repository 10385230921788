import { Moment } from "moment";
import { createModel } from "nyax";
import { RangeValue } from "rc-picker/es/interface";
import { ModelBase } from "src/store/ModelBase";

export const AuditlogUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        rangeValue: null as RangeValue<Moment> | null,
      };
    }

    public reducers() {
      return {
        setRangeValue: (rangeValue: RangeValue<Moment>) => {
          this.state.rangeValue = rangeValue;
        },
      };
    }
  }
);
