/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Menu } from "antd";
import { memoize } from "lodash";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { ReactText, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Icon from "src/components/Icon";
import Text from "src/components/Text";
import { ThemeContext } from "src/contexts";
import {
  useFormatMessage,
  useGetContainer,
  useTheme,
  useThemeSet,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import {
  appMenuDefinitions,
  APP_MENUS,
  noLicenseImportedMenuDefinitions,
} from "src/routes/menu";
import { appRouteDefinitions } from "src/routes/router";
import { MenuDefinition, MenuExpandedDefinition } from "src/routes/_shared";
import { AppModel } from "src/store/models/app";
import { RouterModel } from "src/store/models/router";
import { SidebarModel } from "src/store/models/sidebar";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { getLocale } from "src/utils/locale";

const { SubMenu } = Menu;

const AppSidebarInternal = React.memo(function AppSidebarInternal() {
  const formatMessage = useFormatMessage();
  const theme = useTheme();

  const getContainer = useGetContainer();
  const sidebar = getContainer(SidebarModel);
  const router = getContainer(RouterModel);
  const app = getContainer(AppModel);
  const environmentContainer = getContainer(EnvironmentModel);

  const permissions = useSelector(() => app.state.permissions);
  const selectedKey = useSelector(() => router.getters.currentRouteInfo.type);

  const selectedKeys = useMemo(() => {
    let routeKey: string | undefined = selectedKey;

    while (routeKey && APP_MENUS[routeKey]) {
      const menu: MenuExpandedDefinition = APP_MENUS[routeKey];
      if (menu.hiddenInMenu) {
        routeKey = menu.parentKey;
      } else {
        break;
      }
    }
    return routeKey ? [routeKey] : [];
  }, [selectedKey]);

  const defaultOpenKeys = useMemo(
    () =>
      selectedKey && APP_MENUS[selectedKey]
        ? [APP_MENUS[selectedKey].parentKey ?? selectedKey]
        : [],
    [selectedKey]
  );

  const onClick = useCallback(
    (param: MenuInfo) => {
      sidebar.actions.navigate.dispatch(param.key as string);
    },
    [sidebar]
  );

  const isMenuCollapsed = useSelector(() => sidebar.state.isMenuCollapsed);

  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );
  const hasLicenseAuthorization = useSelector(
    () => environmentContainer.state.hasLicenseAuthorization
  );

  const appMenus = useMemo(
    () =>
      environmentEdition === "Private" && !hasLicenseAuthorization
        ? noLicenseImportedMenuDefinitions
        : appMenuDefinitions,
    [environmentEdition, hasLicenseAuthorization]
  );

  const hasRole = useCallback(
    (menuItem: MenuDefinition) => {
      if (!menuItem.routeType) {
        return true;
      }
      const role = appRouteDefinitions[menuItem.routeType].role;
      if (!role) {
        return true;
      }
      if (role instanceof Array) {
        return role.some((item) => permissions?.includes(item));
      }
      return permissions?.includes(role);
    },
    [permissions]
  );

  const hasChildren = useCallback(
    (children?: MenuDefinition[]) => {
      if (!children) {
        return false;
      }

      return (
        children.filter((item) => item.hiddenInMenu !== true && hasRole(item))
          .length > 0
      );
    },
    [hasRole]
  );

  const navigateToHome = useCallback(() => {
    router.actions.navigateByRouteInfo.dispatch({
      type: "index",
      params: {},
    });
  }, [router.actions.navigateByRouteInfo]);

  const renderSubMenu = useMemo(() => {
    return memoize((menu: MenuDefinition) => {
      const cssIcon = isMenuCollapsed
        ? css`
            margin-bottom: 1px;
            font-size: 16px;
          `
        : css`
            margin-bottom: 1px;
            font-size: 16px;
          `;

      if (hasChildren(menu.children)) {
        return (
          <SubMenu
            key={menu.routeType ?? menu.name}
            icon={
              menu.icon ? (
                <span role="img" className="anticon">
                  <Icon css={cssIcon} name={menu.icon} />
                </span>
              ) : undefined
            }
            title={<Text>{formatMessage(menu.name)}</Text>}
            css={css``}
          >
            {menu.children?.map((item) => renderSubMenu(item))}
          </SubMenu>
        );
      } else if (hasRole(menu) && menu.routeType) {
        return (
          <Menu.Item
            key={menu.routeType ?? menu.name}
            icon={
              menu.icon ? (
                <span role="img" className="anticon">
                  <Icon css={cssIcon} name={menu.icon} />
                </span>
              ) : undefined
            }
            title={formatMessage(menu.name)}
          >
            {formatMessage(menu.name)}
          </Menu.Item>
        );
      } else {
        return null;
      }
    });
  }, [formatMessage, hasChildren, hasRole, isMenuCollapsed]);

  const rootSubmenuKeys = useMemo(() => appMenus.map((item) => item.name), [
    appMenus,
  ]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const onOpenChange = useCallback(
    (keys: ReactText[]) => {
      const latestOpenKey = keys.find(
        (key) => openKeys.indexOf(key as string) === -1
      );
      if (rootSubmenuKeys.indexOf(latestOpenKey as string) === -1) {
        setOpenKeys(keys as string[]);
      } else {
        setOpenKeys((latestOpenKey as string) ? [latestOpenKey as string] : []);
      }
    },
    [openKeys, rootSubmenuKeys]
  );

  return (
    <aside
      css={css`
        display: flex;
        flex-direction: column;
        color: ${theme.bodyText};
        background: linear-gradient(
          178deg,
          ${theme.bodyBackground[0]} 0%,
          ${theme.bodyBackground[1]} 100%
        );
        min-width: ${isMenuCollapsed ? 80 : 200}px;
        width: ${isMenuCollapsed ? 80 : 200}px;
        transition: all 0.2s;
      `}
    >
      <div
        onClick={navigateToHome}
        css={css`
          display: flex;
          align-items: center;
          height: 60px;
          cursor: pointer;
          padding-left: 25px;
        `}
      >
        <Icon
          css={css`
            font-size: 26px;
          `}
          name="icon-logo"
        />
        {!isMenuCollapsed &&
          (window._settings.customizedCustomer === "tss" ? (
            <span
              css={css`
                font-size: 20px;
                font-weight: bold;
              `}
            >
              {formatMessage(messageIds.customizedCustomer.tss.title)}
            </span>
          ) : (
            <Icon
              css={css`
                margin-left: 4px;
                width: ${getLocale() === "zh-Hans" ? "90px" : "110px"};
              `}
              name={
                getLocale() === "zh-Hans"
                  ? "icon-logo-font-zhCN"
                  : "icon-logo-font-enUS"
              }
            />
          ))}
      </div>
      <Menu
        openKeys={openKeys}
        mode="inline"
        selectedKeys={selectedKeys}
        onClick={onClick}
        defaultOpenKeys={defaultOpenKeys}
        onOpenChange={onOpenChange}
        expandIcon={({ isOpen }) => {
          return isMenuCollapsed ? null : (
            <Icon
              name="icon-menu-pull"
              css={css`
                position: absolute;
                top: 50%;
                margin-top: -4px;
                right: 16px;
                font-size: 8px;
                color: ${theme.bodyText};
                transform: rotate(${isOpen ? "180deg" : "0deg"});
              `}
            />
          );
        }}
        css={css`
          border: none;
          color: ${theme.bodyText};
          background-color: transparent;
          overflow-x: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          .ant-menu,
          .ant-menu-submenu-open {
            color: ${theme.bodyText};
            background: transparent;
          }
          .ant-menu-item,
          .ant-menu-submenu-title {
            height: 48px !important;
            line-height: 48px !important;
            padding-left: 30px !important;
            margin-top: 0px;
            margin-bottom: 0px !important;
          }
          .ant-menu-submenu {
            padding-bottom: 0;
            background: transparent;
          }

          .ant-menu-item span:nth-child(2) {
            margin-left: 0px;
          }
          .ant-menu-submenu-title span:nth-child(2) span {
            margin-left: 0px;
          }
          .ant-menu-item-only-child {
            padding-left: 54px !important;
          }

          .ant-menu-inline {
            border: none;
          }
          .ant-menu-submenu-title,
          .ant-menu-inline .ant-menu-item {
            width: 100%;
            margin: 0px auto;
          }
          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            color: ${theme.bodyFrameBackground};
            background-color: ${theme.primaryColor} !important;
          }
          .ant-menu-item-selected {
            color: ${theme.bodyFrameBackground};
            font-weight: 600;
            background-color: ${theme.primaryColor} !important;
          }

          .ant-menu-item-active {
            color: ${theme.bodyFrameBackground};
            background-color: ${theme.menuHoverBackground};

            svg {
              color: ${theme.bodyFrameBackground};
            }
          }
          .ant-menu-inline .ant-menu-item-selected::after {
            border: none !important;
          }
          .ant-menu-item:active,
          .ant-menu-submenu-title:active {
            color: ${theme.primaryColor};
            background-color: ${theme.menuHoverBackground};
          }
          .ant-menu-submenu-title:hover {
            background-color: ${theme.menuHoverBackground};
          }
          .ant-menu-submenu-arrow {
            &::before,
            &::after {
              background-image: linear-gradient(
                to right,
                ${theme.primaryButtonText},
                ${theme.primaryButtonText}
              ) !important;
            }
          }
          .ant-menu-submenu-selected {
            color: ${theme.bodyFrameBackground};
            background-color: transparent;
          }
        `}
        inlineCollapsed={isMenuCollapsed}
      >
        {appMenus.map((item) => renderSubMenu(item))}
      </Menu>
    </aside>
  );
});

export default React.memo(function AppSidebar() {
  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.sidebar}>
      <AppSidebarInternal />
    </ThemeContext.Provider>
  );
});
