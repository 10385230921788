import { Asset, AssetCreationData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { AssetEntityModel } from "src/store/models/entity/asset/entity";
import { AssetListModel } from "src/store/models/entity/asset/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AssetHelperModel = createModel(
  class extends createHelperModel<Asset>({
    setItems: (getContainer, items) =>
      getContainer(AssetEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AssetEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AssetEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(AssetListModel).actions.refresh.dispatch({}),
  }) {
    public effects() {
      return {
        ...super.effects(),

        readById: async (payload: {
          resourceGroupId: string;
          id: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.asset.getById(
                resourceGroupId,
                id
              );
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          assetCreationData: AssetCreationData;
        }) => {
          const { resourceGroupId, assetCreationData } = payload;
          return await this._create({
            createAction: async () => {
              return await this.dependencies.serviceClient.asset.create(
                resourceGroupId,
                assetCreationData
              );
            },
          });
        },

        update: async (payload: {
          id: string;
          resourceGroupId: string;
          assetCreationData: AssetCreationData  ;
        }) => {
          const { id, resourceGroupId, assetCreationData } = payload;
          return await this._update({
            id: id,
            updateAction: () =>
              this.dependencies.serviceClient.asset.update(
                resourceGroupId,
                id,
                assetCreationData
              ),
          });
        },

        delete: async (payload: { id: string; resourceGroupId: string }) => {
          const { id, resourceGroupId } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.asset.delete(resourceGroupId, id),
          });
        },
      };
    }
  }
);
