export default {
  queue: {
    queue: "Queue",
    name: "Queue Name",
    introduction: "The schedule queue is mainly used to bind clusters of robots while dynamically assigning tasks triggered into the queue to idle robots for operation.",
    tasksPerformed: "Number of Executed Tasks",
    remainingTasks: "Number of Remaining Tasks",
    robotCount: "Number of Robots",
    workflowCount: "Number of Associated Flows",
    view: "View Task",
    setting: "Basic Settings",
    bindRobot: "Add Robot",
    deleteTip: "All the remaining tasks in the queue will be terminated after deletion.",
  },
};
