import { Permission } from "@encoo-web/encoo-lib/types";
import _ from "lodash";
import { createModel, mergeModels, mergeSubModels } from "nyax";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";
import { PermissionEntityModel } from "src/store/models/entity/permission/entity";

export const PermissionHelperModel = createModel(
  class extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<Permission>({
        getItemId: (item) => item.id,
        setItems: ({ getContainer }, items) =>
          getContainer(PermissionEntityModel).actions.setItems.dispatch(items),
      }),
      _rwByGroupId: createItemsReadWriteModel(),
    })
  ) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PermissionEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: {
          groupIds: string[];
          force?: boolean;
        }) => {
          const { groupIds, force } = payload;
          const {
            ids: beginReadIds,
            timestamp,
          } = await this.actions._rwByGroupId.beginRead.dispatch({
            ids: groupIds,
            force,
          });
          try {
            if (beginReadIds.length > 0) {
              const items = _.flatten(
                await Promise.all(
                  beginReadIds.map((resourceGroupId) =>
                    this.dependencies.serviceClient.permission.getAll()
                  )
                )
              );
              const {
                ids: endReadIds,
              } = await this.actions._rwByGroupId.endRead.dispatch({
                items: beginReadIds,
                timestamp,
              });

              if (endReadIds.length > 0) {
                const endPackageIdSet = new Set(endReadIds);
                await this.actions._rw.endRead.dispatch({
                  items: [
                    ...this.getters.entity.getters.items
                      .filter((item: Permission) =>
                        endPackageIdSet.has(item.id)
                      )
                      .map((item) => item.id),
                    ...items,
                  ],
                  timestamp,
                });
              }
            }
          } catch (error) {
            await this.actions._rwByGroupId.endRead.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
