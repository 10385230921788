import { Role } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const RoleEntityModel = createModel(
  class extends createItemsEntityModel<Role>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),
        roles: createSelector(
          (): Role[] => this.getters.items,
          (items) => items
        ),
      };
    }
  }
);
