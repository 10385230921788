export default {
  license: {
    entityTypeName: "ライセンス",
    introduction:
      "このページは、コンソールのさまざまなライセンスまたはサービスの承認と使用を表示および管理するために使用されます。",
    table: {
      id: "番号",
      user: "現在のユーザー",
      machineName: "マシン名",
      name: "ロボット名",
      bindTime: "バインド時間",
      authorizeDate: "ライセンスされた日",
    },
    button: {
      apply: "ライセンスを申し込み",
      applyEnterprise: "エンタープライズ版を申し込み",
      assign: "たライセンスを割り当て",
      activation: "ライセンスをアクティブ化",
      detail: "使用状況の詳細",
      remove: "ライセンスを削除",
    },
    tooltip: {
      progress: "使用された数/ライセンス数",
    },
    editor: {
      name: "エンタープライズ版Studio",
      descriptions: "アクティブ化できるStudioの数",
    },
    robot: {
      name: "エンタープライズ版プロロボット",
      descriptions: "接続できるロボットの数",
    },
        documentUnderstanding: {
      name: "文書理解サービス",
      descriptions: "呼び出し可能の文書理解サービスの数",
    },
    documentUnderstandingTable: {
      modelType: "内蔵モデルのタイプ",
      total: "総量",
      used: "使用量",
      rest: "残りの量",
      useOf: "使用率",
      PDF: "PDF抽出モデル",
      OCR: "OCR抽出モデル",
    },
    dailog: {
      demand: "説明",
      demandPlaceholder: "必要なロボットの数、プロセスの数など、必要な数量を教えてください...",
      feedback:
          "リクエストを受け取り次第、できるだけ早くご連絡いたします。緊急の場合は、400-639-2198までお電話ください。",
      customer: "顧客名（識別コード）",
      licensePlaceholder: "ライセンス番号を入力してください",
      sendScuess: "ライセンスリクエストが送信されました",
      sendFailed: "ライセンスを適用するために失敗しました。",
      licenseSerialNumber: "ライセンス番号を入力してください。",
    },
    messageInfo: {
      activateSuccess: "アクティベーションが成功しました",
      activateFailed: "アクティベーションが失敗しました",
    },
    activateTip: {
      ifNoLicense: "エンタープライズライセンスをお持ちでない場合は、",
      tenantIdTip: "識別コードは、ライセンスを申し込みときにENCOOスタッフに提供するために使用されます",
    },
  },
};
