export default {
  trigger: {
    name: "任务计划名称",
    preScheduleTime: "上次运行时间",
    nextScheduleTime: "下次运行时间",
    triggerConfig: "任务计划详情配置",
    executionTargetAndArguments: "执行目标及参数确认",
    triggerTime: "计划时间",
    setBeginTime: "设置开始任务计划时间",
    setEndTime: "设置停用任务计划时间",
    jobSetting: "任务设置",
    enableSuccessfully: "启用成功",
    disabledSuccessfully: "停用成功",
    executeSuccessfully: "执行成功",
    status: {
      all: "全部状态",
      enabled: "已启用",
      disabled: "未启用",
    },
    enableHandle: {
      enabled: "启用",
      disabled: "停用",
    },
    validate: {
      endTimeNotBeforeBeginTime: "停止时间不能早于开始时间",
      endTimeNotBeforeCurrentTime: "停止时间不能早于当前时间",
      pleaseInputRightcron: "请输入正确的cron表达式",
    },
    timeType: {
      minute: "按分钟",
      hour: "按小时",
      day: "按天",
      week: "按周",
      month: "按月",
      cron: "按表达式",
    },
    log: {
      operation: {
        createdTrigger: "定时计划被创建",
        createdJob: "成功创建一个任务",
        stopedTrigger: "停止定时计划",
        startedTrigger: "开启定时计划",
        editedTrigger: "编辑定时计划",
      },
      title: "任务计划日志",
      logName: "任务计划名称",
      logTime: "日志时间",
      content: "日志内容",
      operationName: "操作人",
    },
  },
};
