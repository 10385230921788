import { AccessToken, ServiceCredential } from "@encoo-web/encoo-lib/types";
import { EncooOidcClient } from "@encoo-web/oidc-client";

export class OidcClientAuthenticationCredential implements ServiceCredential {
  private _oidcClient: EncooOidcClient;
  constructor(oidcClient: EncooOidcClient) {
    this._oidcClient = oidcClient;
  }
  getAccessToken(): Promise<AccessToken | null> {
    return this._oidcClient.login().then((user) => ({
      token: user?.access_token || "",
      expiresOnTimestamp: user?.expires_at || 0,
    }));
  }
}
