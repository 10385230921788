import { Role } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { RoleEntityModel } from "src/store/models/entity/role/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const RoleListModel = createModel(
  class extends createListModel<Role>({
    setItems: (getContainer, items) =>
      getContainer(RoleEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RoleEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(RoleEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { force?: boolean }) => {
          const { force = true } = payload;
          this._initialIterator({
            initialAction: () => this.dependencies.serviceClient.role.list(),
            force: force,
          });
        },
      };
    }
  }
);
