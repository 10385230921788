import { UserInfo, UserTenantInfo } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import Oidc from "oidc-client";
import { ModelBase } from "src/store/ModelBase";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export interface BasicUserProfile {
  id: string;
  userId?: string;
  name?: string;
  defaultTenantId?: string;
  defaultLanguage?: string | null;
}

export const LoginUserUIModel = createModel(
  class extends ModelBase {
    private get _client() {
      return this.dependencies.oidcClient;
    }

    private get _userTenantUIContainer() {
      return this.getContainer(UserTenantUIModel);
    }

    public selectors() {
      return {
        selectedUserTenant: createSelector(
          (): UserTenantInfo[] | null =>
            this._userTenantUIContainer.state.userTenants,
          (): string | null => this.state.selectedUserTenantId,
          (userTenants, selectedUserTenantId): UserTenantInfo | null => {
            return (
              userTenants?.find(
                (item) => item.tenantId === selectedUserTenantId
              ) ?? null
            );
          }
        ),
      };
    }

    public initialState() {
      return {
        basicUserProfile: null as BasicUserProfile | null,
        loggedinUser: null as UserInfo | null,
        selectedUserTenantId: null as string | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setLoggedinUser: (value: UserInfo | null) => {
          this.state.loggedinUser = value;
        },
        setSelectedTenantId: (value: string | null) => {
          this.state.selectedUserTenantId = value;
        },
        setBasicUserProfile: (value: BasicUserProfile | null) => {
          this.state.basicUserProfile = value;
        },
      };
    }

    public effects() {
      return {
        logout: async () => {
          await this._client.logOut();
          await this.actions.setLoggedinUser.dispatch(null);
        },

        getUserProfile: async (id: string) => {
          if (this.state.selectedUserTenantId) {
            const profile = await this.dependencies.serviceClient.user.getUserProfile(
              id
            );
            await this.actions.setLoggedinUser.dispatch(profile);
          }
        },

        login: async () => {
          const user = await this._client.login();
          if (user?.profile?.sub) {
            await this.actions.requestBasicUserProfile.dispatch(
              user.profile.sub
            );
          }

          return user;
        },

        requestBasicUserProfile: async (profileId: string) => {
          const profile = await this.dependencies.serviceClient.user.getUserProfileV2(
            profileId
          );
          const {
            id,
            userId,
            name,
            defaultTenantId,
            defaultLanguage,
          } = profile;
          const basicProfile = {
            id,
            userId,
            name,
            defaultTenantId,
            defaultLanguage,
          };
          await this.actions.setBasicUserProfile.dispatch(basicProfile);
        },

        addLogoutListener: async () => {
          if (this.dependencies.profile.env === "local") {
            return;
          }
          const oidcUserManagerInstance = new Oidc.UserManager(
            this.dependencies.userManagerSettings
          );
          oidcUserManagerInstance.events.addUserSignedOut(() => {
            oidcUserManagerInstance.clearStaleState();
            console.log("sso fontend loginOut");
            this.actions.logout.dispatch({});
          });
        },
      };
    }
  }
);
