/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { UserTenantInfo } from "@encoo-web/encoo-lib";
import { Form, Input, message, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { useCallback } from "react";
import Alert from "src/components/Alert";
import Button from "src/components/Button";
import { useDialog } from "src/components/Dialog";
import Icon from "src/components/Icon";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { RouterModel } from "src/store/models/router";
import { LicenseUIModel } from "src/store/models/ui/license/license";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export const UserTenantForm = React.memo(function (props: {
  form: FormInstance;
}) {
  const formatMessage = useFormatMessage();
  return (
    <Form colon={false} layout="vertical" form={props.form}>
      <Form.Item
        label={
          <div>
            <span>{formatMessage(messageIds.user.tenant.name)}：</span>
            <span
              css={css`
                color: darkgray;
              `}
            >
              {formatMessage(messageIds.user.tenant.nameSubtitle)}
            </span>
          </div>
        }
        name="name"
        rules={[
          {
            required: true,
            message: formatMessage(messageIds.common.required),
          },
          {
            type: "string",
            min: 4,
            max: 50,
            message: formatMessage(messageIds.validate.stringLength, {
              name: formatMessage(messageIds.user.tenant.name),
              min: 4,
              max: 50,
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage(messageIds.user.tenant.namePlaceholder)}
        />
      </Form.Item>
      <Alert
        message={formatMessage(messageIds.user.tenant.nameAlert)}
        type="info"
      ></Alert>
    </Form>
  );
});

export default React.memo(function CreateUserTenant(props: {
  onCloseModal: () => void;
}) {
  const formatMessage = useFormatMessage();
  const Dialog = useDialog();
  const getContainer = useGetContainer();

  const router = getContainer(RouterModel);
  const userTenantUIContainer = getContainer(UserTenantUIModel);
  const licenseUIContainer = getContainer(LicenseUIModel);

  const { onCloseModal } = props;
  const [userTenantForm] = Form.useForm();

  const selectTenant = useCallback(
    async (item: UserTenantInfo) => {
      await userTenantUIContainer.actions.switchTenant.dispatch({
        id: item.tenantId,
      });
      licenseUIContainer.actions.setActivateLicenseVisible.dispatch(true);
      router.actions.navigateByRouteInfo.dispatch({
        type: "license",
        params: {},
      });
    },
    [
      licenseUIContainer.actions.setActivateLicenseVisible,
      router.actions.navigateByRouteInfo,
      userTenantUIContainer.actions.switchTenant,
    ]
  );

  const createTenantSuccessRender = useCallback(() => {
    return (
      <div>
        <div>
          <Icon name="icon-runSuccessTimes"></Icon>
          <span
            css={css`
              padding-left: 20px;
              font-size: 18px;
              font-weight: bold;
            `}
          >
            {formatMessage(messageIds.user.tenant.createTenantSuccess)}
          </span>
        </div>
        <div
          css={css`
            padding: 20px 20px 20px 20px;
          `}
        >
          {formatMessage(messageIds.user.tenant.createTenantSuccessHint)}
        </div>
      </div>
    );
  }, [formatMessage]);

  const onCreateSubmit = async () => {
    try {
      const { name } = await userTenantForm.validateFields();
      const userTenant = await userTenantUIContainer.actions.createUserTenant.dispatch(
        { name }
      );
      onCloseModal();
      Dialog({
        title: formatMessage(messageIds.common.alertTitle),
        content: createTenantSuccessRender(),
        okText: formatMessage(messageIds.common.nextStep),
        onOk: async () => {
          if (userTenant) {
            selectTenant(userTenant);
          } else {
            message.error(
              formatMessage(messageIds.common.info.createFailed, {
                entityTypeName: formatMessage(
                  messageIds.user.tenant.entityTypeName
                ),
                name,
              })
            );
          }
        },
      });
    } catch (error) {
      return true;
    }
  };

  return (
    <div>
      <h3
        css={css`
          margin-bottom: 20px;
        `}
      >
        {formatMessage(messageIds.user.createTenant)}
      </h3>
      <UserTenantForm form={userTenantForm} />
      <Space
        size={20}
        css={css`
          padding: 20px 0;
          width: 100%;
          justify-content: flex-end;
        `}
      >
        <Button onClick={onCloseModal}>
          {formatMessage(messageIds.common.cancel)}
        </Button>
        <Button type="primary" onClick={onCreateSubmit}>
          {formatMessage(messageIds.common.create)}
        </Button>
      </Space>
    </div>
  );
});
