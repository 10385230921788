import { AppRouteDefinitions } from "src/routes";

export interface RouteDefinition<
  TType extends string = string,
  TParams extends Record<string, string> = Record<string, string>
> {
  type: TType;
  path: string;
  role?: string | string[];
  component: string;
  silderComponent?: string;
  defaultParams: TParams;
}

export function createRouteDefinition<
  TType extends string,
  TParams extends Record<string, string>
>(routeDefinition: RouteDefinition<TType, TParams>) {
  return routeDefinition;
}

export interface ConvertRouteInfo<TRouteDefinition extends RouteDefinition> {
  type: TRouteDefinition["type"];
  role?: TRouteDefinition["role"];
  component?: TRouteDefinition["component"];
  silderComponent?: TRouteDefinition["silderComponent"];
  params: TRouteDefinition["defaultParams"];
}

export type AppRouteType = keyof AppRouteDefinitions;

export interface MenuDefinition {
  routeType?: AppRouteType;
  name: string;
  icon?: string;
  children?: MenuDefinition[];
  hiddenInMenu?: boolean;
  hiddenInBreadcrumb?: boolean;
}

export interface MenuExpandedDefinition extends MenuDefinition {
  parentKey?: string;
}

export function createMenuDefinition(menuDefinition: MenuDefinition) {
  return menuDefinition;
}
