import { Runinstance } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { RunInstanceEntityModel } from "src/store/models/entity/runInstance/entity";
import { createHelperModel } from "src/store/models/entity/_shared";
import { handleErrorNotFound } from "src/utils/error";

export const RunInstanceHelperModel = createModel(
  class extends createHelperModel<Runinstance>({
    setItems: (getContainer, items) =>
      getContainer(RunInstanceEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RunInstanceEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RunInstanceEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByJobIds: async (payload: {
          resourceGroupId: string;
          jobIds: Array<string>;
          force?: boolean;
        }) => {
          const { resourceGroupId, jobIds, force } = payload;
          return await this._readByParentIds({
            parentIds: jobIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.runInstance.getAll(
                resourceGroupId,
                parentId
              );
            },
            getEntityParentId: (entity: Runinstance) => entity.jobId,
            force,
          });
        },
        getLogsByRunInstanceId: async (payload: {
          resourceGroupId: string;
          runinstanceId: string;
        }) => {
          try {
            const { resourceGroupId, runinstanceId } = payload;
            const logs = await this.dependencies.serviceClient.runInstance.getAllLogsById(
              resourceGroupId,
              runinstanceId
            );
            return logs;
          } catch (error) {
            handleErrorNotFound(error);
          }
        },
        getLogFileByFileId: async (payload: {
          resourceGroupId: string;
          runinstanceId: string;
          logId: string;
        }) => {
          const { resourceGroupId, runinstanceId, logId } = payload;
          const fileData = await this.dependencies.serviceClient.runInstance.getLogFileByFileId(
            resourceGroupId,
            runinstanceId,
            logId
          );
          return fileData;
        },
        getResultVideoRecord: async (payload: {
          resourceGroupId: string;
          runinstanceId: string;
        }) => {
          const { resourceGroupId, runinstanceId } = payload;
          const data = await this.dependencies.serviceClient.runInstance.getResultVideoRecord(
            resourceGroupId,
            runinstanceId
          );
          return data;
        },
        getResult: async (payload: {
          resourceGroupId: string;
          runinstanceId: string;
        }) => {
          const { resourceGroupId, runinstanceId } = payload;
          const data = await this.dependencies.serviceClient.runInstance.getResult(
            resourceGroupId,
            runinstanceId
          );
          return data;
        },
      };
    }
  }
);
