import {
  JobDailyCount,
  JobQueryData,
  JobStatus,
  JobStatusCounts,
  RobotStatus,
} from "@encoo-web/encoo-lib/types/model/dashboard";
import moment from "moment";
import { createModel, createSelector } from "nyax";
import messageIds from "src/locales/messageIds";
import { ModelBase } from "src/store/ModelBase";
import { timezoneOffsetRelativeToUtc } from "src/utils/string";

export interface StaticsType {
  packages?: number;
  workflow?: number;
  queue?: number;
  job?: number;
  robot?: number;
}
export type RobotType = {
  [propName in RobotStatus]: number;
};
export type JobsTypes = {
  date: string;
  status: JobStatus;
  count: number;
  percent: number;
};
const jobColor = [
  "#ccddff",
  "#99bbff",
  "#6699ff",
  "#3377ff",
  "#d0d3d8",
  "#d0d3d8",
  "#4C6398",
];
export const JobStatusMap = {
  Queued: {
    name: messageIds.dashboard.table.wait,
    sort: 0,
    color: jobColor[0],
  },
  Allocated: {
    name: messageIds.dashboard.table.allocated,
    sort: 1,
    color: jobColor[1],
  },
  Running: {
    name: messageIds.dashboard.table.operation,
    sort: 2,
    color: jobColor[2],
  },
  Succeeded: {
    name: messageIds.dashboard.table.success,
    sort: 3,
    color: jobColor[3],
  },
  Cancelling: {
    name: messageIds.dashboard.table.cancelling,
    sort: 4,
    color: jobColor[4],
  },
  Cancelled: {
    name: messageIds.dashboard.table.cancelled,
    sort: 5,
    color: jobColor[5],
  },
  Failed: {
    name: messageIds.dashboard.table.fail,
    sort: 6,
    color: jobColor[6],
  },
};
export const DashboardUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dateRange: { StartDate: "", EndDate: "" },
        isLoading: true,
        time: Date.now(),
        statics: {
          packages: 0,
          workflow: 0,
          queue: 0,
          job: 0,
          robot: 0,
        },
        robots: {
          Disconnected: 0,
          Ready: 0,
          Busy: 0,
          Unlicensed: 0,
        },
        jobsDailyStatics: [] as Array<JobDailyCount>,
        jobTotalStatics: [] as Array<JobStatusCounts>,
        jobColor,
      };
    }
    public reducers() {
      return {
        setDateRange: (
          dateRange: Pick<JobQueryData, "StartDate" | "EndDate">
        ) => {
          this.state.dateRange = dateRange;
        },
        setStatics: (statics: StaticsType) => {
          this.state.statics = { ...this.state.statics, ...statics };
        },
        setJobTotalStatics: (statics: Array<JobStatusCounts>) => {
          this.state.jobTotalStatics = statics;
        },
        setJobsDailyStatics: (statics: Array<JobDailyCount>) => {
          this.state.jobsDailyStatics = statics;
        },
        setRobots: (robots: RobotType) => {
          this.state.robots = robots;
        },
        setIsLoadings: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
      };
    }
    public selectors() {
      return {
        jobTotalData: createSelector(
          () => this.state.jobTotalStatics,
          () => JobStatusMap,
          (items, nameMap) => {
            const statusMap = new Map<string, JobStatusCounts>();
            items.forEach((item) => {
              statusMap.set(item.status, item);
            });
            return [
              {
                ...nameMap.Queued,
                count: statusMap.get("Queued")?.count,
                percent: statusMap.get("Queued")?.percent,
              },
              {
                ...nameMap.Allocated,
                count: statusMap.get("Allocated")?.count,
                percent: statusMap.get("Allocated")?.percent,
              },
              {
                ...nameMap.Running,
                count: statusMap.get("Running")?.count,
                percent: statusMap.get("Running")?.percent,
              },
              {
                ...nameMap.Succeeded,
                count: statusMap.get("Succeeded")?.count,
                percent: statusMap.get("Succeeded")?.percent,
              },
              {
                ...nameMap.Cancelling,
                count: statusMap.get("Cancelling")?.count,
                percent: statusMap.get("Cancelling")?.percent,
              },

              {
                ...nameMap.Cancelled,
                count: statusMap.get("Cancelled")?.count,
                percent: statusMap.get("Cancelled")?.percent,
              },
              {
                ...nameMap.Failed,
                count: statusMap.get("Failed")?.count,
                percent: statusMap.get("Failed")?.percent,
              },
            ];
          }
        ),
        jobDailyData: createSelector(
          () => this.state.jobsDailyStatics,
          () => JobStatusMap,
          () => [this.state.dateRange.StartDate, this.state.dateRange.EndDate],
          (items, statusMap, dates) => {
            let jobsDailyStatics: Array<JobsTypes> = [];
            if (dates[0] && dates[1]) {
              let tempStartDate = moment(dates[0]).format("YYYY-MM-DD");
              const tempEndDate = moment(dates[1]).format("YYYY-MM-DD");
              const list = [];
              while (tempStartDate <= tempEndDate) {
                list.push(tempStartDate);
                tempStartDate = moment(tempStartDate)
                  .add(1, "day")
                  .format("YYYY-MM-DD");
              }
              const dateMap = new Map<string, JobDailyCount>();
              items.forEach((item) =>
                dateMap.set(
                  moment(item.statisticsDate).format("YYYY-MM-DD"),
                  item
                )
              );
              list.forEach((dateItem) => {
                const data = dateMap.get(dateItem);
                if (data) {
                  data.addedJobStatusCounts
                    .map((item) => item)
                    .sort((curItem, Nextitem) => {
                      return statusMap[curItem.status]?.sort >
                        statusMap[Nextitem.status].sort
                        ? 1
                        : -1;
                    })
                    .forEach((item) => {
                      jobsDailyStatics.push({
                        date: dateItem,
                        status: item.status,
                        count: item.count,
                        percent: item.percent,
                      });
                    });
                } else {
                  const emptyData = [
                    "Queued",
                    "Allocated",
                    "Running",
                    "Succeeded",
                    "Cancelling",
                    "Cancelled",
                    "Failed",
                  ].map((item) => ({
                    date: dateItem,
                    status: item as JobStatus,
                    count: 0,
                    percent: 0,
                  }));
                  jobsDailyStatics = jobsDailyStatics.concat(emptyData);
                }
              });
            }
            return jobsDailyStatics;
          }
        ),
      };
    }
    public effects() {
      return {
        initializeRequest: async (resourceGroupId: string) => {
          Promise.all([
            this.actions.robotStatistics.dispatch(resourceGroupId),
            this.actions.packageStatistics.dispatch(resourceGroupId),
            this.actions.workflowStatistics.dispatch(resourceGroupId),
            this.actions.queueStatistics.dispatch(resourceGroupId),
          ]).then(([robot, packages, workflow, queue]) => {
            this.actions.setRobots.dispatch({
              Disconnected: robot.totalRobotStatusCounts.Disconnected || 0,
              Ready: robot.totalRobotStatusCounts.Ready || 0,
              Busy: robot.totalRobotStatusCounts.Busy || 0,
              Unlicensed: robot.totalRobotStatusCounts.Unlicensed || 0,
            });
            this.actions.setStatics.dispatch({
              packages: packages.totalPackageCount,
              workflow: workflow.totalWorkflowCount,
              queue: queue.totalQueueCount,
              robot: robot.totalRobotCount,
            });
          });
          this.actions.jobStatistics.dispatch(resourceGroupId);
        },
        robotStatistics: async (resourceGroupId: string) => {
          return await this.dependencies.serviceClient.dashboard.robotStatistics(
            resourceGroupId
          );
        },
        jobStatistics: async (resourceGroupId: string) => {
          const jobData = await this.dependencies.serviceClient.dashboard.jobStatistics(
            {
              ...this.state.dateRange,
              timeZone: timezoneOffsetRelativeToUtc,
            },
            resourceGroupId
          );
          await this.actions.setJobTotalStatics.dispatch(
            jobData.totalJobStatusCounts || []
          );
          await this.actions.setStatics.dispatch({
            job: jobData.totalJobCount,
          });
          await this.actions.setJobsDailyStatics.dispatch(
            jobData.dailyJobCountDTOs || []
          );
        },
        packageStatistics: async (resourceGroupId: string) => {
          return await this.dependencies.serviceClient.dashboard.packageStatistics(
            resourceGroupId
          );
        },
        workflowStatistics: async (resourceGroupId: string) => {
          return await this.dependencies.serviceClient.dashboard.workflowStatistics(
            resourceGroupId
          );
        },
        queueStatistics: async (resourceGroupId: string) => {
          return await this.dependencies.serviceClient.dashboard.queueStatistics(
            resourceGroupId
          );
        },
      };
    }
  }
);
