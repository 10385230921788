import { RobotScope, Workflow } from "@encoo-web/encoo-lib/types";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { ROBOT_SCOPE_MAP } from "src/store/models/ui/robot/robot";

export const WorkflowModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        executionWorkflow: {} as Workflow,
        scope: ROBOT_SCOPE_MAP.RESOURCEGROUP as RobotScope,
      };
    }
    public selectors() {
      return {
        isGlobal: createSelector(
          () => this.state.scope,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
      };
    }

    public reducers() {
      return {
        setExecutionWorkflow: (value: Workflow) => {
          this.state.executionWorkflow = value;
        },
        setRobotScope: (value: RobotScope) => {
          this.state.scope = value;
        },
      };
    }
  }
);
