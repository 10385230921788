export default {
  role: {
    name: "ロール名",
    introduction:
      "ロール管理はさまざまなロールを作成するために使用され、各ロールのアクセス許可と操作許可は実際のニーズに応じて構成できます。",
    entityTypeName: "ロール",
    search: "権限名/メニュー名",
    permission: "権限",
    createRole: "ロールを作成",
    editRole: "ロールを編集",
  },
};
