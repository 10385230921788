import {
  PagedAsyncIterableIterator,
  PageOptions,
} from "@encoo-web/encoo-lib/types/http/paging";
import _ from "lodash";
import {
  createModel,
  createSelector,
  GetContainer,
  mergeModels,
  mergeSubModels,
} from "nyax";
import { createItemsEntityModel } from "src/store/itemsEntity";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";

export const createEntityModel = function <TEntity extends object>(
  ...[getItemId]: TEntity extends { id: string }
    ? [((item: TEntity) => string)?]
    : [(item: TEntity) => string]
) {
  if (!getItemId) {
    getItemId = (item) => (item as { id: string }).id;
  }

  return createModel(
    class extends createItemsEntityModel<TEntity>(getItemId) {
      public selectors() {
        return {
          ...super.selectors(),
        };
      }
    }
  );
};

export const createHelperModel = function <TEntity extends object>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  refreshList?: (getContainer: GetContainer) => Promise<void>;
  getItemId?: (entity: TEntity) => string;
}) {
  const { setItems, getItems, getItem, refreshList } = payload;
  let { getItemId } = payload;
  if (!getItemId) {
    getItemId = (entity: TEntity) => (entity as { id: string }).id;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const internalGetItemId = getItemId!;

  return class BaseHelperModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: internalGetItemId,
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
      _rwByParentId: createItemsReadWriteModel(),
    })
  ) {
    protected _readByParentIds = async (payload: {
      parentIds: string[];
      getAllAction: (parentId: string) => Promise<TEntity[]>;
      getEntityParentId: (entity: TEntity) => string;
      force?: boolean;
    }) => {
      const { parentIds, getAllAction, getEntityParentId, force } = payload;

      const {
        ids: beginReadIds,
        timestamp,
      } = await this.actions._rwByParentId.beginRead.dispatch({
        ids: parentIds,
        force,
      });

      try {
        if (beginReadIds.length > 0) {
          const items = _.flatten(
            await Promise.all(
              beginReadIds.map((parentId) => getAllAction(parentId))
            )
          );
          const {
            ids: endReadIds,
          } = await this.actions._rwByParentId.endRead.dispatch({
            items: beginReadIds,
            timestamp,
          });

          if (endReadIds.length > 0) {
            const endGroupIdSet = new Set(endReadIds);

            await this.actions._rw.endRead.dispatch({
              items: [
                ...getItems(this.getContainer)
                  .filter((item) => endGroupIdSet.has(getEntityParentId(item)))
                  .map(internalGetItemId),
                ...items,
              ],
              timestamp,
            });
          }
        }
        const parentIdSet = new Set(parentIds);
        return getItems(this.getContainer).filter((item) =>
          parentIdSet.has(getEntityParentId(item))
        );
      } catch (error) {
        await this.actions._rwByParentId.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _readById = async (payload: {
      id: string;
      getByIdAction: () => Promise<TEntity>;
      force?: boolean;
    }) => {
      const { id, getByIdAction, force } = payload;
      const {
        ids: beginReadIds,
        timestamp,
      } = await this.actions._rw.beginRead.dispatch({
        ids: [id],
        force,
      });

      try {
        if (beginReadIds.length > 0) {
          const item = await getByIdAction();

          await this.actions._rw.endRead.dispatch({
            items: [item],
            timestamp,
          });
        }

        return getItem(this.getContainer, id);
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _create = async (payload: {
      createAction: () => Promise<TEntity>;
    }) => {
      const { createAction } = payload;
      const item = await createAction();
      await this.actions._rw.endRead.dispatch({
        items: [item],
        timestamp: Date.now(),
      });
      await refreshList?.(this.getContainer);
      return item;
    };

    protected _update = async (payload: {
      id: string;
      updateAction: () => Promise<TEntity>;
    }) => {
      const { id, updateAction } = payload;
      const { timestamp } = await this.actions._rw.beginWrite.dispatch({
        ids: [id],
      });

      try {
        const item = await updateAction();
        await this.actions._rw.endWrite.dispatch({
          items: [item],
          timestamp,
        });
        return item;
      } catch (error) {
        await this.actions._rw.endWrite.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };

    protected _delete = async (payload: {
      id: string;
      deleteAction: (id: string) => Promise<boolean>;
    }) => {
      const { id, deleteAction } = payload;
      const { timestamp } = await this.actions._rw.beginWrite.dispatch({
        ids: [id],
      });
      try {
        await deleteAction(id);
        await this.actions._rw.endWrite.dispatch({
          items: [id],
          timestamp,
        });
      } catch (error) {
        await this.actions._rw.endWrite.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    };
  };
};

const PAGE_SIZE = 20;
const LIMITED_SIZE = 100;
export const createListModel = function <TEntity extends object>(payload: {
  setItems: (
    context: GetContainer,
    items: (string | TEntity)[]
  ) => Promise<void>;
  getItems: (getContainer: GetContainer) => TEntity[];
  getItem: (getContainer: GetContainer, id: string) => TEntity | undefined;
  getItemId: (entity: TEntity) => string;
}) {
  const { setItems, getItems, getItem, getItemId } = payload;
  return class BaseListModel extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<TEntity>({
        getItemId: (item) => getItemId(item),
        setItems: ({ getContainer }, items) => setItems(getContainer, items),
      }),
    })
  ) {
    private _initialAction?: () => Promise<
      PagedAsyncIterableIterator<TEntity, TEntity[], PageOptions>
    >;

    protected async _saveEntity(items: TEntity[], force?: boolean) {
      const {
        ids: beginReadIds,
        timestamp,
      } = await this.actions._rw.beginRead.dispatch({
        ids: items.map((item) => getItemId(item)),
        force,
      });

      try {
        if (beginReadIds.length > 0) {
          await this.actions._rw.endRead.dispatch({
            items: items,
            timestamp,
          });
        }
      } catch (error) {
        await this.actions._rw.endRead.dispatch({
          items: [],
          timestamp,
        });
        throw error;
      }
    }

    public initialState() {
      return {
        ...super.initialState(),
        currentPageNumber: 0,
        maxPageNumber: -1,
        pageNumberFromServer: 0,
        iterator: null as AsyncIterableIterator<TEntity[]> | null,
        dataIndexs: [] as string[],
        isLoading: false,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),

        setCurrentPageNumber: (pageNumber: number) => {
          this.state.currentPageNumber = pageNumber;
        },
        setIterator: (iterator: AsyncIterableIterator<TEntity[]>) => {
          this.state.iterator = iterator;
        },
        setMaxPageNumber: (pageNumber: number) => {
          this.state.maxPageNumber = pageNumber;
        },
        setDataIndexs: (indexs: string[]) => {
          this.state.dataIndexs = indexs;
        },
        setPageNumberFromServer: (pageNumber: number) => {
          this.state.pageNumberFromServer = pageNumber;
        },
        setIsLoading: (isLoading: boolean) => {
          this.state.isLoading = isLoading;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        dataSource: createSelector(
          () => this.state.dataIndexs,
          () => getItems(this.getContainer),
          (dataIndexs, items) => {
            const pageData: TEntity[] = [];
            dataIndexs.forEach((id) => {
              const entity = getItem(this.getContainer, id);
              if (entity) {
                pageData.push(entity);
              }
            });

            return pageData;
          }
        ),
        pageSize: () => PAGE_SIZE,
        hasNext: () => this.state.maxPageNumber === -1,
      };
    }

    protected async _initialIterator(payload: {
      initialAction: () => Promise<
        PagedAsyncIterableIterator<TEntity, TEntity[], PageOptions>
      >;
      force?: boolean;
    }) {
      const { initialAction, force } = payload;

      await this.actions.setCurrentPageNumber.dispatch(0);
      await this.actions.setMaxPageNumber.dispatch(-1);
      await this.actions.setPageNumberFromServer.dispatch(0);
      await this.actions.setDataIndexs.dispatch([]);

      const list = await initialAction();

      const iterator = list.byPage({
        limit: LIMITED_SIZE,
      });

      this._initialAction = initialAction;

      await this.actions.setIterator.dispatch(iterator);
      await this.actions.loadNext.dispatch(force);
      await this.actions.setCurrentPageNumber.dispatch(1);
    }

    public effects() {
      return {
        ...super.effects(),

        refresh: async () => {
          if (this._initialAction && this.state.iterator) {
            this._initialIterator({
              initialAction: this._initialAction,
              force: true,
            });
          }
        },

        changePage: async (page: number) => {
          if (
            this.state.maxPageNumber !== -1 &&
            page > this.state.maxPageNumber
          ) {
            return;
          }

          if (page >= this.state.pageNumberFromServer - 2) {
            await this.actions.loadNext.dispatch(true);
          }

          this.actions.setCurrentPageNumber.dispatch(page);
        },

        loadNext: async (force?: boolean) => {
          if (
            (this.state.maxPageNumber !== -1 &&
              this.state.pageNumberFromServer >= this.state.maxPageNumber) ||
            this.state.isLoading
          ) {
            return;
          }

          if (!this.state.iterator) {
            throw new Error(
              "iterator is null.Please use 'initialIterator' first."
            );
          }

          await this.actions.setIsLoading.dispatch(true);

          try {
            const result = await this.state.iterator.next();
            const entites: TEntity[] | undefined = result.value;

            if (!entites) {
              await this.actions.setMaxPageNumber.dispatch(
                this.state.pageNumberFromServer
              );
              return;
            }

            const dataIndexs = [
              ...this.state.dataIndexs,
              ...entites.map((item) => getItemId(item)),
            ];

            await this.actions.setDataIndexs.dispatch(dataIndexs);
            await this._saveEntity(entites, force);
            const pageNumberFromServer =
              this.state.pageNumberFromServer +
              Math.ceil(entites.length / PAGE_SIZE);
            if (entites.length < LIMITED_SIZE) {
              await this.actions.setMaxPageNumber.dispatch(
                pageNumberFromServer
              );
            }
            await this.actions.setPageNumberFromServer.dispatch(
              pageNumberFromServer
            );
          } finally {
            await this.actions.setIsLoading.dispatch(false);
          }
        },
      };
    }
  };
};
