import { Robot } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const RobotListModel = createModel(
  class extends createListModel<Robot>({
    setItems: (getContainer, items) =>
      getContainer(RobotEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RobotEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RobotEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          isGlobal?: boolean;
          resourceGroupId: string;
          force?: boolean;
        }) => {
          const { isGlobal = false, resourceGroupId, force = true } = payload;
          this._initialIterator({
            initialAction: () => {
              if (isGlobal) {
                return this.dependencies.serviceClient.robot.listGlobal(
                  resourceGroupId
                );
              }
              return this.dependencies.serviceClient.robot.list(
                resourceGroupId
              );
            },
            force: force,
          });
        },
      };
    }
  }
);
