export default {
  understanding: {
    introduction:
      "文書理解は、主にさまざまなドキュメント抽出テンプレートの構成と管理をカスタマイズでき、大量のドキュメント情報のインテリジェントな抽出を実現し、PDF、画像、スキャンなどのフォマードをサポートします",
    operation: {
      save: "テンプレートを保存",
      publish: "テンプレートを公開",
      cancelPublish: "公開をキャンセル",
      test: "テスト",
      continueTest: "テストを継続",
      edit: "編集",
      upload: "ローカルアップロード",
    },

    message: {
      save: "保存に成功しました",
      publish: "公開に成功しました",
      cancel: "キャンセルに成功しました",
      templateNameExisted: "テンプレート名がすでに存在しています",
      createFail: "作成に失敗しました",
    },
    status: {
      publish: "公開済み",
      cancel: "未公開",
    },
    uploadModel: {
      title: "テストファイルをアップロード",
      description: "PDF　サイズが4Mより小さいにする必要があります",
    },
    createModal: {
      title: "モデルを作成",
    },
    field: {
      name: "テンプレート名",
      status: "テンプレートステータス",
      type: "テンプレートタイプ",
      labelCount: "ラベルの数",
      model: "ビルトインモデル",
    },
    confirmation: {
      publish: "{name}テンプレートを公開しますか？",
      cancel: "{name}テンプレートの公開をキャンセルしますか",
      unpublish: "{name}テンプレートの公開をキャンセルしますか？",
    },
    designer: {
      header: {
        size: "オリジナルサイズ",
        width: "幅：{width}px",
        height: "高さ：:{height}px",
        ratio: "スケーリング比:{ratio}%",
      },
      tools: {
        editLabelTip: "認識領域をフレーム選択",
        zoomInTip: "ズームイン",
        zoomOutTip: "ズームアウト",
        dragCanvasTip: "キャンバスをドラッグ",
      },
      labels: {
        number: "番号",
        name: "ラベル名",
        fieldType: "フィールドタイプ",
        reference: "参照ポイント",
        referenceContent: "参照ポイント",
        referenceDirection: "方向",
        analysisContent: "認識の内容",
        nameMessage: "ラベル名を入力してください",
        fieldTypeMessage: "フィールドタイプを選択してください",
        referenceMessage: "参照ポイントを入力してください",
        referenceDirectionMessage: "方向を選択してください",
        referenceTip: "参照ポイントのテキストを入力し、参照ポインがラベルに対する方向を選択してください",
      },
      direction: {
        top: "上",
        bottom: "下",
        left: "左",
        right: "右",
      },
      fieldDataType: {
        text: "string",
      },
      documentType: {
        pdfText: "PDF抽出モデル",
        pdfImage: "OCR抽出モデル",
      },
      list: {
        anchors: "ファイルアンカーを選択",
        labels: "抽出ラベルをフレーム選択",
      },
      description: {
        explainLabel: "説明",
        ruleLabel: "ルールを設定",
        label: {
          explain: "ボックス抽出ラベルは、主にテキストを抽出する必要がある領域を決定するために使用されます",
          rule1: "フレーム選択により抽出する領域を決定します",
          rule2: "ラベル名、フィールドタイプ、行を設定できます",
          rule3: "ラベルの位置づけを補助するためにラベル参照ポインの設定をサポートします。",
          rule4: "フレーム選択領域は可能な限り正確である必要があります",
        },
        anchor: {
          explain: "ファイルのアンカーを選択は、主に斜めの画像とスキャンされたファイルに使用されます",
          rule1: "選択の方式でアンカーテキスト領域を決めます",
          rule2: "アンカーは変更しないテキストである必要があります",
          rule3: "アンカーが多ければ多いほどいい、4以上にすることをお勧めします。",
        },
      },
    },
    errorTips: {
      fileRequired: "ファイルをアップロードしてください",
    },
  },
};
