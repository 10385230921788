import antdEnUS from "antd/es/locale/en_US";
import { LocaleConfig } from "src/locales";
import enUsMessages from "src/locales/enUS.messages";
import { flattenMessages } from "src/utils/locale";

const enUS: LocaleConfig = {
  locale: "en-US",
  messages: flattenMessages(enUsMessages),
  antdLocale: antdEnUS,
};

export default enUS;
