// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function handleErrorNotFound(error?: any, callback?: () => void) {
  if (error?.response) {
    const { status } = error?.response;
    if (status === 404) {
      error.ishandled = true;
      callback && callback();
    }
  }
}
