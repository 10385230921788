export default {
  sku: {
    community: {
      normal: "Community Edition {name}",
    },
    enterprise: {
      normal: "Enterprise Edition {name}",
      basic: "Enterprise Basic Edition {name}",
      senior: "Enterprise Advanced Edition {name}",
    },
  },
  validate: {
    phone: "Please fill in your mobile phone number correctly.",
    fax: "Please fill in your fax number correctly.",
    email: "Please fill in your email address correctly.",
    notEmpty: "Entries cannot be empty.",
    image: "Entries cannot be empty.",
    video: "Entries cannot be empty.",
    guid: "Entries are not GUIDs.",
    illegalChar: "Illegal characters are not allowed.",
    stringLength: "{name} must be between {min}-{max} characters.",
  },
  common: {
    help: "Help",
    noData: "No data available.",
    required: "Entries cannot be empty.",
    goHome: "Home",
    emailValidation: "Please enter the correct email address.",
    selectAll: "Select All",
    robot: "Robot",
    today: "Today",
    currentMonth: "Current Month",
    editor: "Studio",
    copy: "Copy",
    placeholder: "Please enter",
    alertTitle: "Prompt",
    updataTime: "Update Time",
    unBind: "Unbind",
    bound: "Bound",
    nobound: "Unbound",
    unBound: "Not Unbind",
    packageName: "Flow Package Name",
    create: "New",
    multiControl: "Batch Operation",
    operateOk: "Confirm",
    tag: "Label",
    delete: "Delete",
    remove: "Remove",
    rename: "Rename",
    operation: "Operation",
    upload: "Upload",
    resourceMove: "Resource Group Migration",
    processDeployment: "Flow Deployment",
    status: "Status",
    note: "Remarks",
    view: "View",
    log: "Log",
    loading: "Loading",
    phone: "Phone",
    download: "Download",
    upgrade: "Upgrade",
    cancel: "Cancel",
    edit: "Edit",
    complete: "Completed",
    type: "Type",
    createTime: "Creation Time",
    description: "Remarks",
    creationTime: "Creation Time",
    createdBy: "Founder",
    close: "Close",
    refresh: "Refresh",
    confirm: "OK",
    save: "Save",
    pleaseInput: "Please enter",
    pleaseSelect: "Please select",
    robotName: "Robot Name",
    deleteConfirmation: "Are you sure you want to delete?",
    contact: "Contact {attrType}",
    name: "Name",
    baseConfig: "Basic Config",
    bindRobot: "Associated Robots",
    add: "New",
    nextStep: "Next",
    preStep: "Back",
    createSuccess: "Added.",
    createFailed: "Adding failed",
    uploadSuccess: "Uploaded.",
    config: " Config Info",
    bindWorkflow: "Associated Flow Deployment",
    empty: "N/A",
    home: "Home",
    yes: "Yes",
    no: "No",
    number: "No.",
    uploadFile: "Upload File",
    update: "Change",
    operateSuccess: "Operation succeeded.",
    info: {
      createEntity: "Create {entityTypeName}",
      editEntity: "Change {entityTypeName}",
      createSuccessfully: "{entityTypeName} {name} created.",
      createFailed: "Creating {entityTypeName} {name} failed.",
      removeConfirmation: "Are you sure you want to remove {entityTypeName} {name}?",
      deletionConfirmation: "Are you sure you want to delete {entityTypeName} {name}?",
      deletionFailed: "Deleting {entityTypeName} {name} failed.",
      deleteSuccessfully: "{entityTypeName} {name} deleted.",
      updateSuccessfully: "{entityTypeName} {name} updated.",
      updateFailed: "Updating {entityTypeName} {name} failed.",
      bindSuccessfully: "Binding {name} to {entityTypeName} succeeded.",
      batchBindSuccessfully: "Binding succeeded",
      unbindSuccessfully: "Unbinding {name} from {entityTypeName} succeeded.",
      batchUnbindSuccessfully: "Unbinding succeeded",
      bindFailed: "Binding {name} to {entityTypeName} failed.",
      unbindFailed: "Unbinding {name} from {entityTypeName} failed.",
    },
    updateSuccessfully: "Changed.",
    updateFailed: "Change failed.",
    pleaseSelectTime: "Please select a time.",
    startTime: "Start Time",
    endTime: "End Time",
    baseInfo: "Basic Info",
    prePage: "Previous Page",
    nextPage: "Next Page",
    clickHere: "Click here",
    time: {
      day: " Day",
      hour: " Hour",
    },
    versionNumber: "Version Number",
    uploadComponent: {
      localUpload: "Local Upload",
      supportExtensions: "Supported Extensions",
      import: "Import",
    },
    pcs: " Count",
    goBack: "Return",
  },
  app: {
    name: "ENCOO CONSOLE",
  },
  environment: {
    uniqueCode: "Machine code",
  },
  week: {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
  },
  cron: {
    every: "Per",
    atTime: "-",
    minuteExecute: "Execute per minute",
    everyhourExecuteOnce: "Execute hourly",
    everyDay: "Everyday",
    execute: "Execute",
    everyMonthExecuteOnce: "Execute monthly",
    day: "Day",
    enterCron: "Please enter Cron expression.",
    clickToGenerateCron: "Click to generate expressions online.",
  },
  errors: {
    noPermission: {
      title: "Sorry",
      description: "Since you have not activated the permission, you cannot perform the operation.",
    },
    notFound: {
      title: "Sorry",
      description: "The page you are visiting does not exist.",
      button: "Home",
      request: "Requested resource does not exist.",
    },
    disabled: {
      title: "Sorry",
      description: "Your account has been deactivated by the current tenant. Please contact the administrator to enable access.",
    },
    login: {
      ssoErrorTip: "The current client time or server time is incorrect. Please check the time.",
    },
  },
  upgrade: {
    title: "Upgrade Alert",
    description: "We have recently upgraded the console, including:",
    tip1: "Upgrading and improving some enterprise-level features;",
    tip2: "Fixed the disconnect issue of the robot under Windows 7/Windows Server 2008 (please download the latest Robot installation package to fix it)",
  },
  userEdition: {
    community: "Community Edition",
    enterprise: "Enterprise Edition",
  },
};
