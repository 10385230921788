export default {
  role: {
    name: "角色名称",
    introduction:
      "角色管理用于新建各类角色，可根据实际需要配置各角色访问及操作权限",
    entityTypeName: "角色",
    search: "权限名称/菜单名称",
    permission: "权限",
    createRole: "创建角色",
    editRole: "编辑角色",
  },
};
