export default {
    securityConfig: {
        menu: "Multi-Factor Authentication Configuration",
        isOpenSecurityValidation: "Enable Multi-factor Authentication",
        securityValidationSubtext: "When it is enabled, secondary authentication will be performed to ensure system security after the user logs into the system with the account password. One of the following authentication methods will be used.",
        instructions: "Notes",
        securityType: {
            type1: {
                text: "Phone verification code authentication",
                subText: "A login verification code will be sent to the user's bound mobile phone.",
            },
            type2: {
                text: "Email verification code authentication",
                subText: "A login verification code will be sent to the user's bound email.",
            },
            type3: {
                text: "Virtual MFA authentication",
                subText: "It needs to be configured by the user in the Personal Center - Security Settings. After completing the configuration, the user can log in with TOTP verification code.",
            }
        },
        validationScope: {
            title: "Scope of Authentication",
            allUsers: "All Users",
            pecifyUser: "Specify User",
        }
    }
}