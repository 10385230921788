import {
  DashboardQueryData,
  QueueAssociatedCount,
  QueueBusyTimeRank,
  QueueExecutionDetail,
  QueueFailureProportionRank,
  QueueJobStateCount,
  QueueSuccessProportionRank,
  QueueWaitTimeRank,
} from "@encoo-web/encoo-lib";
import moment from "moment";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const todayString = moment(new Date()).format("YYYY-MM-DD");

export const QueueStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        queueAssociatedCount: null as null | QueueAssociatedCount,
        queueExecutionDetail: null as null | QueueExecutionDetail,
        queueJobStateCount: null as null | QueueJobStateCount,
        queueAssociatedCountByToday: null as null | QueueAssociatedCount,
        queueExecutionDetailByToday: null as null | QueueExecutionDetail,
        queueJobStateCountByToday: null as null | QueueJobStateCount,
        queueSuccessProportionRank: null as null | QueueSuccessProportionRank[],
        queueFailureProportionRank: null as null | QueueFailureProportionRank[],
        queueBusyTimeRank: null as null | QueueBusyTimeRank[],
        queueWaitTimeRank: null as null | QueueWaitTimeRank[],
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setQueueAssociatedCount: (value: null | QueueAssociatedCount) => {
          this.state.queueAssociatedCount = value;
        },
        setQueueExecutionDetail: (value: null | QueueExecutionDetail) => {
          this.state.queueExecutionDetail = value;
        },
        setQueueJobStateCount: (value: null | QueueJobStateCount) => {
          this.state.queueJobStateCount = value;
        },
        setQueueAssociatedCountByToday: (
          value: null | QueueAssociatedCount
        ) => {
          this.state.queueAssociatedCountByToday = value;
        },
        setQueueExecutionDetailByToday: (
          value: null | QueueExecutionDetail
        ) => {
          this.state.queueExecutionDetailByToday = value;
        },
        setQueueJobStateCountByToday: (value: null | QueueJobStateCount) => {
          this.state.queueJobStateCountByToday = value;
        },
        setQueueSuccessProportionRank: (
          value: null | QueueSuccessProportionRank[]
        ) => {
          this.state.queueSuccessProportionRank = value;
        },
        setQueueFailureProportionRank: (
          value: null | QueueFailureProportionRank[]
        ) => {
          this.state.queueFailureProportionRank = value;
        },
        setQueueBusyTimeRank: (value: null | QueueBusyTimeRank[]) => {
          this.state.queueBusyTimeRank = value;
        },
        setQueueWaitTimeRank: (value: null | QueueWaitTimeRank[]) => {
          this.state.queueWaitTimeRank = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          this.actions.requestQueueAssociatedCount.dispatch(payload);
          this.actions.requestQueueExecutionDetail.dispatch(payload);
          this.actions.requestQueueJobStateCount.dispatch(payload);
          this.actions.requestQueueSuccessProportionRank.dispatch(payload);
          this.actions.requestQueueFailureProportionRank.dispatch(payload);
          this.actions.requestQueueBusyTimeRank.dispatch(payload);
          this.actions.requestQueueWaitTimeRank.dispatch(payload);
        },
        requestQueueAssociatedCount: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueAssociatedCount(
            resourceGroupId,
            {
              timeZone: query.timeZone,
              endDate: todayString,
            }
          );
          const todayValue = await this.dependencies.serviceClient.dashboard.queueAssociatedCount(
            resourceGroupId,
            {
              timeZone: query.timeZone,
              startDate: todayString,
              endDate: todayString,
            }
          );
          this.actions.setQueueAssociatedCount.dispatch(value);
          this.actions.setQueueAssociatedCountByToday.dispatch(todayValue);
        },
        requestQueueExecutionDetail: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueExecutionDetail(
            resourceGroupId,
            query
          );
          const todayValue = await this.dependencies.serviceClient.dashboard.queueExecutionDetail(
            resourceGroupId,
            {
              ...query,
              startDate: todayString,
              endDate: todayString,
            }
          );
          this.actions.setQueueExecutionDetail.dispatch(value);
          this.actions.setQueueExecutionDetailByToday.dispatch(todayValue);
        },
        requestQueueJobStateCount: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueJobStateCount(
            resourceGroupId,
            query
          );
          const todayValue = await this.dependencies.serviceClient.dashboard.queueJobStateCount(
            resourceGroupId,
            {
              ...query,
              startDate: todayString,
              endDate: todayString,
            }
          );
          this.actions.setQueueJobStateCount.dispatch(value);
          this.actions.setQueueJobStateCountByToday.dispatch(todayValue);
        },
        requestQueueSuccessProportionRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueSuccessProportionRank(
            resourceGroupId,
            query
          );
          this.actions.setQueueSuccessProportionRank.dispatch(value);
        },
        requestQueueFailureProportionRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueFailureProportionRank(
            resourceGroupId,
            query
          );
          this.actions.setQueueFailureProportionRank.dispatch(value);
        },
        requestQueueBusyTimeRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueBusyTimeRank(
            resourceGroupId,
            query
          );
          this.actions.setQueueBusyTimeRank.dispatch(value);
        },
        requestQueueWaitTimeRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          const value = await this.dependencies.serviceClient.dashboard.queueWaitTimeRank(
            resourceGroupId,
            query
          );
          this.actions.setQueueWaitTimeRank.dispatch(value);
        },
      };
    }
  }
);
