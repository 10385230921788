export default {
  auditlog: {
    introduction:
      "このページは、コンソールの各ユーザーの詳細な操作記録を表示するために使用され、監査と追跡に便利です。",
    createdAt: "操作時間",
    auditObjectTypeDisplayName: "詳細",
    auditObjectType: "モジュール",
    userDisplayName: "ユーザー",
    startTime: "開始時間",
    endTime: "終了時間",
  },
};
