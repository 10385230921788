export default {
    securityConfig: {
        menu: "多重身份验证配置",
        isOpenSecurityValidation: "是否开启多重身份验证",
        securityValidationSubtext: "开启后将会在用户通过账户密码登录系统后进行二次验证以保证系统安全，验证方式为下列方式中的一种。",
        instructions: "说明",
        securityType: {
            type1: {
                text: "手机验证码验证",
                subText: "会向用户所绑定手机号发送登录验证码。",
            },
            type2: {
                text: "邮箱验证码验证",
                subText: "会向用户所绑定邮箱发送登录验证码。",
            },
            type3: {
                text: "虚拟MFA验证",
                subText: "需要用户在个人中心-安全设置中配置,完成配置后可通过TOTP验证码登录。",
            }
        },
        validationScope: {
            title: "验证范围",
            allUsers: "全体用户",
            pecifyUser: "指定用户",
        }
    }
}