import { Job, UpdateJobMessage } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { JobEntityModel } from "src/store/models/entity/job/entity";
import { JobListByQueueModel } from "src/store/models/entity/job/listByQueue";
import { createHelperModel } from "src/store/models/entity/_shared";

export const JobHelperModel = createModel(
  class extends createHelperModel<Job>({
    setItems: (getContainer, items) =>
      getContainer(JobEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(JobEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(JobListByQueueModel).actions.refresh.dispatch({}),
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: {
          resourceGroupIds: Array<string>;
          force?: boolean;
        }) => {
          const { resourceGroupIds, force } = payload;
          return await this._readByParentIds({
            parentIds: resourceGroupIds,
            getAllAction: (resourceGroupId) => {
              return this.dependencies.serviceClient.job.getAll(
                resourceGroupId
              );
            },
            getEntityParentId: (entity: Job) => entity.resourceGroupId,
            force,
          });
        },
        readByWorkflowIds: async (payload: {
          resourceGroupId: string;
          workflowIds: Array<string>;
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowIds, force } = payload;
          return await this._readByParentIds({
            parentIds: workflowIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.workflow.getAllJobs(
                resourceGroupId,
                parentId
              );
            },
            getEntityParentId: (entity: Job) => entity.workflowId,
            force,
          });
        },
        readByQueueIds: async (payload: {
          resourceGroupId: string;
          queueIds: Array<string>;
          force?: boolean;
        }) => {
          const { resourceGroupId, queueIds, force } = payload;
          return await this._readByParentIds({
            parentIds: queueIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.workflow.getJobsByQueue(
                resourceGroupId,
                parentId
              );
            },
            getEntityParentId: (entity: Job) => entity.containingQueueId,
            force,
          });
        },
        readById: async (payload: {
          resourceGroupId: string;
          id: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, id, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.job.getById(
                resourceGroupId,
                id
              );
            },
            force,
          });
        },
        update: async (payload: {
          resourceGroupId: string;
          jobId: string;
          message?: UpdateJobMessage;
          priority?: number;
        }) => {
          const { resourceGroupId, jobId, message, priority } = payload;
          await this.dependencies.serviceClient.job.updateJob(
            resourceGroupId,
            jobId,
            { message, priority }
          );
        },
        updateStops: async (payload: {
          resourceGroupId: string;
          jobIds: Array<string>;
        }) => {
          const { resourceGroupId, jobIds } = payload;
          return await this.dependencies.serviceClient.job.stopJobs(
            resourceGroupId,
            jobIds
          );
        },
        executeJobs: async (payload: {
          resourceGroupId: string;
          jobIds: Array<string>;
        }) => {
          const { resourceGroupId, jobIds } = payload;
          return await this.dependencies.serviceClient.job.executeJobs(
            resourceGroupId,
            jobIds
          );
        },
        batchUpdateJobs: async (payload: {
          resourceGroupId: string;
          priority: string;
          jobIds: Array<string>;
        }) => {
          const { resourceGroupId, jobIds, priority } = payload;
          return await this.dependencies.serviceClient.job.batchUpdateJobs(
            resourceGroupId,
            priority,
            jobIds
          );
        },
      };
    }
  }
);
