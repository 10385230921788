import { Robot, RobotScope } from "@encoo-web/encoo-lib/types";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { LicenseUIModel } from "src/store/models/ui/license/license";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";

type DrawerType = "detail" | "edit";

export type Tab = "setting";

export enum ROBOT_SCOPE_MAP {
  RESOURCEGROUP = "ResourceGroup",
  TENANT = "Tenant",
}

export const RobotUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(RobotEntityModel);
    }
    private get _resourceEntityContainer() {
      return this.getContainer(ResourceGroupModel);
    }
    private get _licenseContainer() {
      return this.getContainer(LicenseUIModel);
    }
    public initialState() {
      return {
        robotId: "",
        type: "detail" as DrawerType,
        isDetail: false,
        isSelectDisabled: true,
        selectedIds: [] as string[],
        keywordName: "",
        keywordTag: "",
        selectedStatus: [] as string[],
        scope: ROBOT_SCOPE_MAP.RESOURCEGROUP as RobotScope,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setRobotId: (value: string) => {
          this.state.robotId = value;
        },
        setMultiSelect: (value: boolean) => {
          this.state.isSelectDisabled = value;
        },
        setMultiSelectIds: (value: string[]) => {
          this.state.selectedIds = value;
        },
        setType: (value: DrawerType) => {
          this.state.type = value;
        },
        setDetail: (value: boolean) => {
          this.state.isDetail = value;
        },
        setKeywordName: (value: string) => {
          this.state.keywordName = value;
        },
        setKeywordTag: (value: string) => {
          this.state.keywordTag = value;
        },
        setSelectedStatus: (value: string[]) => {
          this.state.selectedStatus = value;
        },
        setRobotScope: (value: RobotScope) => {
          this.state.scope = value;
        },
      };
    }

    public effects() {
      return {
        initialState: async () => {
          await this.actions.setDetail.dispatch(false);
        },
      };
    }
    public selectors() {
      return {
        displayData: createSelector(
          () => this._entityContainer.getters.items,
          () => this._resourceEntityContainer.state.selectedResourceGroupId,
          () => this.state.keywordName,
          () => this.state.keywordTag,
          () => this.state.selectedStatus,
          () => this.state.scope,
          (items, groupId, keywordName, keywordTag, selectedStatus, scope) => {
            let filterData = items;
            filterData = filterData.filter((item: Robot) => {
              if (groupId && item.resourceGroupId !== groupId) return false;
              if (!keywordName && !keywordTag && !selectedStatus.length)
                return true;
              if (item.tags && item.tags.join("&").indexOf(keywordTag) < 0)
                return false;
              if (item.scope !== this.state.scope) return false;
              if (item.name.indexOf(keywordName) < 0) return false;
              if (
                selectedStatus.some((status) => {
                  return status !== item.status;
                })
              )
                return false;
              return true;
            });
            return filterData;
          }
        ),
        isGlobal: createSelector(
          () => this.state.scope,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
      };
    }
  }
);
