export default {
  queue: {
    queue: "队列",
    name: "队列名称",
    introduction:
      "调度队列主要用于绑定机器人集群，同时将触发到队列中的任务动态分配给空闲机器人进行运行。",
    tasksPerformed: "进行任务数",
    remainingTasks: "剩余任务数",
    robotCount: "机器人数",
    workflowCount: "流程关联数",
    view: "查看任务",
    setting: "基础设置",
    bindRobot: "添加机器人",
    deleteTip: "删除后队列中的剩余任务均会终止",
  },
};
