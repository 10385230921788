/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React from "react";
import { ThemeContext } from "src/contexts";
import { useTheme, useThemeSet } from "src/hooks";

const AppAsideInternal = React.memo(function AppAsideInternal(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const theme = useTheme();

  return (
    <aside
      css={css`
        display: flex;
        margin-right: 10px;
        border-right: 1px solid ${theme.bodyFrameShadowColor};
      `}
    >
      {children}
    </aside>
  );
});

export default React.memo(function AppAside(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.main}>
      <AppAsideInternal>{children}</AppAsideInternal>
    </ThemeContext.Provider>
  );
});
