import {
  LicenseBinding,
  LicenseCountersData,
  LicenseStatistics,
  ProductNameOfLicenseCounter,
} from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { LicenseBindingsEntityModel } from "src/store/models/entity/licenseBindings/entity";
import { LicenseBindingsHelperModel } from "src/store/models/entity/licenseBindings/helper";
import { formatDate } from "src/utils/string";

export type QuotalType = {
  sku: string;
  name: string;
  count: number;
};
//sku 对应的license 列表
export type BindLicense = Record<string, Array<LicenseBinding>>;

export const LicenseUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(LicenseBindingsHelperModel);
    }
    private get _entityContainer() {
      return this.getContainer(LicenseBindingsEntityModel);
    }
    public initialState() {
      return {
        activateLicenseVisible: false,
        robotQuota: [] as Array<QuotalType>,
        studioQuota: [] as Array<QuotalType>,
        robotBindLicense: {} as BindLicense,
        studioBindLicense: {} as BindLicense,
        dateRange: "",
        licenseStatistics: {} as LicenseStatistics,
        licenseCounterData: [] as LicenseCountersData[],
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setLicenseStatistics: (statistics: LicenseStatistics) => {
          this.state.licenseStatistics = statistics;
        },
        setDateRange: (dateRange: string) => {
          this.state.dateRange = dateRange;
        },
        setActivateLicenseVisible: (value: boolean) => {
          this.state.activateLicenseVisible = value;
        },
        setRobotQuota: (value: Array<QuotalType>) => {
          this.state.robotQuota = value;
        },
        setStudioQuota: (value: Array<QuotalType>) => {
          this.state.studioQuota = value;
        },
        setRobotBindLicense: (value: BindLicense) => {
          this.state.robotBindLicense = {
            ...this.state.robotBindLicense,
            ...value,
          };
        },
        setStudioBindLicense: (value: BindLicense) => {
          this.state.studioBindLicense = {
            ...this.state.studioBindLicense,
            ...value,
          };
        },
        setLicenseCounterData: (value: LicenseCountersData[]) => {
          this.state.licenseCounterData = value;
        },
      };
    }
    public effects() {
      return {
        initializeRequest: async (playLoad: {
          resourceGroupId: string;
          type: ProductNameOfLicenseCounter;
        }) => {
          const { resourceGroupId, type } = playLoad;
          await this.actions.getlicenseInfo.dispatch("");
          await this.actions.getLicenseStatistics.dispatch(resourceGroupId);
          await this.actions.getLicensecounters.dispatch(type);
        },
        getLicenseStatistics: async (resourceGroupId: string) => {
          const statistics = await this.dependencies.serviceClient.dashboard.licenseStatistics(
            resourceGroupId
          );
          this.actions.setLicenseStatistics.dispatch(statistics);
        },
        getSkuLicenseList: async (playLoad: {
          sku: string;
          product: string;
        }) => {
          const { sku, product } = playLoad;
          const list = await this._helperContainer.actions.getAllLicenseBindings.dispatch(
            {
              productName: product,
              sku: sku,
            }
          );
          const data = { [sku]: list };

          if (product === "Robot") {
            this.actions.setRobotBindLicense.dispatch(data);
          } else if (product === "Studio") {
            this.actions.setStudioBindLicense.dispatch(data);
          }
        },
        getlicenseInfo: async (resourceGroupId: string) => {
          const license = await this._helperContainer.actions.licenseBindings.dispatch(
            resourceGroupId
          );
          if (license[0]) {
            await this.actions.setDateRange.dispatch(
              "".concat(
                formatDate(license[0].validFrom),
                " - ",
                formatDate(license[0].validTo)
              )
            );
            await this.actions.setRobotQuota.dispatch(
              license[0]?.quota.filter((item) => item.name === "Robot") ?? []
            );
            await this.actions.setStudioQuota.dispatch(
              license[0]?.quota.filter((item) => item.name === "Studio") ?? []
            );
          } else {
            await this.actions.setDateRange.dispatch("");
            await this.actions.setRobotQuota.dispatch([]);
            await this.actions.setStudioQuota.dispatch([]);
          }
        },
        getLicensecounters: async (type: ProductNameOfLicenseCounter) => {
          const data = await this._helperContainer.actions.getLicensecounters.dispatch(
            type
          );
          await this.actions.setLicenseCounterData.dispatch(data);
        },
      };
    }
  }
);
