export default {
  robot: {
    entityTypeName: "机器人",
    robotName: "机器人名称",
    introduction:
      "机器人管理主要用于创建并管理专用机器人的连接，同时可对机器人的许可状态进行管理。",
    resourceGroupIntroduction:
      "资源组机器人主要用于创建并管理资源组专用机器人的连接，同时可对资源组机器人的许可状态进行管理。",
    sharedIntroduction:
      "共享机器人主要用于创建并管理租户共享机器人的连接，同时可对共享的机器人许可状态进行管理。",
    lastTime: "上次通讯时间",
    addRobot: "添加机器人",
    connectStatusName: "连接状态",
    licenseStatusName: "许可状态",
    licenseStatus: {
      unlicensed: "未许可",
      serverLicensed: "已许可",
    },
    status: {
      all: "全部状态",
      free: "空闲",
      busy: "忙碌",
      noResponse: "无响应",
      break: "中断",
      connect: "连接",
      unLicensed: "未许可",
    },
    consoleManagement: {
      managed: "已托管",
      unManaged: "未托管",
    },
    consoleManagementName: "托管状态",
    resource: {
      title: "群组迁移",
      current: "当前资源组",
      target: "迁移至资源组",
    },
    detail: {
      title: "机器人管理详情",
      bindUser: "已绑定域名/用户名",
      name: "机器人名称",
      status: "机器人状态",
      code: "机器人连接字符串",
      tag: "机器人标签",
      remark: "机器人备注",
      type: "机器人类型",
      time: "机器人上次通讯时间",
      workflow: "关联流程部署",
      queue: "关联调度队列",
    },
    validate: {
      name: "机器人名称",
      status: "机器人状态",
      code: "机器人连接字符串",
    },
    placeholder: {
      tags: "请输入标签按回车确认",
      type: "用户机器人",
    },
    info: {
      bindConfirm: "是否为{name}绑定{entityTypeName}?",
      unbindConfirm: "是否要移除{name}{entityTypeName}?",
    },
    tips: {
      copy: "复制成功",
      create: {
        code: "机器人连接字符串",
        content: "机器人创建成功，请复制激活连接字符串用于机器人客户端激活",
        mark: "可在机器人详情页中查看机器人连接字符串",
      },
      unBind:
        "解绑成功后，该客户端机器人将无法执行流程。你确定要解绑机器人吗？",
      bindDelete: "存在绑定的许可证不可删除！",
      bindFail: {
        title: "解绑失败",
        content: "机器人有等待执行和正在执行的任务",
      },
    },
    scope: {
      resourceGroup: "资源组机器人",
      shared: "共享机器人",
    },
    listName: "{name}名称",
    robotScope: "机器人级别",
    tab: {
      setting: "配置",
      report: "报表",
    },
    config: "基本信息配置",
  },
};
