import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";

export const AppsUIModel = createModel(
  class extends ModelBase {
    private get _helperContainer() {
      return this.getContainer(AppHelperModel);
    }
    private get _entityContainer() {
      return this.getContainer(AppEntityModel);
    }
    private get _resourceGroupContainer() {
      return this.getContainer(ResourceGroupModel);
    }
    public initialState() {
      return {
        selectedAppIds: [] as string[],
      };
    }
    public reducers() {
      return {
        setSelectedAppIds: (selectedAppIds: string[]) => {
          this.state.selectedAppIds = selectedAppIds;
        },
      };
    }
    public selectors() {
      return {
        apps: createSelector(
          () => this._entityContainer.getters.items,
          () => this._resourceGroupContainer.state.selectedResourceGroupId,
          (items, resourceGroupId) =>
            items.filter(
              (item) =>
                item.resourceGroupId === resourceGroupId && item.lastVersion
            )
        ),
      };
    }

    public effects() {
      return {
        initializeRequest: async (resourceGroupId: string) => {
          await this._helperContainer.actions.readByGroupIds.dispatch({
            groupIds: [resourceGroupId],
            force: true,
          });
        },
      };
    }
  }
);
