export default {
  file: {
    introduction:
      "文件服务主要用于存储并管理各类文件，提供对应文件的下载服务，您可以自由的新建多层文件夹以进行文件管理",
    newFolder: "新建文件夹",
    newSubFolder: "新建子文件夹",
    fileSize: "文件大小",
    sameDirectoryName: "文件夹名称不能相同",
    uploadFile: "上传文件",
    hasSpecialCharacter:
      '文件名不能包含以下任何字符: \\ / . : * ? " < > | # + & = 并且以 . 开头',
    preparing: "准备中",
    uploading: "上传中",
    compoining: "合并中",
    allCompleted: "全部上传成功！",
    partCompleted: "部分上传成功！",
    completed: "上传成功",
    allFailed: "全部上传失败！",
    failed: "上传失败",
    sameFileNameExist: "有重名文件，是否覆盖？",
    sameFileNameUploading: "有重名名件正在上传，请稍后再试。",
    setPermissions: "权限管理",
    permissionManagement: "请选择",
    addUser: "添加用户",
    permission: {
      admin: "管理权限",
      readOnly: "可读权限",
      writeOnly: "可写权限",
      noPermission: "无权限",
    },
    creator: "创建者",
    specifyUser: "指定用户",
    other: "其他",
    others: "其他用户",
    othersTip: "当前租户下的其他用户",
    placeholder: {
      enterNameOrEmail: "请输入姓名或邮箱",
    },
    otherEmptyTip: "暂无指定用户，请添加",
    addUserTip: {
      warining: "请给所有添加用户设置权限",
    },
    add: "添加",
    checkAll: "全选",
    fileTip: {
      folderEmpty: "暂无文件夹，请先新建",
      fileEmpty: "暂无文件，请上传",
    },
    fileIntroduce: {
      title1: "什么是文件服务",
      p1:
        "文件服务主要用于存储并管理各类文件，同时提供预订文件的下载服务。您可以自由的新建多层文件夹，并在各层文件夹中自由管理文件",
      title2: "有哪些操作",
      operation: {
        createFolder: "新建文件夹",
        uploadFile: "上传文件",
        downloadFile: "下载文件",
      },
    },
  },
};
