import { JobEntityModel } from "src/store/models/entity/job/entity";
import { JobHelperModel } from "src/store/models/entity/job/helper";
import { JobListModel } from "src/store/models/entity/job/list";
import { JobListByQueueModel } from "src/store/models/entity/job/listByQueue";
import { JobListByWorkFlowModel } from "src/store/models/entity/job/listByWorkFlow";

export const encooJobModels = {
  entity: JobEntityModel,
  helper: JobHelperModel,
  list: JobListModel,
  listByQueue: JobListByQueueModel,
  lisyByWorkflow: JobListByWorkFlowModel,
};
