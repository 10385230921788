export default {
  userCenter: {
    userInfo: "ユーザー情報",
    settings: "設定",
    name: "名前",
    phone: "電話",
    email: "メール",
    binding: "バインド",
    replace: "置き換え",
    unbind: "バインド解除",
    setDefaultTenant: "デフォルトテナントを設定",
    setDefaultResource: "デフォルトリソースグループを設定",
    setDefaultTimeZone: "デフォルトタイムゾーンを設定",
    setDefaultLanguage: "デフォルトの言語を設定",
    updateName: "名前を変更",
    currentName: "現在の名前",
    currentPhone: "現在の電話番号",
    currentEmail: "現在のメール",
    placeholder: {
      inputName: "新しい名前を入力してください",
      inputBindingEmail: "バインディングのメールアドレスを入力してください。",
      inputEmailCode: "メールの確認コードを入力してください",
      inputOriginalPassword: "元のパスワードを入力してください。",
      inputNewPassword:
          "新しいパスワードを入力してください（6-16文字の新しいパスワードを入力してください。スペースなし、大文字と小文字を区別します。）",
      inputNewPasswordAgain: "もう一度新しいパスワードを入力してください。",
      inputNewEmail: "新しいメールアドレスを入力してください。",
      inputNewPhone: "新しい携帯電話の番号を入力してください。",
      inputPhoneCode: "SMSの確認コードを入力してください",
      passwordNotMatch: "2回入力したパスワードが一致しません",
      nameLimited: "名前には最大35文字を入力してください",
    },
    unbindEmailTips: "メール{email}のバインドを解除しますか?",
    unbindPhoneTips: "携帯電話番号{phoneNumber}のバインドを解除しますか?",
    bindEmail: "メールをバインド",
    updatePassword: "パスワードを変更",
    updatePhone: "電話番号を変更",
    updateEmail: "メールを変更",
    getVerificationCode: "確認コードを取得",
    tenantName: "テナント内の名前",
  },
};
