
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const LoadingUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isShowLoading: false,
      };
    }
    public reducers() {
      return {
        setVisabled: (value: boolean) => {
          this.state.isShowLoading = value;
        },
      };
    }
    public effects() {
      return {
        registerLoading: async () => {
          this.dependencies.httpMiddleware.requestQueue.ActionRequestStart(() => {
            if (!this.state.isShowLoading)
              this.actions.setVisabled.dispatch(true);
          });

          this.dependencies.httpMiddleware.requestQueue.ActionRequestEnd((request, allLoaded) => {
            if (allLoaded) this.actions.setVisabled.dispatch(false);
          });         
        },
      };
    }
  }
);
