import {
  RoleAssignment,
  RoleAssignmentDeletionData,
} from "@encoo-web/encoo-lib/types";
import { createModel, mergeModels, mergeSubModels } from "nyax";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";
import { RoleAssignmentEntityModel } from "src/store/models/entity/roleAssignment/entity";

export const RoleAssignmentHelperModel = createModel(
  class extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<RoleAssignment>({
        getItemId: (item) => item.userId,
        setItems: ({ getContainer }, items) =>
          getContainer(RoleAssignmentEntityModel).actions.setItems.dispatch(
            items
          ),
      }),
      _rwByGroupId: createItemsReadWriteModel(),
    })
  ) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RoleAssignmentEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),

        getById: async (payload: { entityId: string }) => {
          const { entityId } = payload;
          await this.dependencies.serviceClient.roleAssignment.getById(
            entityId
          );
        },
        assignUserRoleScope: async (payload: RoleAssignment) => {
          await this.dependencies.serviceClient.roleAssignment.assignUserRoleScope(
            payload
          );
        },
        removeAssignUserRoleScope: async (
          payload: RoleAssignmentDeletionData
        ) => {
          await this.dependencies.serviceClient.roleAssignment.removeUserInRoleScope(
            payload
          );
        },
      };
    }
  }
);
