import { createModel } from "nyax";
import { EncooFileUploadCallbackInfo } from "src/models/file";
import { ModelBase } from "src/store/ModelBase";

export const UploadingUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        uploadingFileRecord: {} as Record<string, EncooFileUploadCallbackInfo>,
        isCollapsed: false,
      };
    }

    public reducers() {
      return {
        setUploadingFileRecord: (
          record: Record<string, EncooFileUploadCallbackInfo>
        ) => {
          this.state.uploadingFileRecord = record;
        },
        setIsCollapsed: (isCollapsed: boolean) => {
          this.state.isCollapsed = isCollapsed;
        },
      };
    }

    public effects() {
      return {
        updateUploadCallbackInfo: async (info: EncooFileUploadCallbackInfo) => {
          const newRecord = {
            ...this.state.uploadingFileRecord,
          };
          newRecord[info.fullName] = info;
          await this.actions.setUploadingFileRecord.dispatch(newRecord);
        },
        clear: async () => {
          await this.actions.setUploadingFileRecord.dispatch({});
        },
      };
    }
  }
);
