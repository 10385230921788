export default {
  file: {
    introduction:
      "ファイルサービスは、主にあらゆる種類のファイルの保存と管理に使用され、対応するファイルのダウンロードサービスを提供します。ファイル管理のために多層フォルダを自由に作成できます。",
    newFolder: "新規フォルダー",
    newSubFolder: "新規サブフォルダー",
    fileSize: "フォルダーサイズ",
    sameDirectoryName: "フォルダー名は同じにすることはできません",
    uploadFile: "ファイルをアップロード",
    hasSpecialCharacter:
      'ファイル名には、次の文字を含めることはできません：\\ / . : * ? " < > |',
    preparing: "準備中",
    uploading: "アップロード中",
    compoining: "マージ中",
    allCompleted: "すべてのファイルがアップロード成功しました！",
    partCompleted: "一部のファイルがアップロード成功しました！",
    completed: "アップロードが成功しました",
    allFailed: "すべてのファイルがアップロード失敗しました！",
    failed: "アップロードに失敗しました",
    sameFileNameExist: "同じ名前のファイルが存在していますが、上書きしますか？",
    sameFileNameUploading: "同じ名前のファイルがアップロードされています。しばらくしてからもう一度お試しください",
    setPermissions: "権限管理",
    permissionManagement: "選択してください",
    addUser: "ユーザーを追加",
    permission: {
      admin: "管理権限",
      readOnly: "読み取り権限",
      writeOnly: "書き込み権限",
      noPermission: "権限なし",
    },
    creator: "作成者",
    specifyUser: "ユーザーを指定",
    other: "その他",
    others: "他のユーザー",
    othersTip: "今のテナント下の他のユーザー",
    placeholder: {
      enterNameOrEmail: "名前またはメールを入力してください",
    },
    otherEmptyTip: "指定ユーザーなし、追加してください",
    addUserTip: {
      warining: "追加されたすべてのユーザーの権限を設定してください",
    },
    add: "追加",
    checkAll: "すべてを選択",
    fileTip: {
      folderEmpty: "フォルダがありません。新しいフォルダを作成してください",
      fileEmpty: "ファイルがありません。アップロードしてください",
    },
    fileIntroduce: {
      title1: "ファイルサービスとは",
      p1:
        "ファイルサービスは、主にあらゆる種類のファイルの保存と管理に使用され、対応するファイルのダウンロードサービスを提供します。ファイル管理のために多層フォルダを自由に作成できます。",
      title2: "どんな操作がある",
      operation: {
        createFolder: "フォルダーを作成",
        uploadFile: "ファイルをアップロード",
        downloadFile: "ファイルをダウンロード",
      },
    },
  },
};
