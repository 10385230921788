import { UserEdition } from "@encoo-web/encoo-lib";
import { useCallback, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { DependenciesContext, ThemeContext } from "src/contexts";
import { EnvironmentEdition } from "src/models/environment";
import ApplicationInsightsService from "src/services/ApplicationInsightsService";
import { AppModel } from "src/store/models/app";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export function useDependencies() {
  return useContext(DependenciesContext);
}

export function useGetContainer() {
  return useDependencies().redux.getContainer;
}

export function useThemeSet() {
  return useDependencies().theme.themeSet;
}

export function useTheme() {
  return useContext(ThemeContext);
}

export function useApplicationInsights(): ApplicationInsightsService {
  return useDependencies().applicationInsights;
}

export function useResourceGroup() {
  const resourceGroup = useGetContainer()(ResourceGroupModel);
  const selectedResourceGroupId = useSelector(
    () => resourceGroup.state.selectedResourceGroupId
  );

  return { selectedResourceGroupId };
}

export function useTenant() {
  const loginUser = useGetContainer()(LoginUserUIModel);
  const selectedTenantId = useSelector(
    () => loginUser.state.selectedUserTenantId
  );
  return { selectedTenantId };
}

export function useRole(roleKeys: string[]) {
  const app = useGetContainer()(AppModel);
  const permissions = useSelector(() => app.state.permissions);

  const roles = useMemo(
    () => roleKeys.map((key): boolean => permissions?.includes(key) ?? false),
    [permissions, roleKeys]
  );

  return roles;
}

export function useEnvironmentEdition(): EnvironmentEdition | null {
  const environmentContainer = useGetContainer()(EnvironmentModel);
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );
  return environmentEdition;
}

export function useUserEdition(): UserEdition | null {
  const loginUser = useGetContainer()(LoginUserUIModel);
  const loggedinUser = useSelector(() => loginUser.state.loggedinUser);
  return loggedinUser?.edition ?? null;
}

export function useFormatMessage() {
  const intl = useIntl();
  return useCallback(
    (messageId: string, values?: Record<string, string | number>) => {
      return intl.formatMessage(
        {
          id: messageId,
          defaultMessage: messageId,
        },
        values
      );
    },
    [intl]
  );
}
