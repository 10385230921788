import { PackageVersion } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const PackageVersionEntityModel = createModel(
  class extends createItemsEntityModel<PackageVersion>((item) => item.id) {
    public initialState() {
      return {
        ...super.initialState(),
      };
    }
    public selectors() {
      return {
        ...super.selectors(),
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
      };
    }
  }
);
