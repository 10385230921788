import {
  Constants,
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib/";
import {
  Template,
  TemplateCreationData,
  TemplateFile,
  UnderstandingResult,
} from "src/models/understanding";
export const UNDERSTANDING_SERVICE_NAME = "understanding";

export class UnderstandingSeviceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, UNDERSTANDING_SERVICE_NAME);
  }

  async list(resourceGroupId: string, namePrefix?: string) {
    const req = this.buildRequest({
      url: "/v1/Templates",
      method: "GET",
      resourceGroupId,
      query: {
        NamePrefix: namePrefix,
      },
    });
    return await super.listInternally<Template>(req);
  }

  async getById(
    resourceGroupId: string,
    templateId: string
  ): Promise<Template> {
    const req = this.buildRequest({
      url: `/v1/Templates/${templateId}`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getByIdInternal(req);
  }

  async create(
    resourceGroupId: string,
    payload: TemplateCreationData
  ): Promise<Template> {
    const req = this.buildRequest({
      url: `/v1/Templates`,
      method: "POST",
      payload,
      resourceGroupId,  
    });
    return await super.createInternal(req);
  }

  async update(
    resourceGroupId: string,
    template: Partial<Template> & Pick<Template, "id">
  ): Promise<Template> {
    const req = this.buildRequest({
      url: `/v1/Templates/${template.id}`,
      method: "PUT",
      payload: template,
      resourceGroupId,
    });
    if (template.eTag) {
      req.headers?.set(Constants.HeaderConstants.IF_MATCH, template.eTag);
    }
    return await super.updateInternal(req);
  }

  async patch(resourceGroupId: string, id: string): Promise<TemplateFile> {
    const req = this.buildRequest({
      url: `/v1/Templates/${id}`,
      method: "PATCH",
      payload: { RecognizePolicy: 1 },
      resourceGroupId,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body;
      }
      return undefined;
    });
  }

  async publish(
    resourceGroupId: string,
    id: string,
    isPublished: boolean
  ): Promise<Template> {
    const req = this.buildRequest({
      url: `/v1/Templates/${id}/isPublished`,
      method: "PUT",
      payload: {
        value: isPublished,
      },
      resourceGroupId,
    });

    return await super.updateInternal(req);
  }

  async delete(resourceGroupId: string, templateId: string): Promise<boolean> {
    const req = this.buildRequest({
      url: `/v1/Templates/${templateId}`,
      method: "DELETE",
      resourceGroupId,
    });
    return await super.deleteInternal(req);
  }

  async getTemplateFile(
    resourceGroupId: string,
    id: string
  ): Promise<TemplateFile> {
    const req = this.buildRequest({
      url: `/v1/Templates/${id}/File`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getByIdInternal(req);
  }

  async getTemplateImage(
    resourceGroupId: string,
    id: string,
    pageIndex: number
  ): Promise<string> {
    const req = this.buildRequest({
      url: `/v1/Templates/${id}/File/images/${pageIndex}`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getByIdInternal(req);
  }

  async createUnderstandingResult(
    resourceGroupId: string,
    templateId: string
  ): Promise<UnderstandingResult> {
    const req = this.buildRequest({
      url: `/v1/UnderstandingResults`,
      method: "POST",
      payload: {
        templateId,
      },
      resourceGroupId,
    });
    return await super.createInternal(req);
  }

  async getUnderstandingResultById(
    resourceGroupId: string,
    id: string
  ): Promise<UnderstandingResult> {
    const req = this.buildRequest({
      url: `/v1/UnderstandingResults/${id}`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getByIdInternal(req);
  }

  async patchUnderstandingResult(
    resourceGroupId: string,
    id: string
  ): Promise<UnderstandingResult> {
    const req = this.buildRequest({
      url: `/v1/UnderstandingResults/${id}`,
      method: "PATCH",
      payload: {
        recognizePolicy: 1,
      },
      resourceGroupId,
    });
    return await this.sendRequest(req).then((res) => {
      if (res.status === 200) {
        return res.body;
      }
      return undefined;
    });
  }

  async getUnderstandingResultImage(
    resourceGroupId: string,
    id: string,
    pageIndex: number
  ): Promise<string> {
    const req = this.buildRequest({
      url: `/v1/UnderstandingResults/${id}/images/${pageIndex}`,
      method: "GET",
      resourceGroupId,
    });
    return await super.getByIdInternal(req);
  }
}
