export default {
  apps: {
    name: "云扩ViCode名称",
    introduction:
      "云扩ViCode管理主要用于浏览待审核的云扩ViCode，对云扩ViCode进行统一上架或下架操作，支持云扩ViCode版本管理及详情查看。",
    workbench: "云扩ViCode",
    currentVersion: "当前启用版本",
    status: {
      all: "全部状态",
      enabled: "启用",
      disabled: "停用",
    },
    currentStatus: {
      enabled: "已启用",
      disabled: "已停用",
    },
    version: "版本号",
    changeVersion: "切换此版本",
    uploadDateTime: "上传时间",
    creater: "开发者",
    device: "云扩ViCode范围",
    mobile: "移动端",
    pc: "电脑端",
    try: "试用",
    moreVersion: "查看更多版本",
    workflowName: "流程部署名称",
    packageVersion: "流程包版本号",
    appVersionsTitle: "版本列表",
    currentSign: "当前",
    upgradeTip: "此版本不是最新版本，可点击升级至{lastAvailableVersion}版本",
    upgradeConfirm: "确定升级至{lastAvailableVersion}版本?",
    upgradeBatchConfirm: "确定升级?",
    updateAppCurrentVersionConfirm: "是否确认将当前云扩ViCode切换到此版本?",
    deleteAppVersionMessage:
      "该版本为当前使用版本，无法删除。请切换版本后再执行删除操作",
  },
};
