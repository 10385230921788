export default {
  job: {
    lastExecuteTime: "Last Execution Time",
    terminate: "Cancel",
    execute: "Execute",
    message: "Select the number of flow as 0",
    messagePriority: "The priority of the tasks whose status is pending can be adjusted in batch.",
    messageExecute: "The tasks whose status is success, failed, and canceled can be executed in batch.",
    messageTerminate: "The tasks whose status is pending, assigned, and running can be canceled in batch.",
    introduction: "The task record page displays the execution status of all process tasks under the current resource group and supports viewing process execution and log details.",
    jobNumber: "Task Number",
    executableTarget: "Execution Target",
    targetName: "Target Name",
    view: "View Details",
    status: {
      queued: "Waiting",
      allocated: "Assigned",
      running: "Running",
      failed: "Failed",
      succeeded: "Succeeded",
      cancelled: "Cancel",
      cancelling: "Canceling...",
    },
    executeOrder: "Task Priority",
    deployAssignment: "Flow Deployment Assignment",
    executionAssignment: "Flow Execution Assignment",
    adjustPriority: "Adjust Priority",
    operateErrorMessage: "Prompt",
    tipAll: "Batch processed",
    tipAllTask: "tasks in total",
    tipSuccess: "Success  ",
    tipSuccessUnit: "  Count",
    tipFail: "Failed  ",
    tipMessage: "The failure information is as follows:",
    priorityCount: "Priority Value",
    defaultCount: "Task priority defaults to 2000",
    updatePriorityCountTip: "0 is the lowest task priority and 5000 is the highest. The higher the priority is, the sooner the task will be executed.",
    priorityValidateTips: "Please enter a number between 0-5000",
    lastRobotName: "Latest Execution Robot",
  },
};
