export default {
  menu: {
    dashboard: {
      name: "仪表盘",
      overallMonitor: { name: "总体监控" },
      userFlowStatistics: { name: "用户流程统计表" },
      robotRunStatistics: { name: "机器人运行统计表" },
      robotStatusStatistics: { name: "机器人监控" },
      queueStatistics: { name: "队列监控" },
    },
    rpa: {
      name: "RPA管理",
      package: { name: "流程包管理" },
      workflow: { name: "流程部署" },
      robot: { name: "机器人管理" },
      queue: { name: "调度队列" },
      log: { name: "任务记录" },
    },
    apps: {
      name: "云扩ViCode管理",
    },
    understanding: {
      name: "文档理解",
      editor: {
        name: "编辑模板",
      },
      tester: {
        name: "测试结果",
      },
    },
    data: {
      name: "数据中心",
      asset: { name: "资产管理" },
      file: { name: "文件服务" },
      dataTable: { name: "数据表管理" },
    },
    setting: {
      name: "全局管理",
      user: { name: "用户信息" },
      role: { name: "角色管理" },
      resourcegroup: { name: "资源组管理" },
      auditlog: { name: "审计日志" },
      license: { name: "许可证" },
      userCenter: { name: "个人中心" },
      systemSetting: { name: "系统设置" },
    },
  },
  home: {
    welcomePanel: {
      title: "欢迎使用云扩RPA控制台",
      lastLandingTime: "上次登录时间",
      currentTenant: "当前租户",
      enterprise: "企业版",
      community: "社区版",
      applyForEnterprise: "申请企业版许可证",
    },
    navigation: {
      title: "常用导航",
      dashboard: "仪表盘",
      uploadPackage: "上传流程包",
      processDeployment: "流程部署",
      robotManagement: "机器人管理",
      performLog: "任务记录",
    },
    service: {
      title: "更多服务",
      documentationUnderstand: "文档理解",
      appManagement: "云扩ViCode管理",
      dataCenter: "数据中心",
    },
    practicalLink: {
      title: "实用链接",
      consoleDocument: "云扩文档",
      consoleCourse: "云扩课程",
      consoleBBS: "云扩论坛",
    },
    packageDownload: {
      title: "安装包下载",
      name: "应用名称",
      editName: "云扩RPA编辑器",
      robotName: "云扩RPA机器人",
      releaseTime: "发布时间",
      version: "版本号",
      download: "下载",
    },
    serviceAddress: {
      title: "服务地址",
      address: " 编辑器连接字符串",
      reminder:
        "(此字符串用于编辑器登录控制台激活使用，请点击按钮进行字符串复制)",
      signScuess: "正在登录中...",
    },
  },
};
