export default {
  workflow: {
    title: "プロセス展開",
    name: "プロセス展開名",
    introduction:
        "プロセス展開は、主にプロセス実行のターゲット、パラメーター、再試行、ビデオ録画などを設定するために使用され、手動、タスクプランなどによるプロセスのトリガーをサポートします。",
    packageName: "プロセスパッケージ名",
    version: "バージョン",
    trigger: "タスクプラン",
    jobList: "タスクリスト",
    config: "設定",
    baseConfigInfo: "基本情報を設定",
    packageVersion: "パッケージのバージョン",
    parameterInfo: "パラメータ情報",
    performanceTarget: "実行ターゲット",
    associateQueue: "関連するキュー",
    associateRobot: "関連するロボット",
    saveAndSetTrigger: "保存してスケジュールを設定",
    execute: "実行",
    workflowExecute: "プロセスの実行",
    tryMaxCount: "失敗した最大試行回数",
    tryMaxCountTip:
        "ヒント：プロセスタスクの実行に失敗した場合、システムは設定値に従って自動的に試行します。デフォルトは3回、設定できる範囲は0〜10回です",
    enableArgumentsReAssignment: "引数の再割り当てを有効にする",
    executeManually: "手動で実行",
    createSuccess: "プロセス展開の作成が成功しました",
    bindRobotError: "ロボットバインドに失敗しました",
    importAsset: "資産をインポート",
    selectAsset: "資産を選択",
    isEnableVideoRecord: "ビデオ録画を有効にするか",
    alwaysReport: "常にレポート",
    neverReport: "レポートしない",
    onlyWhenSucceededReport: "成功時のみレポート",
    onlyWhenFailedReport: "失敗時のみレポート",
    videoRecordTip: "ヒント：タスクログの詳細ページでビデオ再生を見ることができます",
    videoReportCondition: "レポート条件",
    priority: "タスク優先度",
    priorityTip:
        "ヒント：タスクの優先度は最低が0で、最高が5000で、優先度が高ければ高いほど優先に実行されます",
  },
};
