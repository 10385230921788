import { Queue, QueueBasicData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { QueueListModel } from "src/store/models/entity/queue/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const QueueHelperModel = createModel(
  class extends createHelperModel<Queue>({
    setItems: (getContainer, items) =>
      getContainer(QueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(QueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(QueueEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(QueueListModel).actions.refresh.dispatch({}),
  }) {
    public effects() {
      return {
        ...super.effects(),

        readByGroupIds: async (payload: {
          resourceGroupIds: string[];
          force?: boolean;
        }) => {
          const { resourceGroupIds } = payload;
          return await this._readByParentIds({
            parentIds: resourceGroupIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.queue.getAll(parentId);
            },
            getEntityParentId: (entity: Queue) => entity.resourceGroupId,
            force: true,
          });
        },

        getById: async (payload: {
          resourceGroupId: string;
          queueId: string;
        }) => {
          const { resourceGroupId, queueId } = payload;
          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [queueId],
          });

          try {
            const item = await this.dependencies.serviceClient.queue.getById(
              resourceGroupId,
              queueId
            );
            await this.actions._rw.endWrite.dispatch({
              items: [item],
              timestamp,
            });
            return item;
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },

        bindRobots: async (payload: {
          resourceGroupId: string;
          queueId: string;
          robotIds: string[];
        }) => {
          const { resourceGroupId, queueId, robotIds } = payload;
          await this.dependencies.serviceClient.queue.bindRobot(
            resourceGroupId,
            queueId,
            {
              robotIds,
            }
          );
        },

        create: async (payload: {
          resourceGroupId: string;
          queueBasicData: QueueBasicData;
          selectedIds: string[];
        }) => {
          const { resourceGroupId, queueBasicData, selectedIds } = payload;
          return await this._create({
            createAction: async () => {
              const item = await this.dependencies.serviceClient.queue.create(
                resourceGroupId,
                queueBasicData
              );
              await this.actions.bindRobots.dispatch({
                resourceGroupId,
                queueId: item.id,
                robotIds: selectedIds,
              });

              selectedIds.forEach((robotId) => {
                this.dependencies.applicationInsights.sendTrackEvent(
                  { name: "Console_BindRobotToQueueEvent" },
                  {
                    QueueID: item.id,
                    RobotID: robotId,
                  }
                );
              });

              // TODO:接口字段暂无robot
              return item;
            },
          });
        },

        getBindingRobots: async (payload: {
          queueId: string;
          resourceGroupId: string;
        }) => {
          const { queueId, resourceGroupId } = payload;
          return await this.dependencies.serviceClient.queue.getAllBindingInfos(
            resourceGroupId,
            queueId
          );
        },

        update: async (payload: {
          queueId: string;
          resourceGroupId: string;
          queueBasicData: QueueBasicData;
        }) => {
          const { queueId, resourceGroupId, queueBasicData } = payload;
          return await this._update({
            id: queueId,
            updateAction: () =>
              this.dependencies.serviceClient.queue.update(
                resourceGroupId,
                queueId,
                queueBasicData
              ),
          });
        },

        delete: async (payload: { id: string; resourceGroupId: string }) => {
          const { id, resourceGroupId } = payload;
          return await this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.queue.delete(resourceGroupId, id),
          });
        },
        unbindRobot: async (payload: {
          resourceGroupId: string;
          queueId: string;
          robotId: string;
        }) => {
          const { resourceGroupId, queueId, robotId } = payload;
          await this.dependencies.serviceClient.queue.unbindRobot(
            resourceGroupId,
            queueId,
            robotId
          );
        },
      };
    }
  }
);
