import { encooAppsModels } from "src/store/models/entity/apps";
import { encooAppVersionModels } from "src/store/models/entity/appVersion";
import { encooAssetModels } from "src/store/models/entity/asset";
import { encooAuditlogModels } from "src/store/models/entity/auditlog";
import { encooDataTableModels } from "src/store/models/entity/dataTable";
import { encooFileModels } from "src/store/models/entity/file";
import { encooInstallationModels } from "src/store/models/entity/installation";
import { encooJobModels } from "src/store/models/entity/job";
import { encooLicenseModels } from "src/store/models/entity/license";
import { encooLicenseBindings } from "src/store/models/entity/licenseBindings";
import { encooPackageModels } from "src/store/models/entity/package";
import { encooPackageVersionModels } from "src/store/models/entity/packageVersion";
import { encooPermissionModels } from "src/store/models/entity/permission";
import { encooQueueModels } from "src/store/models/entity/queue";
import { encooResourceGroupModels } from "src/store/models/entity/resourceGroup";
import { encooRobotModels } from "src/store/models/entity/robot";
import { encooRoleModels } from "src/store/models/entity/role";
import { encooRoleAssignmentModels } from "src/store/models/entity/roleAssignment";
import { encooRunInstanceModels } from "src/store/models/entity/runInstance";
import { encooRunInstanceLogModels } from "src/store/models/entity/runinstanceLog";
import { encooTriggerModels } from "src/store/models/entity/trigger";
import { encooTriggerLogModels } from "src/store/models/entity/triggerLog";
import { encooUnderstandingResultModels } from "src/store/models/entity/understandingResult";
import { encooUnderstandingTemplateModels } from "src/store/models/entity/understandingTemplate";
import { encooUserModels } from "src/store/models/entity/user";
import { encooWorkflowModels } from "src/store/models/entity/workflow";

export const entityModels = {
  auditlog: encooAuditlogModels,
  resourceGroup: encooResourceGroupModels,
  user: encooUserModels,
  robot: encooRobotModels,
  package: encooPackageModels,
  packageVersion: encooPackageVersionModels,
  workflow: encooWorkflowModels,
  trigger: encooTriggerModels,
  triggerLog: encooTriggerLogModels,
  queue: encooQueueModels,
  role: encooRoleModels,
  roleAssignment: encooRoleAssignmentModels,
  app: encooAppsModels,
  appVersion: encooAppVersionModels,
  permission: encooPermissionModels,
  job: encooJobModels,
  license: encooLicenseBindings,
  runInstance: encooRunInstanceModels,
  understandingTemplate: encooUnderstandingTemplateModels,
  understandingResult: encooUnderstandingResultModels,
  installation: encooInstallationModels,
  asset: encooAssetModels,
  runInstanceLog: encooRunInstanceLogModels,
  file: encooFileModels,
  dataTable: encooDataTableModels,
  licenseList: encooLicenseModels,
};
