import { DataTable } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { DataTableEntityModel } from "src/store/models/entity/dataTable/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const DataTableListModel = createModel(
  class extends createListModel<DataTable>({
    setItems: (getContainer, items) =>
      getContainer(DataTableEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataTableEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataTableEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { force?: boolean }) => {
          const { force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.dataTable.list(),
            force: force,
          });
        },
      };
    }
  }
);
