/** @jsx jsx */
import { LoadingOutlined } from "@ant-design/icons";
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Progress, Spin } from "antd";
import React, { Fragment, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import Text from "src/components/Text";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { EncooFileUploadCallbackInfo } from "src/models/file";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";
import { cssTextTruncate } from "src/styles/text";
import { formatByte } from "src/utils/string";

type FileUploadingSummaryState =
  | "Uploading"
  | "AllCompleted"
  | "PartCompleted"
  | "Failed";

const fileUploadingSummaryStateMessages: Record<
  FileUploadingSummaryState,
  string
> = {
  Uploading: messageIds.file.uploading,
  AllCompleted: messageIds.file.allCompleted,
  PartCompleted: messageIds.file.partCompleted,
  Failed: messageIds.file.allFailed,
};

interface FileUploadingHeaderProps {
  uploadingList: EncooFileUploadCallbackInfo[];
}

const FileUploadingHeader = React.memo(function FileUploadingHeader(
  props: FileUploadingHeaderProps
) {
  const { uploadingList } = props;
  const theme = useTheme();
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();

  const container = getContainer(UploadingUIModel);

  const storedIsCollapsed = useSelector(() => container.state.isCollapsed);

  const toggleCollapsed = useCallback(() => {
    container.actions.setIsCollapsed.dispatch(!storedIsCollapsed);
  }, [container.actions.setIsCollapsed, storedIsCollapsed]);

  const onClose = useCallback(() => {
    container.actions.clear.dispatch({});
  }, [container.actions.clear]);

  const state: FileUploadingSummaryState = useMemo(() => {
    const uploading = uploadingList.filter(
      (info) =>
        info.state === "Uploading" ||
        info.state === "Preparing" ||
        info.state === "Compoining"
    );
    if (uploading.length > 0) {
      return "Uploading";
    }

    const completeted = uploadingList.filter(
      (info) => info.state === "Completed"
    );

    if (completeted.length > 0 && completeted.length === uploadingList.length) {
      return "AllCompleted";
    } else if (completeted.length > 0) {
      return "PartCompleted";
    } else {
      return "Failed";
    }
  }, [uploadingList]);

  const completedCount = useMemo(
    () => uploadingList.filter((item) => item.state === "Completed").length,
    [uploadingList]
  );

  const stateText = useMemo(() => {
    return `${formatMessage(
      fileUploadingSummaryStateMessages[state]
    )}(${completedCount} / ${uploadingList.length})`;
  }, [completedCount, formatMessage, state, uploadingList.length]);

  const percent = useMemo(
    () =>
      Math.round(
        (uploadingList.reduce((total, info) => total + info.percent, 0) /
          uploadingList.length) *
          100
      ),
    [uploadingList]
  );

  const isCollapsed = useMemo(
    () => storedIsCollapsed && state === "Uploading",
    [state, storedIsCollapsed]
  );

  const progressStrokeWidth = useMemo(() => (isCollapsed ? 4 : 24), [
    isCollapsed,
  ]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          height: 20px;
        `}
      >
        {state === "Uploading" ? (
          <Spin
            size={"small"}
            indicator={
              <LoadingOutlined
                css={css`
                  font-size: 24;
                  margin-right: 5px;
                  color: ${theme.primaryColor};
                `}
                spin
              />
            }
          />
        ) : (
          <Icon
            css={css`
              margin-right: 5px;
              font-size: 16px;
              color: ${state === "Failed"
                ? theme.warningColor
                : state === "PartCompleted"
                ? theme.rownColor
                : theme.successColor};
            `}
            name={
              state === "Failed" || state === "PartCompleted"
                ? "icon-circle-fill-tip"
                : "icon-complete"
            }
          />
        )}

        <Text>{stateText}</Text>
        {state === "Uploading" ? (
          <Button
            css={css`
              margin-left: auto;
              color: ${theme.defaultColor};
              :focus {
                color: ${theme.defaultColor};
              }
            `}
            type="link"
            onClick={toggleCollapsed}
          >
            <Icon
              css={css`
                font-size: 14px;
                transform: rotate(${isCollapsed ? "180deg" : "0deg"});
              `}
              name={"icon-expanded"}
            />
          </Button>
        ) : (
          <Button
            css={css`
              margin-left: auto;
              color: ${theme.defaultColor};
              :focus {
                color: ${theme.defaultColor};
              }
            `}
            type="link"
            onClick={onClose}
          >
            <Icon
              css={css`
                font-size: 14px;
                transform: rotate(${isCollapsed ? "180deg" : "0deg"});
              `}
              name={"icon-Path1"}
            />
          </Button>
        )}
      </div>
      <div
        css={css`
          position: relative;
        `}
      >
        <Progress
          css={css`
            .ant-progress-inner {
              margin-top: 10px;
              border-radius: 0;
            }
            .ant-progress-success-bg,
            .ant-progress-bg {
              border-radius: 0;
            }
          `}
          showInfo={false}
          percent={percent}
          strokeWidth={progressStrokeWidth}
          strokeColor={isCollapsed ? theme.primaryColor : "#cdddfe"}
        />
        {!isCollapsed && (
          <div
            css={css`
              position: absolute;
              top: 10px;
              height: 100%;
              margin-left: 5px;
              color: ${theme.bodyText};
            `}
          >
            {`${percent}%`}
          </div>
        )}
      </div>
    </div>
  );
});

interface FileUploadingItemProps {
  item: EncooFileUploadCallbackInfo;
}

const FileUploadingItem = React.memo(function FileUploadingItem(
  props: FileUploadingItemProps
) {
  const { item } = props;
  const theme = useTheme();
  const formatMessage = useFormatMessage();

  const percent = useMemo(() => Math.round(item.percent * 100), [item.percent]);

  return (
    <li
      css={css`
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid ${theme.bodyDivider};
      `}
    >
      <Icon
        css={css`
          margin-top: 8px;
          margin-right: 12px;
          font-size: 16px;
        `}
        name="icon-file"
      />
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={[
              css`
                flex-grow: 1;
                max-width: 270px;
                font-size: 14px;
                color: ${theme.bodyText};
              `,
              cssTextTruncate,
            ]}
          >
            {item.nodeName}
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              min-width: 70px;
            `}
          >
            {item.state === "Uploading" ? (
              <Fragment>
                <div
                  css={css`
                    margin-left: auto;
                    margin-right: 5px;
                    color: ${theme.bodySubtext};
                  `}
                >{`${percent}%`}</div>
                <Progress
                  css={css`
                    margin-bottom: 3px;
                  `}
                  percent={percent}
                  type="circle"
                  size="small"
                  showInfo={false}
                  width={16}
                  strokeWidth={15}
                />
              </Fragment>
            ) : item.state === "Completed" || item.state === "Failed" ? (
              <Fragment>
                <div
                  css={css`
                    margin-left: auto;
                    margin-right: 5px;
                    font-size: 12px;
                    color: ${item.state === "Completed"
                      ? theme.successColor
                      : theme.rownColor};
                  `}
                >
                  {item.state === "Completed"
                    ? formatMessage(messageIds.file.completed)
                    : formatMessage(messageIds.file.failed)}
                </div>
                <Icon
                  css={css`
                    font-size: 16px;
                    color: ${item.state === "Completed"
                      ? theme.successColor
                      : theme.rownColor};
                  `}
                  name={
                    item.state === "Completed"
                      ? "icon-complete"
                      : "icon-circle-fill-tip"
                  }
                />
              </Fragment>
            ) : item.state === "Preparing" || item.state === "Compoining" ? (
              <div
                css={css`
                  margin-left: auto;
                  font-size: 12px;
                  color: ${theme.bodySubtext};
                `}
              >
                {item.state === "Preparing"
                  ? formatMessage(messageIds.file.preparing)
                  : formatMessage(messageIds.file.compoining)}
              </div>
            ) : null}
          </div>
        </div>
        <div
          css={css`
            flex-grow: 1;
            font-size: 12px;
            color: ${theme.bodySubtext};
          `}
        >
          {formatByte(item.totalSize)}
        </div>
      </div>
    </li>
  );
});

export default React.memo(function FileUploadingComponent() {
  const getContainer = useGetContainer();
  const container = getContainer(UploadingUIModel);
  const theme = useTheme();

  const uploadingRecord = useSelector(
    () => container.state.uploadingFileRecord
  );
  const storedIsCollapsed = useSelector(() => container.state.isCollapsed);

  const uploadingList = useMemo(() => Object.values(uploadingRecord), [
    uploadingRecord,
  ]);

  const isCollapsed = useMemo(
    () =>
      storedIsCollapsed &&
      uploadingList.some(
        (item) =>
          item.state === "Uploading" ||
          item.state === "Preparing" ||
          item.state === "Compoining"
      ),
    [storedIsCollapsed, uploadingList]
  );

  const dataSource = useMemo(
    () =>
      uploadingList.sort((item1, item2) =>
        item1.state === "Uploading" && item2.state !== "Uploading"
          ? -1
          : item1.state === item2.state
          ? 0
          : 1
      ),
    [uploadingList]
  );

  return dataSource.length > 0 ? (
    <div
      css={css`
        position: fixed;
        bottom: 40px;
        right: 40px;
        width: 420px;
        padding: 24px;
        background: ${theme.bodyBackground};
        box-shadow: 0px 2px 12px 0px ${theme.shadowColor};
        border-radius: 2px;
      `}
    >
      <FileUploadingHeader uploadingList={uploadingList} />
      {!isCollapsed && (
        <ul
          css={css`
            list-style-type: none;
            padding: 0px;
            margin: 0px;
            max-height: 270px;
            overflow-y: auto;
          `}
        >
          {dataSource.map((item) => (
            <FileUploadingItem key={item.fullName} item={item} />
          ))}
        </ul>
      )}
    </div>
  ) : null;
});
