/** @jsx jsx */
import { MenuFoldOutlined } from "@ant-design/icons";
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Space } from "antd";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import Icon from "src/components/Icon";
import LocaleComponent from "src/containers/layout/components/LocaleComponent";
import LoginUserComponent from "src/containers/layout/components/LoginUserComponent";
import ResourceGroupComponent from "src/containers/layout/components/ResourceGroupComponent";
import { ThemeContext } from "src/contexts";
import {
  useFormatMessage,
  useGetContainer,
  useTheme,
  useThemeSet,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { SidebarModel } from "src/store/models/sidebar";

const AppHeaderInternal = React.memo(function AppHeaderInternal(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;
  const theme = useTheme();

  const getContainer = useGetContainer();
  const formatMessage = useFormatMessage();
  const sidebar = getContainer(SidebarModel);

  const isMenuCollapsed = useSelector(() => sidebar.state.isMenuCollapsed);

  const changeCollapsed = useCallback(
    () => sidebar.actions.setMenuCollapsed.dispatch(!isMenuCollapsed),
    [isMenuCollapsed, sidebar.actions.setMenuCollapsed]
  );

  const imgSize = css`
    font-size: 14px;
  `;
  return (
    <header
      css={css`
        display: flex;
        justify-content: space-between;
        min-width: 800px;
        min-height: 50px;
        padding-left: 20px;
        padding-right: 70px;
        background-color: ${theme.bodyBackground};
        box-shadow: 0px 1px 11px 0px ${theme.bodyFrameShadowColor};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Button
          css={css`
            display: flex;
            align-items: center;
            padding: 0;
          `}
          type="link"
          onClick={changeCollapsed}
        >
          <MenuFoldOutlined
            css={css`
              margin-right: 20px;
              font-size: 20px;
              line-height: 20px;
              color: ${theme.primaryColor};
              transform: rotate(${isMenuCollapsed ? "180deg" : "0deg"});
            `}
          />
        </Button>
        {children}
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Space align="center" size={18}>
          <ResourceGroupComponent />
          {window._settings.customizedCustomer === "tss" ? null : (
            <React.Fragment>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://academy.encoo.com/wiki/Index.md?uuid=dd2b79a8-5dc9-46ff-b2f6-06da49a4db0f"
                css={css`
                  display: flex;
                  align-items: center;
                  color: ${theme.bodyText};
                `}
              >
                <Icon name="icon-question-circle" css={imgSize} />
                <span
                  css={css`
                    margin-left: 6px;
                    font-size: 12px;
                  `}
                >
                  {formatMessage(messageIds.common.help)}
                </span>
              </a>
              {/* <Icon name="icon-bell" css={imgSize} /> */}
              <LocaleComponent />
            </React.Fragment>
          )}
          <LoginUserComponent />
        </Space>
      </div>
    </header>
  );
});

export default React.memo(function AppHeader(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.header}>
      <AppHeaderInternal>{children}</AppHeaderInternal>
    </ThemeContext.Provider>
  );
});
