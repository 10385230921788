export default {
  resourceGroup: {
    title: "资源组名称",
    introduction:
      "资源组管理主要用于定义各资源组，可为各用户在不同资源组下分配不同权限的角色，实现用户权限精细化管控。",
    entityTypeName: "资源组",
    name: "资源组名称",
    addToResourceGroup: "添 加",
    user: "资源组用户",
    allResourceGroup: "",
  },
};
