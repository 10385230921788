import { Robot } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const RobotEntityModel = createModel(
  class extends createItemsEntityModel<Robot>((item) => item.id) {
    public selectors() {
      return {
        ...super.selectors(),

        robots: createSelector(
          (): Robot[] => this.getters.items,
          (items) => items
        ),
      };
    }
  }
);
