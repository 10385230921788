import { VersionDetail } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const PackageUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isShowUploadModal: false,
        packageDetail: {} as VersionDetail,
        packageName: "",
      };
    }
    public reducers() {
      return {
        setShowUploadModal: (value: boolean) => {
          this.state.isShowUploadModal = value;
        },
        updataPackageDetail: (value: VersionDetail) => {
          this.state.packageDetail = value;
        },
        setPackageName: (value: string) => {
          this.state.packageName = value;
        },
      };
    }
  }
);
