import { Auditlog } from "@encoo-web/encoo-lib";
import { createModel, mergeModels, mergeSubModels } from "nyax";
import { createItemsReadWriteModel } from "src/store/itemsReadWrite";
import { ModelBase } from "src/store/ModelBase";
import { AuditlogEntityModel } from "src/store/models/entity/auditlog/entity";

export const AuditlogHelperModel = createModel(
  class extends mergeModels(
    ModelBase,
    mergeSubModels({
      _rw: createItemsReadWriteModel<Auditlog>({
        getItemId: (item) => item.id,
        setItems: ({ getContainer }, items) =>
          getContainer(AuditlogEntityModel).actions.setItems.dispatch(items),
      }),
      _rwAll: createItemsReadWriteModel(),
    })
  ) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(AuditlogEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readAll: async (payload: { force?: boolean }) => {
          const { force } = payload;

          const {
            ids: beginReadIds,
            timestamp,
          } = await this.actions._rwAll.beginRead.dispatch({
            ids: [""],
            force,
          });

          try {
            if (beginReadIds.length > 0) {
              const items = await this.dependencies.serviceClient.auditlog.getAll(
                ""
              );

              const {
                ids: endReadIds,
              } = await this.actions._rwAll.endRead.dispatch({
                items: beginReadIds,
                timestamp,
              });

              if (endReadIds.length > 0) {
                await this.actions._rw.endRead.dispatch({
                  items: [...this.getters.entity.state.allIds, ...items],
                  timestamp,
                });
              }
            }

            return this.getters.entity.getters.items;
          } catch (error) {
            await this.actions._rwAll.endRead.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
