import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { getLocale, LocaleLang, setLocale } from "src/utils/locale";

export const LocaleUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectedLocal: getLocale(),
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectedLocale: (value: LocaleLang) => {
          setLocale(value);
        },
      };
    }
    public effects() {
      return {
        initDefaultLanguage: async () => {
          const basicUserProfile = this.getContainer(LoginUserUIModel).state
            .basicUserProfile;
          if (!basicUserProfile) {
            return;
          }
          if (basicUserProfile?.defaultLanguage) {
            const defaultLanguage = basicUserProfile.defaultLanguage.includes(
              "zh"
            )
              ? "zh-Hans"
              : "en-US";
            if (defaultLanguage !== this.state.selectedLocal) {
              await this.actions.setSelectedLocale.dispatch(defaultLanguage);
            }
          }
        },
      };
    }
  }
);
