export default {
  understanding: {
    introduction: "Document understanding is mainly used to customize the configuration and management of various document extraction templates to achieve smart information extraction from a large amount of documents. Electronic PDF and images, scanned documents, and other formats are supported.",
    operation: {
      save: "Save Template",
      publish: "Publish Template",
      cancelPublish: "Unpublish",
      test: "Test",
      continueTest: "Continue to test",
      edit: "Change",
      upload: "Local Upload",
    },

    message: {
      save: "Saved",
      publish: "Published",
      cancel: "Canceled",
      templateNameExisted: "Template name has already existed.",
      createFail: "Creation failed.",
    },
    status: {
      publish: "Published",
      cancel: "Not Published",
    },
    uploadModel: {
      title: "Upload Test File",
      description: "PDF file shall not exceed 20M, and one single image file shall not exceed 4M.",
    },
    createModal: {
      title: "Add Model",
    },
    field: {
      name: "Template Name",
      status: "Template Status",
      type: "Template Type",
      labelCount: "Tag Number",
      model: "Built-in Model",
    },
    confirmation: {
      publish: "Whether to publish {name} template?",
      cancel: "Whether to unpublish {name} template?",
      unpublish: "Whether to unpublish template {name}?",
    },
    designer: {
      header: {
        size: "Original Size",
        width: "Width: {width} px",
        height: "Height: {height} px",
        ratio: "Zoom Ratio: {ratio}%",
      },
      tools: {
        editLabelTip: "Select Frame as Recognition Area",
        zoomInTip: "Zoom in",
        zoomOutTip: "Zoom out",
        dragCanvasTip: "Drag element on canvas",
      },
      labels: {
        number: "No.",
        name: "Tag Name",
        fieldType: "Field Type",
        reference: "Reference Point",
        referenceContent: "Reference Point",
        referenceDirection: "Direction",
        analysisContent: "Recognize Content",
        nameMessage: "Please enter tag name",
        fieldTypeMessage: "Please select field type",
        referenceMessage: "Please enter reference point",
        referenceDirectionMessage: "Please select direction",
        referenceTip: "Please enter the text content of the reference point and select the direction of the reference point with respect to the tag",
      },
      direction: {
        top: "Up",
        bottom: "Down",
        left: "Left",
        right: "Right",
      },
      fieldDataType: {
        text: "string",
      },
      documentType: {
        pdfText: "Electronic PDF Extraction Model",
        pdfImage: "OCR Extraction Model",
      },
      list: {
        anchors: "Select File Anchor Point",
        labels: "Frame Selection Extraction Tag",
      },
      description: {
        explainLabel: "Notes",
        ruleLabel: "Set Rules",
        label: {
          explain: "Frame Selection Extraction Tag is mainly used to determine the corresponding text extraction area.",
          rule1: "The text extraction area can be determined by frame selection.",
          rule2: "Tag name and field type can be configured.",
          // rule3: "Tag references can be set to assist with tag positioning.",
          rule4: "The frame selection area shall be as precise as possible.",
        },
        anchor: {
          explain: "File Anchor Point is used for template matching and skew correction of files.",
          rule1: "The file anchor point can be determined by clicking and selecting.",
          rule2: "The anchor point must be invariant text.",
          rule3: "There shall be no less than three anchor points per page, and the more the better.",
        },
      },
    },
    errorTips: {
      fileRequired: "Please upload file",
    },
  },
};
