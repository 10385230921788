/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Modal as AntdModal } from "antd";
import { ModalProps } from "antd/lib/modal";
import React, { useMemo } from "react";
import Button from "src/components/Button";
import { useFormatMessage } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { reactMemo } from "src/utils/react";

export type ModalSizeType = "small" | "large";
export const MODAL_BODY_MAXHEIGHT = "665px";

const Footer = reactMemo(function Footer(
  props: Pick<ModalProps, "okText" | "onOk" | "cancelText" | "onCancel">
) {
  const { okText, onOk, cancelText, onCancel } = props;
  const formatMessage = useFormatMessage();

  return (
    <div
      css={css`
        button {
          min-width: 88px;
          height: 32px;
        }
      `}
    >
      <Button onClick={onCancel}>
        {cancelText || formatMessage(messageIds.common.cancel)}
      </Button>
      <Button type="primary" onClick={onOk}>
        {okText || formatMessage(messageIds.common.confirm)}
      </Button>
    </div>
  );
});

export default React.memo(function Modal(
  props: ModalProps & { children: React.ReactNode; size?: ModalSizeType }
) {
  const {
    title,
    width,
    size,
    okText,
    onOk,
    cancelText,
    onCancel,
    destroyOnClose = true,
    maskClosable = false,
    children,
    ...restProps
  } = props;
  let { footer } = props;

  if (footer === undefined) {
    footer = (
      <Footer
        okText={okText}
        cancelText={cancelText}
        onOk={onOk}
        onCancel={onCancel}
      />
    );
  }

  const modalWidth = useMemo(() => {
    const defaultWidth = 440;
    if (width) {
      return width;
    }
    switch (size) {
      case "small":
        return defaultWidth;
      case "large":
        return 800;
      default:
        return defaultWidth;
    }
  }, [width, size]);

  return (
    <AntdModal
      title={title}
      width={modalWidth}
      footer={footer}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      {...restProps}
      css={css`
        .ant-modal-header {
          border-bottom: 0;
          padding: 20px;
          .ant-modal-title {
            font-weight: 600;
          }
        }
        .ant-modal-body {
          padding: 0 20px;
          max-height: ${footer ? MODAL_BODY_MAXHEIGHT : "722px"};
          overflow-y: auto;
        }
        .ant-modal-footer {
          border-top: 0;
          padding: 20px;
        }
        .ant-modal-footer button + button {
          margin-left: 20px;
        }
      `}
    >
      {children}
    </AntdModal>
  );
});
