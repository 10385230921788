export default {
  understanding: {
    introduction:
      "文档理解主要用于自定义配置并管理各类文档抽取模板，实现大量文档信息智能抽取，支持电子版PDF及图片、扫描件等格式",
    operation: {
      save: "保存模板",
      publish: "发布模板",
      cancelPublish: "取消发布",
      test: "测试",
      continueTest: "继续测试",
      edit: "修改",
      upload: "本地上传",
    },

    message: {
      save: "保存成功",
      publish: "发布成功",
      cancel: "取消成功",
      templateNameExisted: "模板名称已存在",
      createFail: "创建失败",
    },
    status: {
      publish: "已发布",
      cancel: "未发布",
    },
    uploadModel: {
      title: "上传测试文件",
      description: "PDF文件不得超过20M，单张图片文件不得超过4M",
    },
    createModal: {
      title: "新增模型",
    },
    field: {
      name: "模板名称",
      status: "模板状态",
      type: "模板类型",
      labelCount: "标签数",
      model: "内置模型",
    },
    confirmation: {
      publish: "是否发布{name}模板？",
      cancel: "是否取消发布{name}模板？",
      unpublish: "是否取消发布模板{name}？",
    },
    designer: {
      header: {
        size: "原始尺寸",
        width: "宽:{width}px",
        height: "高:{height}px",
        ratio: "缩放比例:{ratio}%",
      },
      tools: {
        editLabelTip: "框选识别区域",
        zoomInTip: "放大",
        zoomOutTip: "缩小",
        dragCanvasTip: "拖拽画布",
      },
      labels: {
        number: "序号",
        name: "标签名称",
        fieldType: "字段类型",
        reference: "参照点",
        referenceContent: "参照点",
        referenceDirection: "方向",
        analysisContent: "识别内容",
        nameMessage: "请输入标签名称",
        fieldTypeMessage: "请选择字段类型",
        referenceMessage: "请输入参照点",
        referenceDirectionMessage: "请选择方向",
        referenceTip: "请输入参照点的文本内容并选择参照点相对于标签的方向",
      },
      direction: {
        top: "上",
        bottom: "下",
        left: "左",
        right: "右",
      },
      fieldDataType: {
        text: "string",
      },
      documentType: {
        pdfText: "电子版PDF抽取模型",
        pdfImage: "OCR抽取模型",
      },
      list: {
        anchors: "选择文件锚点",
        labels: "框选抽取标签",
      },
      description: {
        explainLabel: "说明",
        ruleLabel: "设置规则",
        label: {
          explain: "框选抽取标签主要用于确定对应的文本抽取区域",
          rule1: "通过框选方式确定文本抽取区域",
          rule2: "可对标签名称以及字段类型进行配置",
          // rule3: "支持设置标签参照辅助标签定位",
          rule4: "框选区域需要尽量精准",
        },
        anchor: {
          explain: "文件锚点用于对文件进行模板匹配及倾斜校正",
          rule1: "通过点击选择的方式确定文件锚点",
          rule2: "锚点必须是不变的文字",
          rule3: "每页锚点不得少于3个，越多越好",
        },
      },
    },
    errorTips: {
      fileRequired: "请上传文件",
    },
  },
};
