/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React from "react";
import { useTheme } from "src/hooks";

export default React.memo(function Text(props: {
  className?: string;
  type?: "default" | "secondary";
  children?: React.ReactNode;
  hasAsterisk?: boolean;
}) {
  const { className, type, children, hasAsterisk } = props;

  const theme = useTheme();

  return (
    <span
      css={css`
        color: ${type === "secondary" ? theme.bodySubtext : theme.bodyText};
        ${hasAsterisk
          ? `
        :before {
                display: inline-block;
                margin-right: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
              }
        `
          : null}
      `}
      className={className}
    >
      {children}
    </span>
  );
});
