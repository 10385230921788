import { AppModel } from "src/store/models/app";
import { entityModels } from "src/store/models/entity";
import { RouterModel } from "src/store/models/router";
import { SidebarModel } from "src/store/models/sidebar";
import { uiModels } from "src/store/models/ui";

export const rootModels = {
  _router: RouterModel,
  app: AppModel,
  sidebar: SidebarModel,
  entity: entityModels,
  ui: uiModels,
};
