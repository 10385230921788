export default {
    securityConfig: {
        menu: "複数の認証プロファイル",
        isOpenSecurityValidation: "複数の認証をオンにするかどうか",
        securityValidationSubtext: "オープンしたら、ユーザーがアカウントパスワードでシステムにログインした後、システムの安全を保証するために二回検証します。認証方式は下記の方式の一つです。",
        instructions: "说明",
        securityType: {
            type1: {
                text: "携帯電話の検証コード",
                subText: "ユーザーがバインドした携帯番号に登録確認コードを送信します。",
            },
            type2: {
                text: "メールアドレスの検証",
                subText: "ユーザーがバインディングしたメールボックスに登録確認コードを送信します。",
            },
            type3: {
                text: "仮想MFA検証",
                subText: "ユーザーは個人センター-セキュリティ設定に配置する必要があります。配置が完了したら、TOTP認証コードで登録できます。",
            }
        },
        validationScope: {
            title: "検証範囲",
            allUsers: "全ユーザ",
            pecifyUser: "ユーザを指定",
        }
    }
}