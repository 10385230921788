/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Dropdown, Tooltip } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import head from "src/assets/head.png";
import Icon from "src/components/Icon";
import TenantComponent from "src/containers/layout/components/TenantComponent";
import { useFormatMessage, useGetContainer, useTheme } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { SidebarModel } from "src/store/models/sidebar";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import {
  UserCenterViewType,
  UserInfoUIModel
} from "src/store/models/ui/userCenter/userInfo";

export interface MenuType {
  type: UserCenterViewType;
  text: string;
  icon: string;
}

export default React.memo(function LoginUserComponnet(props: {
  showUserCenterMenu?: boolean;
}) {
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const theme = useTheme();
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const sidebar = getContainer(SidebarModel);
  const userInfo = getContainer(UserInfoUIModel);
  const user = useSelector(() => loginUserUIModel.state.loggedinUser);
  const { showUserCenterMenu = true } = props;
  const loginOut = useCallback(
    () => loginUserUIModel.actions.logout.dispatch({}),
    [loginUserUIModel.actions.logout]
  );

  const userMenu: MenuType[] = useMemo(
    () => [
      {
        type: "userInfo",
        text: formatMessage(messageIds.userCenter.userInfo),
        icon: "icon-userInfo",
      },
      {
        type: "settings",
        text: formatMessage(messageIds.userCenter.settings),
        icon: "icon-userSettings",
      },
    ],
    [formatMessage]
  );

  const navigationTo = useCallback(
    (type: UserCenterViewType) => {
      userInfo.actions.setSelectedViewType.dispatch(type);
      sidebar.actions.navigate.dispatch("userCenter");
    },
    [sidebar.actions.navigate, userInfo.actions.setSelectedViewType]
  );
  const menu = React.useMemo(
    () => (
      <div
        css={css`
          position: absolute;
          top: 20px;
          right: -50px;
          width: 220px;
          background: ${theme.bodyBackground};
          border-radius: 4px;
          box-shadow: 0px 2px 10px 4px ${theme.bodyFrameShadowColor};
        `}
      >
        <div
          css={css`
            padding: 14px 20px 0;
            border-bottom: 1px solid ${theme.bodyFrameDivider};
          `}
        >
          <p
            css={css`
              margin-bottom: 6px;
              font-size: 14px;
              color: ${theme.bodyText};
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            {user?.displayName}
          </p>
          <p
            css={css`
              margin-bottom: 10px;
              font-size: 12px;
              color: ${theme.bodySubtext};
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            `}
          >
            <Tooltip placement="bottom" title={user?.email}>
              {user?.email}
            </Tooltip>
          </p>
        </div>

        <div
          css={css`
            margin: 10px 0;
            cursor: pointer;
          `}
        >
          <TenantComponent />
          {showUserCenterMenu &&
            userMenu.map((item) => (
              <p
                key={item.text}
                onClick={() => navigationTo(item.type)}
                css={css`
                  margin-bottom: 0;
                  padding: 0 22px;
                  line-height: 42px;
                  &:hover {
                    background-color: ${theme.lightPrimaryColor};
                  }
                `}
              >
                <Icon
                  name={item.icon}
                  css={css`
                    margin-right: 12px;
                  `}
                />
                {item.text}
              </p>
            ))}
        </div>

        <div
          onClick={loginOut}
          css={css`
            display: flex;
            align-items: center;
            padding: 20px;
            border-top: 1px solid ${theme.bodyDivider};
            cursor: pointer;
          `}
        >
          <Icon
            name="icon-loginOut"
            css={css`
              margin-right: 12px;
            `}
          />
          {formatMessage(messageIds.user.logout)}
        </div>
      </div>
    ),
    [
      formatMessage,
      loginOut,
      navigationTo,
      showUserCenterMenu,
      theme.bodyBackground,
      theme.bodyDivider,
      theme.bodyFrameDivider,
      theme.bodyFrameShadowColor,
      theme.bodySubtext,
      theme.bodyText,
      theme.lightPrimaryColor,
      user,
      userMenu,
    ]
  );

  return (
    <div>
      <img
        alt=""
        src={head}
        css={css`
          width: 24px;
          height: 24px;
          margin-right: 8px;
          border-radius: 50%;
        `}
      />
      <Dropdown overlay={menu}>
        <span
          css={css`
            cursor: pointer;
            :hover {
              color: ${theme.primaryColor};
            }
          `}
        >
          {user?.displayName}
        </span>
      </Dropdown>
    </div>
  );
});
