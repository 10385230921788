import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { UserEntityModel } from "src/store/models/entity/user/entity";

export type UserCenterViewType = "userInfo" | "settings";

export const UserInfoUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(UserEntityModel);
    }

    public initialState() {
      return {
        selectViewType: "userInfo" as UserCenterViewType,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectedViewType: (value: UserCenterViewType) => {
          this.state.selectViewType = value;
        },
      };
    }
    public effects() {
      return {};
    }
    public selectors() {
      return {};
    }
  }
);
