import {
  AppVersion,
  AppVersionCreationData,
} from "@encoo-web/encoo-lib/types/model/app";
import { createModel } from "nyax";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const AppVersionHelperModel = createModel(
  class extends createHelperModel<AppVersion>({
    setItems: (getContainer, items) =>
      getContainer(AppVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(AppVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(AppVersionEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByAppIds: async (payload: {
          resourceGroupId: string;
          appIds: string[];
          force?: boolean;
        }) => {
          const { appIds, resourceGroupId, force } = payload;
          return await this._readByParentIds({
            parentIds: appIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.apps.getAllAppVersions(
                resourceGroupId,
                parentId
              );
            },
            getEntityParentId: (entity: AppVersion) => entity.resourceGroupId,
            force,
          });
        },
        readById: async (payload: {
          resourceGroupId: string;
          appId: string;
          versionId: string;
          force?: boolean;
        }) => {
          const { appId, versionId, resourceGroupId, force } = payload;
          return await this._readById({
            id: versionId,
            getByIdAction: () => {
              return this.dependencies.serviceClient.apps.getAppVersionById(
                resourceGroupId,
                appId,
                versionId
              );
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          appId: string;
          appVersion: AppVersionCreationData;
        }) => {
          const { resourceGroupId, appId, appVersion } = payload;

          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.apps.createAppVersion(
                resourceGroupId,
                appId,
                appVersion
              ),
          });
        },
        delete: async (payload: {
          resourceGroupId: string;
          appId: string;
          id: string;
        }) => {
          const { id, appId, resourceGroupId } = payload;
          const appVersion = await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.apps.deleteAppVersion(
                resourceGroupId,
                appId,
                id
              ),
          });

          await this.getContainer(AppHelperModel).actions.readById.dispatch({
            resourceGroupId,
            id: appId,
            force: true,
          });

          return appVersion;
        },
      };
    }
  }
);
