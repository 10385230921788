import apps from "src/locales/zh-CN/apps";
import asset from "src/locales/zh-CN/asset";
import auditlog from "src/locales/zh-CN/auditlog";
import common from "src/locales/zh-CN/common";
import customizedCustomer from "src/locales/zh-CN/customizedCustomer";
import dashboard from "src/locales/zh-CN/dashboard";
import dataTable from "src/locales/zh-CN/dataTable";
import file from "src/locales/zh-CN/file";
import job from "src/locales/zh-CN/job";
import layout from "src/locales/zh-CN/layout";
import license from "src/locales/zh-CN/license";
import packageData from "src/locales/zh-CN/package";
import queue from "src/locales/zh-CN/queue";
import resourceGroup from "src/locales/zh-CN/resourceGroup";
import robot from "src/locales/zh-CN/robot";
import role from "src/locales/zh-CN/role";
import runInstance from "src/locales/zh-CN/runInstance";
import systemSetting from "src/locales/zh-CN/systemSetting";
import trigger from "src/locales/zh-CN/trigger";
import understanding from "src/locales/zh-CN/understanding";
import user from "src/locales/zh-CN/user";
import userCenter from "src/locales/zh-CN/userCenter";
import userTenant from "src/locales/zh-CN/userTenant";
import workflow from "src/locales/zh-CN/workflow";

export default {
  ...common,
  ...layout,
  ...packageData,
  ...role,
  ...robot,
  ...workflow,
  ...job,
  ...trigger,
  ...understanding,
  ...resourceGroup,
  ...queue,
  ...user,
  ...auditlog,
  ...apps,
  ...license,
  ...dashboard,
  ...runInstance,
  ...userCenter,
  ...asset,
  ...userTenant,
  ...customizedCustomer,
  ...file,
  ...dataTable,
  ...systemSetting,
};
