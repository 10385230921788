export default {
  sku: {
    community: {
      normal: "社区版{name}",
    },
    enterprise: {
      normal: "企业版{name}",
      basic: "企业基础版{name}",
      senior: "企业高级版{name}",
    },
  },
  validate: {
    phone: "请正确填写手机号",
    fax: "请正确填写传真号码",
    email: "请正确填写邮箱",
    notEmpty: "填写项不能为空",
    image: "填写项不能为空",
    video: "填写项不能为空",
    guid: "填写项不是Guid",
    illegalChar: "不能包含非法字符",
    stringLength: "{name}须在{min}-{max}字符之间",
  },
  common: {
    help: "帮助",
    noData: "暂无数据",
    required: "填写项不能为空",
    goHome: "返回主页",
    emailValidation: "填写正确的邮件地址",
    selectAll: "全选",
    robot: "机器人",
    today: "今天",
    currentMonth: "当月",
    editor: "编辑器",
    copy: "复制",
    placeholder: "请输入",
    alertTitle: "提示",
    updataTime: "更新时间",
    unBind: "解绑",
    bound: "已绑定",
    nobound: "未绑定",
    unBound: "未解绑",
    packageName: "流程包名称",
    create: "新建",
    multiControl: "批量操作",
    operateOk: "确认",
    tag: "标签",
    delete: "删除",
    remove: "移除",
    rename: "重命名",
    operation: "操作",
    upload: "上传",
    resourceMove: "资源组迁移",
    processDeployment: "流程部署",
    status: "状态",
    note: "备注",
    view: "查看",
    log: "日志",
    loading: "加载中...",
    phone: "电话",
    download: "下载",
    upgrade: "升级",
    cancel: "取消",
    edit: "编辑",
    complete: "完成",
    type: "类型",
    createTime: "创建时间",
    description: "备注",
    creationTime: "创建时间",
    createdBy: "创建人",
    close: "关闭",
    refresh: "刷新",
    confirm: "确定",
    save: "保存",
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
    robotName: "机器人名称",
    deleteConfirmation: "确定删除",
    contact: "联系{attrType}",
    name: "名称",
    baseConfig: "基本配置",
    bindRobot: "关联机器人",
    add: "新增",
    nextStep: "下一步",
    preStep: "上一步",
    createSuccess: "添加成功",
    createFailed: "添加失败",
    uploadSuccess: "上传成功",
    config: " 配置信息",
    bindWorkflow: "关联流程部署",
    empty: "无",
    home: "首页",
    yes: "是",
    no: "否",
    number: "序号",
    uploadFile: "上传文件",
    update: "修改",
    operateSuccess: "操作成功",
    info: {
      createEntity: "新建{entityTypeName}",
      editEntity: "修改{entityTypeName}",
      createSuccessfully: "创建 {entityTypeName} {name} 成功",
      createFailed: "创建 {entityTypeName} {name} 失败",
      removeConfirmation: "确定移除 {entityTypeName} {name} ?",
      deletionConfirmation: "确定删除 {entityTypeName} {name} ?",
      deletionFailed: "删除 {entityTypeName} {name} 失败",
      deleteSuccessfully: "删除 {entityTypeName} {name} 成功",
      updateSuccessfully: "更新 {entityTypeName} {name} 成功",
      updateFailed: "更新 {entityTypeName} {name} 失败",
      bindSuccessfully: "{name} 绑定 {entityTypeName}  成功",
      batchBindSuccessfully: "绑定成功",
      unbindSuccessfully: "{name} 解绑 {entityTypeName}  成功",
      batchUnbindSuccessfully: "解绑成功",
      bindFailed: "{name} 绑定 {entityTypeName}  失败",
      unbindFailed: "{name} 解绑 {entityTypeName}  失败",
    },
    updateSuccessfully: "修改成功",
    updateFailed: "修改失败",
    pleaseSelectTime: "请选择时间",
    startTime: "开始时间",
    endTime: "结束时间",
    baseInfo: "基本信息",
    prePage: "上一页",
    nextPage: "下一页",
    clickHere: "点击此处",
    time: {
      day: " 天",
      hour: " 小时",
    },
    versionNumber: "版本号",
    uploadComponent: {
      localUpload: "本地上传",
      supportExtensions: "支持扩展名",
      import: "导入",
    },
    pcs: " 个",
    goBack: "返回",
  },
  app: {
    name: "云扩控制台",
  },
  environment: {
    uniqueCode: "机器码",
  },
  week: {
    MON: "星期一",
    TUE: "星期二",
    WED: "星期三",
    THU: "星期四",
    FRI: "星期五",
    SAT: "星期六",
    SUN: "星期日",
  },
  cron: {
    every: "每",
    atTime: "第",
    minuteExecute: "分钟执行",
    everyhourExecuteOnce: "小时执行一次",
    everyDay: "每天的",
    execute: "执行",
    everyMonthExecuteOnce: "月执行一次",
    day: "日",
    enterCron: "请输入Cron表达式",
    clickToGenerateCron: "点击在线生成表达式",
  },
  errors: {
    noPermission: {
      title: "很遗憾",
      description: "由于您还没开通权限，无法进行操作",
    },
    notFound: {
      title: "很遗憾",
      description: "抱歉，你访问的页面不存在",
      button: "返回首页",
      request: "请求资源不存在",
    },
    disabled: {
      title: "很遗憾",
      description: "您已被当前租户停用，请联系管理员启用后访问",
    },
    login: {
      ssoErrorTip: "当前客户端时间或服务器时间不正确，请校验时间",
    },
  },
  upgrade: {
    title: "升级提示",
    description: "我们已于近期对控制台进行升级, 主要包括:",
    tip1: "对部分企业级功能进行升级完善",
    tip2:
      "修复了win7/winserver 2008 环境下机器人无法连接问题（请下载最新的Robot安装包进行修复）",
  },
  userEdition: {
    community: "社区版",
    enterprise: "企业版",
  },
};
