export default {
  user: {
    introduction:
      "ユーザー情報は、主にコンソールアカウント情報の管理に使用され、アカウントの無効化、表示、削除などの操作をサポートします。",
    entityTypeName: "ユーザー",
    name: "名前",
    nickName: "ニックネーム",
    email: "メール",
    password: "パスワード",
    confirmPassword: "パスワードを確認",
    confirmPasswordMessage: "パスワードを確認してください。",
    passwordMismatch: "パスワードが一致しません",
    initializationMessage:
        "*　メールアドレスとパスワードをユーザーに送信してください。アカウントは最初のログイン後にアクティブになります。",
    addUserSuccessfully: "ユーザーの追加が成功しました。",
    phoneNumber: "電話",
    isAdmin: "システム管理者",
    setAsAdmin: "管理者に設定",
    lastLoginTime: "前回のログイン時間",
    roles: "ロール",
    editRoles: "ロールを編集",
    addToTenant: "追加",
    status: {
      title: "ステータス",
      all: "すべて",
      enabled: "有効",
      disabled: "無効",
      inactive: "未アクティブ",
    },
    enable: "有効",
    disable: "無効",
    logout: "ログアウト",
    createUser: "ユーザーを作成",
    createTenant: "テナントを作成",
    tenant: {
      entityTypeName: "テナント",
      name: "テキスト名",
      nameSubtitle: "現在、最大3つのエンタープライズ版テナントの作成が許可されています",
      namePlaceholder: "実際の会社名を使用してください。作成後に変更することはできません。",
      nameAlert:
          "ヒント：新しいテナントが作成される後、自動的にテナントの管理者になり、テナントのすべての権限が付与されます。",
      createTenantSuccess: "テナントが成功に作成されました",
      createTenantSuccessHint:
          "[次へ]をクリックすると新しいテナントにジャンプします。ライセンス番号をインポートしてエンタープライズ版機能をアクティブにしてください",
    },
    switchTenant: "テナントをスイッチ",
    userNameInTenant: "テナント内のユーザー名",
    addUserToTenantTip:
        "*　ENCOOアカウントを登録したユーザーを招待して、現在のエンタープライズテナントに入ることができます",
    userEmail: "ユーザーメール",
    userEmailInputTip: "追加するユーザーメールアドレスを入力してください",
    userName: "ユーザー名",
    userNameInputTip: "現在のテナント中のユーザー名を入力してください。",
    addMoreUser: "もっとユーザーを追加",
    addUserTip: "招待リンクはメールで送信され、ユーザーは招待リンクを介してアクティブ化できます",
  },
};
