import { App } from "@encoo-web/encoo-lib/types";
import moment from "moment";
import { createModel, createSelector } from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import { ModelBase } from "src/store/ModelBase";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppHelperModel } from "src/store/models/entity/apps/helper";
import { AppVersionEntityModel } from "src/store/models/entity/appVersion/entity";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { RouterModel } from "src/store/models/router";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";

export const AppDetailUIModel = createModel(
  class extends ModelBase {
    private get _appHelperContainer() {
      return this.getContainer(AppHelperModel);
    }
    private get _appEntityContainer() {
      return this.getContainer(AppEntityModel);
    }
    private get _appVersionHelperContainer() {
      return this.getContainer(AppVersionHelperModel);
    }
    private get _appVersionEntityContainer() {
      return this.getContainer(AppVersionEntityModel);
    }

    private get _resourceGroupContainer() {
      return this.getContainer(ResourceGroupModel);
    }

    public initialState() {
      return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appId: this.containerKey!,
        selectedAppVersionId: null as string | null,
        isInitialized: false,
      };
    }
    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },

        setSelectedAppVersionId: (selectedAppVersionId: string | null) => {
          this.state.selectedAppVersionId = selectedAppVersionId;
        },
      };
    }

    public selectors() {
      return {
        app: createSelector(
          () => this._resourceGroupContainer.state.selectedResourceGroupId,
          () => this.state.isInitialized,
          () => this._appEntityContainer.state.byId[this.state.appId],
          (resourceGroupId, isInitialized, app): App | undefined => {
            const appDetail = isInitialized ? app : undefined;
            return appDetail?.resourceGroupId === resourceGroupId
              ? appDetail
              : undefined;
          }
        ),
        appVersions: createSelector(
          (): App | undefined => this.getters.app,
          () => this._resourceGroupContainer.state.selectedResourceGroupId,
          () => this.state.isInitialized,
          () => this._appVersionEntityContainer.getters.items,
          (
            app: App | undefined,
            resourceGroupId: string | undefined,
            isInitialized: boolean,
            items
          ) => {
            return isInitialized
              ? items
                  .filter(
                    (item) =>
                      item.resourceGroupId === resourceGroupId &&
                      item.appId === app?.id
                  )
                  .sort((item1, item2) =>
                    moment(item1.createdAt) > moment(item2.createdAt)
                      ? -1
                      : moment(item1.createdAt) === moment(item2.createdAt)
                      ? 0
                      : 1
                  )
              : [];
          }
        ),
        selectedAppVersionId: createSelector(
          (): App | undefined => this.getters.app,
          () => this.state.selectedAppVersionId,
          (app, selectedAppVersionId): string | undefined =>
            selectedAppVersionId ??
            app?.currentVersionId ??
            app?.lastAvailableVersionId
        ),
        selectedAppVersion: () =>
          this.getters.selectedAppVersionId
            ? this._appVersionEntityContainer.state.byId[
                this.getters.selectedAppVersionId
              ]
            : undefined,
      };
    }

    public effects() {
      return {
        initializeRequest: async (resourceGroupId: string) => {
          const app = await this._appHelperContainer.actions.readById.dispatch({
            resourceGroupId,
            id: this.state.appId,
            force: true,
          });

          if (app) {
            await this._appVersionHelperContainer.actions.readByAppIds.dispatch(
              {
                resourceGroupId,
                appIds: [app.id],
              }
            );
          }

          await this.actions.initializeSuccess.dispatch({});
        },
      };
    }

    public epics() {
      return {
        resetSelectedAppVersionIdWhenDeleted: () =>
          this.rootAction$.pipe(
            filter(
              () =>
                this.state.isInitialized && !!this.state.selectedAppVersionId
            ),
            map(() => this.getters.appVersions ?? []),
            pairwise(),
            filter(([oldAppVersions, newAppVersions]) => {
              let needUpdate = false;
              if (oldAppVersions !== newAppVersions) {
                const selectedAppVersionId = this.state.selectedAppVersionId;
                if (!selectedAppVersionId) {
                  needUpdate = false;
                } else {
                  needUpdate = !newAppVersions?.some(
                    (item) => item.id === selectedAppVersionId
                  );
                }
              } else {
                needUpdate = false;
              }
              return needUpdate;
            }),
            map(() => this.actions.setSelectedAppVersionId.create(null))
          ),
        resetSelectedAppVersionIdWhenRouteChanged: () =>
          this.rootAction$.pipe(
            filter(
              () =>
                this.state.isInitialized && !!this.state.selectedAppVersionId
            ),
            map(
              () =>
                this.getContainer(RouterModel).getters.currentRouteInfo.params
            ),
            pairwise(),
            filter(
              ([oldCurrentRouteInfo, newCurrentRouteInfo]) =>
                oldCurrentRouteInfo !== newCurrentRouteInfo
            ),

            map(() => this.actions.setSelectedAppVersionId.create(null))
          ),
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
