/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { UserEdition } from "@encoo-web/encoo-lib";
import { Breadcrumb, message as AntMessage } from "antd";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import AppAside from "src/containers/layout/AppAside";
import AppHeader from "src/containers/layout/AppHeader";
import AppMain from "src/containers/layout/AppMain";
import AppSidebar from "src/containers/layout/AppSidebar";
import {
  useApplicationInsights,
  useFormatMessage,
  useGetContainer,
  useResourceGroup,
  useTheme,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { appRouteDefinitions, AppRouteInfo } from "src/routes";
import { AppRouteType, MenuDefinition } from "src/routes/_shared";
import { RouterModel } from "src/store/models/router";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ErrorUIModel } from "src/store/models/ui/error/error";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { eventEmitter } from "src/utils/eventEmitter";
import { getBreadcrumbRouters } from "src/utils/router";

interface ErrorType {
  code: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Array<any>;
  message: string;
}

export default React.memo(function AppLayout() {
  const applicationInsights = useApplicationInsights();
  const formatMessage = useFormatMessage();
  const getContainer = useGetContainer();
  const router = getContainer(RouterModel);
  const errorModel = getContainer(ErrorUIModel);
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const environmentContainer = getContainer(EnvironmentModel);

  const theme = useTheme();
  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);
  const pathname = useSelector(() => router.getters.pathname);
  const loggedinUser = useSelector(() => loginUserUIModel.state.loggedinUser);
  const environmentVersion = useSelector(
    () => environmentContainer.state.environmentVersion
  );
  const environmentEdition = useSelector(
    () => environmentContainer.state.environmentEdition
  );

  const selectedUserTenant = useSelector(
    () => loginUserUIModel.getters.selectedUserTenant
  );

  const resourceGroup = useResourceGroup();

  const [lastVisitedPageInfo, setLastVisitedPageInfo] = useState<{
    time: string;
    url: string;
    name?: string;
  } | null>(null);

  const breadcrumbRouters = useMemo(() => {
    return getBreadcrumbRouters(currentRouteInfo.type);
  }, [currentRouteInfo.type]);

  const mainNode = useMemo(() => {
    if (currentRouteInfo.component) {
      const LoadComponent = React.lazy(
        () => import(`${currentRouteInfo.component}`)
      );
      return (
        <Suspense fallback={null}>
          <LoadComponent />
        </Suspense>
      );
    }
    return null;
  }, [currentRouteInfo.component]);

  const asideNode = useMemo(() => {
    if (currentRouteInfo.silderComponent) {
      const LoadComponent = React.lazy(
        () => import(`${currentRouteInfo.silderComponent}`)
      );
      return (
        <Suspense fallback={null}>
          <LoadComponent />
        </Suspense>
      );
    }
    return null;
  }, [currentRouteInfo.silderComponent]);

  const cssBreadcrumbButton = css`
    padding: 0;
    color: ${theme.bodySubtext};
    &:hover {
      color: ${theme.primaryColor};
    }
    &:focus {
      color: ${theme.bodySubtext};
    }
  `;
  useEffect(() => {
    errorModel.actions.registerError.dispatch({
      errorAction: (err) => {
        if (!err.response) return;
        switch (err.response.status) {
          case 404:
            return AntMessage.info(
              formatMessage(messageIds.errors.notFound.request)
            );
          case 401:
            return;
        }
        if (err.response.data) {
          switch (
            Object.prototype.toString
              .call(err.response.data)
              .slice(8, -1)
              .toLowerCase()
          ) {
            case "string":
              AntMessage.error(err.response.data);
              break;
            case "object": {
              const { message, errors } = err.response.data as ErrorType;
              const errorMessage = [] as Array<string>;
              if (errors && Array.isArray(errors)) {
                errors.forEach((item: { errors: Array<{ error: string }> }) => {
                  item.errors.forEach((childItem) => {
                    errorMessage.push(childItem.error);
                  });
                });
                if (errorMessage.length) {
                  return AntMessage.error(
                    <div
                      css={css`
                        display: inline-block;
                        vertical-align: top;
                      `}
                    >
                      {errorMessage.map((item) => (
                        <div>{item}</div>
                      ))}
                    </div>
                  );
                }
              }
              AntMessage.error(message);
              break;
            }
          }
        }
      },
    });
  }, [errorModel.actions, formatMessage]);

  useEffect(() => {
    loginUserUIModel.actions.addLogoutListener.dispatch({});
  }, [loginUserUIModel.actions.addLogoutListener]);

  const onBreadcrumbClicked = useCallback(
    (type: AppRouteType) => {
      const defaultParams = appRouteDefinitions[type].defaultParams;

      const currentParams = currentRouteInfo.params as Record<string, string>;
      const params: Record<string, string> = {};
      Object.keys(defaultParams).forEach(
        (name) => (params[name] = currentParams[name])
      );
      router.actions.navigateByRouteInfo.dispatch({
        type,
        params,
      } as AppRouteInfo);
    },
    [currentRouteInfo.params, router.actions.navigateByRouteInfo]
  );

  const onloadApplicationInsights = useCallback(() => {
    applicationInsights.loadAppInsights();
  }, [applicationInsights]);

  useEffect(() => {
    eventEmitter.addListener("loadAppInsights", onloadApplicationInsights);
    if (eventEmitter.events.get("loadAppInsights")?.length === 1) {
      onloadApplicationInsights();
    }
  }, [onloadApplicationInsights]);

  const getCurrentMenu = useCallback((): MenuDefinition | undefined => {
    return breadcrumbRouters[breadcrumbRouters.length - 1];
  }, [breadcrumbRouters]);

  const setCommonInsightParams = useCallback(() => {
    if (
      selectedUserTenant &&
      loggedinUser &&
      environmentVersion &&
      environmentEdition &&
      resourceGroup.selectedResourceGroupId
    ) {
      const consoleType =
        environmentEdition === "Private"
          ? "私有化企业版"
          : loggedinUser.edition === UserEdition.Enterprise
          ? "公有云企业版"
          : "社区版";

      applicationInsights.commonProperties = {
        UserID: loggedinUser.userId,
        ConsoleType: consoleType,
        ConsoleVersion: environmentVersion,
        TenantID: selectedUserTenant.tenantId,
        TenantName: selectedUserTenant.tenantName,
        UserPhone: loggedinUser.phoneNumber,
        UserEmail: loggedinUser.email,
        ResourceGroupID: resourceGroup.selectedResourceGroupId,
      };
    }
  }, [
    applicationInsights,
    environmentEdition,
    environmentVersion,
    loggedinUser,
    resourceGroup.selectedResourceGroupId,
    selectedUserTenant,
  ]);

  useEffect(() => {
    if (Number(loggedinUser?.status) === 2) {
      router.actions.navigateByRouteInfo.dispatch({
        type: "discontinued",
        params: {},
      });
    }
  }, [loggedinUser, router.actions.navigateByRouteInfo]);

  const sendTrackPageView = useCallback(() => {
    const menu = getCurrentMenu();
    if (applicationInsights.commonProperties.UserID) {
      applicationInsights.sendTrackEvent(
        { name: "Console_AccessPageEvent" },
        {
          AccessMenu: menu?.name && formatMessage(menu.name),
          StartAccessTime: Date(),
        }
      );
      setLastVisitedPageInfo({
        time: Date(),
        url: window.location.href,
        name: menu?.name,
      });
    }
  }, [applicationInsights, formatMessage, getCurrentMenu]);

  const sendTrackLastPage = useCallback(() => {
    if (applicationInsights.commonProperties.UserID) {
      if (
        lastVisitedPageInfo &&
        lastVisitedPageInfo.url !== window.location.href
      ) {
        applicationInsights.sendTrackEvent(
          { name: "Console_AccessPageEvent" },
          {
            AccessMenu:
              lastVisitedPageInfo.name &&
              formatMessage(lastVisitedPageInfo.name),
            StartAccessTime: lastVisitedPageInfo.time,
            EndAccessTime: Date(),
            Url: lastVisitedPageInfo.url,
          }
        );
      }
    }
  }, [applicationInsights, formatMessage, lastVisitedPageInfo]);

  useEffect(() => {
    setCommonInsightParams();
  }, [setCommonInsightParams]);

  useEffect(() => {
    sendTrackLastPage();
    return () => {
      if (lastVisitedPageInfo) {
        applicationInsights.sendTrackEvent(
          { name: "Console_AccessPageEvent" },
          {
            AccessMenu: lastVisitedPageInfo?.name,
            StartAccessTime: lastVisitedPageInfo?.time,
            EndAccessTime: Date(),
            Url: window.location.href,
          }
        );
      }
    };
  }, [applicationInsights, lastVisitedPageInfo, pathname, sendTrackLastPage]);

  useEffect(() => {
    sendTrackPageView();
  }, [pathname, sendTrackPageView]);

  if (currentRouteInfo.type === "callback") {
    return mainNode;
  }

  if (
    currentRouteInfo.type === "userTenantEntrySelect" ||
    currentRouteInfo.type === "discontinued"
  ) {
    return <AppMain>{mainNode}</AppMain>;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
        min-height: 0;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          min-width: 0;
          min-height: 0;
        `}
      >
        <AppSidebar />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: 0;
            min-height: 0;
          `}
        >
          <AppHeader>
            <Breadcrumb
              css={css`
                .ant-breadcrumb-link {
                  color: ${theme.primaryColor};
                }
              `}
              separator="|"
            >
              <Breadcrumb.Item>
                <Button
                  type="link"
                  css={cssBreadcrumbButton}
                  onClick={() => onBreadcrumbClicked("index")}
                >
                  {formatMessage(messageIds.common.home)}
                </Button>
              </Breadcrumb.Item>
              {breadcrumbRouters.map((menu, index) => (
                <Breadcrumb.Item key={menu.name}>
                  {index === breadcrumbRouters.length - 1 ? (
                    <span>{formatMessage(menu.name)}</span>
                  ) : menu.routeType ? (
                    <Button
                      type="link"
                      css={cssBreadcrumbButton}
                      onClick={() =>
                        onBreadcrumbClicked(menu.routeType ?? "index")
                      }
                    >
                      {formatMessage(menu.name)}
                    </Button>
                  ) : (
                    <span
                      css={css`
                        color: ${theme.bodySubtext};
                      `}
                    >
                      {formatMessage(menu.name)}
                    </span>
                  )}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </AppHeader>
          <div
            css={css`
              display: flex;
              flex-grow: 1;
              min-width: 0;
              min-height: 0;
              margin-top: 16px;
              margin-left: 16px;
              box-shadow: 0px 2px 12px 0px ${theme.bodyFrameShadowColor};
            `}
          >
            {asideNode && selectedUserTenant && (
              <AppAside>{asideNode}</AppAside>
            )}
            {selectedUserTenant && <AppMain>{mainNode}</AppMain>}
          </div>
        </div>
      </div>
    </div>
  );
});
