export default {
  queue: {
    queue: "キュー",
    name: "名前",
    introduction:
        "キューは主にロボットクラスターのバインドに利用され、キューにトリガーされたタスクを動的に暇なロボットに割り当てして実行します。",
    tasksPerformed: "実行されたタスク数",
    remainingTasks: "残りのタスク数",
    robotCount: "ロボットの数",
    workflowCount: "関連するプロセスの数",
    view: "タスクの詳細",
    setting: "設定",
    bindRobot: "ロボットを追加",
    deleteTip: "キュー内の残りのすべてのタスクは、削除後に終了されます",
  },
};
