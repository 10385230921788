import { Trigger } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { TriggerEntityModel } from "src/store/models/entity/trigger/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const TriggerListByWorkFlowModel = createModel(
  class extends createListModel<Trigger>({
    setItems: (getContainer, items) =>
      getContainer(TriggerEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowId, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.workflow.listAllTriggers(
                resourceGroupId,
                workflowId
              ),
            force: force,
          });
        },
      };
    }
  }
);
