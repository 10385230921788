import { Job, PackageVersionArguments } from "@encoo-web/encoo-lib/types";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { JobHelperModel } from "src/store/models/entity/job/helper";
import { PackageVersionHelperModel } from "src/store/models/entity/packageVersion/helper";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";
import { handleErrorNotFound } from "src/utils/error";

export type JobArguments = Omit<PackageVersionArguments, "defaultValue"> & {
  packageValue?: string;
  workflowValue?: string;
  jobValue?: string;
};
export const JobDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        jobDetail: null as Job | null,
        workflowArguments: [] as PackageVersionArguments[] | null,
        packageVersionArguments: [] as PackageVersionArguments[] | null,
      };
    }

    public reducers() {
      return {
        setJobDetail: (value: Job | null) => {
          this.state.jobDetail = value;
        },
        setWorkflowArguments: (value: PackageVersionArguments[] | null) => {
          this.state.workflowArguments = value;
        },
        setPackageVersionArguments: (
          value: PackageVersionArguments[] | null
        ) => {
          this.state.packageVersionArguments = value;
        },
      };
    }

    public selectors() {
      return {
        jobDetailArguments: createSelector(
          () => this.state.packageVersionArguments,
          () => this.state.workflowArguments,
          () => this.state.jobDetail?.arguments,
          (packageVersionArguments, workflowArguments, jobArguments) => {
            const allArguments = packageVersionArguments?.map(
              (packageVersionArgumentsItem) => {
                const argumentName = packageVersionArgumentsItem.name;

                const jobItem = jobArguments?.find(
                  (jobArgumentsItem) => jobArgumentsItem.name === argumentName
                );

                const jobValue = jobItem?.assetContent
                  ? jobItem.assetContent.name
                  : jobItem?.defaultValue;

                const workflowItem = workflowArguments?.find(
                  (workflowArgumentsItem) =>
                    workflowArgumentsItem.name === argumentName
                );

                const workflowValue = workflowItem?.assetContent
                  ? workflowItem.assetContent.name
                  : workflowItem?.defaultValue;

                const packageItem = packageVersionArgumentsItem;

                const packageValue = packageItem.assetContent
                  ? packageItem.assetContent.name
                  : packageItem.defaultValue;

                const argumentItem = {
                  ...packageVersionArgumentsItem,
                  packageValue,
                  workflowValue,
                  jobValue,
                };
                delete argumentItem.defaultValue;
                return argumentItem;
              }
            );
            return allArguments;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestJobDetail: async (payload: {
          resourceGroupId: string;
          id: string;
        }) => {
          const { resourceGroupId, id } = payload;
          const job = await this.getContainer(
            JobHelperModel
          ).actions.readById.dispatch({
            resourceGroupId,
            id,
          });
          this.actions.setJobDetail.dispatch(job ?? null);
        },
        requestJobDetailArguments: async (payload: {
          workflowId: string;
          resourceGroupId: string;
          packageId: string;
          packageVersionId: string;
        }) => {
          const {
            resourceGroupId,
            workflowId,
            packageId,
            packageVersionId,
          } = payload;

          try {
            const packageVersion = await this.getContainer(
              PackageVersionHelperModel
            ).actions.getVersionDetail.dispatch({
              resourceGroupId,
              packageId: packageId,
              versionId: packageVersionId,
            });
            this.actions.setPackageVersionArguments.dispatch(
              packageVersion?.arguments || null
            );
          } catch (error) {
            handleErrorNotFound(error);
          }

          const workflow = await this.getContainer(
            WorkflowHelperModel
          ).actions.getById.dispatch({
            resourceGroupId,
            workflowId,
          });

          this.actions.setWorkflowArguments.dispatch(
            workflow?.arguments || null
          );
        },
      };
    }
  }
);
