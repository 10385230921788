export default {
  userCenter: {
    userInfo: "个人信息",
    settings: "基本设置",
    name: "个人姓名",
    phone: "手机号",
    email: "邮箱",
    binding: "绑定",
    replace: "更换",
    unbind: "解绑",
    setDefaultTenant: "设置默认租户",
    setDefaultResource: "设置默认资源组",
    setDefaultTimeZone: "设置默认时区",
    setDefaultLanguage: "设置默认语言",
    updateName: "修改姓名",
    currentName: "当前姓名",
    currentPhone: "当前手机号",
    currentEmail: "当前邮箱",
    placeholder: {
      inputName: "请输入新的姓名",
      inputBindingEmail: "请输入绑定的邮箱",
      inputEmailCode: "请输入邮箱验证码",
      inputOriginalPassword: "请输入原始密码",
      inputNewPassword:
        "请输入新密码（请输入8-16个字符，至少包含大写、小写、数字和特殊字符中三项）",
      inputNewPasswordAgain: "请再次输入新密码",
      inputNewEmail: "请输入新的邮箱",
      inputNewPhone: "请输入新的手机号",
      inputPhoneCode: "请输入短信验证码",
      passwordNotMatch: "两次输入的密码不匹配",
      nameLimited: "名字最多输入35个字符",
    },
    unbindEmailTips: "是否要解绑 {email} 这个邮箱?",
    unbindPhoneTips: "是否要解绑 {phoneNumber} 这个手机号?",
    bindEmail: "绑定邮箱",
    updatePassword: "修改密码",
    updatePhone: "更换手机号",
    updateEmail: "更换邮箱",
    getVerificationCode: "获取验证码",
    tenantName: "用户姓名",
  },
};
