export default {
  file: {
    introduction: "The file service is mainly used for storing and managing all kinds of files and providing download services for corresponding files. You can freely create multiple levels of folders for file management.",
    newFolder: "New Directory",
    newSubFolder: "New Subfolder",
    fileSize: "File Size",
    sameDirectoryName: "Folder names cannot be the same.",
    uploadFile: "Upload File",
    hasSpecialCharacter: 'The file name cannot contain any of the following characters: \\ / . : * ? " < > | # + & = or start with . ',
    preparing: "Preparing",
    uploading: "Uploading",
    compoining: "Merging",
    allCompleted: "All files uploaded!",
    partCompleted: "Partial files uploaded!",
    completed: "Uploaded.",
    allFailed: "All files upload failed!",
    failed: "Upload failed.",
    sameFileNameExist: "File already exists. Do you want to overwrite it?",
    sameFileNameUploading: "A file with the same name is being uploaded. Please try again later.",
    setPermissions: "Permission Management",
    permissionManagement: "Please select",
    addUser: "Add User",
    permission: {
      admin: "Manage Permissions",
      readOnly: "Read-only Permission",
      writeOnly: "Write Permission",
      noPermission: "No Permission",
    },
    creator: "Creator",
    specifyUser: "Specify User",
    other: "Others",
    others: "Other User",
    othersTip: "Other User under the Current Tenant",
    placeholder: {
      enterNameOrEmail: "Please enter your name or email address",
    },
    otherEmptyTip: "No specified user. Please add.",
    addUserTip: {
      warining: "Please set permissions for all added users",
    },
    add: "Add",
    checkAll: "Select All",
    fileTip: {
      folderEmpty: "No folder. Please create a new one.",
      fileEmpty: "No files. Please upload.",
    },
    fileIntroduce: {
      title1: "What is File Service?",
      p1: "The file service is mainly used for storing and managing all kinds of files and providing download services for corresponding files. You can freely create multiple levels of folders for file management.",
      title2: "What are the operations?",
      operation: {
        createFolder: "New Directory",
        uploadFile: "Upload File",
        downloadFile: "Download File",
      },
    },
  },
};
