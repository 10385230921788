import { Queue } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { QueueEntityModel } from "src/store/models/entity/queue/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const QueueListModel = createModel(
  class extends createListModel<Queue>({
    setItems: (getContainer, items) =>
      getContainer(QueueEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(QueueEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(QueueEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.queue.list(resourceGroupId),
            force: force,
          });
        },
      };
    }
  }
);
