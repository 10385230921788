import { PackageVersion, PackageVersionUpdateData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { PackageVersionEntityModel } from "src/store/models/entity/packageVersion/entity";
import { PackageVersionListModel } from "src/store/models/entity/packageVersion/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const PackageVersionHelperModel = createModel(
  class extends createHelperModel<PackageVersion>({
    setItems: (getContainer, items) =>
      getContainer(PackageVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(PackageVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageVersionEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(PackageVersionListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PackageVersionEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByPackageIds: async (payload: {
          resourceGroupId: string;
          packageIds: string[];
          force?: boolean;
        }) => {
          const { resourceGroupId, packageIds, force } = payload;
          return await this._readByParentIds({
            parentIds: packageIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.package.getAllPackageVersions(
                resourceGroupId,
                parentId
              );
            },
            getEntityParentId: (entity: PackageVersion) =>
              entity.resourceGroupId,
            force,
          });
        },
        updateCompleted: async (payload: {
          resourceGroupId: string;
          packageId: string;
          packageVersionId: string;
          packageVersionUpdateData: PackageVersionUpdateData;
        }) => {
          const {
            resourceGroupId,
            packageId,
            packageVersionId,
            packageVersionUpdateData,
          } = payload;
          const item = await this.dependencies.serviceClient.package.updateCompleted(
            resourceGroupId,
            packageId,
            packageVersionId,
            packageVersionUpdateData
          );
          this.getContainer(PackageVersionListModel).actions.refresh.dispatch({});
          return item;
        },
        getVersionDetail: async (payload: {
          resourceGroupId: string;
          packageId: string;
          versionId: string;
        }) => {
          const { resourceGroupId, packageId, versionId } = payload;

          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [versionId],
          });

          try {
            const item = await this.dependencies.serviceClient.package.getPackageVersionById(
              resourceGroupId,
              packageId,
              versionId
            );

            await this.actions._rw.endWrite.dispatch({
              items: [item],
              timestamp,
            });
            return item;
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
        delete: async (payload: {
          packageId: string;
          packageVersionId: string;
          resourceGroupId: string;
        }) => {
          const { packageId, packageVersionId, resourceGroupId } = payload;

          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [packageVersionId],
          });

          try {
            await this.dependencies.serviceClient.package.deletePackageVersion(
              resourceGroupId,
              packageId,
              packageVersionId
            );

            await this.actions._rw.endWrite.dispatch({
              items: [packageVersionId],
              timestamp,
            });
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
