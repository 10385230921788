import apps from "src/locales/en-US/apps";
import asset from "src/locales/en-US/asset";
import auditlog from "src/locales/en-US/auditlog";
import common from "src/locales/en-US/common";
import customizedCustomer from "src/locales/en-US/customizedCustomer";
import dashboard from "src/locales/en-US/dashboard";
import dataTable from "src/locales/en-US/dataTable";
import file from "src/locales/en-US/file";
import job from "src/locales/en-US/job";
import layout from "src/locales/en-US/layout";
import license from "src/locales/en-US/license";
import packageData from "src/locales/en-US/package";
import queue from "src/locales/en-US/queue";
import resourceGroup from "src/locales/en-US/resourceGroup";
import robot from "src/locales/en-US/robot";
import role from "src/locales/en-US/role";
import runInstance from "src/locales/en-US/runInstance";
import systemSetting from "src/locales/en-US/systemSetting";
import trigger from "src/locales/en-US/trigger";
import understanding from "src/locales/en-US/understanding";
import user from "src/locales/en-US/user";
import userCenter from "src/locales/en-US/userCenter";
import userTenant from "src/locales/en-US/userTenant";
import workflow from "src/locales/en-US/workflow";

export default {
  ...common,
  ...layout,
  ...packageData,
  ...role,
  ...robot,
  ...workflow,
  ...job,
  ...trigger,
  ...understanding,
  ...resourceGroup,
  ...queue,
  ...user,
  ...auditlog,
  ...apps,
  ...license,
  ...dashboard,
  ...runInstance,
  ...asset,
  ...userCenter,
  ...userTenant,
  ...customizedCustomer,
  ...file,
  ...dataTable,
  ...systemSetting,
};
