import { UserTenantInfo } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export type TenantMenuModalVisible = "createUserTenant" | null;
export const UserTenantUIModel = createModel(
  class extends ModelBase {
    private get _loginUserUIContainer() {
      return this.getContainer(LoginUserUIModel);
    }

    private get _router() {
      return this.getContainer(RouterModel);
    }

    public selectors() {
      return {
        defaultUserTenant: createSelector(
          () =>
            this._loginUserUIContainer.state.basicUserProfile?.defaultTenantId,
          () => this.state.userTenants,
          (defaultTenantId, userTenants): UserTenantInfo | null => {
            return (
              userTenants?.find((item) => item.tenantId === defaultTenantId) ??
              null
            );
          }
        ),
      };
    }

    public initialState() {
      return {
        tenantMenuModalVisible: null as TenantMenuModalVisible,
        userTenants: null as UserTenantInfo[] | null,
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setTenantMenuModalVisible: (value: TenantMenuModalVisible) => {
          this.state.tenantMenuModalVisible = value;
        },
        setUserTenants: (value: UserTenantInfo[] | null) => {
          this.state.userTenants = value;
        },
      };
    }

    public effects() {
      return {
        setTenantHeaderValue: async (tenantId: string) => {
          this.dependencies.httpMiddleware.tenantHeader.setHeaderValue(
            tenantId
          );
        },
        createUserTenant: async (payload: { name: string }) => {
          const { name } = payload;
          const loggedinUser = this._loginUserUIContainer.state.loggedinUser;

          if (loggedinUser) {
            const value = await this.dependencies.serviceClient.user.createUserTenant(
              loggedinUser.id,
              {
                name,
              }
            );
            await this.actions.getUserTenants.dispatch(loggedinUser?.id);
            const userTenant = this.state.userTenants?.find(
              (item) => item.tenantId === value.id
            );
            return userTenant;
          }
        },
        switchTenant: async (paylod: { id: string }) => {
          const { id } = paylod;
          window.location.href = `${window.location.origin}?tenantId=${id}`;
        },
        getUserTenants: async (id: string) => {
          const userTenants = await this.dependencies.serviceClient.user.getUserTenants(
            id
          );
          await this.actions.setUserTenants.dispatch(userTenants);
        },
        initSelectedTenant: async () => {
          const currentRouteInfo = this._router.getters.currentRouteInfo;
          const basicUserProfile = this._loginUserUIContainer.state
            .basicUserProfile;

          if (
            currentRouteInfo.type === "userTenantEntrySelect" ||
            currentRouteInfo.type === "callback" ||
            currentRouteInfo.type === "transferToActivateUser"
          ) {
            return;
          }

          if (!basicUserProfile) {
            return;
          }

          if (!this.state.userTenants) {
            await this.actions.getUserTenants.dispatch(basicUserProfile.id);
          }

          if (!this._loginUserUIContainer.getters.selectedUserTenant) {
            const searchParams = new URLSearchParams(window.location.search);
            const tenantId = searchParams.get("tenantId");

            if (tenantId) {
              await this._loginUserUIContainer.actions.setSelectedTenantId.dispatch(
                tenantId
              );
              await this.actions.setTenantHeaderValue.dispatch(tenantId);

              await this.getContainer(
                LoginUserUIModel
              ).actions.getUserProfile.dispatch(basicUserProfile.id);
            } else {
              window.location.href = `${window._settings.consoleV4CompanyEntryUrl}`;
            }
          }
        },
      };
    }
  }
);
