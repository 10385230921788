import { AssetValueType } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const AssetUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        valueType: "Credential" as AssetValueType,
        assetId: "",
        isShowDetail: false,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setValueType: (value: AssetValueType) => {
          this.state.valueType = value;
        },
        setAssetId: (value: string) => {
          this.state.assetId = value;
        },
        setShowDetail: (value: boolean) => {
          this.state.isShowDetail = value;
        },
      };
    }
  }
);
