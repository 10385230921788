export default {
  license: {
    entityTypeName: "许可证",
    introduction:
      "本页面用于展示及管理控制台各类许可证或服务的授权及使用情况。",
    table: {
      id: "编码",
      user: "当前用户",
      machineName: "机器名称",
      name: "机器人名称",
      bindTime: "绑定时间",
      authorizeDate: "授权日期",
    },
    button: {
      apply: "申请许可证",
      applyEnterprise: "申请企业版",
      assign: "分配许可证",
      activation: "激活许可证",
      detail: "查看使用情况",
      remove: "移除许可",
    },
    tooltip: {
      progress: "已经使用数量/许可证数量",
    },
    editor: {
      name: "企业版编辑器",
      descriptions: "可以被激活的编辑器数量",
    },
    robot: {
      name: "企业高级版机器人",
      descriptions: "可以被连接的机器人数量",
    },
    documentUnderstanding: {
      name: "文档理解服务",
      descriptions: "可以被调用的文档理解服务数量",
    },
    documentUnderstandingTable: {
      modelType: "内置模型类型",
      total: "总量",
      used: "使用量",
      rest: "剩余量",
      useOf: "使用占比",
      PDF: "电子版PDF抽取模型",
      OCR: "OCR抽取模型",
    },
    dailog: {
      demand: "需求描述",
      demandPlaceholder: "请告知您的需求数量，比如需多少个机器人，多少进程等…",
      feedback:
        "我们收到请求后将会尽快与您取得联系，如需求紧急， 请直接拨打电话: 400-639-2198",
      customer: "客户名称（标识码）",
      licensePlaceholder: "请输入许可证编号",
      sendScuess: "许可证申请成功已发送",
      sendFailed: "许可证申请失败",
      licenseSerialNumber: "请输入许可证编号",
    },
    messageInfo: {
      activateSuccess: "激活成功",
      activateFailed: "激活失败",
    },
    activateTip: {
      ifNoLicense: "如果您还没有企业版许可证，请",
      tenantIdTip: "标识码用于申请许可证时提供给云扩工作人员",
    },
  },
};
