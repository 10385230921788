export default {
  asset: {
    searchParams: "検索パラメータ",
    introduction:
      "資産管理は主に、さまざまなパラメーターとアカウントパスワードの資格情報を定義し、暗号化されたストレージをサポートし、プロセスの実行中に呼び出すことができます",
    paramsName: "パラメータ名",
    assetType: "資産の種類",
    storageType: "ストレージタイプ",
    assetValue: "資産値",
    assetName: "資産名",
    userName: "ユーザー名",
    password: "パスワード",
    stringValue: "文字列値",
    assetStorageType: "資産ストレージタイプ",
    booleanValue: "ブール値",
    passwordOfAccountCertificate: "アカウント証明書のパスワード",
    string: "文字列",
    integer: "整数",
    float: "フロート",
    booleanType: "ブール型",
    ordinaryStorage: "通常のストレージ",
    encryptedStorage: "暗号化されたストレージ",
    true: "true",
    false: "false",
    value: "値",
    validation: {
      float: "フロートデータを入力してください",
      int: "整数データを入力してください",
    },
    tip: {
      userName: "ヒント：ユーザー名を変更したい場合は、再度パスワードを入力する必要があります",
      blank: "ヒント：アイテムを空白のままにして送信すると、アイテムに変更を加えないことを意味します",
    },
  },
};
