import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential,
} from "@encoo-web/encoo-lib";
import Axios from "axios";
import {
  EncooFile,
  EncooFileUploadCallbackInfo,
  EncooFileUploadUri,
} from "src/models/file";
import { joinDirectory } from "src/utils/string";
import { isWebUrl } from "src/utils/url";

export const FILE_SERVICE_NAME = "file";

delete Axios.defaults.headers.put["Content-Type"];

const deleteStrSlash = (str: string) => {
  let transformStr = `${str ? `/${str}` : ""}`;
  if (transformStr.charAt(transformStr.length - 1) === "/") {
    transformStr = transformStr.substr(0, transformStr.length - 1);
  }
  return transformStr;
};

export class FileServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, FILE_SERVICE_NAME);
  }

  public async createDirectory(
    directoryFullName: string,
    name: string
  ): Promise<EncooFile> {
    const req = this.buildRequest({
      url: `/v1/datacenter/files/${directoryFullName}`,
      method: "POST",
      payload: {
        name,
        type: "Directory",
      },
    });

    const files: EncooFile[] = await super.createInternal(req);

    if (files.length > 0) {
      return files[files.length - 1];
    }

    throw new Error("Create directory failed");
  }

  public async createFile(
    directoryFullName: string,
    fileName: string
  ): Promise<EncooFile> {
    const req = this.buildRequest({
      url: `/v1/datacenter/files/${directoryFullName}`,
      method: "POST",
      payload: {
        name: fileName,
        type: "File",
      },
    });

    return await super.updateInternal(req);
  }

  public async getFiles(fullName: string): Promise<EncooFile[]> {
    fullName = deleteStrSlash(fullName);
    const req = this.buildRequest({
      url: `/v1/datacenter/files${fullName}`,
      method: "GET",
    });

    return await super.updateInternal(req);
  }

  public async getMeta(fullName: string): Promise<EncooFile> {
    fullName = deleteStrSlash(fullName);
    const req = this.buildRequest({
      url: `/v1/datacenter/files${fullName}?metadata`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async delete(fullName: string) {
    fullName = deleteStrSlash(fullName);
    const req = this.buildRequest({
      url: `/v1/datacenter/files${fullName}`,
      method: "DELETE",
    });

    return await super.deleteInternal(req);
  }

  public async getFileDownloadUri(fileFullName: string): Promise<string> {
    const req = this.buildRequest({
      url: `/v1/datacenter/files/${fileFullName}?downloadUri`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);
    return body;
  }

  public async getFileUploadUris(
    fileFullName: string,
    fileSize: number,
    contentType: string
  ): Promise<EncooFileUploadUri[]> {
    const req = this.buildRequest({
      url: `/v1/datacenter/files/${fileFullName}?fileSize=${fileSize}&contentType=${encodeURIComponent(
        contentType
      )}&uploadUri`,
      method: "GET",
    });

    const { body } = await this.sendRequest(req);

    return body;
  }

  public async uploadFile(
    directoryFullName: string,
    file: File,
    processCallback?: (info: EncooFileUploadCallbackInfo) => void
  ): Promise<void> {
    const totalSize = file.size;
    const fileFullName = joinDirectory(directoryFullName, file.name);

    await processCallback?.({
      fullName: fileFullName,
      nodeName: file.name,
      state: "Preparing",
      percent: 0,
      totalSize,
    });

    try {
      await this.createFile(directoryFullName, file.name);
      const uploadUris = await this.getFileUploadUris(
        fileFullName,
        file.size,
        "application/octet-stream"
      );

      const partUris = uploadUris.filter(
        (uri) => uri.type === "MultiPart" || uri.type === "SingleFile"
      );
      const combineUri = uploadUris.find(
        (uri) => uri.type === "CombineByConsole" || uri.type === "Combine"
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: "Uploading",
        percent: 0,
        totalSize,
      });

      let uploadCompleteCount = 0;

      const partResponseDatas: Record<string, string>[] = await Promise.all(
        partUris.map(async (uri) => {
          const response = await Axios({
            method: uri.verb,
            headers: uri.headers,
            url: uri.uri,
            transformRequest: (datas, headers) => {
              headers["Content-Type"] = "application/octet-stream";
              return datas;
            },
            data:
              uri.type === "SingleFile"
                ? file
                : file.slice(
                    uri.partContent.startPos - 1,
                    uri.partContent.startPos - 1 + uri.partContent.length
                  ),
          });
          const partData: Record<string, string> = {};
          uri.partResponseDatas?.forEach((data) => {
            if (data.fromType === "Header") {
              partData[data.name] =
                response.headers[data.fromValue] ??
                response.headers[data.fromValue.toLocaleLowerCase()];
            }
          });

          uploadCompleteCount++;

          processCallback?.({
            fullName: fileFullName,
            nodeName: file.name,
            state: "Uploading",
            percent: uploadCompleteCount / partUris.length,
            totalSize,
          });

          return partData;
        })
      );

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: "Compoining",
        percent: 1,
        totalSize,
      });

      if (combineUri) {
        let data: Record<string, unknown> | string | undefined = {};

        if (combineUri.type === "Combine") {
          data = combineUri.combineContent;
        } else {
          let combineByConsoleContent = combineUri.combineByConsoleContent;
          partResponseDatas.forEach((data) => {
            combineByConsoleContent = { ...combineByConsoleContent, ...data };
          });
          data = combineByConsoleContent;
        }
        const url = isWebUrl(combineUri.uri)
          ? combineUri.uri
          : `${this.environment.endpoint}/${combineUri.uri}`;
        if (combineUri.type === "Combine") {
          await Axios({
            method: combineUri.verb,
            headers: combineUri.headers,
            url,
            data,
          });
        } else {
          const req = this.buildRequest({
            url,
            payload: data,
            method: combineUri.verb,
            headers: {
              "content-type":
                combineUri?.headers?.contentType ?? "application/json",
            },
          });
          await this.sendRequest(req);
        }
      }

      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: "Completed",
        percent: 1,
        totalSize,
      });
    } catch (error) {
      await processCallback?.({
        fullName: fileFullName,
        nodeName: file.name,
        state: "Failed",
        percent: 0,
        totalSize,
      });

      throw error;
    }
  }
}
