import { FilePermissionUpdateData } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { EncooFile } from "src/models/file";
import { FileEntityModel } from "src/store/models/entity/file/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const FileHelperModel = createModel(
  class extends createHelperModel<EncooFile>({
    setItems: (getContainer, items) =>
      getContainer(FileEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(FileEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(FileEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        getAllChildren: async (payload: {
          parentId: string;
          fullName: string;
        }) => {
          const { parentId, fullName } = payload;
          return this._readByParentIds({
            parentIds: [parentId],
            getAllAction: async () => {
              const files = await this.dependencies.serviceClient.file.getFiles(
                fullName
              );
              return files.map((file) => ({
                ...file,
                parentId,
              }));
            },
            getEntityParentId: (entity: EncooFile) => entity.parentId ?? "",
            force: true,
          });
        },
        getFileByFullName: async (payload: { fullName: string }) => {
          const { fullName } = payload;
          const file = await this.dependencies.serviceClient.file.getMeta(
            fullName
          );
          const entityContailer = this.getContainer(FileEntityModel);
          const entityFile = entityContailer.state.byId[file.id];
          if (entityFile?.parentId) {
            file.parentId = entityFile.parentId;
          }

          await entityContailer.actions.batchUpdate.dispatch([file]);

          return file;
        },
        getDownloadUri: async (payload: { fullName: string }) => {
          return await this.dependencies.serviceClient.file.getFileDownloadUri(
            payload.fullName
          );
        },
        createDirectory: async (payload: {
          parentId: string;
          fullName: string;
          name: string;
        }) => {
          const { parentId, fullName, name } = payload;
          return this._create({
            createAction: async () => {
              const file = await this.dependencies.serviceClient.file.createDirectory(
                fullName,
                name
              );
              return {
                ...file,
                parentId,
              };
            },
          });
        },
        delete: async (payload: { id: string; fullName: string }) => {
          const { id, fullName } = payload;
          return this._delete({
            id,
            deleteAction: () =>
              this.dependencies.serviceClient.file.delete(fullName),
          });
        },

        getFilePermission: async (id: string) => {
          const filePermission = await this.dependencies.serviceClient.dataPermission.getById(
            id,
            "file"
          );
          return filePermission;
        },

        updateFilePermission: async (payload: {
          id: string;
          filePermissionData: FilePermissionUpdateData;
        }) => {
          const { id, filePermissionData } = payload;
          return await this.dependencies.serviceClient.dataPermission.update(
            id,
            filePermissionData
          );
        },
      };
    }
  }
);
