export default {
  trigger: {
    name: "タスクプラン名",
    preScheduleTime: "前回の実行時間",
    nextScheduleTime: "次回の実行時間",
    triggerConfig: "タスクプランの詳細構成",
    executionTargetAndArguments: "実行ターゲットとパラメータの確認",
    triggerTime: "プラン時間",
    setBeginTime: "タスクプランを開始する時間を設定",
    setEndTime: "タスクプランを終了する時間を設定",
    jobSetting: "タスク設定",
    enableSuccessfully: "有効化に成功しました",
    disabledSuccessfully: "無効化に成功しました",
    executeSuccessfully: "実行が成功しました",
    status: {
      all: "すべて",
      enabled: "有効",
      disabled: "無効",
    },
    enableHandle: {
      enabled: "有効",
      disabled: "無効",
    },
    validate: {
      endTimeNotBeforeBeginTime: "終了時間を開始時間より早くにすることはできません",
      endTimeNotBeforeCurrentTime: "終了時間を現在の時刻より早くにすることはできません",
      pleaseInputRightcron: "正しいcron式を入力してください",
    },
    timeType: {
      minute: "n分ごと",
      hour: "n時間ごと",
      day: "n日ごと",
      week: "n週ごと",
      month: "n月ごと",
      cron: "cron式",
    },
    log: {
      operation: {
        createdTrigger: "スケジュールが作成されました",
        createdJob: "タスクの作成に成功しました",
        stopedTrigger: "スケジュールを停止",
        startedTrigger: "スケジュールを開始",
        editedTrigger: "スケジュールを編集",
      },
      title: "タスクプランログ",
      logName: "タスクプラン名",
      logTime: "ログ時間",
      content: "ログの内容",
      operationName: "操作者",
    },
  },
};
