import React from "react";
import { Dependencies } from "src/dependencies";
import { Theme } from "src/styles/theme";

export const DependenciesContext = React.createContext<Dependencies>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export const ThemeContext = React.createContext<Theme>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);
