export default {
  resourceGroup: {
    title: "リソースグループ名",
    introduction:
      "リソースグループ管理は、主に各リソースグループを定義するために使用され、各ユーザーに違うリソースグループの下で違う権限を持つロールを割り当て、ユーザー権限のきめ細かい管理と制御を実現できます。",
    entityTypeName: "リソースグループ",
    name: "リソースグループ名",
    addToResourceGroup: "追加",
    user: "リソースグループユーザー",
    allResourceGroup: "",
  },
};
