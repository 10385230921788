import { TriggerCreationData, TriggerUpdateData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { TriggerDetailViewModeType } from "src/store/models/ui/trigger/types";

export const TriggerDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        triggerDetailViewMode: "view" as TriggerDetailViewModeType,
        editData: {} as TriggerUpdateData,
        creationData: {} as TriggerCreationData,
      };
    }

    public reducers() {
      return {
        setTriggerDetailViewMode: (value: TriggerDetailViewModeType) => {
          this.state.triggerDetailViewMode = value;
        },
        updateEditData: (value: TriggerUpdateData) => {
          this.state.editData = { ...this.state.editData, ...value };
        },
        updateCreationData: (value: Partial<TriggerCreationData>) => {
          this.state.creationData = { ...this.state.creationData, ...value };
        },
      };
    }
  }
);
