export default {
  sku: {
    community: {
      normal: "コミュニティ版{name}",
    },
    enterprise: {
      normal: "エンタープライズ版{name}",
      basic: "エンタープライズベーシック版{name}",
      senior: "エンタープライズプロ版{name}",
    },
  },
  validate: {
    phone: "正しい電話番号を入力してください",
    fax: "正しいファックス番号を入力してください",
    email: "正しいメールアドレスを入力してください",
    notEmpty: "このアイテムを空にすることはできません",
    image: "このアイテムを空にすることはできません",
    video: "このアイテムを空にすることはできません",
    guid: "入力した内容はGuidではありません",
    illegalChar: "不正な文字を含めることはできません",
    stringLength: "{name}の長さが{min}-{max}文字の間である必要があります",
  },
  common: {
    help: "ヘルプ",
    noData: "データなし",
    required: "このアイテムを空にすることはできません",
    goHome: "ホームページへ",
    emailValidation: "正しいメールアドレスを入力してください",
    selectAll: "すべてを選択",
    robot: "ロボット",
    today: "今日",
    currentMonth: "今月",
    editor: "エディター",
    copy: "コピー",
    placeholder: "入力してください",
    alertTitle: "提示",
    updataTime: "更新時間",
    unBind: "バインドを解除",
    bound: "バインド済み",
    nobound: "未バインド",
    unBound: "バインドが未解除",
    packageName: "プロセスパッケージ名",
    create: "作成",
    multiControl: "一括操作",
    tag: "タグ",
    delete: "削除",
    remove: "取り除く",
    rename: "名前を変更",
    operation: "操作",
    upload: "アップロード",
    resourceMove: "リソースグループを移行",
    processDeployment: "プロセス展開",
    status: "ステータス",
    note: "説明",
    view: "詳細",
    log: "ログ",
    loading: "読み込んでいます...",
    phone: "電話",
    download: "ダウンロード",
    upgrade: "アップグレード",
    cancel: "キャンセル",
    edit: "編集",
    complete: "完成",
    type: "タイプ",
    createTime: "作成時間",
    description: "説明",
    creationTime: "作成時間",
    createdBy: "作成者",
    close: "閉じる",
    refresh: "リフレッシュ",
    confirm: "はい",
    save: "保存",
    pleaseInput: "入力してください",
    pleaseSelect: "選んでください",
    robotName: "ロボット名",
    deleteConfirmation: "削除を確認",
    contact: "{attrType}に連絡",
    name: "名前",
    baseConfig: "基本設定",
    bindRobot: "関連ロボット",
    add: "追加",
    nextStep: "次へ",
    preStep: "前へ",
    createSuccess: "作成に成功しました",
    createFailed: "作成に失敗しました",
    uploadSuccess: "アップロードが成功しました",
    config: "構成情報",
    bindWorkflow: "プロセス展開をバインド",
    empty: "なし",
    home: "ホームページ",
    yes: "はい",
    no: "いいえ",
    number: "番号",
    uploadFile: "ファイルをアップロード",
    update: "編集",
    operateSuccess: "操作が成功しました",
    info: {
      createEntity: "{entityTypeName}を作成",
      editEntity: "{entityTypeName}を編集",
      createSuccessfully: "{entityTypeName} {name}の作成が成功しました",
      createFailed: "{entityTypeName} {name}の作成が失敗しました",
      removeConfirmation: "本当に{entityTypeName} {name}を取り除きますか？",
      deletionConfirmation: "本当に{entityTypeName} {name}を削除しますか？",
      deletionFailed: "{entityTypeName} {name}の削除が失敗しました",
      deleteSuccessfully: "{entityTypeName} {name}の削除が成功しました",
      updateSuccessfully: "{entityTypeName} {name}の更新が成功しました",
      updateFailed: "{entityTypeName} {name}の更新が失敗しました",
      bindSuccessfully: "{name}は成功に{entityTypeName}をバインドしました",
      batchBindSuccessfully: "バインドが成功しました",
      unbindSuccessfully:
        "{name}は成功に{entityTypeName}のバインドを解除しました",
      batchUnbindSuccessfully: "バインド解除が成功しました",
      bindFailed: "{name}は{entityTypeName}をバインドすることが失敗しました",
      unbindFailed:
        "{name}は{entityTypeName}のバインドを解除することが失敗しました",
    },
    updateSuccessfully: "編集が成功しました",
    updateFailed: "編集が失敗しました",
    pleaseSelectTime: "時間を選択してください",
    startTime: "開始時間",
    endTime: "終了時間",
    baseInfo: "基本情報",
    prePage: "前のページ",
    nextPage: "次のページ",
    clickHere: "ここをクリック",
    time: {
      day: "日",
      hour: "時間",
    },
    versionNumber: "バージョン",
    uploadComponent: {
      localUpload: "ローカルアップロード",
      supportExtensions: "サポートする拡張",
      import: "インポート",
    },
    pcs: " 個",
    goBack: "戻る",
    premission: "権限",
    otherPermission: "ほかの権限",
    append: "追加",
  },
  app: {
    name: "ENCOOコンソール",
  },
  environment: {
    uniqueCode: "マシンコード",
  },
  week: {
    MON: "月曜日",
    TUE: "火曜日",
    WED: "水曜日",
    THU: "木曜日",
    FRI: "金曜日",
    SAT: "土曜日",
    SUN: "日曜日",
  },
  cron: {
    every: " ",
    atTime: "第",
    minuteExecute: "分ごとで実行",
    everyhourExecuteOnce: "時間ごと一回",
    everyDay: "毎日",
    execute: "実行",
    everyMonthExecuteOnce: "月ごと一回",
    day: "日",
    enterCron: "CRON式を入力してください",
    clickToGenerateCron: "クリックしてオンラインでCRON式を生成",
  },
  errors: {
    noPermission: {
      title: "申し訳ありません",
      description: "権限を有効にしていないため、操作を実行できません",
    },
    notFound: {
      title: "申し訳ありません",
      description: "申し訳ありませんが、アクセスするページは存在しません",
      button: "ホームページへ",
      request: "要求するリソースは存在しません。",
    },
    disabled: {
      title: "残念",
      description:
        "現在のテナントによって無効にされています。アクセスを有効にするには、管理者に連絡してください",
    },
    login: {
      ssoErrorTip:
        "現在のクライアント時間またはサーバー時間が正しくありません。時間を確認してください",
    },
  },
  upgrade: {
    title: "アップグレードの提示",
    description: "最近コンソールを更新しました。主に以下が含まれます：",
    tip1: "いくつかの企業レベルの機能を向上させました",
    tip2:
      "ロボットがwin7/winserver 2008の環境下で接続できなかったの問題を修正しました（修正するために、最新のロボットインストールパッケージをダウンロードしてください）",
  },
  userEdition: {
    community: "コミュニティ版",
    enterprise: "エンタープライズ版",
  },
};
