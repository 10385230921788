import { Runinstance } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { RunInstanceHelperModel } from "src/store/models/entity/runInstance/helper";

export const RunInstanceUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: this.containerKey!,
        runInstances: [] as Array<Runinstance>,
      };
    }

    public reducers() {
      return {
        setRunInstance: (value: Array<Runinstance>) => {
          this.state.runInstances = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestRunInstanceByJobId: async (payload: {
          resourceGroupId: string;
        }) => {
          const { resourceGroupId } = payload;
          const list = await this.getContainer(
            RunInstanceHelperModel
          ).actions.readByJobIds.dispatch({
            resourceGroupId,
            jobIds: [this.state.id],
            force: true,
          });
         this.actions.setRunInstance.dispatch(list);
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
