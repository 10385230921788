import { createModel } from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const AppModel = createModel(
  class extends ModelBase {
    private get _environmentContainer() {
      return this.getContainer(EnvironmentModel);
    }
    public initialState() {
      return {
        isInitialized: false,
        isLoadingPermissions: false,
        permissions: null as string[] | null,
      };
    }

    public reducers() {
      return {
        initializeSuccess: () => {
          this.state.isInitialized = true;
        },
        setIsLoadingPermissions: (isLoadingPermissions: boolean) => {
          this.state.isLoadingPermissions = isLoadingPermissions;
        },
        setPermissions: (permissions: string[] | null) => {
          this.state.permissions = permissions;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          const loginUser = this.getContainer(LoginUserUIModel).state
            .loggedinUser;
          if (loginUser) {
            await this._environmentContainer.actions.initEnvironmentDeploy.dispatch(
              {}
            );
            await this.actions.upatePermissions.dispatch({});
          }
          await this.actions.initializeSuccess.dispatch({});
        },
        upatePermissions: async () => {
          await this.actions.setIsLoadingPermissions.dispatch(true);
          const resourceGroupModel = this.getContainer(ResourceGroupModel);
          const selectedResourceGroupId =
            resourceGroupModel.state.selectedResourceGroupId;
          const loginUser = this.getContainer(LoginUserUIModel).state
            .loggedinUser;

          const selectedUserTenant = this.getContainer(LoginUserUIModel).getters
            .selectedUserTenant;

          if (selectedUserTenant && loginUser && selectedResourceGroupId) {
            const permission = await this.dependencies.serviceClient.user.getPermissions(
              selectedResourceGroupId,
              loginUser.id
            );
            try {
              await this.actions.setPermissions.dispatch(
                permission.permissions
              );
            } catch (error) {
              // hop
            }
          } else {
            await this.actions.setPermissions.dispatch(null);
          }

          await this.actions.setIsLoadingPermissions.dispatch(false);
        },
      };
    }
    public epics() {
      return {
        resetEnvironmentWhenLoginUserChanged: () =>
          this.rootAction$.pipe(
            filter(
              () => this.state.isInitialized && !this.state.isLoadingPermissions
            ),
            map(() => this.getContainer(LoginUserUIModel).state.loggedinUser),
            pairwise(),
            filter(([oldLoggedinUser, newLoggedinUser]) => {
              return (
                newLoggedinUser !== null && oldLoggedinUser !== newLoggedinUser
              );
            }),
            map(() =>
              this._environmentContainer.actions.initEnvironmentDeploy.create(
                {}
              )
            )
          ),
        resetPermissionsWhenSelectedResourceGroupIdChanged: () =>
          this.rootAction$.pipe(
            filter(
              () => this.state.isInitialized && !this.state.isLoadingPermissions
            ),
            map(
              () =>
                this.getContainer(ResourceGroupModel).state
                  .selectedResourceGroupId
            ),
            pairwise(),
            filter(
              ([oldSelectedResourceGroupId, newSelectedResourceGroupId]) => {
                return (
                  oldSelectedResourceGroupId !== newSelectedResourceGroupId
                );
              }
            ),
            map(() => this.actions.upatePermissions.create({}))
          ),
        checkRouteRole: () =>
          this.rootAction$.pipe(
            filter(
              () =>
                this.state.isInitialized &&
                !this.state.isLoadingPermissions &&
                !!this.state.permissions
            ),
            map(() => this.state.permissions),
            pairwise(),
            filter(([oldPermissions, newPermisstions]) => {
              const role = this.getContainer(RouterModel).getters
                .currentRouteInfo.role;

              if (!role || !newPermisstions) {
                return false;
              }
              if (role instanceof Array) {
                return role.every((item) => !newPermisstions?.includes(item));
              }
              return !newPermisstions.includes(role);
            }),
            map(() =>
              this.getContainer(RouterModel).actions.replaceByRouteInfo.create({
                type: "noPermission",
                params: {},
              })
            )
          ),
      };
    }
  }
);
