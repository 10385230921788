import {
  DailyUserFlowInfo,
  PerUserFlowInfo,
  RobotExecutionstatisticsQueryData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const UserFlowStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dailyUserFlowDetail: null as DailyUserFlowInfo[] | null,
        perUserFlowDetail: null as PerUserFlowInfo[] | null,
      };
    }
    public reducers() {
      return {
        setDailyUserFlowDetail: (value: DailyUserFlowInfo[] | null) => {
          this.state.dailyUserFlowDetail = value;
        },
        setPerUserFlowDetail: (value: PerUserFlowInfo[] | null) => {
          this.state.perUserFlowDetail = value;
        },
      };
    }

    public effects() {
      return {
        requestUserFlowStatistics: async (props: {
          resourceGroupId: string;
          query: RobotExecutionstatisticsQueryData;
        }) => {
          const value = await this.dependencies.serviceClient.dashboard.userFlowstatistics(
            props.resourceGroupId,
            props.query
          );
          await this.actions.setDailyUserFlowDetail.dispatch(
            value.dailyUserFlowDetails ?? null
          );
          await this.actions.setPerUserFlowDetail.dispatch(
            value.perUserFlowDetails ?? null
          );
        },
      };
    }
  }
);
