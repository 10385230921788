import {
  UpdatePasswordData,
  UpdateUserData,
  User,
  UserAddToTenant,
  UserClaim,
  UserCreationData,
  UserQueryData,
  UserTenantBindingInfo,
  UserUpdateData,
  VerificationCodeCheckData,
  VerificationCodeData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { UserEntityModel } from "src/store/models/entity/user/entity";
import { UserListModel } from "src/store/models/entity/user/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const UserHelperModel = createModel(
  class extends createHelperModel<User>({
    setItems: (getContainer, items) =>
      getContainer(UserEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(UserEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(UserEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(UserListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(UserEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readAll: async (payload: {
          userQuery?: UserQueryData;
          force?: boolean;
        }) => {
          const { userQuery, force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () =>
              this.dependencies.serviceClient.user.getAll(userQuery),
            getEntityParentId: (entity: User) => "",
            force,
          });
        },
        users: async (payload: { userQuery?: UserQueryData }) => {
          const { userQuery } = payload;
          return this.dependencies.serviceClient.user.getAll(userQuery);
        },
        create: async (payload: {
          user: UserCreationData;
          tenantId: string | undefined;
          userId: string | undefined;
        }) => {
          const { user, tenantId = "", userId = "" } = payload;
          const item = await this.dependencies.serviceClient.user.create(user);
          if (userId && tenantId) {
            return await this.actions.addUserTeant.dispatch({
              userId,
              bindingInfo: {
                email: item.email,
                tenantId: tenantId,
              },
            });
          }
        },
        batchAddUser: async (payload: {
          tenantId: string;
          users: UserAddToTenant[];
        }) => {
          const { tenantId, users } = payload;
          return this.dependencies.serviceClient.user.batchAddTenantUsers(
            tenantId,
            users
          );
        },
        enableUser: async (payload: { user: User; userId: string }) => {
          const { user, userId } = payload;
          return this._update({
            id: userId,
            updateAction: async () => {
              const flag = await this.dependencies.serviceClient.user.enableUser(
                userId
              );
              return { ...user, status: flag ? 0 : 2 };
            },
          });
        },

        disableUser: async (payload: { user: User; userId: string }) => {
          const { user, userId } = payload;
          return this._update({
            id: userId,
            updateAction: async () => {
              const flag = await this.dependencies.serviceClient.user.disableUser(
                userId
              );
              return { ...user, status: flag ? 2 : 0 };
            },
          });
        },
        addUserTeant: async (payload: {
          userId: string;
          bindingInfo: UserTenantBindingInfo;
        }) => {
          const { userId, bindingInfo } = payload;
          return this._create({
            createAction: async () => {
              const item = await this.dependencies.serviceClient.user.addUserToTenant(
                userId,
                bindingInfo
              );
              return item;
            },
          });
        },
        removeUserFromTenant: async (payload: {
          userId: string;
          tenantId: string;
        }) => {
          const { userId, tenantId } = payload;
          return this._delete({
            id: "".concat(userId),
            deleteAction: async (id) => {
              return await this.dependencies.serviceClient.user.removeUserFromTenant(
                userId,
                tenantId
              );
            },
          });
        },
        getById: async (payload: { userId: string }) => {
          const { userId } = payload;
          return this._readById({
            id: userId,
            getByIdAction: () => {
              return this.dependencies.serviceClient.user.getById(userId);
            },
          });
        },
        update: async (payload: { user: User; update: UserUpdateData }) => {
          const { user, update } = payload;
          return this._update({
            id: user.id,
            updateAction: async () => {
              return this.dependencies.serviceClient.user.update(user, update);
            },
          });
        },
        delete: async (payload: { userId: string }) => {
          const { userId } = payload;
          return this._delete({
            id: "".concat(userId),
            deleteAction: () => {
              return this.dependencies.serviceClient.user.delete(userId);
            },
          });
        },
        sendToken: async (payload: VerificationCodeData) => {
          return await this.dependencies.serviceClient.user.sendToken(payload);
        },

        verifyToken: async (payload: VerificationCodeCheckData) => {
          await this.dependencies.serviceClient.user.verifyToken(payload);
        },

        updatePhoneNumber: async (payload: {
          id: string;
          updateUserData: UpdateUserData;
        }) => {
          const { id, updateUserData } = payload;
          await this.dependencies.serviceClient.user.updatePhoneNumber(
            id,
            updateUserData
          );
        },

        unbindPhoneNumber: async (id: string) => {
          await this.dependencies.serviceClient.user.unbindPhoneNumber(id);
        },

        bindingEmail: async (payload: {
          id: string;
          updateUserData: UpdateUserData;
        }) => {
          const { id, updateUserData } = payload;
          await this.dependencies.serviceClient.user.bindingEmail(
            id,
            updateUserData
          );
        },

        unbindEmail: async (id: string) => {
          await this.dependencies.serviceClient.user.unbindEmail(id);
        },

        updatePassword: async (payload: {
          id: string;
          updatePasswordData: UpdatePasswordData;
        }) => {
          const { id, updatePasswordData } = payload;
          await this.dependencies.serviceClient.user.updatePassword(
            id,
            updatePasswordData
          );
        },
        updateUser: async (payload: {
          id: string;
          updateValue: UserClaim[];
        }) => {
          const { id, updateValue } = payload;
          await this.dependencies.serviceClient.user.updateUser(
            id,
            updateValue
          );
        },
        updateLanguage: async (payload: { id: string; language: string }) => {
          const { id, language } = payload;
          await this.dependencies.serviceClient.user.updateLanguage(
            id,
            language
          );
        },
      };
    }
  }
);
