/** @jsx jsx */

import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Dropdown, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import Icon from "src/components/Icon";
import { useGetContainer, useTheme } from "src/hooks";
import { supportedLocales } from "src/services/LocaleService";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { getLocale, LocaleLang } from "src/utils/locale";

export default React.memo(function LocaleComponnet() {
  const getContainer = useGetContainer();
  const localModel = getContainer(LocaleUIModel);
  const selectedLocale = useSelector(() => localModel.state.selectedLocal);
  const theme = useTheme();

  const handleClick = useCallback(
    (params: MenuInfo) => {
      if (params.key !== selectedLocale) {
        localModel.actions.setSelectedLocale.dispatch(params.key as LocaleLang);
      }
    },
    [localModel.actions.setSelectedLocale, selectedLocale]
  );

  const menu = React.useMemo(
    () => (
      <Menu onClick={handleClick}>
        {supportedLocales &&
          supportedLocales.map((locale) => (
            <Menu.Item key={locale.config.locale}>{locale.title}</Menu.Item>
          ))}
      </Menu>
    ),
    [handleClick]
  );
  return (
    <Dropdown overlay={menu}>
      <div
        css={css`
          display: flex;
          align-items: center;
          color: ${theme.bodyText};
          cursor: pointer;
          :hover {
            color: ${theme.primaryColor};
          }
        `}
      >
        <Icon
          name="icon-translate"
          css={css`
            margin-right: 8px;
            font-size: 14px;
            line-height: 32px;
          `}
        />
        <span
          css={css`
            font-size: 12px;
          `}
        >
          {supportedLocales.find((l) => l.config.locale === getLocale())?.title}
        </span>
      </div>
    </Dropdown>
  );
});
