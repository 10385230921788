import { Workflow } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const WorkflowListModel = createModel(
  class extends createListModel<Workflow>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(WorkflowEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.workflow.list(resourceGroupId),
            force: force,
          });
        },
      };
    }
  }
);
