import Axios from "axios";
import { createModel } from "nyax";
import { Template, TemplateCreationData } from "src/models/understanding";
import { TemplateEntityModel } from "src/store/models/entity/understandingTemplate/entity";
import { TemplateListModel } from "src/store/models/entity/understandingTemplate/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const TemplateHelperModel = createModel(
  class extends createHelperModel<Template>({
    setItems: (getContainer, items) =>
      getContainer(TemplateEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TemplateEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TemplateEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(TemplateListModel).actions.refresh.dispatch({}),
  }) {
    public effects() {
      return {
        ...super.effects(),

        readById: async (payload: {
          resourceGroupId: string;
          id: string;
          force?: boolean;
        }) => {
          const { id, resourceGroupId, force } = payload;
          return await this._readById({
            id,
            getByIdAction: async () => {
              const template = await this.dependencies.serviceClient.understanding.getById(
                resourceGroupId,
                id
              );

              return template;
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          template: TemplateCreationData;
          file: File;
        }) => {
          const { resourceGroupId, file, template } = payload;

          return await this._create({
            createAction: async () => {
              const newTemplate = await this.dependencies.serviceClient.understanding.create(
                resourceGroupId,
                template
              );

              if (!newTemplate.uploadUri) {
                throw new Error("upload uri is not exist");
              }
              delete Axios.defaults.headers.put["Content-Type"];
              await Axios({
                method: "PUT",
                headers: newTemplate.uploadUri.headers,
                url: newTemplate.uploadUri.uri,
                data: file,
              });
              try {
                await this.dependencies.serviceClient.understanding.patch(
                  resourceGroupId,
                  newTemplate.id
                );
                return newTemplate;
              } catch (error) {
                if (error?.response) {
                  const { code } = error?.response.data;
                  if (code === "InvalidPdfDocument") {
                    await this.actions.delete.dispatch({
                      id: newTemplate.id,
                      resourceGroupId,
                    });
                  }
                }
              }
              return Promise.reject(false);
            },
          });
        },
        update: async (payload: {
          resourceGroupId: string;
          template: Partial<Template> & Pick<Template, "id">;
        }) => {
          const { resourceGroupId, template } = payload;
          return await this._update({
            id: template.id,
            updateAction: () =>
              this.dependencies.serviceClient.understanding.update(
                resourceGroupId,
                template
              ),
          });
        },
        publish: async (payload: {
          id: string;
          resourceGroupId: string;
          isPublished: boolean;
        }) => {
          const { resourceGroupId, id, isPublished } = payload;
          return await this._update({
            id,
            updateAction: () =>
              this.dependencies.serviceClient.understanding.publish(
                resourceGroupId,
                id,
                isPublished
              ),
          });
        },
        delete: async (payload: { id: string; resourceGroupId: string }) => {
          const { id, resourceGroupId } = payload;
          return await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.understanding.delete(
                resourceGroupId,
                id
              ),
          });
        },
      };
    }
  }
);
