export default {
  dashboard: {
    title: {
      package: "プロセスパッケージの数",
      workflow: "プロセス展開の数",
      queue: "キューの数",
      robot: "ロボットの数",
      task: "タスクレコードの概要",
      taskDate: "タスクの数/日付",
      taskInfo: "タスクの詳細",
    },
    viewDetail: "詳細",
    table: {
      status: "ステータス",
      percent: "パーセント",
      count: "数量",
      wait: "待つ",
      allocated: "割り当て済み",
      operation: "実行中",
      success: "成功",
      cancelling: "キャンセル中",
      cancelled: "キャンセル",
      fail: "失敗",
    },
    totalCount: "トータル",
    sevenDay: "最近の7日",
    thirtyDay: "最近の30日",
    today: "今日",
  },
  robotRunStatistics: {
    dailyRobot: {
      runInstanceExecutionCount: "実行されたタスクの合計数",
      successExecutionCount: "実行成功の合計数",
      robotCount: "ロボットの総数",
      robotAliveTime: "ロボット存在合計時間",
      robotBusyTime: "ビジー合計時間",
      averageBusyProportion: "平均ビジープロポーション",
      averageSuccessProportion: "平均成功率",
    },
    perRobot: {
      runInstanceExecutionCount: "実行されたタスクの数",
      successExecutionCount: "実行成功の数",
      busyMilliseconds: "ビジー時間",
      aliveMilliseconds: "ロボット存在時間",
      busyProportion: "ビジープロポーション",
      successProportion: "成功率",
    },
  },
  userFlowStatistics: {
    dailyUserFlow: {
      uploadedPackageCount: "アップロードしたパッケージの合計数",
      deployedPackageCount: "展開されたパッケージの合計数",
      createdWorkflowCount: "作成されたプロセス展開の合計数",
      createdCronTriggerCount: "作成されたタスクプランの合計数",
      createdJobCount: "作成されたタスクの合計数",
      jobSuccessCount: "実行成功のタスク合計数",
      totalJobExecutionMilliseconds: "タスクの合計実行時間",
    },
    perUserFlow: {
      userName: "ユーザー名",
      uploadedPackageCount: "アップロードされたプロセスパッケージの数",
      createdWorkflowCount: "作成されたプロセス展開の数",
      createdCronTriggerCount: "作成されたプロセス展開の数",
      createdJobCount: "作成されたタスクの数",
      jobSuccessCount: "実行成功のタスクの数",
      totalJobExecutionMilliseconds: "タスクの実行時間",
    },
  },
  robotStatusStatistics: {
    averageBusyRatioOfAvailableRobots: "利用可能なロボットの平均ビジー率",
    averageBusyRatio: "平均ビジー率",
    todayAverageBusyRatio: "今日の平均ビジー率",
    robotBusyTotalTimeTOP10: "利用可能なロボットのビジー総時間TOP10",
    todayAdded: "今日の追加数",
    robotBusyTotalTime: "利用可能なロボットの忙しい総時間",
    busyTotalTime: "忙しい総時間",
    robotFailRateTOP10: "利用可能なロボットの故障率TOP10",
    robot: "ロボット",
    licenseType: {
      serverLicensed: "ライセンス済み",
      unlicensed: "ライセンスされていない",
    },
    availableRobotStateDistribution:
      "利用可能なロボットのリアルタイムステータス分布",
    robotTotalCount: "ロボットの総数",
    serverLicensedCount: "ライセンス済み数",
    unlicensedCount: "ライセンスされていない数",
    availableRobotTotalExeCount: "利用可能なロボットの実行タスクの総数",
    exeTotalCount: "実行の総数",
    viewRobotDetail: "ロボットの詳細",
    exeWorkflowTotalTime: "実行プロセス総時間",
    faultNumber: "件故障",
  },
  queueStatistics: {
    averageBusyRate: "平均ビジー率",
    todayAverageBusyRate: "今日のビジー率",
    todayAdded: "今日の追加",
    busyTotalTime: "忙しい総時間",
    faultNumber: "件故障",
    successNumber: "件成功",
    queueDetail: "キューの詳細",
    queueExeTotalTime: "キューのタスク実行の総時間",
    exeTotalTime: "タスク実行の総時間",
    queueName: "キューの名前",
    queueBusyTop10: "キュー中のビジー合計時間トップ10",
    queueCount: "キューの数",
    queueTotalCount: "キューの総数",
    queueFailTop10: "キュー故障率トップ10",
    queueJobState: "キュー中のタスク分布",
    queueJobCount: "キューの実行総数",
    aveWaitTime: "平均タスク待ち時間トップ10",
    busyRate: "ビジー率",
    robotBindedCount: "バインディングロボット数",
    robotBindedTotalCount: "バインディングロボット数",
    queueJobWaitTop10: "キュー中の平均タスク待ち時間トップ10",
    queueRobotAveBusyRate: "キューロボットの平均ビジープロポーション",
    queueSuccessRate: "キュー成功率トップ10",
    associatedWorkflowCount: "関連されたプロセス展開数",
    associatedWorkflowTotalCount: "関連されたプロセス展開数",
    exeWorkflowTotalTime: "プロセス実行の総時間",
  },
};
