import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const PackageDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        ...super.initialState(),
        id: "",
        isShowVersionDetail: false,
        isEdit: true,
        isShowWorkflowModal: false,
        isShowBasicConfig: true,
        description: "",
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setId: (id: string) => {
          this.state.id = id;
        },
        setShowVersionDetail: (value: boolean) => {
          this.state.isShowVersionDetail = value;
        },
        setEdit: (value: boolean) => {
          this.state.isEdit = value;
        },
        setShowWorkflowModal: (value: boolean) => {
          this.state.isShowWorkflowModal = value;
        },
        setShowBasicConfig: (value: boolean) => {
          this.state.isShowBasicConfig = value;
        },
        updateDescription: (value: string) => {
          this.state.description = value;
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
        downloadPackage: async (payload: {
          packageId: string;
          packageVersionId: string;
          resourceGroupId: string;
        }) => {
          const { packageId, packageVersionId, resourceGroupId } = payload;
          this.dependencies.serviceClient.package
            .getPackageVersionDownloadUri(
              resourceGroupId,
              packageId,
              packageVersionId
            )
            .then((res) => {
              const link = document.createElement("a");
              link.style.display = "none";
              link.href = res;
              link.setAttribute("download", `download`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
        },
      };
    }
  }
);
