import { Workflow } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";

export type TriggerListModalType = "create" | "viewTriggerLog" | null;
export const TriggerModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        associatedWorkflow: null as Workflow | null,
        triggerListModalType: null as TriggerListModalType,
      };
    }

    public reducers() {
      return {
        setAssociatedWorkflow: (value: Workflow | null) => {
          this.state.associatedWorkflow = value;
        },
        setTriggerListModalType: (value: TriggerListModalType) => {
          this.state.triggerListModalType = value;
        },
      };
    }

    public effects() {
      return {
        requestAssociatedWorkflow: async (payload: {
          workflowId: string;
          resourceGroupId: string;
        }) => {
          const { workflowId, resourceGroupId } = payload;
          const associatedWorkflow = await this.getContainer(
            WorkflowHelperModel
          ).actions.getById.dispatch({
            resourceGroupId,
            workflowId,
          });
          this.actions.setAssociatedWorkflow.dispatch(
            associatedWorkflow ?? null
          );
        },
      };
    }
  }
);
