export default {
  package: {
    package: "Flow Package",
    version: "Version Number",
    introduction: "Flow package management is mainly used to manage the flow packages uploaded by the studio or manually uploaded and their corresponding version details. It supports flow package download and deletion operations.",
    uploadTime: "Upload Time",
    uploadUser: "Upload User",
    packageDetail: "Flow Package Version Details",
    count: "Flow Package Deployment Frequency",
    packageNote: "Flow Package Remarks",
    deploymentName: "Flow Deployment Name",
    deploymentCount: "Flow Deployment Frequency",
    deploymentNote: "Deployment Remarks",
    deploymentDetail: "Deployment Details",
    deployed: "Deployed",
    notDeploy: "Not Deployed",
    preStep: "Back",
    nextStep: "Next",
    save: "Save and set the trigger.",
    baseConfig: "Basic Config",
    performanceTargets: "Execution Target",
    packageName: "Flow Package Name",
    parameterManagement: "Flow Package Parameter Management",
    name: "Name",
    parameterType: "Parameter Type",
    parameterDefault: "Default Parameter Value",
    queueExecution: "Specify Execution Queue",
    robotExecution: "Specify Execution Robot",
    addWorkflow: "Add Deployment",
    uploadPackage: "Upload Flow Package",
    currentVersion: "Current Version Number",
    latestVersion: "Latest Version Number",
    packageVersionFormat: "The version number format is x.x.x.",
  },
};
