export default {
  package: {
    package: "プロセスパッケージ",
    version: "バージョン",
    introduction:
        "プロセスパッケージ管理は、主にStudioからアップロードされた、または手動でアップロードされたプロセスパッケージとそれに対応するバージョンの詳しい情報を管理するために使用されます。プロセスパッケージをダウンロード、削除などの操作をサポートしています。",
    uploadTime: "アップロード時間",
    uploadUser: "アップロードユーザー",
    packageDetail: "プロセスパッケージバージョンの詳細",
    count: "プロセスパッケージ展開の回数",
    packageNote: "パッケージの説明",
    deploymentName: "プロセス展開名",
    deploymentCount: "プロセス展開の回数",
    deploymentNote: "展開の説明",
    deploymentDetail: "展開の詳細",
    deployed: "展開済み",
    notDeploy: "未展開",
    preStep: "前へ",
    nextStep: "次へ",
    save: "保存してトリガを設定",
    baseConfig: "基本設定",
    performanceTargets: "実行ターゲット",
    packageName: "パッケージ名",
    parameterManagement: "パッケージの引数を管理",
    name: "名前",
    parameterType: "パラメータタイプ",
    parameterDefault: "パラメータデフォルト値",
    queueExecution: "キューを選択",
    robotExecution: "ロボットを選択",
    addWorkflow: "展開を作成",
    uploadPackage: "パッケージをアップロード",
    currentVersion: "今のバージョン",
    latestVersion: "最新バージョン",
    packageVersionFormat: "バージョン番号の形式はX.X.Xです",
  },
};
