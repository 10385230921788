export const DependenciesSymbol = "Dependencies";
export const HistorySymbol = "History";

export const UserManagerSettingsSymbol = "UserManagerSettings";
export const HttpClientSymbol = "HttpClient";
export const ServiceCredentialsSymbol = "ServiceCredentials";
export const TenantHeaderMiddlewareSymbol = "TenantHeaderMiddleware";
export const ErrorMiddlewareSymbol = "ErrorMiddlewareSymbol";
export const APIError401MiddlewareSymbol = "APIError401MiddlewareSymbol";
export const RequestQueueMiddlewareSymbol = "RequestQueueMiddleware";
export const ProfileSymbol = "Profile";
