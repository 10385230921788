import {
  License,
  LicenseApplyPlayLoad,
  LicenseBindingData,
  LicenseBindingQueryData,
  ProductNameOfLicenseCounter,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { LicenseBindingsEntityModel } from "src/store/models/entity/licenseBindings/entity";
import { createHelperModel } from "src/store/models/entity/_shared";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const LicenseBindingsHelperModel = createModel(
  class extends createHelperModel<License>({
    setItems: (getContainer, items) =>
      getContainer(LicenseBindingsEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(LicenseBindingsEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(LicenseBindingsEntityModel).state.byId[id],
  }) {
    private get _userUIModel() {
      return this.getContainer(LoginUserUIModel);
    }
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(LicenseBindingsEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: { force?: boolean }) => {
          const { force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.licenseBindings.getAll();
            },
            getEntityParentId: (entity: License) => entity.id,
            force,
          });
        },
        licenseBindings: async (resourceGroupId?: string) => {
          return this.dependencies.serviceClient.licenseBindings.getAll(
            resourceGroupId
          );
        },
        bindToForUnboundLicense: async (playload: {
          licenseBindingData: LicenseBindingData;
          tenantId: string | undefined;
          resourceGroupId: string;
        }) => {
          const { licenseBindingData, tenantId, resourceGroupId } = playload;

          return this.dependencies.serviceClient.licenseBindings.bindToForUnboundLicense(
            licenseBindingData,
            tenantId ?? "",
            resourceGroupId
          );
        },
        getLicenseByBoundToId: async (boundToId: string) => {
          return this.dependencies.serviceClient.licenseBindings.getLicenseByBoundToId(
            boundToId
          );
        },
        getAllLicenseBindings: async (playload: LicenseBindingQueryData) => {
          const { boundToId, productName, sku, binded } = playload;
          return this.dependencies.serviceClient.licenseBindings.getAllLicenseBindings(
            { boundToId, productName, sku, binded }
          );
        },
        unbind: async (playload: {
          licenseBindingId: string;
          boundToId: string;
        }) => {
          const { licenseBindingId, boundToId } = playload;
          return this.dependencies.serviceClient.licenseBindings.unbind(
            licenseBindingId,
            boundToId
          );
        },
        applyLicense: async (playload: LicenseApplyPlayLoad) => {
          const { mobilePhone, email, description } = playload;
          return this.dependencies.serviceClient.licenseBindings.applyLicense({
            mobilePhone,
            email,
            description,
          });
        },
        importLicense: async (playload: string) => {
          return this.dependencies.serviceClient.licenseBindings.importLicence(
            playload
          );
        },
        licenseBindingsBindTo: async (playload: LicenseBindingData) => {
          return this.dependencies.serviceClient.licenseBindings.licenseBindingsBindTo(
            playload
          );
        },
        getLicensecounters: async (playload: ProductNameOfLicenseCounter) => {
          return this.dependencies.serviceClient.licenseBindings.getLicensecounters(
            playload
          );
        },
      };
    }
  }
);
