export default {
  userCenter: {
    userInfo: "Personal Information",
    settings: "Basic settings",
    name: "Name",
    phone: "Phone Number",
    email: "Email Address",
    binding: "Bind",
    replace: "Change",
    unbind: "Unbind",
    setDefaultTenant: "Set Default Tenant",
    setDefaultResource: "Set Default Resource Group",
    setDefaultTimeZone: "Set Default Timezone",
    setDefaultLanguage: "Set Default Language",
    updateName: "Modify Name",
    currentName: "Current Name",
    currentPhone: "Current Phone Number",
    currentEmail: "Current Email Address",
    placeholder: {
      inputName: "Please enter your new name",
      inputBindingEmail: "Please enter the bound email address",
      inputEmailCode: "Please enter the verification code sent to your mailbox",
      inputOriginalPassword: "Please enter your original password",
      inputNewPassword: "Please enter your new password (Please enter 8-16 characters, including at least three of the four character categories, that is, uppercase characters, lowercase characters, digits, and special characters.)",
      inputNewPasswordAgain: "Please re-enter your new password",
      inputNewEmail: "Please enter your new email address",
      inputNewPhone: "Please enter your new phone number",
      inputPhoneCode: "Please enter the verification code sent to your phone",
      passwordNotMatch: "The passwords you entered do not match.",
      nameLimited: "Your name shall be less than 35 characters.",
    },
    unbindEmailTips: "Whether to unbind the {email} email address?",
    unbindPhoneTips: "Whether to unbind the {phoneNumber} phone number?",
    bindEmail: "Bind Email Address",
    updatePassword: "Modify Password",
    updatePhone: "Change Phone Number",
    updateEmail: "Change Email Address",
    getVerificationCode: "Get Verification Code",
    tenantName: "User Name",
  },
};
