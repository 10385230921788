import { createModel, createSelector } from "nyax";
import { ItemTypes, ValidateTypes } from "src/containers/role/_shared";
import { ModelBase } from "src/store/ModelBase";
import { PermissionEntityModel } from "src/store/models/entity/permission/entity";
export interface TreeDataType {
  key: string;
  title: string;
  children: Array<TreeDataType>;
  parent?: TreeDataType;
  source: ItemTypes;
}

export const PermissionConfig = {
  validate: (data: ItemTypes) => !!(data as ItemTypes)?.displayName,
  key: (data: ItemTypes) => data.name,
  title: (data: ItemTypes) => data.displayName,
  children: (data: ItemTypes) => data.permissions || [],
};
export const RolePermissionModal = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(PermissionEntityModel);
    }
    public initialState() {
      return {
        checkIds: [] as string[],
        isInitialized: false,
        roleId: "",
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setCheckIds: (ids: string[]) => {
          this.state.checkIds = ids;
        },
        setRoleId: (value: string) => {
          this.state.roleId = value;
        },
      };
    }
    public selectors() {
      return {
        dataMap: createSelector(
          () => this._entityContainer.getters.items,
          (items) => {
            const nameMap = new Map<string, ItemTypes>();
            const recursionData = (
              datas: ItemTypes[],
              config: ValidateTypes
            ) => {
              datas.forEach((item) => {
                if (config.validate(item)) {
                  nameMap.set(config.key(item), item);
                  recursionData(config.children(item), config);
                }
              });
            };
            recursionData(items, PermissionConfig);
            return nameMap;
          }
        ),
        displayData: createSelector(
          () => this._entityContainer.getters.items,
          (items) => {
            const nameMap = new Map<string, TreeDataType>();
            const recursionData = (
              datas: ItemTypes[],
              config: ValidateTypes,
              parent?: TreeDataType
            ) => {
              const treeDatas: TreeDataType[] = [];
              datas.forEach((item) => {
                if (config.validate(item)) {
                  const node: TreeDataType = {
                    key: config.key(item),
                    title: config.title(item),
                    parent: parent,
                    children: [],
                    source: item,
                  };
                  node.children = recursionData(
                    config.children(item),
                    config,
                    node
                  );
                  nameMap.set(node.key, node);
                  treeDatas.push(node);
                }
              });
              return treeDatas;
            };
            return recursionData(items, PermissionConfig);
          }
        ),
      };
    }
    public effects() {
      return {};
    }
  }
);
