/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Select } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetContainer } from "src/hooks";
import { AppRouteInfo } from "src/routes";
import { RouterModel } from "src/store/models/router";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { getRouteOfList } from "src/utils/router";

const { Option } = Select;

export default React.memo(function ResourceGroupComponnet() {
  const getContainer = useGetContainer();

  const resourceGroupContainer = getContainer(ResourceGroupModel);
  const loginUserUIModelContainer = getContainer(LoginUserUIModel);
  const router = getContainer(RouterModel);

  const loggedinUser = useSelector(
    () => loginUserUIModelContainer.state.loggedinUser
  );
  const selectedUserTenant = useSelector(
    () => loginUserUIModelContainer.getters.selectedUserTenant
  );
  const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);
  const selectedResourceGroupId = useSelector(
    () => resourceGroupContainer.state.selectedResourceGroupId
  );

  const resourceGroupTableData = useSelector(
    () => resourceGroupContainer.getters.userResourceGroup
  );

  const defaultResourceGroup = useSelector(
    () => resourceGroupContainer.getters.defaultResourceGroup
  );

  const resourceGroupIdByRouter = useMemo(() => {
    const params = currentRouteInfo.params as Record<string, string>;
    const resourceGroupId = params.resourceGroupId || "";
    return resourceGroupId;
  }, [currentRouteInfo.params]);

  const initResourceGroupId: string | undefined = useMemo(() => {
    if (resourceGroupIdByRouter) {
      return resourceGroupIdByRouter;
    }
    if (
      selectedResourceGroupId &&
      resourceGroupTableData?.find(
        (item) => item.id === selectedResourceGroupId
      )
    ) {
      return selectedResourceGroupId;
    } else {
      return defaultResourceGroup?.id || resourceGroupTableData?.[0]?.id;
    }
  }, [
    defaultResourceGroup,
    resourceGroupIdByRouter,
    resourceGroupTableData,
    selectedResourceGroupId,
  ]);

  const listRoute = useMemo(() => {
    return getRouteOfList(currentRouteInfo.type);
  }, [currentRouteInfo.type]);

  const onSetSelectedResourceGroupId = useCallback(
    async (id: string, isInitPageLoaded = false) => {
      if (!isInitPageLoaded) {
        const { params } = currentRouteInfo;
        if (Object.keys(params).includes("resourceGroupId")) {
          currentRouteInfo.params = { ...params, resourceGroupId: id };
          if (
            (currentRouteInfo?.params as {
              resourceGroupId: string;
              id: string;
            })?.id
          ) {
            const currentParams = currentRouteInfo.params as Record<
              string,
              string
            >;
            router.actions.navigateByRouteInfo.dispatch({
              ...listRoute,
              params: { resourceGroupId: currentParams.resourceGroupId },
            } as AppRouteInfo);
          } else {
            router.actions.navigateByRouteInfo.dispatch(currentRouteInfo);
          }
        }
      }
      await resourceGroupContainer.actions.setSelectedResourceGroupId.dispatch(
        id
      );
    },
    [
      currentRouteInfo,
      listRoute,
      resourceGroupContainer.actions.setSelectedResourceGroupId,
      router.actions.navigateByRouteInfo,
    ]
  );

  const initSelectedResourceGroup = useCallback(async () => {
    if (loggedinUser && selectedUserTenant) {
      await onSetSelectedResourceGroupId(initResourceGroupId || "", true);
    }
  }, [
    initResourceGroupId,
    loggedinUser,
    onSetSelectedResourceGroupId,
    selectedUserTenant,
  ]);

  useEffect(() => {
    resourceGroupContainer.actions.initializeRequest.dispatch({});
  }, [resourceGroupContainer.actions.initializeRequest]);

  useEffect(() => {
    initSelectedResourceGroup();
  }, [initSelectedResourceGroup]);

  return (
    <Select
      value={selectedResourceGroupId}
      dropdownMatchSelectWidth
      css={css`
        width: 120px;
      `}
      onChange={(val) => onSetSelectedResourceGroupId(val)}
    >
      {resourceGroupTableData?.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
});
