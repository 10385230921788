import { PackageVersion } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { PackageVersionEntityModel } from "src/store/models/entity/packageVersion/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const PackageVersionListModel = createModel(
  class extends createListModel<PackageVersion>({
    setItems: (getContainer, items) =>
      getContainer(PackageVersionEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(PackageVersionEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageVersionEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          packageId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, packageId, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.package.listPackageVersion(
                resourceGroupId,
                packageId
              ),
            force: force,
          });
        },
      };
    }
  }
);
