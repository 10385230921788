import {
  AppsServiceClient,
  AssetServiceClient,
  AuditlogServiceClient,
  AxiosHttpClient,
  DashboardServiceClient,
  DataPermissionServiceClient,
  DataTableServiceClient,
  HeaderMiddleware,
  HttpClient,
  InstallationServiceClient,
  JobServiceClient,
  LicenseBindingsServiceClient,
  PackageServiceClient,
  PermissionServiceClient,
  Profile,
  QueueServiceClient,
  RequestQueue,
  ResourceGroupServiceClient,
  RobotServiceClient,
  RoleAssignmentServiceClient,
  RoleServiceClient,
  RuninstanceServiceClient,
  ServiceCredential,
  TenantServiceClient,
  UserServiceClient,
  WorkflowServiceClient
} from "@encoo-web/encoo-lib";
import { EncooOidcClient } from "@encoo-web/oidc-client";
import { createBrowserHistory } from "history";
import { Container } from "inversify";
import Oidc from "oidc-client";
import { getDependencies } from "src/dependencies";
import {
  APIError401MiddlewareSymbol,
  DependenciesSymbol,
  ErrorMiddlewareSymbol,
  HistorySymbol,
  HttpClientSymbol,
  ProfileSymbol,
  RequestQueueMiddlewareSymbol,
  ServiceCredentialsSymbol,
  TenantHeaderMiddlewareSymbol,
  UserManagerSettingsSymbol
} from "src/dependencies/identifiers";
import { OidcClientAuthenticationCredential } from "src/dependencies/oidcClient";
import { devProfile } from "src/dependencies/_settings";
import ApplicationInsightsService, {
  devApplicationInsightsConfig,
  prodApplicationInsightsConfig
} from "src/services/ApplicationInsightsService";
import { EnvironmentServiceClient } from "src/services/environmentService";
import { FileServiceClient } from "src/services/FileServiceClient";
import { LocaleService } from "src/services/LocaleService";
import { ReduxService } from "src/services/ReduxService";
import { SystemSettingsServiceClient } from "src/services/SystemSettings";
import { ThemeService } from "src/services/ThemeService";
import { UnderstandingSeviceClient } from "src/services/UnderstandingSeviceClient";
import { ErrorMiddleware } from "src/store/models/ui/error/error";
import { createInjectableFactory } from "src/utils/inversify";

export interface ExtraQueryParams {
  inviteCode: string;
}

declare global {
  interface Window {
    _settings: {
      endpoint: string;
      managementUrl: string;
      consoleV4CompanyEntryUrl: string;
      appsUrl: string;
      env: string;
      customizedCustomer?: "tss" | null;
      disableApplicationInsights?: false;
    };
  }
}

const container = new Container({
  defaultScope: "Singleton",
});

container.bind(DependenciesSymbol).toDynamicValue(() => {
  return getDependencies();
});
container.bind(HistorySymbol).toDynamicValue(() => {
  return createBrowserHistory();
});

container
  .bind(LocaleService)
  .toDynamicValue(createInjectableFactory(LocaleService));
container
  .bind(ReduxService)
  .toDynamicValue(createInjectableFactory(ReduxService));
container
  .bind(ThemeService)
  .toDynamicValue(createInjectableFactory(ThemeService));

const hostPrefix = `${window.location.protocol}//${window.location.host}`;

container.bind(UserManagerSettingsSymbol).toDynamicValue((context) => {
  const extraQueryParams = {} as ExtraQueryParams;
  const inviteCode = new URLSearchParams(window.location.search).get(
    "inviteCode"
  );
  if (inviteCode) {
    extraQueryParams.inviteCode = inviteCode;
  }

  return {
    authority: context.container.get<Profile>(ProfileSymbol).managementUrl,
    /* eslint-disable */
    client_id: "encoo_console_v3",
    redirect_uri: `${hostPrefix}/callback`,
    silent_redirect_uri: `${hostPrefix}/silent_renew`,
    post_logout_redirect_uri: `${hostPrefix}/index`,
    response_type: "code",
    /* eslint-enable */
    scope: "openid profile apigateway offline_access",
    accessTokenExpiringNotificationTime: 4,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    // eslint-disable-next-line @typescript-eslint/camelcase
    response_mode: "query",
    extraQueryParams,
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  };
});

container
  .bind(EncooOidcClient)
  .toDynamicValue(
    (context) =>
      new EncooOidcClient(context.container.get(UserManagerSettingsSymbol))
  );

container
  .bind(ServiceCredentialsSymbol)
  .toDynamicValue(
    (context) =>
      new OidcClientAuthenticationCredential(
        context.container.get(EncooOidcClient)
      )
  );

container
  .bind(TenantHeaderMiddlewareSymbol)
  .toDynamicValue(() => new HeaderMiddleware("TenantId", ""));

container
  .bind(RequestQueueMiddlewareSymbol)
  .toDynamicValue(() => new RequestQueue());

container.bind(APIError401MiddlewareSymbol).toDynamicValue((context) => {
  const errorMiddleware = new ErrorMiddleware();

  errorMiddleware.setErrorAction((err) => {
    if (!err.response) return;
    switch (err.response.status) {
      case 401:
        context.container.get(EncooOidcClient).signinSilent();
        return;
    }
  });
  return errorMiddleware;
});

container
  .bind(ErrorMiddlewareSymbol)
  .toDynamicValue(() => new ErrorMiddleware());

container.bind(HttpClientSymbol).toDynamicValue((context) => {
  const client = new AxiosHttpClient();
  client.use(context.container.get(APIError401MiddlewareSymbol));
  client.use(context.container.get(ErrorMiddlewareSymbol));
  client.use(context.container.get(TenantHeaderMiddlewareSymbol));
  client.use(context.container.get(RequestQueueMiddlewareSymbol));
  return client;
});

if (
  window._settings &&
  window._settings.endpoint &&
  window._settings.managementUrl
) {
  // container.bind(ProfileSymbol).toDynamicValue(() => preprodProfile);
  container.bind(ProfileSymbol).toDynamicValue(() => window._settings);
} else {
  container.bind(ProfileSymbol).toDynamicValue(() => devProfile);
}

container
  .bind(ResourceGroupServiceClient)
  .toDynamicValue(
    (context) =>
      new ResourceGroupServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(PackageServiceClient)
  .toDynamicValue(
    (context) =>
      new PackageServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(WorkflowServiceClient)
  .toDynamicValue(
    (context) =>
      new WorkflowServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RobotServiceClient)
  .toDynamicValue(
    (context) =>
      new RobotServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RoleServiceClient)
  .toDynamicValue(
    (context) =>
      new RoleServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RoleAssignmentServiceClient)
  .toDynamicValue(
    (context) =>
      new RoleAssignmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(UserServiceClient)
  .toDynamicValue(
    (context) =>
      new UserServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AuditlogServiceClient)
  .toDynamicValue(
    (context) =>
      new AuditlogServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(QueueServiceClient)
  .toDynamicValue(
    (context) =>
      new QueueServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(AppsServiceClient)
  .toDynamicValue(
    (context) =>
      new AppsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(PermissionServiceClient)
  .toDynamicValue(
    (context) =>
      new PermissionServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(LicenseBindingsServiceClient)
  .toDynamicValue(
    (context) =>
      new LicenseBindingsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(RuninstanceServiceClient)
  .toDynamicValue(
    (context) =>
      new RuninstanceServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(JobServiceClient)
  .toDynamicValue(
    (context) =>
      new JobServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(UnderstandingSeviceClient)
  .toDynamicValue(
    (context) =>
      new UnderstandingSeviceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(InstallationServiceClient)
  .toDynamicValue(
    (context) =>
      new InstallationServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DashboardServiceClient)
  .toDynamicValue(
    (context) =>
      new DashboardServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(EnvironmentServiceClient)
  .toDynamicValue(
    (context) =>
      new EnvironmentServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(AssetServiceClient)
  .toDynamicValue(
    (context) =>
      new AssetServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(TenantServiceClient)
  .toDynamicValue(
    (context) =>
      new TenantServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(FileServiceClient)
  .toDynamicValue(
    (context) =>
      new FileServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container
  .bind(DataPermissionServiceClient)
  .toDynamicValue(
    (context) =>
      new DataPermissionServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );
container
  .bind(DataTableServiceClient)
  .toDynamicValue(
    (context) =>
      new DataTableServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

container.bind(ApplicationInsightsService).toDynamicValue((context) => {
  const env = context.container.get<Profile>(ProfileSymbol).env;
  const config =
    env === "prod"
      ? prodApplicationInsightsConfig
      : devApplicationInsightsConfig;
  return new ApplicationInsightsService({
    ...config,
    disableTelemetry: !!window._settings.disableApplicationInsights,
  });
});

container
  .bind(SystemSettingsServiceClient)
  .toDynamicValue(
    (context) =>
      new SystemSettingsServiceClient(
        context.container.get<HttpClient>(HttpClientSymbol),
        context.container.get<ServiceCredential>(ServiceCredentialsSymbol),
        context.container.get<Profile>(ProfileSymbol)
      )
  );

export default container;
