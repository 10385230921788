import { Robot, RobotScope } from "@encoo-web/encoo-lib/";
import { createModel, createSelector } from "nyax";
import { Key } from "react";
import { ModelBase } from "src/store/ModelBase";
import { ROBOT_SCOPE_MAP } from "src/store/models/ui/robot/robot";

export type CreateQueueContentType = "basic" | "bindWorkflow" | "associatedJob";
export type ConfigType = "view" | "edit";

export const QueueDetailUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        activeKey: "basic",
        bindingRobots: [] as Robot[],
        isShowBindRobotsModal: false,
        selectedRobotIds: [] as Key[],
        selectedGlobalRobotIds: [] as Key[],
        configType: "view" as ConfigType,
        scope: "ResourceGroup" as RobotScope,
      };
    }

    public selectors() {
      return {
        isGlobal: createSelector(
          () => this.state.scope,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
      };
    }

    public reducers() {
      return {
        setActiveKey: async (value: CreateQueueContentType) => {
          this.state.activeKey = value;
        },
        setBindingRobots: async (value: Robot[]) => {
          this.state.bindingRobots = value;
        },
        setBindRobotsModal: async (value: boolean) => {
          this.state.isShowBindRobotsModal = value;
        },
        setSelectedGlobalRobotIds: async (value: Key[]) => {
          this.state.selectedGlobalRobotIds = value;
        },
        setSelecteRobotIds: async (value: Key[]) => {
          this.state.selectedRobotIds = value;
        },
        setConfigType: async (value: ConfigType) => {
          this.state.configType = value;
        },
        setRobotScope: (value: RobotScope) => {
          this.state.scope = value;
        },
      };
    }

    public effects() {
      return {
        getBindingRobots: async (payload: {
          queueId: string;
          resourceGroupId: string;
        }) => {
          const { queueId, resourceGroupId } = payload;
          const robots = await this.dependencies.serviceClient.queue.getAllBindingInfos(
            resourceGroupId,
            queueId
          );
          await this.actions.setBindingRobots.dispatch(robots);
        },
        handleSelectedRobotIds: async (payload: {
          value: Key[];
          isGlobal?: boolean;
        }) => {
          const { value, isGlobal = false } = payload;
          if (isGlobal) {
            await this.actions.setSelectedGlobalRobotIds.dispatch(value);
          } else {
            await this.actions.setSelecteRobotIds.dispatch(value);
          }
        },
        clearRobotBindingData: async () => {
          await this.actions.setSelectedGlobalRobotIds.dispatch([]);
          await this.actions.setSelecteRobotIds.dispatch([]);
          await this.actions.setRobotScope.dispatch("ResourceGroup");
        },
      };
    }
  }
);
