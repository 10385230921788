import Axios from "axios";
import { createModel } from "nyax";
import { UnderstandingResult } from "src/models/understanding";
import { UnderstandingResultEntityModel } from "src/store/models/entity/understandingResult/entity";
import { createHelperModel } from "src/store/models/entity/_shared";

export const UnderstandingResultHelperModel = createModel(
  class extends createHelperModel<UnderstandingResult>({
    setItems: (getContainer, items) =>
      getContainer(UnderstandingResultEntityModel).actions.setItems.dispatch(
        items
      ),
    getItems: (getContainer) =>
      getContainer(UnderstandingResultEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(UnderstandingResultEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),

        readById: async (payload: {
          resourceGroupId: string;
          id: string;
          force?: boolean;
        }) => {
          const { id, resourceGroupId, force } = payload;
          return await this._readById({
            id,
            getByIdAction: async () => {
              const result = await this.dependencies.serviceClient.understanding.getUnderstandingResultById(
                resourceGroupId,
                id
              );

              return result;
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          templateId: string;
          file: File;
        }) => {
          const { resourceGroupId, templateId, file } = payload;

          return await this._create({
            createAction: async () => {
              const understandingResult = await this.dependencies.serviceClient.understanding.createUnderstandingResult(
                resourceGroupId,
                templateId
              );

              if (!understandingResult.uploadUri) {
                throw new Error("upload uri is not exist");
              }
              delete Axios.defaults.headers.put["Content-Type"];
              await Axios({
                method: "PUT",
                headers: understandingResult.uploadUri.headers,
                url: understandingResult.uploadUri.uri,
                data: file,
              });
              return await this.dependencies.serviceClient.understanding.patchUnderstandingResult(
                resourceGroupId,
                understandingResult.id
              );
            },
          });
        },
      };
    }
  }
);
