import { Package } from "@encoo-web/encoo-lib";
import Axios from "axios";
import { createModel } from "nyax";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageListModel } from "src/store/models/entity/package/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const PackageHelperModel = createModel(
  class extends createHelperModel<Package>({
    setItems: (getContainer, items) =>
      getContainer(PackageEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(PackageEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(PackageEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(PackageListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(PackageEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByGroupIds: async (payload: {
          resourceGroupIds: string[];
          force?: boolean;
        }) => {
          const { resourceGroupIds } = payload;
          return await this._readByParentIds({
            parentIds: resourceGroupIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.package.getAll(parentId);
            },
            getEntityParentId: (entity: Package) => entity.resourceGroupId,
            force: true,
          });
        },

        getById: async (payload: {
          resourceGroupId: string;
          packageId: string;
        }) => {
          const { resourceGroupId, packageId } = payload;

          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [packageId],
          });

          try {
            const item = await this.dependencies.serviceClient.package.getById(
              resourceGroupId,
              packageId
            );

            await this.actions._rw.endWrite.dispatch({
              items: [item],
              timestamp,
            });
            return item;
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },

        uploadPackage: async (payload: { groupId: string; file: File }) => {
          const { groupId, file } = payload;
          const uploadData = await this.dependencies.serviceClient.package.createPreloadedUri(
            groupId
          );
          const { id, headers, uploadUri } = uploadData;
          delete Axios.defaults.headers.put["Content-Type"];
          await Axios({
            method: "PUT",
            headers,
            url: uploadUri,
            data: file,
          });
          const item = await this.dependencies.serviceClient.package.updataPreloadedVersionFiles(
            id,
            groupId
          );
          return item;
        },

        delete: async (payload: { id: string; resourceGroupId: string }) => {
          const { id, resourceGroupId } = payload;
          const { timestamp } = await this.actions._rw.beginWrite.dispatch({
            ids: [id],
          });
          try {
            await this.dependencies.serviceClient.package.delete(
              resourceGroupId,
              id
            );
            await this.actions._rw.endWrite.dispatch({
              items: [id],
              timestamp,
            });
          } catch (error) {
            await this.actions._rw.endWrite.dispatch({
              items: [],
              timestamp,
            });
            throw error;
          }
        },
      };
    }
  }
);
