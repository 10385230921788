import {
  Robot,
  RobotScope,
  WorkflowCreationData,
  WorkflowUpdateData,
} from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { PackageEntityModel } from "src/store/models/entity/package/entity";
import { PackageHelperModel } from "src/store/models/entity/package/helper";
import { PackageVersionEntityModel } from "src/store/models/entity/packageVersion/entity";
import { PackageVersionHelperModel } from "src/store/models/entity/packageVersion/helper";
import { QueueHelperModel } from "src/store/models/entity/queue/helper";
import { WorkflowHelperModel } from "src/store/models/entity/workflow/helper";
import { ROBOT_SCOPE_MAP } from "src/store/models/ui/robot/robot";
import {
  ActiveTabViewType,
  WorkflowDetailViewModeType,
} from "src/store/models/ui/workflow/types";
import { handleErrorNotFound } from "src/utils/error";

export type PackageVersionIdForWorkflow = {
  packageId: string;
  packageVersionId: string;
};

export const WorkflowDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        activeKey: "trigger" as ActiveTabViewType,
        workflowDetailViewMode: "view" as WorkflowDetailViewModeType,
        creationData: {} as WorkflowCreationData,
        editData: {} as WorkflowUpdateData,
        robotIdsForCreatWorkflow: [] as Array<string>,
        globalRobotIdsForCreatWorkflow: [] as string[],
        robotIdsForEditWorkflow: [] as Array<string>,
        robotListForEditWorkflow: [] as Array<Robot>,
        packageVersionIdForWorkflow: null as PackageVersionIdForWorkflow | null,
        scopeForCreateWorkflow: ROBOT_SCOPE_MAP.RESOURCEGROUP as RobotScope,
        scopeForEditWorkflow: ROBOT_SCOPE_MAP.RESOURCEGROUP as RobotScope,
        bindRobotsForWorkflow: [] as Robot[],
      };
    }

    public selectors() {
      return {
        workflowAssociatedPackageInfo: createSelector(
          () => this.state.packageVersionIdForWorkflow,
          () => {
            if (this.state.packageVersionIdForWorkflow) {
              return this.getContainer(PackageEntityModel).state.byId[
                this.state.packageVersionIdForWorkflow.packageId
              ];
            }
          },
          () => {
            if (this.state.packageVersionIdForWorkflow) {
              return this.getContainer(PackageVersionEntityModel).state.byId[
                this.state.packageVersionIdForWorkflow.packageVersionId
              ];
            }
          },
          (packageVersionIdForWorkflow, packageDetail, packageVersion) => {
            if (packageVersionIdForWorkflow) {
              return {
                package: packageDetail,
                packageVersion,
              };
            }
          }
        ),
        isGlobalForCreate: createSelector(
          () => this.state.scopeForCreateWorkflow,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
        isGlobalForEdit: createSelector(
          () => this.state.scopeForEditWorkflow,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
      };
    }

    public reducers() {
      return {
        setActiveKey: (value: ActiveTabViewType) => {
          this.state.activeKey = value;
        },
        setWorkflowDetailViewMode: (value: WorkflowDetailViewModeType) => {
          this.state.workflowDetailViewMode = value;
        },
        updateEditData: (value: Partial<WorkflowUpdateData>) => {
          this.state.editData = { ...this.state.editData, ...value };
        },
        setCreationData: (value: WorkflowCreationData) => {
          this.state.creationData = value;
        },
        updateCreationData: (value: Partial<WorkflowCreationData>) => {
          this.state.creationData = { ...this.state.creationData, ...value };
        },
        setRobotIdsForCreatWorkflow: (value: Array<string>) => {
          this.state.robotIdsForCreatWorkflow = value;
        },
        setGlobalRobotIdsForCreatWorkflow: (value: Array<string>) => {
          this.state.globalRobotIdsForCreatWorkflow = value;
        },
        setRobotListForEditWorkflow: (value: Array<Robot>) => {
          this.state.robotListForEditWorkflow = value;
        },
        setPackageVersionIdForWorkflow: (
          value: PackageVersionIdForWorkflow | null
        ) => {
          this.state.packageVersionIdForWorkflow = value;
        },
        setScopeForCreateWorkflow: (scope: RobotScope) => {
          this.state.scopeForCreateWorkflow = scope;
        },
        setScopeForEditWorkflow: (scope: RobotScope) => {
          this.state.scopeForEditWorkflow = scope;
        },
        setBindRobotForWorkflow: (robots: Robot[]) => {
          this.state.bindRobotsForWorkflow = robots;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestWorkflowRobots: async (payload: {
          workflowId: string;
          resourceGroupId: string;
        }) => {
          const { workflowId, resourceGroupId } = payload;
          const robotList = await this.getContainer(
            WorkflowHelperModel
          ).actions.getRobots.dispatch({
            resourceGroupId,
            workflowId,
          });
          await this.actions.setBindRobotForWorkflow.dispatch(robotList);
          return robotList;
        },
        requestWorkflowPackageInfo: async (payload: {
          resourceGroupId: string;
          packageId: string;
          versionId: string;
        }) => {
          const { resourceGroupId, packageId, versionId } = payload;
          await this.actions.setPackageVersionIdForWorkflow.dispatch({
            packageId,
            packageVersionId: versionId,
          });

          try {
            await this.getContainer(
              PackageHelperModel
            ).actions.getById.dispatch({
              resourceGroupId,
              packageId,
            });
            await this.getContainer(
              PackageVersionHelperModel
            ).actions.getVersionDetail.dispatch({
              resourceGroupId,
              packageId,
              versionId,
            });
          } catch (error) {
            handleErrorNotFound(error);
          }
        },
        requestWorkflowQueueInfo: async (payload: {
          queueId: string;
          resourceGroupId: string;
        }) => {
          const { queueId, resourceGroupId } = payload;
          await this.getContainer(QueueHelperModel).actions.getById.dispatch({
            resourceGroupId,
            queueId,
          });
        },
        handleSelectedRobotForCreateWorkflow: async (value: string[]) => {
          if (this.getters.isGlobalForCreate) {
            await this.actions.setGlobalRobotIdsForCreatWorkflow.dispatch(
              value
            );
          } else {
            await this.actions.setRobotIdsForCreatWorkflow.dispatch(value);
          }
        },
      };
    }
  }
);
