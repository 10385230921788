/** @jsx jsx */
import { Global, jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import "moment/locale/zh-cn";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { AlertProvider } from "src/components/Dialog";
import { DependenciesContext, ThemeContext } from "src/contexts";
import { getDependencies } from "src/dependencies";
import cssGlobal from "src/styles/global";

export default React.memo(function AppWrapper(
  props: React.PropsWithChildren<{}>
) {
  const { children } = props;

  const [dependencies] = useState(getDependencies());
  const [history] = useState(dependencies.history);
  const [intl] = useState(dependencies.locale.intl);
  const [antdLocale] = useState(dependencies.locale.antdLocale);
  const [store] = useState(dependencies.redux.store);

  return (
    <DndProvider backend={HTML5Backend}>
      <DependenciesContext.Provider value={dependencies}>
        <RawIntlProvider value={intl}>
          <ConfigProvider locale={antdLocale}>
            <Provider store={store}>
              <ThemeContext.Provider value={dependencies.theme.themeSet.main}>
                <AlertProvider>
                  <ConnectedRouter history={history}>
                    <Global
                      styles={css`
                        .ant-spin-dot-item {
                          background: ${dependencies.theme.themeSet.main
                            .primaryColor};
                        }
                        ${cssGlobal}
                      `}
                    />
                    {children}
                  </ConnectedRouter>
                </AlertProvider>
              </ThemeContext.Provider>
            </Provider>
          </ConfigProvider>
        </RawIntlProvider>
      </DependenciesContext.Provider>
    </DndProvider>
  );
});
