import { Robot } from "@encoo-web/encoo-lib";
import { RobotCreationData } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { RobotEntityModel } from "src/store/models/entity/robot/entity";
import { RobotListModel } from "src/store/models/entity/robot/list";
import { createHelperModel } from "src/store/models/entity/_shared";
export const RobotHelperModel = createModel(
  class extends createHelperModel<Robot>({
    setItems: (getContainer, items) =>
      getContainer(RobotEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(RobotEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RobotEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(RobotListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(RobotEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: {
          groupIds: string[];
          force?: boolean;
        }) => {
          const { groupIds, force } = payload;
          this._readByParentIds({
            parentIds: groupIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.robot.getAll(parentId);
            },
            getEntityParentId: (entity: Robot) => entity.resourceGroupId,
            force,
          });
        },
        getById: async (payload: {
          resourceGroupId: string;
          robotId: string;
          isGlobal?: boolean;
        }) => {
          const { resourceGroupId, robotId, isGlobal = false } = payload;
          return this._readById({
            id: robotId,
            force: true,
            getByIdAction: () => {
              if (isGlobal) {
                return this.dependencies.serviceClient.robot.getGlobalById(
                  resourceGroupId,
                  robotId
                );
              }
              return this.dependencies.serviceClient.robot.getById(
                resourceGroupId,
                robotId
              );
            },
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          robot: RobotCreationData;
          isGlobal?: boolean;
        }) => {
          const { resourceGroupId, robot, isGlobal = false } = payload;
          return this._create({
            createAction: () => {
              if (isGlobal) {
                return this.dependencies.serviceClient.robot.createGlobal(
                  resourceGroupId,
                  robot
                );
              }
              return this.dependencies.serviceClient.robot.create(
                resourceGroupId,
                robot
              );
            },
          });
        },
        delete: async (payload: {
          resourceGroupId: string;
          robotId: string;
          isGlobal?: boolean;
        }) => {
          const { resourceGroupId, robotId, isGlobal = false } = payload;
          return this._delete({
            id: robotId,
            deleteAction: () => {
              if (isGlobal) {
                return this.dependencies.serviceClient.robot.deleteGlobal(
                  resourceGroupId,
                  robotId
                );
              }
              return this.dependencies.serviceClient.robot.delete(
                resourceGroupId,
                robotId
              );
            },
          });
        },
        update: async (payload: {
          resourceGroupId: string;
          robotId: string;
          robot: RobotCreationData;
          isGlobal?: boolean;
        }) => {
          const { resourceGroupId, robotId, robot, isGlobal = false } = payload;
          return this._update({
            id: robotId,
            updateAction: () => {
              if (isGlobal) {
                return this.dependencies.serviceClient.robot.updateGlobal(
                  resourceGroupId,
                  robotId,
                  robot
                );
              }
              return this.dependencies.serviceClient.robot.update(
                resourceGroupId,
                robotId,
                robot
              );
            },
          });
        },
        getRunInstance: async (payload: {
          resourceGroupId: string;
          robotId: string;
        }) => {
          const { resourceGroupId, robotId } = payload;
          return await this.dependencies.serviceClient.robot.getRunInstance(
            resourceGroupId,
            robotId
          );
        },
        createRunInstance: async (payload: {
          resourceGroupId: string;
          robotId: string;
          robot: RobotCreationData;
        }) => {
          const { resourceGroupId, robotId, robot } = payload;
          return await this.dependencies.serviceClient.robot.createRunInstance(
            resourceGroupId,
            robotId,
            robot
          );
        },
        deleteRunInstance: async (payload: {
          resourceGroupId: string;
          robotId: string;
        }) => {
          const { resourceGroupId, robotId } = payload;
          return await this.dependencies.serviceClient.robot.deleteRunInstance(
            resourceGroupId,
            robotId
          );
        },
      };
    }
  }
);
