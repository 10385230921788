export default {
  menu: {
    dashboard: {
      name: "ダッシュボード",
      overallMonitor: { name: "全体モニタ" },
      userFlowStatistics: { name: "ユーザーのプロセス統計表" },
      robotRunStatistics: { name: "ロボット実行統計表" },
      robotStatusStatistics: { name: "ロボットモニタ" },
      queueStatistics: { name: "キューモニター" },
    },
    rpa: {
      name: "RPA管理",
      package: { name: "パッケージ管理" },
      workflow: { name: "プロセス展開" },
      robot: { name: "ロボット管理" },
      queue: { name: "キュー" },
      log: { name: "タスクレコード" },
    },
    apps: {
      name: "アプリ管理",
    },
    understanding: {
      name: "文書理解",
      editor: {
        name: "テンプレートを編集",
      },
      tester: {
        name: "テスト結果",
      },
    },
    data: {
      name: "データセンター",
      asset: { name: "資産管理" },
      file: { name: "ファイルサービス" },
      dataTable: { name: "データテーブル管理" },
    },
    setting: {
      name: "グローバル管理",
      organization: { name: "組織構成管理" },
      user: { name: "ユーザー情報" },
      role: { name: "ロール管理" },
      resourcegroup: { name: "リソースグループ管理" },
      auditlog: { name: "監査ログ" },
      license: { name: "ライセンス" },
      userCenter: { name: "ユーザーセンター" },
      systemSetting: { name: "Setting" },
    },
  },
  home: {
    welcomePanel: {
      title: "ENCOOコンソールへようこそ",
      lastLandingTime: "前回のログイン時刻",
      currentTenant: "現在のテナント",
      enterprise: "エンタープライズ版",
      community: "コミュニティ版",
      applyForEnterprise: "エンタープライズ版のライセンスを申請",
    },
    navigation: {
      title: "一般的なリンク",
      dashboard: "ダッシュボード",
      uploadPackage: "パッケージをアップロード",
      processDeployment: "プロセス展開",
      robotManagement: "ロボット管理",
      performLog: "タスクレコード",
    },
    service: {
      title: "その他のサービス",
      documentationUnderstand: "文書理解",
      appManagement: "アプリ管理",
      dataCenter: "データセンター",
    },
    practicalLink: {
      title: "ドキュメントのリンク",
      consoleDocument: "ENCOOドキュメント",
      consoleCourse: "ENCOOレッスン",
      consoleBBS: "ENCOOフォーラム",
    },
    packageDownload: {
      title: "インストールパッケージをダウンロード",
      name: "アプリ名",
      editName: "Encoo Studio",
      robotName: "ENCOOロボット",
      releaseTime: "公開された時間",
      version: "バージョン",
      download: "ダウンロード",
    },
    serviceAddress: {
      title: "サービスアドレス",
      address: "Studio接続用文字列",
      reminder:
        "(この文字列は、コンソールでStudioを登録とアクティブ化するために使用されます。ボタンをクリックして文字列をコピーしてください)",
      signScuess: "登録中...",
    },
  },
};
