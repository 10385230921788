export default {
  auditlog: {
    introduction: "This page is used to display the detailed operation records of each user of the console, for the convenience of auditing and tracking.",
    createdAt: "Operation Time",
    auditObjectTypeDisplayName: "Operation Details",
    auditObjectType: "Operation Module",
    userDisplayName: "Operator",
    startTime: "Start Time",
    endTime: "End Time",
  },
};
