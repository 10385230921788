export default {
  runInstance: {
    viewLog: "查看日志",
    logInfo: "日志信息",
    logTime: "日志时间",
    logTypeList: {
      systemLog: "系统日志",
      defLog: "自定义日志",
    },
    allLog: "全部",
    errLog: "错误",
    infoLog: "信息",
    debugLog: "调试",
    logRank: "日志级别",
    logType: "日志类型",
    logContent: "日志内容",
    screenshotShown: "截图展示",
    runinstanceNoVideoTip: "当前任务无视频记录",
    videoShow: "视频展示",
    noScreenshotShown: "当前无截图记录",
  },
  execution: {
    runOrder: "执行次序",
    executionRobot: "执行机器人",
    log: "日志",
    priority: "优先执行",
    restart: "重新启动",
    termination: "终止",
    expansion: "展开",
    taskSource: "任务来源",
    status: {
      create: "创建",
      running: "运行",
      failed: "失败",
      cancelled: "取消",
      succeeded: "成功",
      cancelling: "取消中",
    },
  },
};
