// todo 数据结构前端暂时定。api对接时根据后台数据格式再调整

import { Entity } from "@encoo-web/encoo-lib/types";

export type LabelReferenceDirection = "Up" | "Right" | "Down" | "Left";
export type LabelFieldType = "string" | "number" | "date";
export type UnderstandingDocumentType =
  | "pdf-text"
  | "pdf-image"
  | "image-standalone";

export interface Anchor {
  text?: string;
  direction?: LabelReferenceDirection;
}

export interface Label {
  id?: string;
  pageIndex?: number;
  name: string;
  rect: Rect;
  fieldDataType?: string;
  anchors?: Anchor[];
  textValue?: string;
}

export interface UploadInfo {
  uri?: string;
  headers?: {
    additionalProp1?: string;
    additionalProp2?: string;
    additionalProp3?: string;
  };
}

export interface TemplatePage {
  pageIndex: number;
  labelDefinations?: Label[];
  fixedAnchors?: TemplateFileBound[];
}

export interface TemplateCreationData {
  name: string;
  documentType: UnderstandingDocumentType;
  description?: string;
}

export interface TemplateImage {
  imageString: string;
  width: number;
  height: number;
  resolutionRate: number;
}

export interface TemplateFileBound extends Rect {
  text?: string;
}

export interface TemplateFilePage {
  pageIndex: number;
  image: TemplateImage;
  bounds?: TemplateFileBound[];
}
export interface TemplateFile {
  templateId: string;
  pages: TemplateFilePage[];
}

export interface Template extends TemplateCreationData, Entity {
  id: string;
  name: string;
  description?: string;
  tenantId: string;
  resourceGroupId: string;
  isPublished: boolean;
  pages?: TemplatePage[];
  uploadUri?: UploadInfo;
}

export interface UnderstandingResultPage {
  pageIndex: number;
  labelResults?: Label[];
  image: TemplateImage;
}

export interface UnderstandingResult extends Entity {
  id: string;
  templateId: string;
  pages?: UnderstandingResultPage[];
  uploadUri?: UploadInfo;
}

/**************************
 * 矩形框相关结构以及操作
 ***************************/
export type ResizeDirection =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

export interface Size {
  width: number;
  height: number;
}

export interface Point {
  x: number;
  y: number;
}

export type Rect = Point & Size;

export const EMPTY_SIZE = {
  width: 0,
  height: 0,
};

export const resizePointWithRadio = function (point: Point, radio: number) {
  return {
    x: point.x * radio,
    y: point.y * radio,
  };
};

export const resizeSizeWithRadio = function (size: Size, radio: number) {
  return {
    width: size.width * radio,
    height: size.height * radio,
  };
};

export const resizeRectWithRadio = function (rect: Rect, radio: number) {
  return {
    x: rect.x * radio,
    y: rect.y * radio,
    width: rect.width * radio,
    height: rect.height * radio,
  };
};

export const getRectFromPoint = (startPoint: Point, endPoint: Point) => {
  return {
    x: Math.min(startPoint.x, endPoint.x),
    y: Math.min(startPoint.y, endPoint.y),
    width: Math.abs(endPoint.x - startPoint.x),
    height: Math.abs(endPoint.y - startPoint.y),
  };
};
