import { createModel, createSelector } from "nyax";
import { filter, map, pairwise } from "rxjs/operators";
import {
  Anchor,
  EMPTY_SIZE,
  Label,
  Point,
  Rect,
  resizePointWithRadio,
  resizeRectWithRadio,
  resizeSizeWithRadio,
  Size,
  TemplateFile,
  TemplateFileBound,
  TemplateImage,
  TemplatePage,
  UnderstandingDocumentType
} from "src/models/understanding";
import { ModelBase } from "src/store/ModelBase";
import { UnderstandingResultEntityModel } from "src/store/models/entity/understandingResult/entity";
import { UnderstandingResultHelperModel } from "src/store/models/entity/understandingResult/helper";
import { TemplateEntityModel } from "src/store/models/entity/understandingTemplate/entity";
import { TemplateHelperModel } from "src/store/models/entity/understandingTemplate/helper";
import { newGuid } from "src/utils/uuid";

type ViewMode = "Editor" | "Tester" | null;
type EditMode = "editLabel" | "dragCanvas";
type ListMode = "archors" | "labels";

export interface UITemplateAnchor extends TemplateFileBound {
  id: string;
  pageIndex: number;
  rect: Rect;
}

export const UITemplateEditorModel = createModel(
  class extends ModelBase {
    private _findImage(pageIndex: number) {
      const viewMode = this.state.viewMode;
      const file = this.state.file;
      const id = this.state.id;
      let image: TemplateImage | undefined = undefined;
      if (viewMode === "Editor") {
        image = file?.pages.find((page) => page.pageIndex === pageIndex)?.image;
      } else {
        image = this.getContainer(UnderstandingResultEntityModel).state.byId[
          id
        ]?.pages?.find((page) => page.pageIndex === pageIndex)?.image;
      }

      return image;
    }

    private _getBoundId(bound: TemplateFileBound) {
      return `${bound.text}|${bound.x}|${bound.y}|${bound.width}|${bound.height}`;
    }

    public initialState() {
      return {
        canvasRatio: 100,
        isInitialized: false,
        isCanvasRatioInitialized: false,
        viewMode: null as ViewMode,
        documentType: null as UnderstandingDocumentType | null,
        listMode: "archors" as ListMode,
        id: "",
        editMode: "editLabel" as EditMode,
        labels: [] as Label[],
        selectedLabelId: null as string | null,
        validateAllError: false,
        currentPageIndex: 0,
        imageUrls: {} as Record<number, string> | null,
        file: null as TemplateFile | null,
        resourceGroupId: null as string | null,
        anchors: [] as UITemplateAnchor[],
        fixedAnchorIds: [] as string[],
        selectedAnchorId: null as string | null,
      };
    }
    public reducers() {
      return {
        setInitialized: (value: boolean) => {
          this.state.isInitialized = value;
        },
        setCanvasRatioInitialized: (value: boolean) => {
          this.state.isCanvasRatioInitialized = value;
        },
        setCanvasRatio: (canvasRatio: number) => {
          this.state.canvasRatio = canvasRatio;
        },
        setViewMode: (mode: ViewMode) => {
          this.state.viewMode = mode;
        },
        setListMode: (mode: ListMode) => {
          this.state.listMode = mode;
        },
        setEditMode: (mode: EditMode) => {
          this.state.editMode = mode;
        },
        setDocumentType: (type: UnderstandingDocumentType | null) => {
          this.state.documentType = type;
        },
        setLabels: (labels: Label[]) => {
          this.state.labels = labels;
        },
        setSelectedLabelId: (labelId?: string | null) => {
          this.state.selectedLabelId = labelId ?? null;
        },
        setValidateAllError: (value: boolean) => {
          this.state.validateAllError = value;
        },
        setId: (id: string) => {
          this.state.id = id;
        },
        setCurrentPageIndex: (pageIndex: number) => {
          this.state.currentPageIndex = pageIndex;
        },
        setFile: (file: TemplateFile | null) => {
          this.state.file = file;
        },
        setImageUrls: (images: Record<number, string>) => {
          this.state.imageUrls = images;
        },
        setResourceGroupId: (resourceGroupId: string | null) => {
          this.state.resourceGroupId = resourceGroupId;
        },
        setAnchors: (anchors: UITemplateAnchor[]) => {
          this.state.anchors = anchors;
        },
        setFixedAnchorIds: (anchorIds: string[]) => {
          this.state.fixedAnchorIds = anchorIds;
        },
        setSelectedAnchorId: (anchorId: string | null) => {
          this.state.selectedAnchorId = anchorId;
        },
      };
    }
    public selectors() {
      return {
        pageCount: createSelector(
          () => this.state.viewMode,
          (): TemplateFile | null => this.state.file,
          () => this.state.id,
          (viewMode, file, id) => {
            if (viewMode === "Editor") {
              return file?.pages.length;
            } else {
              return this.getContainer(UnderstandingResultEntityModel).state
                .byId[id]?.pages?.length;
            }
          }
        ),
        imageUrl: createSelector(
          (): Record<number, string> | null => this.state.imageUrls,
          () => this.state.currentPageIndex,
          (imageUrls, currentPageIndex) => {
            return imageUrls?.[currentPageIndex];
          }
        ),
        imageSize: createSelector(
          () => this.state.viewMode,
          (): TemplateFile | null => this.state.file,
          () => this.state.currentPageIndex,
          () =>
            this.getContainer(UnderstandingResultEntityModel).state.byId[
              this.state.id
            ],
          (viewMode, file, currentPageIndex, understandingResult) => {
            let image: TemplateImage | undefined = undefined;
            if (viewMode === "Editor") {
              image = file?.pages.find(
                (page) => page.pageIndex === currentPageIndex
              )?.image;
              if (!image) {
                image = file?.pages.find((page) => page.pageIndex === 0)?.image;
              }
            } else {
              image = understandingResult?.pages?.find(
                (page) => page.pageIndex === currentPageIndex
              )?.image;

              if (!image) {
                image = understandingResult?.pages?.find(
                  (page) => page.pageIndex === 0
                )?.image;
              }
            }

            return image
              ? {
                  width: image.width,
                  height: image.height,
                }
              : null;
          }
        ),
        canvasSize: createSelector(
          (): Size | null => this.getters.imageSize,
          () => this.state.canvasRatio,
          () => this.state.isCanvasRatioInitialized,
          (imageSize, canvasRatio, isCanvasRatioInitialized) => {
            if (imageSize && canvasRatio > 0 && isCanvasRatioInitialized) {
              return resizeSizeWithRadio(imageSize, canvasRatio / 100);
            }

            return EMPTY_SIZE;
          }
        ),
        currentPageAnchors: createSelector(
          () => this.state.anchors,
          () => this.state.canvasRatio,
          () => this.state.isCanvasRatioInitialized,
          () => this.state.currentPageIndex,
          (
            anchors,
            canvasRatio,
            isCanvasRatioInitialized,
            currentPageIndex
          ): UITemplateAnchor[] => {
            if (anchors && canvasRatio > 0 && isCanvasRatioInitialized) {
              return anchors
                .filter((anchor) => anchor.pageIndex === currentPageIndex)
                .map((anchor) => {
                  return {
                    ...anchor,
                    rect: resizeRectWithRadio(anchor.rect, canvasRatio / 100),
                  };
                });
            }
            return [];
          }
        ),
        fixedAnchors: createSelector(
          () => this.state.anchors,
          () => this.state.fixedAnchorIds,
          (anchors, fixedAnchorIds) =>
            fixedAnchorIds
              .filter((id) => anchors.some((anchor) => anchor.id === id))
              .map((id) => anchors.filter((anchor) => anchor.id === id)[0])
        ),
        currentPageLabels: createSelector(
          () => this.state.labels,
          () => this.state.canvasRatio,
          () => this.state.isCanvasRatioInitialized,
          () => this.state.currentPageIndex,
          (
            labels,
            canvasRatio,
            isCanvasRatioInitialized,
            currentPageIndex
          ): Label[] | undefined => {
            if (labels && canvasRatio > 0 && isCanvasRatioInitialized) {
              return labels
                .filter((label) => label.pageIndex === currentPageIndex)
                .map((label) => {
                  return {
                    ...label,
                    rect: resizeRectWithRadio(label.rect, canvasRatio / 100),
                  };
                });
            }

            return [];
          }
        ),
        labels: createSelector(
          () => this.state.labels,
          () => this.state.canvasRatio,
          () => this.state.isCanvasRatioInitialized,
          (
            labels,
            canvasRatio,
            isCanvasRatioInitialized
          ): Label[] | undefined => {
            if (labels && canvasRatio > 0 && isCanvasRatioInitialized) {
              return labels.map((label) => {
                return {
                  ...label,
                  rect: resizeRectWithRadio(label.rect, canvasRatio / 100),
                };
              });
            }

            return [];
          }
        ),
        labelErrors: createSelector(
          (): Label[] | undefined => this.getters.labels,
          (labels) => {
            const errors: Record<
              string,
              {
                name?: boolean;
                fieldDataType?: boolean;
                reference?: boolean;
                referenceDirection?: boolean;
              }
            > = {};
            if (labels) {
              labels.forEach((label) => {
                const error = errors[label.id ?? ""] || {};
                error["name"] = !label.name || label.name.trim() === "";
                error["fieldDataType"] = !label.fieldDataType;

                error["reference"] = false;
                error["referenceDirection"] = false;
                if (label.anchors && label.anchors.length > 0) {
                  const anchor = label.anchors[0];

                  error["reference"] = !anchor.text || anchor.text === "";
                  error["referenceDirection"] = !anchor.direction;
                }

                if (
                  error.name ||
                  error.fieldDataType ||
                  error.reference ||
                  error.referenceDirection
                ) {
                  errors[label.id ?? ""] = error;
                }
              });
            }
            return errors;
          }
        ),
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          mode: ViewMode;
          resourceGroupId: string;
          id: string;
        }) => {
          const { mode, resourceGroupId, id } = payload;
          await this.actions.setFile.dispatch(null);
          await this.actions.setDocumentType.dispatch(null);
          await this.actions.setAnchors.dispatch([]);
          await this.actions.setFixedAnchorIds.dispatch([]);
          await this.actions.setSelectedAnchorId.dispatch(null);
          await this.actions.setInitialized.dispatch(false);
          await this.actions.setViewMode.dispatch(mode);
          await this.actions.setListMode.dispatch("archors");
          await this.actions.setValidateAllError.dispatch(false);
          await this.actions.setCanvasRatioInitialized.dispatch(false);
          await this.actions.setLabels.dispatch([]);
          await this.actions.setImageUrls.dispatch({});
          await this.actions.setId.dispatch(id);
          await this.actions.setResourceGroupId.dispatch(resourceGroupId);
          await this.actions.setCurrentPageIndex.dispatch(0);

          if (mode === "Editor") {
            await this.actions.setEditMode.dispatch("editLabel");
            const template = await this.getContainer(
              TemplateHelperModel
            ).actions.readById.dispatch({
              resourceGroupId,
              id,
              force: true,
            });

            const file = await this.dependencies.serviceClient.understanding.getTemplateFile(
              resourceGroupId,
              id
            );
            await this.actions.setFile.dispatch(file);

            const labels: Label[] = [];
            const anchors: UITemplateAnchor[] = [];
            const fixedAnchorIds: string[] = [];

            template?.pages?.forEach((page) => {
              // 后面几页可能没有resolutionRate，统一用第一页的
              const image = this._findImage(0);
              const labelDefinations = page.labelDefinations?.map((label) => {
                return {
                  ...label,
                  id: newGuid(),
                  pageIndex: page.pageIndex,
                  rect: resizeRectWithRadio(
                    label.rect,
                    1 / (image?.resolutionRate ?? 1)
                  ),
                };
              });
              if (labelDefinations) {
                labels.push(...labelDefinations);
              }

              const fixedAnchors = page.fixedAnchors?.map((anchor) =>
                this._getBoundId(anchor)
              );

              if (fixedAnchors) {
                fixedAnchorIds.push(...fixedAnchors);
              }
            });

            file?.pages?.forEach((page) => {
              // 统一用第一页的
              const image = this._findImage(0);
              const bounds = page.bounds?.map((bound) => {
                return {
                  ...bound,
                  pageIndex: page.pageIndex,
                  id: this._getBoundId(bound),
                  rect: resizeRectWithRadio(
                    bound,
                    1 / (image?.resolutionRate ?? 1)
                  ),
                };
              });

              if (bounds) {
                anchors.push(...bounds);
              }
            });

            await this.actions.setAnchors.dispatch(anchors);
            await this.actions.setFixedAnchorIds.dispatch(fixedAnchorIds);
            await this.actions.setDocumentType.dispatch(
              template?.documentType ?? null
            );
            await this.actions.setLabels.dispatch(labels);
            await this.actions.setListMode.dispatch(
              template?.documentType === "pdf-image" ||
                template?.documentType === "image-standalone"
                ? "archors"
                : "labels"
            );
          } else {
            await this.actions.setEditMode.dispatch("dragCanvas");
            const understandingResult = await this.getContainer(
              UnderstandingResultHelperModel
            ).actions.readById.dispatch({
              resourceGroupId,
              id,
              force: true,
            });

            if (!understandingResult) {
              return;
            }

            await this.getContainer(
              TemplateHelperModel
            ).actions.readById.dispatch({
              resourceGroupId,
              id: understandingResult.templateId,
              force: true,
            });

            const labels: Label[] = [];
            understandingResult?.pages?.forEach((page) => {
              // 统一用第一页的
              const image = this._findImage(0);
              const labelResults = page.labelResults?.map((label) => {
                return {
                  ...label,
                  id: newGuid(),
                  pageIndex: page.pageIndex,
                  rect: resizeRectWithRadio(
                    label.rect,
                    1 / (image?.resolutionRate ?? 1)
                  ),
                };
              });
              if (labelResults) {
                labels.push(...labelResults);
              }
            });
            await this.actions.setListMode.dispatch("labels");
            await this.actions.setLabels.dispatch(labels);
          }

          await this.actions.setInitialized.dispatch(true);
        },
        initializeCanvasRatio: async (payload: {
          imageSize?: Size | null;
          designerSize: Size;
        }) => {
          const { imageSize, designerSize } = payload;

          if (!imageSize) {
            return;
          }

          const { width: designerWidth, height: designerHeight } = designerSize;
          const { width: imageWidth, height: imageHeight } = imageSize;

          let newRadio = 100;

          if (
            (imageWidth * 1.0) / imageHeight >
            (designerWidth * 1.0) / designerHeight
          ) {
            newRadio = (designerWidth * 1.0) / imageWidth;
          } else {
            newRadio = (designerHeight * 1.0) / imageHeight;
          }

          newRadio = Math.round((Math.floor(newRadio * 20) / 20.0) * 100);
          await this.actions.setCanvasRatio.dispatch(Math.max(10, newRadio));
          await this.actions.setCanvasRatioInitialized.dispatch(true);
        },
        setCurrentPageIndex: async (pageIndex: number) => {
          if (
            this.state.imageUrls?.[pageIndex] ||
            !this.state.resourceGroupId
          ) {
            return;
          }

          let imageUrl: string | null = null;
          if (this.state.viewMode === "Editor") {
            imageUrl = await this.dependencies.serviceClient.understanding.getTemplateImage(
              this.state.resourceGroupId,
              this.state.id,
              pageIndex
            );
          } else {
            imageUrl = await this.dependencies.serviceClient.understanding.getUnderstandingResultImage(
              this.state.resourceGroupId,
              this.state.id,
              pageIndex
            );
          }
          const newImageUrls: Record<number, string> = {
            ...this.state.imageUrls,
          };
          newImageUrls[pageIndex] = imageUrl;
          await this.actions.setImageUrls.dispatch(newImageUrls);
        },
        zoomIn: async () => {
          const canvasRatio = this.state.canvasRatio;
          await this.actions.setCanvasRatio.dispatch(
            Math.min(200, canvasRatio + 5)
          );
        },
        zoomOut: async () => {
          const canvasRatio = this.state.canvasRatio;
          await this.actions.setCanvasRatio.dispatch(
            Math.max(10, canvasRatio - 5)
          );
        },
        createLabel: async (labelRect: Rect) => {
          const newLabel = {
            rect: resizeRectWithRadio(labelRect, 100 / this.state.canvasRatio),
            name: `item${this.state.labels.length + 1}`,
            id: newGuid(),
            pageIndex: this.state.currentPageIndex,
            fieldDataType:"string",
          };
          await this.actions.setLabels.dispatch([
            ...this.state.labels,
            newLabel,
          ]);
          await this.actions.setSelectedLabelId.dispatch(newLabel.id);
        },
        updateLabel: async (label: Partial<Label> & Pick<Label, "id">) => {
          const index = this.state.labels.findIndex(
            (lbl) => lbl.id === label.id
          );

          if (index >= 0) {
            const newLabel = { ...this.state.labels[index], ...label };

            await this.actions.setLabels.dispatch(
              this.state.labels.map((lbl, i) => (index === i ? newLabel : lbl))
            );
          }
        },
        updateLabelAnchor: async (payload: {
          labelId?: string;
          anchor: Partial<Anchor>;
        }) => {
          const { labelId, anchor } = payload;
          const label = this.state.labels.find((lbl) => lbl.id === labelId);

          if (label) {
            let old: Anchor = {};
            if (label.anchors && label.anchors.length > 0) {
              old = label.anchors[0];
            }
            const newAnchor = {
              ...old,
              ...anchor,
            };
            await this.actions.updateLabel.dispatch({
              id: labelId,
              anchors: [newAnchor],
            });
          }
        },
        deleteLabel: async (labelId?: string) => {
          const labels = this.state.labels;
          const newLabels = labels.filter((label) => label.id !== labelId);
          await this.actions.setLabels.dispatch(newLabels);
        },
        moveLabelRect: async (payload: { labelId?: string; delta: Point }) => {
          const { labelId, delta } = payload;
          const index = this.state.labels.findIndex(
            (label) => label.id === labelId
          );

          if (index >= 0) {
            const radio = 100 / this.state.canvasRatio;
            const resizedDelta = resizePointWithRadio(delta, radio);
            const label = { ...this.state.labels[index] };

            label.rect = {
              x: label.rect.x + resizedDelta.x,
              y: label.rect.y + resizedDelta.y,
              width: label.rect.width,
              height: label.rect.height,
            };

            await this.actions.setLabels.dispatch(
              this.state.labels.map((lbl, i) => (index === i ? label : lbl))
            );
          }
        },
        addFixedAnchor: async (anchorId: string) => {
          const hasAnchor = this.state.fixedAnchorIds.some(
            (id) => id === anchorId
          );
          if (!hasAnchor) {
            const newFixedAnchorIds = [...this.state.fixedAnchorIds, anchorId];
            await this.actions.setFixedAnchorIds.dispatch(newFixedAnchorIds);
          }
        },
        deleteAnchor: async (anchorId: string) => {
          const newAnchorIds = this.state.fixedAnchorIds.filter(
            (id) => id !== anchorId
          );
          await this.actions.setFixedAnchorIds.dispatch(newAnchorIds);
        },
        resizeLabelRect: async (payload: {
          labelId?: string;
          delta: Point;
          newSize: Size;
        }) => {
          const { labelId, delta, newSize } = payload;
          const index = this.state.labels.findIndex(
            (label) => label.id === labelId
          );

          if (index >= 0) {
            const label = { ...this.state.labels[index] };

            const radio = 100 / this.state.canvasRatio;
            const resizedDelta = resizePointWithRadio(delta, radio);
            const resizedSize = resizeSizeWithRadio(newSize, radio);

            label.rect = {
              x: label.rect.x + resizedDelta.x,
              y: label.rect.y + resizedDelta.y,
              width: resizedSize.width,
              height: resizedSize.height,
            };

            await this.actions.setLabels.dispatch(
              this.state.labels.map((lbl, i) => (index === i ? label : lbl))
            );
          }
        },
        exchangeLabel: async (payload: { fromId?: string; toId?: string }) => {
          const { fromId, toId } = payload;
          const labels = this.state.labels;
          const fromIndex = labels.findIndex((label) => label.id === fromId);

          const toIndex = labels.findIndex((label) => label.id === toId);

          if (fromIndex > -1 && toIndex > -1) {
            const fromLabel = labels[fromIndex];

            const newLabels: Label[] = [];
            this.state.labels.forEach((lbl, index) => {
              if (index === toIndex) {
                newLabels.push(fromLabel);
              }

              if (index !== fromIndex) {
                newLabels.push(lbl);
              }
            });

            await this.actions.setLabels.dispatch(newLabels);
          }
        },
        setSelectedLabelId: async (labelId?: string | null) => {
          if (!labelId) {
            return;
          }
          const label = this.state.labels.find((lbl) => lbl.id === labelId);

          if (label) {
            await this.actions.setCurrentPageIndex.dispatch(
              label?.pageIndex ?? 0
            );
          }
        },
        save: async () => {
          const resourceGroupId = this.state.resourceGroupId;
          if (this.state.viewMode !== "Editor" || !resourceGroupId) {
            return;
          }
          await this.actions.setValidateAllError.dispatch(true);
          if (Object.keys(this.getters.labelErrors).length > 0) {
            await this.actions.setValidateAllError.dispatch(false);
            return;
          }
          await this.actions.setValidateAllError.dispatch(false);
          const entitiy = this.getContainer(TemplateEntityModel).state.byId[
            this.state.id
          ];

          const pageCount = this.getters.pageCount;
          if (entitiy && pageCount) {
            const pages: TemplatePage[] = [];
            const allFixedAnchors = this.getters.fixedAnchors;

            for (let index = 0; index < pageCount; index++) {
              // 统一用第一页的
              const image = this._findImage(0);

              const labels = this.state.labels
                .filter((lbl) => lbl.pageIndex === index)
                .map((lbl) => {
                  return {
                    ...lbl,
                    rect: resizeRectWithRadio(
                      lbl.rect,
                      image?.resolutionRate ?? 1
                    ),
                  };
                });

              const fixedAnchors =
                this.state.documentType === "pdf-image" ||
                this.state.documentType === "image-standalone"
                  ? allFixedAnchors
                      .filter((anchor) => anchor.pageIndex === index)
                      .map((anchor) => ({
                        text: anchor.text,
                        x: anchor.x,
                        y: anchor.y,
                        width: anchor.width,
                        height: anchor.height,
                      }))
                  : undefined;

              if (labels) {
                pages.push({
                  pageIndex: index,
                  labelDefinations: labels,
                  fixedAnchors,
                });
              }
            }

            await this.getContainer(
              TemplateHelperModel
            ).actions.update.dispatch({
              resourceGroupId,
              template: {
                ...entitiy,
                pages,
              },
            });
          }
        },
      };
    }

    public epics() {
      return {
        resetSelectedAnchorId: () =>
          this.rootAction$.pipe(
            filter(() => !!this.state.selectedAnchorId),
            map(() => this.state.fixedAnchorIds),
            pairwise(),
            filter(([oldFixedAnchorIds, newFixedAnchorIds]) => {
              let needUpdate = false;
              if (oldFixedAnchorIds !== newFixedAnchorIds) {
                const selectedAnchorId = this.state.selectedAnchorId;
                if (!selectedAnchorId) {
                  needUpdate = false;
                } else {
                  needUpdate = !newFixedAnchorIds?.some(
                    (id) => id === selectedAnchorId
                  );
                }
              } else {
                needUpdate = false;
              }
              return needUpdate;
            }),
            map(() => this.actions.setSelectedAnchorId.create(null))
          ),

        resetSelectedLabelId: () =>
          this.rootAction$.pipe(
            filter(() => !!this.state.selectedLabelId),
            map(() => this.state.labels),
            pairwise(),
            filter(([oldLabels, newLabels]) => {
              let needUpdate = false;
              if (oldLabels !== newLabels) {
                const selectedLabelId = this.state.selectedLabelId;
                if (!selectedLabelId) {
                  needUpdate = false;
                } else {
                  needUpdate = !newLabels?.some(
                    (item) => item.id === selectedLabelId
                  );
                }
              } else {
                needUpdate = false;
              }
              return needUpdate;
            }),
            map(() => this.actions.setSelectedLabelId.create(null))
          ),
      };
    }
  }
);
