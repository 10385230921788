import {
  App,
  AppCreationData,
  AppStatus,
} from "@encoo-web/encoo-lib/types/model/app";
import { createModel } from "nyax";
import { AppEntityModel } from "src/store/models/entity/apps/entity";
import { AppVersionHelperModel } from "src/store/models/entity/appVersion/helper";
import { createHelperModel } from "src/store/models/entity/_shared";
import { compareVersion } from "src/utils/string";

export const AppHelperModel = createModel(
  class extends createHelperModel<App>({
    setItems: (getContainer, items) =>
      getContainer(AppEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(AppEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(AppEntityModel).state.byId[id],
  }) {
    public effects() {
      return {
        ...super.effects(),
        readByGroupIds: async (payload: {
          groupIds: string[];
          force?: boolean;
        }) => {
          const { groupIds, force } = payload;
          return await this._readByParentIds({
            parentIds: groupIds,
            getAllAction: (parentId) => {
              return this.dependencies.serviceClient.apps.getAll(parentId);
            },
            getEntityParentId: (entity: App) => entity.resourceGroupId,
            force,
          });
        },
        readById: async (payload: {
          resourceGroupId: string;
          id: string;
          force?: boolean;
        }) => {
          const { id, resourceGroupId, force } = payload;
          return await this._readById({
            id,
            getByIdAction: () => {
              return this.dependencies.serviceClient.apps.getById(
                resourceGroupId,
                id
              );
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          app: AppCreationData;
        }) => {
          const { resourceGroupId, app } = payload;

          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.apps.create(resourceGroupId, app),
          });
        },
        update: async (payload: {
          resourceGroupId: string;
          app: Partial<App> & Pick<App, "id">;
        }) => {
          const { resourceGroupId, app } = payload;
          return await this._update({
            id: app.id,
            updateAction: () =>
              this.dependencies.serviceClient.apps.update(resourceGroupId, app),
          });
        },
        delete: async (payload: { id: string; resourceGroupId: string }) => {
          const { id, resourceGroupId } = payload;
          return await this._delete({
            id: id,
            deleteAction: () =>
              this.dependencies.serviceClient.apps.delete(resourceGroupId, id),
          });
        },
        updateCurrentVersion: async (payload: {
          resourceGroupId: string;
          appId: string;
          toVersionId: string;
          toVersion: string;
        }) => {
          const { resourceGroupId, appId, toVersionId, toVersion } = payload;
          const app = await this.actions.readById.dispatch({
            resourceGroupId,
            id: appId,
            force: true,
          });

          if (app?.status === "Disabled") {
            throw new Error("app status is disabled. can not change version.");
          }

          const appVersion = await this.getContainer(
            AppVersionHelperModel
          ).actions.readById.dispatch({
            resourceGroupId,
            appId,
            versionId: toVersionId,
          });

          let updatedApp = undefined;
          if (app && appVersion) {
            updatedApp = await this.actions.update.dispatch({
              resourceGroupId,
              app: {
                ...app,
                name: appVersion.name,
                description: appVersion.description,
                currentVersion: toVersion,
                currentVersionId: toVersionId,
              },
            });
          }

          return updatedApp;
        },
        upgradeBatch: async (payload: {
          resourceGroupId: string;
          appIds: string[];
        }) => {
          const { resourceGroupId, appIds } = payload;

          const apps = [];
          for (const appId of appIds) {
            const app = await this.actions.readById.dispatch({
              resourceGroupId,
              id: appId,
              force: true,
            });

            if (
              app &&
              app.lastAvailableVersion &&
              app.currentVersion &&
              compareVersion(app.lastAvailableVersion, app.currentVersion) > 0
            ) {
              const updatedApp = await this.actions.updateCurrentVersion.dispatch(
                {
                  resourceGroupId,
                  appId: app.id,
                  toVersion: app.lastAvailableVersion,
                  toVersionId: app.lastAvailableVersionId ?? "",
                }
              );

              apps.push(updatedApp);
            } else if (app) {
              apps.push(app);
            }
          }

          return apps;
        },
        updateAppStatus: async (payload: {
          resourceGroupId: string;
          appId: string;
          newStatus: AppStatus;
          toVersion?: string;
          toVersionId?: string;
        }) => {
          const {
            resourceGroupId,
            appId,
            toVersion,
            toVersionId,
            newStatus,
          } = payload;

          const app = await this.actions.readById.dispatch({
            resourceGroupId,
            id: appId,
            force: true,
          });

          if (app && app?.status !== newStatus) {
            const currentVersion =
              newStatus === "Disabled"
                ? undefined
                : app.currentVersion ?? toVersion ?? app.lastAvailableVersion;
            const currentVersionId =
              newStatus === "Disabled"
                ? undefined
                : app.currentVersionId ??
                  toVersionId ??
                  app.lastAvailableVersionId;
            const updatedApp = await this.actions.update.dispatch({
              resourceGroupId,
              app: {
                ...app,
                status: newStatus,
                currentVersion,
                currentVersionId,
              },
            });

            return updatedApp;
          }
          return app;
        },
        updateAppStatusBatch: async (payload: {
          resourceGroupId: string;
          appIds: string[];
          newStatus: AppStatus;
        }) => {
          const { resourceGroupId, appIds, newStatus } = payload;
          const apps = [];
          for (const appId of appIds) {
            const updatedApp = await this.actions.updateAppStatus.dispatch({
              resourceGroupId,
              appId,
              newStatus,
            });

            apps.push(updatedApp);
          }

          return apps;
        },
        deleteBatch: async (payload: {
          resourceGroupId: string;
          appIds: string[];
        }) => {
          const { resourceGroupId, appIds } = payload;

          for (const appId of appIds) {
            await this.actions.delete.dispatch({ resourceGroupId, id: appId });
          }
        },
      };
    }
  }
);
