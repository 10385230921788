import { ResourceGroup } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ResourceGroupEntityModel } from "src/store/models/entity/resourceGroup/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const ResourceGroupListModel = createModel(
  class extends createListModel<ResourceGroup>({
    setItems: (getContainer, items) =>
      getContainer(ResourceGroupEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ResourceGroupEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ResourceGroupEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: { force?: boolean }) => {
          const { force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.resourceGroup.list(),
            force: force,
          });
        },
      };
    }
  }
);
