import { ResourceGroup, User } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { ResourceGroupEntityModel } from "src/store/models/entity/resourceGroup/entity";
import { UserHelperModel } from "src/store/models/entity/user/helper";

export type ScopeType = 1 | 2;

export const ALL_RESOURCE_GROUP = "tenant";

export const ResourceGroupSettingModel = createModel(
  class extends ModelBase {
    private get _userHelperModel() {
      return this.getContainer(UserHelperModel);
    }
    public selectors() {
      return {
        selectedResourceGroup: createSelector(
          (): string => this.state.selectedResourceGroupId,
          (): Array<ResourceGroup> =>
            this.getContainer(ResourceGroupEntityModel).getters.items,
          (
            selectedResourceGroupId,
            resourceGroupList
          ): ResourceGroup | undefined =>
            resourceGroupList.find(
              (item) => item.id === selectedResourceGroupId
            )
        ),
        currentUsers: createSelector(
          () => this.state.boundedUsers,
          (items): Array<User> => {
            return items;
          }
        ),
      };
    }
    public initialState() {
      return {
        selectedResourceGroupId: "",
        selectedScopeType: 2 as ScopeType,
        boundedUsers: [] as Array<User>,
        unboundedUsers: [] as Array<User>,
      };
    }
    public reducers() {
      return {
        setSelectedResourceGroupId: (value: string) => {
          this.state.selectedResourceGroupId = value;
          this.state.selectedScopeType = value === ALL_RESOURCE_GROUP ? 1 : 2;
        },
        setboundedUsers: (value: Array<User>) => {
          this.state.boundedUsers = value;
        },
        setUnboundedUsers: (value: Array<User>) => {
          this.state.unboundedUsers = value;
        },
      };
    }
    public effects() {
      return {
        getBoundUsers: async (scopeId: string) => {
          const users = await this._userHelperModel.actions.users.dispatch({
            userQuery: {
              IsExist: true,
              ScopeType: this.state.selectedScopeType,
              ScopeId: scopeId,
            },
          });
          this.actions.setboundedUsers.dispatch(users);
        },
        getUnboundUsers: async (scopeId: string) => {
          const users = await this._userHelperModel.actions.users.dispatch({
            userQuery: {
              IsExist: false,
              ScopeType: this.state.selectedScopeType,
              ScopeId: scopeId,
            },
          });
          this.actions.setUnboundedUsers.dispatch(users);
        },
      };
    }
  }
);
