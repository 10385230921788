import { QueueBasicData, RobotScope } from "@encoo-web/encoo-lib";
import { Key } from "antd/lib/table/interface";
import { createModel, createSelector } from "nyax";
import { StepProps } from "src/components/Step";
import { ModelBase } from "src/store/ModelBase";
import { ROBOT_SCOPE_MAP } from "src/store/models/ui/robot/robot";
export type CreateQueueContentType = "basic" | "bindRobot";

export const QueueUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isShowCreateModal: false,
        currentStep: "basic",
        selectedIds: [] as Key[],
        selectedGlobalIds: [] as Key[],
        currentIndex: 0,
        configData: {} as QueueBasicData,
        queueName: "",
        scope: "ResourceGroup" as RobotScope,
      };
    }

    public selectors() {
      return {
        isGlobal: createSelector(
          () => this.state.scope,
          (scope) => {
            return scope === ROBOT_SCOPE_MAP.TENANT;
          }
        ),
      };
    }

    public reducers() {
      return {
        setCreateModal: (value: boolean) => {
          this.state.isShowCreateModal = value;
        },
        setCurrentStep: (value: CreateQueueContentType) => {
          this.state.currentStep = value;
        },
        setSelectedGlobalRobotIds: async (value: Key[]) => {
          this.state.selectedGlobalIds = value;
        },
        setSelecteRobotIds: async (value: Key[]) => {
          this.state.selectedIds = value;
        },
        setCurrentIndex: (value: number) => {
          this.state.currentIndex = value;
        },
        setConfigData: (value: QueueBasicData) => {
          this.state.configData = value;
        },
        setQueueName: (value: string) => {
          this.state.queueName = value;
        },
        setRobotScope: (value: RobotScope) => {
          this.state.scope = value;
        },
      };
    }
    public effects() {
      return {
        updataStep: async (payload: { value: number; steps: StepProps[] }) => {
          const { value, steps } = payload;
          await this.actions.setCurrentIndex.dispatch(value);
          await this.actions.setCurrentStep.dispatch(
            steps[value].name as CreateQueueContentType
          );
        },
        handleSelectedRobotIds: async (payload: {
          value: Key[];
          isGlobal?: boolean;
        }) => {
          const { value, isGlobal = false } = payload;
          if (isGlobal) {
            await this.actions.setSelectedGlobalRobotIds.dispatch(value);
          } else {
            await this.actions.setSelecteRobotIds.dispatch(value);
          }
        },
        clearRobotBindingData: async () => {
          await this.actions.setSelectedGlobalRobotIds.dispatch([]);
          await this.actions.setSelecteRobotIds.dispatch([]);
          await this.actions.setRobotScope.dispatch("ResourceGroup");
        },
      };
    }
  }
);
