export default {
  workflow: {
    title: "流程部署",
    name: "流程部署名称",
    introduction:
      "流程部署主要用于对流程执行的目标、参数、重试、视频录制等进行配置，支持通过手动、任务计划等方式触发流程。",
    packageName: "流程包名称",
    version: "版本号",
    trigger: "任务计划",
    jobList: "任务列表",
    config: "配置",
    baseConfigInfo: "配置基本信息",
    packageVersion: "流程包版本",
    parameterInfo: "参数信息",
    performanceTarget: "执行目标",
    associateQueue: "关联队列",
    associateRobot: "关联机器人",
    saveAndSetTrigger: "保存并设置定时计划",
    execute: "执行",
    workflowExecute: "流程执行",
    tryMaxCount: "失败最大尝试次数",
    tryMaxCountTip:
      "提示：若流程任务执行失败，系统会根据设置的数值进行自动尝试，默认为3次，设置范围为0～10次",
    enableArgumentsReAssignment: "开启参数重新赋值",
    executeManually: "手动执行",
    createSuccess: "流程部署创建成功",
    bindRobotError: "机器人绑定失败",
    importAsset: "导入资产",
    selectAsset: "选择资产",
    isEnableVideoRecord: "是否开启视频录制",
    alwaysReport: "全部回传",
    neverReport: "不回传",
    onlyWhenSucceededReport: "仅成功时回传",
    onlyWhenFailedReport: "仅失败时回传",
    videoRecordTip: "提示：可以在任务日志详情页中查看视频回放",
    videoReportCondition: "回传条件",
    priority: "任务优先级",
    priorityTip:
      "提示: 任务优先级最低为0，最高为5000，优先级越高任务将越优先执行",
  },
};
