import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { ActiveTabViewType } from "src/store/models/ui/workflow/types";

export const DataTableDetailModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        activeKey: "trigger" as ActiveTabViewType,
      };
    }

    public reducers() {
      return {
        setActiveKey: (value: ActiveTabViewType) => {
          this.state.activeKey = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
      };
    }
  }
);
