import { APP_MENUS } from "src/routes/menu";
import { appRouteDefinitions, AppRouteDefinitions } from "src/routes/router";
import { MenuExpandedDefinition } from "src/routes/_shared";

const NUMBER_ONE = 1;

export function getBreadcrumbRouters(menuKey: string | undefined) {
  const routers = [];

  while (menuKey && APP_MENUS[menuKey]) {
    const menu: MenuExpandedDefinition = APP_MENUS[menuKey];
    if (!menu.hiddenInBreadcrumb) {
      routers.unshift(menu);
    }

    menuKey = menu.parentKey;
  }

  return routers;
}

export function getRouteOfList(menuKey: string | undefined) {
  while (menuKey && APP_MENUS[menuKey]) {
    menuKey = APP_MENUS[menuKey].parentKey;

    const route = appRouteDefinitions[menuKey as keyof AppRouteDefinitions];
    if (!route) {
      continue;
    }

    const defaultParams = route.defaultParams as { resourceGroupId: string };
    if (
      Object.keys(defaultParams).length === NUMBER_ONE &&
      "resourceGroupId" in defaultParams
    ) {
      return route;
    }
  }
}
