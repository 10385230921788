import { TriggerLog } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { TriggerLogEntityModel } from "src/store/models/entity/triggerLog/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const TriggerLogListModel = createModel(
  class extends createListModel<TriggerLog>({
    setItems: (getContainer, items) =>
      getContainer(TriggerLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(TriggerLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerLogEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          triggerId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowId, triggerId, force } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.workflow.listAllTriggerLogs(
                resourceGroupId,
                workflowId,
                triggerId
              ),
            force,
          });
        },
      };
    }
  }
);
