import {
  DailyRobotExecutionInfo,
  PerRobotExecutionInfo,
  RobotExecutionstatisticsQueryData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export const RobotRunStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dailyRobotExecutionDetails: null as DailyRobotExecutionInfo[] | null,
        perRobotExecutionData: null as PerRobotExecutionInfo[] | null,
      };
    }
    public reducers() {
      return {
        setDailyRobotExecutionDetails: (
          value: DailyRobotExecutionInfo[] | null
        ) => {
          this.state.dailyRobotExecutionDetails = value;
        },
        setRobotExecutionDetails: (value: PerRobotExecutionInfo[] | null) => {
          this.state.perRobotExecutionData = value;
        },
      };
    }

    public effects() {
      return {
        requestRobotRunStatistics: async (props: {
          resourceGroupId: string;
          query: RobotExecutionstatisticsQueryData;
        }) => {
          const value = await this.dependencies.serviceClient.dashboard.robotExecutionstatistics(
            props.resourceGroupId,
            props.query
          );
          await this.actions.setDailyRobotExecutionDetails.dispatch(
            value.dailyRobotExecutionDetails ?? null
          );
          await this.actions.setRobotExecutionDetails.dispatch(
            value.perRobotExecutionDetails ?? null
          );
        },
      };
    }
  }
);
