import { ConvertRouteInfo, createRouteDefinition } from "src/routes/_shared";

export const appRouteDefinitions = {
  index: createRouteDefinition({
    type: "index",
    path: "/",
    component: "./home/Home",
    defaultParams: {},
  }),
  userTenantEntrySelect: createRouteDefinition({
    type: "userTenantEntrySelect",
    path: "/userTenantEntrySelect",
    component: "./tenant/UserTenantEntrySelect",
    defaultParams: {},
  }),
  home: createRouteDefinition({
    type: "index",
    path: "/index",
    component: "./home/Home",
    defaultParams: {},
  }),
  app: createRouteDefinition({
    type: "app",
    path: "/resourceGroup/:resourceGroupId/app",
    component: "./apps/Apps",
    defaultParams: {
      resourceGroupId: "",
    },
    role: "app_service_view",
  }),
  appDetail: createRouteDefinition({
    type: "appDetail",
    path: "/resourceGroup/:resourceGroupId/appDetail/:id",
    component: "./apps/AppDetail",
    silderComponent: "./apps/Apps",
    defaultParams: {
      id: "",
      resourceGroupId: "",
    },
    role: "app_service_view",
  }),
  package: createRouteDefinition({
    type: "package",
    path: "/resourceGroup/:resourceGroupId/package",
    component: "./package/Package",
    defaultParams: {
      resourceGroupId: "",
    },
    role: "package_service_view",
  }),
  packageDetail: createRouteDefinition({
    type: "packageDetail",
    path: "/resourceGroup/:resourceGroupId/packageDetail/:id",
    silderComponent: "./package/Package",
    component: "./package/PackageDetail",
    defaultParams: {
      id: "",
      resourceGroupId: "",
    },
    role: "package_service_view",
  }),

  workflow: createRouteDefinition({
    type: "workflow",
    path: "/resourceGroup/:resourceGroupId/workflow",
    component: "./workflow/WorkflowListPage",
    defaultParams: {
      resourceGroupId: "",
    },
    role: "workflows_service_view",
  }),
  workflowDetail: createRouteDefinition({
    type: "workflowDetail",
    path: "/resourceGroup/:resourceGroupId/workflowDetail/:id",
    silderComponent: "./workflow/WorkflowListPage",
    component: "./workflow/WorkflowInfoWrapper",
    defaultParams: {
      id: "",
      resourceGroupId: "",
    },
    role: "workflows_service_view",
  }),
  triggerDetail: createRouteDefinition({
    type: "triggerDetail",
    path:
      "/resourceGroup/:resourceGroupId/workflow/:workflowId/triggerDetail/:id",
    component: "./trigger/TriggerDetail",
    defaultParams: {
      id: "",
      resourceGroupId: "",
      workflowId: "",
    },
    role: "workflows_service_triggers_view",
  }),
  job: createRouteDefinition({
    type: "job",
    path: "/resourceGroup/:resourceGroupId/job",
    component: "./job/Job",
    defaultParams: {
      resourceGroupId: "",
    },
    role: "jobs_service_view",
  }),
  jobDetail: createRouteDefinition({
    type: "jobDetail",
    path: "/resourceGroup/:resourceGroupId/jobDetail/:id",
    component: "./job/JobDetail",
    defaultParams: {
      id: "",
      resourceGroupId: "",
    },
    role: "jobs_service_view",
  }),
  runInstanceLog: createRouteDefinition({
    type: "runInstanceLog",
    path: "/resourceGroup/:resourceGroupId/job/:jobId/runInstanceLog/:id",
    component: "./runInstance/RunInstanceLog",
    defaultParams: {
      resourceGroupId: "",
      jobId: "",
      id: "",
    },
    role: "jobs_service_view",
  }),
  robot: createRouteDefinition({
    type: "robot",
    path: "/resourceGroup/:resourceGroupId/robot",
    component: "./robot/Robot",
    defaultParams: {
      resourceGroupId: "",
    },
    role: ["robots_service_view", "robots_service_view_global"],
  }),
  robotDetail: createRouteDefinition({
    type: "robotDetail",
    path: "/resourceGroup/:resourceGroupId/robotDetail/:id",
    silderComponent: "./robot/Robot",
    component: "./robot/RobotDetail",
    defaultParams: {
      resourceGroupId: "",
      id: "",
    },
    role: ["robots_service_view", "robots_service_view_global"],
  }),
  queue: createRouteDefinition({
    type: "queue",
    path: "/resourceGroup/:resourceGroupId/queue",
    component: "./queue/Queue",
    defaultParams: {
      resourceGroupId: "",
    },
    role: "queues_service_view",
  }),
  queueDetail: createRouteDefinition({
    type: "queueDetail",
    path: "/resourceGroup/:resourceGroupId/queue/:id",
    silderComponent: "./queue/Queue",
    component: "./queue/QueueDetail",
    defaultParams: {
      resourceGroupId: "",
      id: "",
    },
    role: "queues_service_view",
  }),
  template: createRouteDefinition({
    type: "template",
    path: "/resourceGroup/:resourceGroupId/template/",
    component: "./understanding/Template",
    role: "documentunderstanding_service_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  templateEditor: createRouteDefinition({
    type: "templateEditor",
    path: "/resourceGroup/:resourceGroupId/templateEditor/:id",
    component: "./understanding/TemplateEditor",
    role: "documentunderstanding_service_create_update",
    defaultParams: {
      resourceGroupId: "",
      id: "",
    },
  }),
  templateTester: createRouteDefinition({
    type: "templateTester",
    path: "/understanding/templateTester",
    component: "./understanding/TemplateTester",
    role: "documentunderstanding_service_result_create",
    defaultParams: { resourceGroupId: "", id: "" },
  }),
  systemSetting: createRouteDefinition({
    type: "systemSetting",
    path: "/systemSetting",
    component: "./settings/systemSetting/SystemSetting",
    defaultParams: {},
    role: "setting_service_view",
  }),
  user: createRouteDefinition({
    type: "user",
    path: "/setting/user",
    component: "./settings/user/User",
    defaultParams: {},
    role: "users_service_view",
  }),
  userCenter: createRouteDefinition({
    type: "userCenter",
    path: "/setting/userCenter",
    component: "./settings/userCenter/UserCenter",
    defaultParams: {},
  }),
  resourcegroup: createRouteDefinition({
    type: "resourcegroup",
    path: "/setting/resourcegroup",
    component: "./settings/resourceGroup/ResourceGroup",
    defaultParams: {},
    role: "resourceGroups_service_view",
  }),
  resourcegroupDetail: createRouteDefinition({
    type: "resourcegroupDetail",
    path: "/setting/ResourceGroupDetail",
    silderComponent: "./settings/resourceGroup/ResourceGroup",
    component: "./settings/resourceGroup/ResourceGroupDetail",
    defaultParams: {},
    role: "resourceGroups_service_view",
  }),
  auditlog: createRouteDefinition({
    type: "auditlog",
    path: "/setting/auditlog",
    component: "./settings/auditlog/Auditlog",
    defaultParams: {},
    role: "audit_service_view",
  }),
  role: createRouteDefinition({
    type: "role",
    path: "/setting/role",
    component: "./role/Role",
    defaultParams: {},
    role: "roles_service_view",
  }),
  license: createRouteDefinition({
    type: "license",
    path: "/license",
    component: "./license/License",
    defaultParams: {},
    role: "license_service_view",
  }),
  callback: createRouteDefinition({
    type: "callback",
    path: "/callback",
    component: "./callback/Callback",
    defaultParams: {},
  }),
  dashboard: createRouteDefinition({
    type: "dashboard",
    path: "/resourceGroup/:resourceGroupId/dashboard",
    component: "./dashboard/Dashboard",
    role: "dashboard_service_overallmonitor_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  robotRunStatistics: createRouteDefinition({
    type: "robotRunStatistics",
    path: "/resourceGroup/:resourceGroupId/robotRunStatistics",
    component: "./dashboard/RobotRunStatistics",
    role: "dashboard_service_robotexecution_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  robotStatusStatistics: createRouteDefinition({
    type: "robotStatusStatistics",
    path: "/resourceGroup/:resourceGroupId/robotStatusStatistics",
    component: "./dashboard/robotStatusStatistics/RobotStatusStatistics",
    role: "dashboard_service_robotsdashboard_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  queueStatistics: createRouteDefinition({
    type: "queueStatistics",
    path: "/resourceGroup/:resourceGroupId/queueStatistics",
    component: "./dashboard/queueStatistics/QueueStatistics",
    role: "dashboard_service_queuesdashboard_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  userFlowStatistics: createRouteDefinition({
    type: "userFlowStatistics",
    path: "/resourceGroup/:resourceGroupId/userFlowStatistics",
    component: "./dashboard/UserFlowStatistics",
    role: "dashboard_service_userworkflow_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  asset: createRouteDefinition({
    type: "asset",
    path: "/resourceGroup/:resourceGroupId/asset",
    component: "./asset/Asset",
    role: "asset_service_view",
    defaultParams: {
      resourceGroupId: "",
    },
  }),
  transferToActivateUser: createRouteDefinition({
    type: "transferToActivateUser",
    path: "/transferToActivateUser",
    component: "./transferToActivateUser/TransferToActivateUser",
    defaultParams: {
      inviteCode: "",
    },
  }),

  file: createRouteDefinition({
    type: "file",
    path: "/file",
    component: "./file/File",
    silderComponent: "./file/DirectoryTree",
    role: "file_service_view",
    defaultParams: {
      fullName: "",
    },
  }),

  dataTable: createRouteDefinition({
    type: "dataTable",
    path: "/dataTable",
    component: "./dataTable/DataTable",
    defaultParams: {},
  }),

  dataTableDetail: createRouteDefinition({
    type: "dataTableDetail",
    path: "/dataTableDetail/:id",
    silderComponent: "./dataTable/DataTable",
    component: "./dataTable/DataTableInfoWrapper",
    defaultParams: {
      id: "",
    },
  }),

  noPermission: createRouteDefinition({
    type: "noPermission",
    path: "/noPermission",
    component: "./errors/NoPermission",
    defaultParams: {},
  }),
  discontinued: createRouteDefinition({
    type: "discontinued",
    path: "/discontinued",
    component: "./errors/Discontinued",
    defaultParams: {},
  }),
  "404": createRouteDefinition({
    type: "404",
    path: "/404",
    component: "./errors/NotFound",
    defaultParams: {},
  }),
};

export type AppRouteDefinitions = typeof appRouteDefinitions;
export type AppRouteInfo = {
  [K in keyof AppRouteDefinitions]: ConvertRouteInfo<AppRouteDefinitions[K]>;
}[keyof AppRouteDefinitions];
