import { Locale as AntdLocale } from "antd/es/locale-provider";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import { LocaleConfig } from "src/locales";
import enUS from "src/locales/enUS";
import jaJP from "src/locales/JaJP";
import zhHans from "src/locales/zhHans";
import { getLocale } from "src/utils/locale";

export const supportedLocales: Array<{
  config: LocaleConfig;
  title: string;
}> = [
  {
    title: "English",
    config: enUS,
  },
  {
    title: "中文 (简体)",
    config: zhHans,
  },
  {
    title: "日本語",
    config: jaJP,
  },
];

export class LocaleService {
  public static injectable = [];

  private _intl!: IntlShape;
  private _antdLocale!: AntdLocale;

  public get intl() {
    return this._intl;
  }

  public get antdLocale() {
    return this._antdLocale;
  }

  constructor() {
    this._updateIntl();
  }

  private _updateIntl(config?: LocaleConfig) {
    if (!config) {
      config = supportedLocales.find((l) => l.config.locale === getLocale())
        ?.config;
    }
    config = config ?? enUS;

    const cache = createIntlCache();
    this._intl = createIntl(config, cache);

    this._antdLocale = config.antdLocale;
  }
}
