export default {
  robot: {
    entityTypeName: "Robot",
    robotName: "Robot Name",
    introduction: "Robot management is mainly used to create and manage connections to dedicated robots and manage the license status of the robots.",
    resourceGroupIntroduction: "Resource group robots are mainly used to create and manage connections to resource group-specific robots and manage the license status of the resource group robots.",
    sharedIntroduction: "Shared robots are mainly used to create and manage connections to the shared robots of tenants and manage the license status of the shared robots.",
    lastTime: "Last Communication Time",
    addRobot: "Add Robot",
    connectStatusName: "Connection Status",
    licenseStatusName: "License Status",
    licenseStatus: {
      unlicensed: "Unlicensed",
      serverLicensed: "Licensed",
    },
    status: {
      all: "All status",
      free: "Idle",
      busy: "Busy",
      noResponse: "No Response",
      break: "Interrupted",
      connect: "Connection",
      unLicensed: "Unlicensed",
    },
    consoleManagement: {
      managed: "Hosted",
      unManaged: "Not Hosted",
    },
    consoleManagementName: "Hosting Status",
    resource: {
      title: "Group Migration",
      current: "Current Resource Group",
      target: "Migrate to Resource Group",
    },
    detail: {
      title: "Robot Management Details",
      bindUser: "Bound Domain Name/User name",
      name: "Robot Name",
      status: "Robot Status",
      code: "Robot Connection String",
      tag: "Robot Label",
      remark: "Robot Remarks",
      type: "Robot Type",
      time: "Robot Last Communication Time",
      workflow: "Associated Flow Deployment",
      queue: "Associated Scheduling Queue",
    },
    validate: {
      name: "Robot Name",
      status: "Robot Status",
      code: "Robot Connection String",
    },
    placeholder: {
      tags: "Please enter the label and press Enter for confirmation.",
      type: "User Robot",
    },
    info: {
      bindConfirm: "Whether to bind {entityTypeName} for {name}?",
      unbindConfirm: "Whether to delete {name}{entityTypeName}?",
    },
    tips: {
      copy: "Copied",
      create: {
        code: "Robot Connection String",
        content: "The robot is created. Please copy the connection activation string for activating the robot client.",
        mark: "You can view the robot connection string in the robot detail page.",
      },
      unBind: "After successful unbinding, this client robot will not be able to execute the process. Are you sure you want to unbind the robot?",
      bindDelete: "The bound licenses cannot be deleted!",
      bindFail: {
        title: "Unbinding failed.",
        content: "The robot has tasks waiting to be executed and being executed.",
      },
    },
    scope: {
      resourceGroup: "Resource Group Robot",
      shared: "Shared Robot",
    },
    listName: "{name} Name",
    robotScope: "Robot Level",
    tab: {
      setting: "Configuration",
      report: "Report",
    },
    config: "Basic Information Configuration",
  },
};
