import { RoleAssignment } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { createItemsEntityModel } from "src/store/itemsEntity";

export const RoleAssignmentEntityModel = createModel(
  class extends createItemsEntityModel<RoleAssignment>((item) => item.userId) {
    public selectors() {
      return {
        ...super.selectors(),
        roles: createSelector(
          (): RoleAssignment[] => this.getters.items,
          (items) => items
        ),
      };
    }
  }
);
