import { Job } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { JobEntityModel } from "src/store/models/entity/job/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const JobListByQueueModel = createModel(
  class extends createListModel<Job>({
    setItems: (getContainer, items) =>
      getContainer(JobEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(JobEntityModel).getters.items,
    getItem: (getContainer, id) => getContainer(JobEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (payload: {
          resourceGroupId: string;
          queueId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, queueId, force = true } = payload;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.workflow.listJobsByQueue(
                resourceGroupId,
                queueId
              ),
            force: force,
          });
        },
      };
    }
  }
);
