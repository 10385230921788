import { RunInstanceLog } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { RunInstanceLogEntityModel } from "src/store/models/entity/runinstanceLog/entity";
import { createListModel } from "src/store/models/entity/_shared";

export const RunInstanceLogListModel = createModel(
  class extends createListModel<RunInstanceLog>({
    setItems: (getContainer, items) =>
      getContainer(RunInstanceLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(RunInstanceLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(RunInstanceLogEntityModel).state.byId[id],
    getItemId: (item) => item.id,
  }) {
    public effects() {
      return {
        ...super.effects(),
        initialIterator: async (params: {
          resourceGroupId: string;
          runinstanceId: string;
          payload: { logType?: string; logLevel?: string };
          force?: boolean;
        }) => {
          const { resourceGroupId, runinstanceId, force, payload } = params;
          this._initialIterator({
            initialAction: () =>
              this.dependencies.serviceClient.runInstance.getListLogsById(
                resourceGroupId,
                runinstanceId,
                payload
              ),
            force,
          });
        },
      };
    }
  }
);
