import { Job } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { JobHelperModel } from "src/store/models/entity/job/helper";

export const JobModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        jobs: [] as Array<Job>,
        jobsForWorkflow: [] as Array<Job>,
        jobsForQueue: [] as Array<Job>,
      };
    }

    public reducers() {
      return {
        setJobs: (value: Array<Job>) => {
          this.state.jobs = value;
        },
        setJobsForWorkflow: (value: Array<Job>) => {
          this.state.jobsForWorkflow = value;
        },
        setJobForQueue: (value: Array<Job>) => {
          this.state.jobsForQueue = value;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        requestJobsByResourceGroupId: async (payload: {
          resourceGroupId: string;
        }) => {
          const { resourceGroupId } = payload;
          const list = await this.getContainer(
            JobHelperModel
          ).actions.readByGroupIds.dispatch({
            resourceGroupIds: [resourceGroupId],
          });
          this.actions.setJobs.dispatch(list);
        },
        requestJobsByWorkflowId: async (payload: {
          resourceGroupId: string;
          workflowId: string;
        }) => {
          const { resourceGroupId, workflowId } = payload;
          const list = await this.getContainer(
            JobHelperModel
          ).actions.readByWorkflowIds.dispatch({
            resourceGroupId,
            workflowIds: [workflowId],
          });
          this.actions.setJobsForWorkflow.dispatch(list);
        },
        requestJobsByQueueId: async (payload: {
          resourceGroupId: string;
          queueId: string;
        }) => {
          const { resourceGroupId, queueId } = payload;
          const list = await this.getContainer(
            JobHelperModel
          ).actions.readByQueueIds.dispatch({
            resourceGroupId,
            queueIds: [queueId],
          });
          this.actions.setJobForQueue.dispatch(list);
        },
      };
    }
  }
);
