import {
  APPS_SERVICE_NAME,
  AUDITLOG_SERVICE_NAME,
  AxiosHttpClient,
  PACKAGE_SERVICE_NAME,
  PERMISSION_SERVICE_NAME,
  Profile,
  QUEUE_SERVICE_NAME,
  ROBOT_SERVICE_NAME,
  ROLE_SERVICE_NAME,
  RUNINSTANCE_SERVICE_NAME,
  USER_SERVICE_NAME,
  WORKFLOW_SERVICE_NAME
} from "@encoo-web/encoo-lib";
import { FILE_SERVICE_NAME } from "src/services/FileServiceClient";
import { UNDERSTANDING_SERVICE_NAME } from "src/services/UnderstandingSeviceClient";

export const canaryProfile: Profile = {
  endpoint: "http://10.240.0.30/",
  managementUrl: "https://auth.bottime.com/",
  env: "local",
  servicesEndpoint: new Map([
    [WORKFLOW_SERVICE_NAME, "http://10.240.0.41/"],
    [PACKAGE_SERVICE_NAME, "http://10.240.0.33/"],
    [AUDITLOG_SERVICE_NAME, "http://10.240.0.18"],
    [PERMISSION_SERVICE_NAME, "http://10.240.0.31"],
    [USER_SERVICE_NAME, "http://10.240.0.23"],
    [ROLE_SERVICE_NAME, "http://10.240.0.31"],
    [QUEUE_SERVICE_NAME, "http://10.240.0.49/"],
    [ROBOT_SERVICE_NAME, "http://10.240.0.51/"],
    [WORKFLOW_SERVICE_NAME, "http://10.240.0.41/"],
    [APPS_SERVICE_NAME, "http://10.240.0.64"],
    [RUNINSTANCE_SERVICE_NAME, "http://10.240.0.50"],
    [UNDERSTANDING_SERVICE_NAME, "http://10.240.0.70/"],
    [FILE_SERVICE_NAME, "http://10.10.20.30:20000/"],
  ]),
};

export const defaultHttpClient = new AxiosHttpClient();

export const privteProfile: Profile = {
  endpoint: "http://139.217.115.227:8080",
  managementUrl: "http://139.217.115.227:81",
  env: "local",
};

export const localProfile: Profile = {
  endpoint: "http://10.240.0.20/",
  managementUrl: "https://auth.bottime.com",
  env: "local",
};

export const devProfile: Profile = {
  endpoint: `https://consolev3dev.bottime.com`,
  managementUrl: `https://auth.bottime.com`,
  env: "dev",
};

export const preprodProfile: Profile = {
  endpoint: ` https://consolev3ppe.bottime.com/`,
  managementUrl: `https://authppe.bottime.com/`,
  env: "preprod",
};

export const privateEditionProfile = {
  endpoint: `http://10.10.10.88:8080`,
  managementUrl: `http://10.10.10.88:81`,
  env: "dev",
};
