export default {
  license: {
    entityTypeName: "License",
    introduction: "This page is used to display and manage the licensing and usage of various licenses or services for the console.",
    table: {
      id: "Encoding",
      user: "Current User",
      machineName: "Machine Name",
      name: "Robot Name",
      bindTime: "Binding Time",
      authorizeDate: "Authorization Date",
    },
    button: {
      apply: "Apply for License",
      applyEnterprise: "Apply for Enterprise Edition",
      assign: "Assign License",
      activation: "Activate License",
      detail: "View Usage",
      remove: "Remove License",
    },
    tooltip: {
      progress: "Used Number / Number of Licenses",
    },
    editor: {
      name: "Enterprise Edition Studio",
      descriptions: "Number of studios that can be activated",
    },
    robot: {
      name: "Enterprise Advanced Robot",
      descriptions: "Number of robots that can be connected",
    },
    documentUnderstanding: {
      name: "Document Understanding Service",
      descriptions: "Number of document understanding services that can be called",
    },
    documentUnderstandingTable: {
      modelType: "Built-in Model Type",
      total: "Total Number",
      used: "Used Number",
      rest: "Remaining Number",
      useOf: "Usage Proportion",
      PDF: "Electronic PDF Extraction Model",
      OCR: "OCR Extraction Model",
    },
    dailog: {
      demand: "Requirement Description",
      demandPlaceholder: "Please tell us the quantity you need, such as how many robots, or how many processes.",
      feedback: "We will contact you as soon as possible after receiving your request, but if your request is urgent, please call us directly: 400-639-2198",
      customer: "Client Name (Identifier)",
      licensePlaceholder: "Please enter the license number",
      sendScuess: "Successful license application has been sent.",
      sendFailed: "License application failed.",
      licenseSerialNumber: "Please enter the license number",
    },
    messageInfo: {
      activateSuccess: "Activation succeeded.",
      activateFailed: "Activation failed.",
    },
    activateTip: {
      ifNoLicense: "If you do not have an Enterprise Edition License, please",
      tenantIdTip: "The identifier is provided to ENCOO staff when applying for license.",
    },
  },
};
