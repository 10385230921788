import {
  HttpClient,
  Profile,
  ServiceClient,
  ServiceCredential
} from "@encoo-web/encoo-lib";
import { SecurityData, SecurityUpdateData } from "src/models/securityAuth";

export const SYSTEMSETTINGS_SERVICE_NAME = "systemSettings";

export class SystemSettingsServiceClient extends ServiceClient {
  constructor(
    httpClient: HttpClient,
    credential: ServiceCredential,
    profile: Profile
  ) {
    super(httpClient, credential, profile, SYSTEMSETTINGS_SERVICE_NAME);
  }

  async getSecurityInfo(): Promise<SecurityData> {
    const req = this.buildRequest({
      url: `/v2/settings/security`,
      method: "GET",
    });
    const { body } = await this.sendRequest(req);
    return body;
  }

  async updateSecurityInfo(payload: SecurityUpdateData): Promise<SecurityData> {
    const req = this.buildRequest({
      url: `/v2/settings/security`,
      method: "POST",
      payload,
    });
    const { body } = await this.sendRequest(req);
    return body;
  }
}
