export default {
  workflow: {
    title: "Flow Deployment",
    name: "Flow Deployment Name",
    introduction:
      "Flow deployment is mainly used to configure the target, parameters, retry, and video recording executed in the flow. It supports flow trigger manually and by task plans.",
    packageName: "Flow Package Name",
    version: "Version Number",
    trigger: "Task Schedule",
    jobList: "Task List",
    config: "Configuration",
    baseConfigInfo: "Basic Configuration Information",
    packageVersion: "Flow Package Version",
    parameterInfo: "Parameter Information",
    performanceTarget: "Execution Target",
    associateQueue: "Associated Queue",
    associateRobot: "Associated Robots",
    saveAndSetTrigger: "Save and set the cron job.",
    execute: "Execute",
    workflowExecute: "Flow Execution",
    tryMaxCount: "Maximum Number of Failure Attempts",
    tryMaxCountTip:
      "Tip: If the flow task execution fails, the system will automatically try according to the set value. The default is 3 times, and the setting range is 0 to 10 times.",
    enableArgumentsReAssignment: "Enable Parameter Reassignment",
    executeManually: "Manual Execution",
    createSuccess: "Flow deployment created.",
    bindRobotError: "Robot binding failed.",
    importAsset: "Import Asset",
    selectAsset: "Select Asset",
    isEnableVideoRecord: "Whether to enable video recording",
    alwaysReport: "Return All",
    neverReport: "No Return",
    onlyWhenSucceededReport: "Return Only If It Succeeds",
    onlyWhenFailedReport: "Return Only If It Fails",
    videoRecordTip:
      "Tip: You can view the video playback on the detail page of the task log.",
    videoReportCondition: "Return Conditions",
    priority: "Task Priority",
    priorityTip:
      "Tips: 0 is the lowest task priority and 5000 is the highest. The higher the priority is, the sooner the task will be executed",
  },
};
