/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { useSelector } from "react-redux";
import AppLayout from "src/containers/AppLayout";
import TransferToActivateUser from "src/containers/transferToActivateUser/TransferToActivateUser";
import { useFormatMessage, useGetContainer } from "src/hooks";
import messageIds from "src/locales/messageIds";
import { AppModel } from "src/store/models/app";
import { RouterModel } from "src/store/models/router";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

const expireTime = 30;

interface EncooWindow extends Window {
  ActiveXObject: unknown;
}

declare const window: EncooWindow;

export default hot(
  React.memo(function App() {
    const formatMessage = useFormatMessage();
    const getContainer = useGetContainer();
    const app = getContainer(AppModel);
    const router = getContainer(RouterModel);
    const loginUserUIContainer = getContainer(LoginUserUIModel);

    const currentRouteInfo = useSelector(() => router.getters.currentRouteInfo);

    const isInitialized = useSelector(() => app.state.isInitialized);

    const [mouseHandleLastTime, setMouseHandleLastTime] = useState<number>(
      new Date().getTime()
    );

    const onMouseHandle = useCallback(() => {
      document.onmouseover = () => {
        setMouseHandleLastTime(new Date().getTime());
      };
    }, []);

    const initIePolyfill = useCallback(() => {
      (function (arr) {
        arr.forEach(function (item) {
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty("remove")) {
            return;
          }
          Object.defineProperty(item, "remove", {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
              this.parentNode.removeChild(this);
            },
          });
        });
      })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
    }, []);

    useEffect(() => {
      if (!!window?.ActiveXObject || "ActiveXObject" in window) {
        initIePolyfill();
      }
    }, [initIePolyfill]);

    useEffect(() => {
      const title =
        window._settings.customizedCustomer === "tss"
          ? formatMessage(messageIds.customizedCustomer.tss.title)
          : formatMessage(messageIds.app.name);
      document.title = title;
    }, [formatMessage]);

    useEffect(() => {
      app.actions.initializeRequest.dispatch({});
    }, [app.actions.initializeRequest]);

    useEffect(() => {
      onMouseHandle();
    }, [onMouseHandle]);

    useEffect(() => {
      const timer = setInterval(() => {
        if (
          new Date().getTime() - mouseHandleLastTime >
          expireTime * 60 * 1000
        ) {
          loginUserUIContainer.actions.logout.dispatch({});
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [mouseHandleLastTime, loginUserUIContainer.actions.logout]);

    if (currentRouteInfo.type === "transferToActivateUser") {
      return <TransferToActivateUser />;
    }

    return isInitialized ? (
      <AppLayout />
    ) : (
      <Spin
        css={css`
          margin: auto;
        `}
      />
    );
  })
);
