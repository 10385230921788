import { interfaces } from "inversify";

export function createInjectableFactory<
  TInstance,
  TNewable extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new (...deps: any[]): TInstance;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    injectable: interfaces.ServiceIdentifier<any>[];
  }
>(newable: TNewable) {
  return (context: interfaces.Context) => {
    const deps = newable.injectable.map((identifier) =>
      context.container.get(identifier)
    );

    return new newable(...deps);
  };
}
