import {
  Trigger,
  TriggerCreationData,
  TriggerUpdateData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { TriggerEntityModel } from "src/store/models/entity/trigger/entity";
import { TriggerListByWorkFlowModel } from "src/store/models/entity/trigger/listByWorkflow";
import { createHelperModel } from "src/store/models/entity/_shared";

export const TriggerHelperModel = createModel(
  class extends createHelperModel<Trigger>({
    setItems: (getContainer, items) =>
      getContainer(TriggerEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(TriggerEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(TriggerListByWorkFlowModel).actions.refresh.dispatch({}),
  }) {
    public effects() {
      return {
        ...super.effects(),

        readByWorkflowIds: async (payload: {
          groupId: string;
          workflowIds: string[];
          force?: boolean;
        }) => {
          const { groupId, workflowIds, force } = payload;
          return await this._readByParentIds({
            parentIds: workflowIds,
            getAllAction: (workflowId) => {
              return this.dependencies.serviceClient.workflow.getAllTriggers(
                groupId,
                workflowId
              );
            },
            getEntityParentId: (entity: Trigger) => entity.workflowId,
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          params: Exclude<TriggerCreationData, "triggerType">;
        }) => {
          const { resourceGroupId, workflowId, params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflow.createTrigger(
                resourceGroupId,
                workflowId,
                { ...params, triggerType: "Cron" }
              ),
          });
        },
        update: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          triggerId: string;
          params: TriggerUpdateData;
        }) => {
          const { resourceGroupId, workflowId, triggerId, params } = payload;
          return await this._update({
            id: triggerId,
            updateAction: () =>
              this.dependencies.serviceClient.workflow.updateTrigger(
                resourceGroupId,
                workflowId,
                triggerId,
                params
              ),
          });
        },
        delete: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          triggerId: string;
        }) => {
          const { resourceGroupId, workflowId, triggerId } = payload;
          return await this._delete({
            id: triggerId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflow.deleteTrigger(
                resourceGroupId,
                workflowId,
                triggerId
              ),
          });
        },
        getById: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          triggerId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowId, triggerId, force } = payload;
          return await this._readById({
            id: triggerId,
            getByIdAction: () =>
              this.dependencies.serviceClient.workflow.getTrigger(
                resourceGroupId,
                workflowId,
                triggerId
              ),
            force,
          });
        },
        execute: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          params: Exclude<Partial<TriggerCreationData>, "triggerType">;
        }) => {
          const { resourceGroupId, workflowId, params } = payload;
          const item = await this.dependencies.serviceClient.workflow.createTrigger(
            resourceGroupId,
            workflowId,
            { ...params, triggerType: "Once" } as TriggerCreationData
          );
          return item;
        },
      };
    }
  }
);
