export default {
  apps: {
    name: "ミニプログラム名",
    introduction:
      "ミニプログラム管理は主に、レビュー待ちのミニプログラムの確認、ミニプログラムのオンラインとオフライン、ミニプログラムのバージョン管理と詳細表示をサポートします。",
    workbench: "ワークベンチ",
    currentVersion: "今のバージョン",
    status: {
      all: "すべて",
      enabled: "有効",
      disabled: "無効",
    },
    currentStatus: {
      enabled: "有効",
      disabled: "無効",
    },
    version: "バージョン",
    changeVersion: "このバージョンに切り替え",
    uploadDateTime: "アップロード時間",
    creater: "開発者",
    device: "端末",
    mobile: "モバイル",
    pc: "PC",
    try: "試します",
    moreVersion: "他のバージョンを表示",
    workflowName: "プロセス展開名",
    packageVersion: "プロセスパッケージのバージョン",
    appVersionsTitle: "バージョン一覧",
    currentSign: "今",
    upgradeTip: "このバージョンは最新バージョンではありません。クリックすると{lastAvailableVersion}バージョンにアップグレードできます",
    upgradeConfirm: "本当に{lastAvailableVersion}バージョンにアップグレードしますか？",
    upgradeBatchConfirm: "本当にアップグレードしますか？",
    updateAppCurrentVersionConfirm: "本当にこのミニプログラムをこのバージョンに切り替えますか",
    deleteAppVersionMessage:
      "このバージョンは、使用されているため、削除することはできません",
  },
};
