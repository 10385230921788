import { AppDetailUIModel } from "src/store/models/ui/apps/appDetail";
import { AppsUIModel } from "src/store/models/ui/apps/apps";
import { AssetUIModel } from "src/store/models/ui/asset/asset";
import { AuditlogUIModel } from "src/store/models/ui/auditlog/auditlog";
import { DashboardUIModel } from "src/store/models/ui/dashboard/dashboard";
import { QueueStatisticsUIModel } from "src/store/models/ui/dashboard/queueStatistics";
import { RobotRunStatisticsUIModel } from "src/store/models/ui/dashboard/robotRunStatistics";
import { RobotStatusStatisticsUIModel } from "src/store/models/ui/dashboard/robotStatusStatistics";
import { UserFlowStatisticsUIModel } from "src/store/models/ui/dashboard/userFlowStatistics";
import { DataTableModel } from "src/store/models/ui/dataTable/dataTable";
import { DataTableDetailModel } from "src/store/models/ui/dataTable/dataTableDetail";
import { EnvironmentModel } from "src/store/models/ui/environment/environment";
import { ErrorUIModel } from "src/store/models/ui/error/error";
import { fileModels } from "src/store/models/ui/file";
import { InstallationUIModel } from "src/store/models/ui/installation/installation";
import { JobModel } from "src/store/models/ui/job/job";
import { JobDetailModel } from "src/store/models/ui/job/jobDetail";
import { LicenseUIModel } from "src/store/models/ui/license/license";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";
import { LocaleUIModel } from "src/store/models/ui/locale/locale";
import { PackageUIModel } from "src/store/models/ui/package/package";
import { PackageDetailUIModel } from "src/store/models/ui/package/packageDetail";
import { QueueUIModel } from "src/store/models/ui/queue/queue";
import { QueueDetailUIModel } from "src/store/models/ui/queue/queueDetail";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";
import { ResourceGroupSettingModel } from "src/store/models/ui/resourceGroup/resourceGroup";
import { RobotUIModel } from "src/store/models/ui/robot/robot";
import { RolePermissionModal } from "src/store/models/ui/role/permission";
import { RoleListUIModel } from "src/store/models/ui/role/role";
import { RunInstanceUIModel } from "src/store/models/ui/runInstance/runInstance";
import { RunInstanceLogModel } from "src/store/models/ui/runInstance/runInstanceLog";
import { SystemSettingUIModel } from "src/store/models/ui/systemSetting/systemSetting";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";
import { CronExpressModel } from "src/store/models/ui/trigger/cronExpress";
import { TriggerModel } from "src/store/models/ui/trigger/trigger";
import { TriggerDetailModel } from "src/store/models/ui/trigger/triggerDetail";
import { understandingModels } from "src/store/models/ui/understanding";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";
import { UserUIModel } from "src/store/models/ui/user/user";
import { UserInfoUIModel } from "src/store/models/ui/userCenter/userInfo";
import { WorkflowModel } from "src/store/models/ui/workflow/workflow";
import { WorkflowDetailModel } from "src/store/models/ui/workflow/workflowDetail";

export const uiModels = {
  robot: RobotUIModel,
  package: PackageUIModel,
  packageDetail: PackageDetailUIModel,
  resourceGroup: ResourceGroupModel,
  resourceGroupSetting: ResourceGroupSettingModel,
  understanding: understandingModels,
  role: RoleListUIModel,
  auditlog: AuditlogUIModel,
  Permission: RolePermissionModal,
  user: UserUIModel,
  local: LocaleUIModel,
  workflow: WorkflowModel,
  workflowDetail: WorkflowDetailModel,
  trigger: TriggerModel,
  triggerDetail: TriggerDetailModel,
  job: JobModel,
  jobDetail: JobDetailModel,
  queue: QueueUIModel,
  queueConfig: QueueDetailUIModel,
  app: AppsUIModel,
  appDetail: AppDetailUIModel,
  loginUser: LoginUserUIModel,
  runInstance: RunInstanceUIModel,
  runInstanceLog: RunInstanceLogModel,
  installation: InstallationUIModel,
  license: LicenseUIModel,
  cronExpress: CronExpressModel,
  dashboard: DashboardUIModel,
  environment: EnvironmentModel,
  Loading: LoadingUIModel,
  error: ErrorUIModel,
  userTenant: UserTenantUIModel,
  userCenter: UserInfoUIModel,
  asset: AssetUIModel,
  robotRunStatistics: RobotRunStatisticsUIModel,
  userFlowStatistics: UserFlowStatisticsUIModel,
  robotStatusStatisticsUIModel: RobotStatusStatisticsUIModel,
  queueStatisticsUIModel: QueueStatisticsUIModel,
  file: fileModels,
  dataTable: DataTableModel,
  dataTableDetail: DataTableDetailModel,
  systemSetting: SystemSettingUIModel
};
