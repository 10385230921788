import antdJaJP from "antd/es/locale/ja_JP";
import { LocaleConfig } from "src/locales";
import jaJPMessages from "src/locales/JaJP.messages";
import { flattenMessages } from "src/utils/locale";

const jaJP: LocaleConfig = {
  locale: "ja-JP",
  messages: flattenMessages(jaJPMessages),

  antdLocale: antdJaJP,
};

export default jaJP;
