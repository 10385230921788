export default {
  menu: {
    dashboard: {
      name: "Dashboard",
      overallMonitor: { name: "Overall Monitoring" },
      userFlowStatistics: { name: "User Flow Statistical Table" },
      robotRunStatistics: { name: "Robot Execution Statistical Table" },
      robotStatusStatistics: { name: "Robot Monitoring" },
      queueStatistics: { name: "Queue Monitoring" },
    },
    rpa: {
      name: "RPA Management",
      package: { name: "Flow Package Management" },
      workflow: { name: "Flow Deployment" },
      robot: { name: "Robot Management" },
      queue: { name: "Scheduling Queue" },
      log: { name: "Task History" },
    },
    apps: {
      name: "Application Management",
    },
    understanding: {
      name: "Document Understanding",
      editor: {
        name: "Edit Template",
      },
      tester: {
        name: "Test Result",
      },
    },
    data: {
      name: "Data Center",
      asset: { name: "Asset Management" },
      file: { name: "File Service" },
      dataTable: { name: "Data Table Management" },
    },
    setting: {
      name: "Global Management",
      user: { name: "User Information" },
      role: { name: "Role Management" },
      resourcegroup: { name: "Resource Group Management" },
      auditlog: { name: "Audit Log" },
      license: { name: "License" },
      userCenter: { name: "Personal Center" },
      systemSetting: { name: "System Settings" }
    },
  },
  home: {
    welcomePanel: {
      title: "Welcome to use ENCOO RPA Console",
      lastLandingTime: "Last Login Time",
      currentTenant: "Current Tenant",
      enterprise: "Enterprise Edition",
      community: "Community Edition",
      applyForEnterprise: "Apply for Enterprise Edition License",
    },
    navigation: {
      title: "Common Navigation",
      dashboard: "Dashboard",
      uploadPackage: "Upload Flow Package",
      processDeployment: "Flow Deployment",
      robotManagement: "Robot Management",
      performLog: "Task History",
    },
    service: {
      title: "More Services",
      documentationUnderstand: "Document Understanding",
      appManagement: "Application Management",
      dataCenter: "Data Center",
    },
    practicalLink: {
      title: "Quick Links",
      consoleDocument: "ENCOO DOCUMENTATION",
      consoleCourse: "ENCOO ACADEMY",
      consoleBBS: "ENCOO FORUM",
    },
    packageDownload: {
      title: "Installation Package Download",
      name: "Application Name",
      editName: "ENCOO RPA Studio",
      robotName: "ENCOO RPA Robot",
      releaseTime: "Publish Time",
      version: "Version Number",
      download: "Download",
    },
    serviceAddress: {
      title: "Service Address",
      address: " Studio Connection String",
      reminder: "(This string is used for studio login and console activation. Please click the button to copy the string.)",
      signScuess: "Logging in...",
    },
  },
};
