export default {
  dashboard: {
    title: {
      package: "流程包数量",
      workflow: "流程部署数量",
      queue: "调度队列数量",
      robot: "机器人数量",
      task: "任务记录概况",
      taskDate: "任务数量/日期",
      taskInfo: "任务详情",
    },
    viewDetail: "查看详情",
    table: {
      status: "状态",
      percent: "百分比",
      count: "数量",
      wait: "等待",
      allocated: "已分配",
      operation: "运行中",
      success: "成功",
      cancelling: "取消中",
      cancelled: "取消",
      fail: "失败",
    },
    totalCount: "总计",
    sevenDay: "最近7天",
    thirtyDay: "最近30天",
    today: "今日",
  },
  robotRunStatistics: {
    dailyRobot: {
      runInstanceExecutionCount: "执行任务总次数",
      successExecutionCount: "执行成功总次数",
      robotCount: "机器人总数",
      robotAliveTime: "机器人总时长",
      robotBusyTime: "忙碌总时长",
      averageBusyProportion: "平均忙碌比",
      averageSuccessProportion: "平均成功率",
    },
    perRobot: {
      runInstanceExecutionCount: "执行任务次数",
      successExecutionCount: "执行成功次数",
      busyMilliseconds: "忙碌时长",
      aliveMilliseconds: "机器人时长",
      busyProportion: "机器人忙碌比",
      successProportion: "成功率",
    },
  },
  userFlowStatistics: {
    dailyUserFlow: {
      uploadedPackageCount: "上传流程包总数",
      deployedPackageCount: "已部署流程包总数",
      createdWorkflowCount: "创建流程部署总数",
      createdCronTriggerCount: "创建任务计划总数",
      createdJobCount: "创建任务总数",
      jobSuccessCount: "成功执行任务总数",
      totalJobExecutionMilliseconds: "任务执行总时长",
    },
    perUserFlow: {
      userName: "用户名称",
      uploadedPackageCount: "上传流程包数",
      createdWorkflowCount: "创建流程部署数",
      createdCronTriggerCount: "创建任务计划数",
      createdJobCount: "创建任务数",
      jobSuccessCount: "成功执行任务数",
      totalJobExecutionMilliseconds: "任务执行时长",
    },
  },
  robotStatusStatistics: {
    averageBusyRatioOfAvailableRobots: "可用机器人平均忙碌比",
    averageBusyRatio: "平均忙碌比",
    todayAverageBusyRatio: "今日平均忙碌比",
    robotBusyTotalTimeTOP10: "可用机器人忙碌总时长TOP10",
    todayAdded: "今日新增",
    robotBusyTotalTime: "可用机器人忙碌总时长",
    busyTotalTime: "忙碌总时长",
    robotFailRateTOP10: "可用机器人故障占比TOP10",
    robot: "机器人",
    licenseType: { serverLicensed: "已许可", unlicensed: "未许可" },
    availableRobotStateDistribution: "可用机器人实时状态分布",
    robotTotalCount: "机器人总数",
    serverLicensedCount: "已许可数",
    unlicensedCount: "未许可数",
    availableRobotTotalExeCount: "可用机器人执行任务总数",
    exeTotalCount: "执行总数",
    viewRobotDetail: "查看机器人详情",
    exeWorkflowTotalTime: "执行流程总时长",
    faultNumber: "个故障",
  },
  queueStatistics: {
    averageBusyRate: "平均忙碌比",
    todayAverageBusyRate: "今日忙碌比",
    todayAdded: "今日新增",
    busyTotalTime: "忙碌总时长",
    faultNumber: "个故障",
    successNumber: "个成功",
    queueDetail: "调度队列详情",
    queueExeTotalTime: "队列执行任务总时长",
    exeTotalTime: "执行任务总时长",
    queueName: "队列名称",
    queueBusyTop10: "调度队列忙碌总时长TOP10",
    queueCount: "调度队列数量",
    queueTotalCount: "调度队列总数",
    queueFailTop10: "队列故障占比TOP10",
    queueJobState: "调度队列任务分布",
    queueJobCount: "任务队列执行总数",
    aveWaitTime: "平均等待时间",
    busyRate: "忙碌比",
    robotBindedCount: "绑定机器人数",
    robotBindedTotalCount: "绑定机器人数",
    queueJobWaitTop10: "调度队列任务平均等待时长TOP10",
    queueRobotAveBusyRate: "队列机器人平均忙碌比",
    queueSuccessRate: "队列成功占比TOP10",
    associatedWorkflowCount: "关联流程部署数量",
    associatedWorkflowTotalCount: "关联流程部署总数",
    exeWorkflowTotalTime: "执行流程总时长",
  },
};
