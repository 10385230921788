import { ThemeSet } from "src/styles/theme";
import { lightThemeSet } from "src/styles/theme/light";

export class ThemeService {
  public static injectable = [];

  private _themeSet!: ThemeSet;

  public get themeSet() {
    return this._themeSet;
  }

  constructor() {
    this._updateThemeSet();
  }

  private _updateThemeSet(name?: string) {
    let themeSet: ThemeSet;

    switch (name) {
      case "light":
        themeSet = lightThemeSet;
        break;
      default:
        themeSet = lightThemeSet;
        break;
    }

    this._themeSet = themeSet;
  }
}
