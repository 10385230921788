import { TriggerLog } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { TriggerLogEntityModel } from "src/store/models/entity/triggerLog/entity";
import { TriggerLogListModel } from "src/store/models/entity/triggerLog/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const TriggerLogHelperModel = createModel(
  class extends createHelperModel<TriggerLog>({
    setItems: (getContainer, items) =>
      getContainer(TriggerLogEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(TriggerLogEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(TriggerLogEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(TriggerLogListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(TriggerLogEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByTriggerIds: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          triggerIds: string[];
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowId, triggerIds, force } = payload;
          return await this._readByParentIds({
            parentIds: triggerIds,
            getAllAction: (triggerId) => {
              return this.dependencies.serviceClient.workflow.getAllTriggerLogs(
                resourceGroupId,
                workflowId,
                triggerId
              );
            },
            getEntityParentId: (entity: TriggerLog) => entity.triggerId,
            force,
          });
        },
      };
    }
  }
);
