export default {
  runInstance: {
    viewLog: "ログの詳細",
    logInfo: "ログ情報",
    logTime: "ログ時刻",
    logRank: "ログレベル",
    logContent: "ログの内容",
    screenshotShown: "スクリーンショットを表示",
    runinstanceNoVideoTip: "今のタスクには録画記録がありません",
    videoShow: "ビデオを表示",
    noScreenshotShown: "今スクリーンショットレコードがありません",
  },
  execution: {
    runOrder: "実行順位",
    executionRobot: "実行用ロボット",
    log: "ログ",
    priority: "優先度",
    restart: "再起動",
    termination: "終了",
    expansion: "展開",
    taskSource: "タスクソース",
    status: {
      create: "作成",
      running: "実行中",
      failed: "失敗",
      cancelled: "キャンセル",
      succeeded: "成功",
      cancelling: "キャンセル中",
    },
  },
};
