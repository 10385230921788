import {
  DashboardQueryData,
  RobotBusyTimeRank,
  RobotExecutionDetail,
  RobotFailureRateRank,
  RobotStateCount,
} from "@encoo-web/encoo-lib";
import moment from "moment";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export type RobotExecuteTotalCountInfoType = {
  totalExecutionCount?: number;
  todayExecutionCount?: number;
};

export type RobotBusyTotalBurningTimeInfoType = {
  totalBusyMilliseconds?: number;
  todayBusyMilliseconds?: number;
};

export const todayString = moment(new Date()).format("YYYY-MM-DD");

export const RobotStatusStatisticsUIModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        robotExecutionDetail: null as RobotExecutionDetail | null,
        robotTodayExecutionDetail: null as RobotExecutionDetail | null,
        robotFailureRateRank: null as RobotFailureRateRank[] | null,
        robotStateCount: null as RobotStateCount | null,
        robotBusyTimeRank: null as RobotBusyTimeRank[] | null,
      };
    }

    public selectors() {
      return {};
    }

    public reducers() {
      return {
        setRobotExecutionDetail: (value: RobotExecutionDetail | null) => {
          this.state.robotExecutionDetail = value;
        },
        setRobotTodayExecutionDetail: (value: RobotExecutionDetail | null) => {
          this.state.robotTodayExecutionDetail = value;
        },
        setRobotFailureRateRank: (value: RobotFailureRateRank[] | null) => {
          this.state.robotFailureRateRank = value;
        },
        setRobotStateCount: (value: RobotStateCount | null) => {
          this.state.robotStateCount = value;
        },
        setRobotBusyTimeRank: (value: RobotBusyTimeRank[] | null) => {
          this.state.robotBusyTimeRank = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;

          const values = await Promise.all([
            this.actions.requestRobotExecutionDetail.dispatch(payload),
            this.actions.requestRobotExecutionDetail.dispatch({
              resourceGroupId,
              query: {
                ...query,
                startDate: todayString,
                endDate: todayString,
              },
            }),
            this.actions.requestRobotFailureRateRank.dispatch(payload),
            this.actions.requestRobotStateCount.dispatch({
              resourceGroupId,
              query: { timeZone: query.timeZone, endDate: todayString },
            }),
            this.actions.requestRobotBusyTimeRank.dispatch(payload),
          ]);

          const [
            robotExecutionDetailData,
            robotTodayExecutionDetailData,
            robotFailureRateRankData,
            robotStateCountData,
            robotBusyTimeRankData,
          ] = values;

          this.actions.setRobotExecutionDetail.dispatch(
            robotExecutionDetailData
          );
          this.actions.setRobotTodayExecutionDetail.dispatch(
            robotTodayExecutionDetailData
          );
          this.actions.setRobotFailureRateRank.dispatch(
            robotFailureRateRankData
          );
          this.actions.setRobotStateCount.dispatch(robotStateCountData);
          this.actions.setRobotBusyTimeRank.dispatch(robotBusyTimeRankData);
        },
        requestRobotExecutionDetail: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          return await this.dependencies.serviceClient.dashboard.robotExecutionDetail(
            resourceGroupId,
            query
          );
        },
        requestRobotFailureRateRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          return await this.dependencies.serviceClient.dashboard.robotFailureRateRank(
            resourceGroupId,
            query
          );
        },
        requestRobotStateCount: async (payload: {
          resourceGroupId: string;
          query: Partial<DashboardQueryData>;
        }) => {
          const { resourceGroupId, query } = payload;
          return await this.dependencies.serviceClient.dashboard.robotStateCount(
            resourceGroupId,
            query
          );

          // TDDO mock数据，后续删除
          // const data = {
          //   totalCount: 115,
          //   totalLicenseStateCounts: { ServerLicensed: 20, Unlicensed: 95 },
          //   totalRobotStateCounts: { Disconnected: 114, Ready: 1 },
          //   totalLicenseRobotStateCounts: {
          //     ServerLicensed: {
          //       Disconnected: 19,
          //       Ready: 1,
          //     },
          //     Unlicensed: {
          //       Disconnected: 95,
          //     },
          //   },
          // };
          // return Promise.resolve(data as RobotStateCount);
        },
        requestRobotBusyTimeRank: async (payload: {
          resourceGroupId: string;
          query: DashboardQueryData;
        }) => {
          const { resourceGroupId, query } = payload;
          return await this.dependencies.serviceClient.dashboard.robotBusyTimeRank(
            resourceGroupId,
            query
          );

          // TDDO mock数据，后续删除
          // const data = [
          //   {
          //     robotId: "069457ef-ea82-4215-88e9-458fd020e188",
          //     robotName: "CXGTEST",
          //     busyMilliseconds: 4346834.3234,
          //     executionCount: 15,
          //     busyProportion: 0.614,
          //     successExecutionCount: 0,
          //     successProportion: 0,
          //     failureExecutionCount: 15,
          //     failureProportion: 93.75,
          //     packageNameExecutionMilliseconds: {
          //       DEMO: 69798.8572,
          //       "wangruifeng-asset": 22382.936299999998,
          //       小E绘画大师: 4254652.5299,
          //     } as Record<string, number>,
          //     robotCreatedAt: "2020-11-18T02:43:10.7393847+00:00",
          //   },
          //   {
          //     robotId: "42e3efd0-4a20-4778-b160-bec3e3a8ecc2",
          //     robotName: "YanZhiwei_RobotTest",
          //     busyMilliseconds: 132378.1480000019,
          //     executionCount: 8,
          //     busyProportion: 0.005,
          //     successExecutionCount: 4,
          //     successProportion: 100,
          //     failureExecutionCount: 3,
          //     failureProportion: 18.75,
          //     packageNameExecutionMilliseconds: {
          //       WindowsCpTest: 84810.8211,
          //       xxxyyy: 47567.32690000534,
          //     },
          //     robotCreatedAt: "2020-10-09T10:29:04.8862131+00:00",
          //   },
          // ];
          // return Promise.resolve(data as RobotBusyTimeRank[] | null);
        },
      };
    }
  }
);
