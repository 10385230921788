export default {
  role: {
    name: "Role Name",
    introduction: "Role management is used to create various roles and configure access and operation permissions of each role according to actual needs.",
    entityTypeName: "Role",
    search: "Permission Name/Menu Name",
    permission: "Permission",
    createRole: "New Role",
    editRole: "Edit Role",
  },
};
