import { DataTable } from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export type dataTableModalType = "create" | "uploadFile" | null;

export const DataTableModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        dataTableModalVisible: null as dataTableModalType,
        uploadFileTable: null as DataTable | null,
      };
    }

    public reducers() {
      return {
        setDataTableModalVisible: (value: dataTableModalType) => {
          this.state.dataTableModalVisible = value;
        },
        setUploadFileTable: (value: DataTable | null) => {
          this.state.uploadFileTable = value;
        },
      };
    }
  }
);
