export default {
  dashboard: {
    title: {
      package: "Flow Package Count",
      workflow: "Flow Deployment Count",
      queue: "Scheduling Queue Count",
      robot: "Robot Count",
      task: "Task History Overview",
      taskDate: "Task Count / Date",
      taskInfo: "Task Details",
    },
    viewDetail: "View Details",
    table: {
      status: "Status",
      percent: "Percentage",
      count: "Count",
      wait: "Waiting",
      allocated: "Assigned",
      operation: "Running",
      success: "Succeeded",
      cancelling: "Canceling",
      cancelled: "Cancel",
      fail: "Failed",
    },
    totalCount: "Total",
    sevenDay: "Last 7 days",
    thirtyDay: "Last 30 days",
    today: "Today",
  },
  robotRunStatistics: {
    dailyRobot: {
      runInstanceExecutionCount: "Total Task Execution Count",
      successExecutionCount: "Total Succeeded Task Count",
      robotCount: "Total Robot Count",
      robotAliveTime: "Total Robot Time",
      robotBusyTime: "Total Busy Time",
      averageBusyProportion: "Average Busy Ratio",
      averageSuccessProportion: "Average Success rate",
    },
    perRobot: {
      runInstanceExecutionCount: "Task Execution Count",
      successExecutionCount: "Succeeded Task Count",
      busyMilliseconds: "Busy Time",
      aliveMilliseconds: "Robot Time",
      busyProportion: "Robot Busy Ratio",
      successProportion: "Success Rate",
    },
  },
  userFlowStatistics: {
    dailyUserFlow: {
      uploadedPackageCount: "Total Uploaded Flow Package Count",
      deployedPackageCount: "Total Deployed Flow Package Count",
      createdWorkflowCount: "Total Flow Deployment Creation Count",
      createdCronTriggerCount: "Total Task Schedule Creation Count",
      createdJobCount: "Total Task Count",
      jobSuccessCount: "Total Succeeded Task Count",
      totalJobExecutionMilliseconds: "Total Task Execution Time",
    },
    perUserFlow: {
      userName: "User Name",
      uploadedPackageCount: "Uploaded Flow Package Count",
      createdWorkflowCount: "Flow Deployment Creation Count",
      createdCronTriggerCount: "Task Schedule Creation Count",
      createdJobCount: "Task Creation Count",
      jobSuccessCount: "Succeeded Task Count",
      totalJobExecutionMilliseconds: "Task Execution Time",
    },
  },
  robotStatusStatistics: {
    averageBusyRatioOfAvailableRobots: "Average Available Robot Busy Ratio",
    averageBusyRatio: "Average Busy Ratio",
    todayAverageBusyRatio: "Today Average Busy Ratio",
    robotBusyTotalTimeTOP10: "Total Available Robot Busy Time TOP10",
    todayAdded: "Today New",
    robotBusyTotalTime: "Total Available Robot Busy Time",
    busyTotalTime: "Total Busy Time",
    robotFailRateTOP10: "Available Robot Failure Rate TOP10",
    robot: "Robot",
    licenseType: { serverLicensed: "licensed", unlicensed: "unlicensed" },
    availableRobotStateDistribution: "Real Time Available Robot Status Distribution",
    robotTotalCount: "Total Robot Count",
    serverLicensedCount: "Licensed Count",
    unlicensedCount: "Unlicensed Count",
    availableRobotTotalExeCount: "Available Robot Task Execution Count",
    exeTotalCount: "Execution Count",
    viewRobotDetail: "View Robot Details",
    exeWorkflowTotalTime: "Total Flow Execution Time",
    faultNumber: "Failure(s)",
  },
  queueStatistics: {
    averageBusyRate: "Average Busy Ratio",
    todayAverageBusyRate: "Today Busy Ratio",
    todayAdded: "Today New",
    busyTotalTime: "Total Busy Time",
    faultNumber: "Failure(s)",
    successNumber: "Success(es)",
    queueDetail: "Scheduling Queue Details",
    queueExeTotalTime: "Total Task Queue Execution Time",
    exeTotalTime: "Total Task Execution Time",
    queueName: "Queue Name",
    queueBusyTop10: "Total Scheduling Queue Busy Time TOP10",
    queueCount: "Scheduling Queue Count",
    queueTotalCount: "Total Scheduling Queue Count",
    queueFailTop10: "Queue Failure Rate TOP10",
    queueJobState: "Scheduling Queue Task Distribution",
    queueJobCount: "Total Task Queue Execution Count",
    aveWaitTime: "Average Waiting Time",
    busyRate: "Busy Ratio",
    robotBindedCount: "Bound Robot Count",
    robotBindedTotalCount: "Bound Robot Count",
    queueJobWaitTop10: "Average Scheduling Queue Task Waiting Time TOP10",
    queueRobotAveBusyRate: "Average Queue Robot Busy Ratio",
    queueSuccessRate: "Queue Success Rate TOP10",
    associatedWorkflowCount: "Associated Flow Deployment Count",
    associatedWorkflowTotalCount: "Total Associated Flow Deployment Count",
    exeWorkflowTotalTime: "Total Flow Execution Time",
  },
};
