import {
  FilePermissData,
  FilePermissionUpdateData,
  PermissionMode,
  User,
  UserPermissionInfo,
} from "@encoo-web/encoo-lib/types";
import { createModel } from "nyax";
import { EncooFile } from "src/models/file";
import { ModelBase } from "src/store/ModelBase";
import { FileEntityModel } from "src/store/models/entity/file/entity";
import { FileHelperModel } from "src/store/models/entity/file/helper";
import { RouterModel } from "src/store/models/router";
import { FileUIModel } from "src/store/models/ui/file/file";

export interface FileItem extends EncooFile {
  children?: FileItem[];
  loadedChildren?: boolean;
}

export interface UserCheckedData extends User {
  checked?: boolean;
  permission?: PermissionMode;
}

export type UserInfo = Partial<UserCheckedData & UserPermissionInfo>;

export const FileItemUIModel = createModel(
  class extends ModelBase {
    private get _id() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.containerKey!;
    }

    private get _helperContainer() {
      return this.getContainer(FileHelperModel);
    }

    private get _entityContainer() {
      return this.getContainer(FileEntityModel);
    }

    public initialState() {
      return {
        children: [] as string[],
        loadedChildren: false,
        filePermission: {} as FilePermissData,
        isShowUserModal: false,
        checkedUser: [] as UserInfo[],
        currentFile: null as EncooFile | null,
      };
    }

    public reducers() {
      return {
        setChildren: (children: string[]) => {
          this.state.children = children;
        },
        setLoadedChildren: (loadedChildren: boolean) => {
          this.state.loadedChildren = loadedChildren;
        },
        setFilePermission: (filePermission: FilePermissData) => {
          this.state.filePermission = filePermission;
        },
        connectCheckedUser: (value: UserInfo[]) => {
          this.state.checkedUser = [...this.state.checkedUser, ...value];
        },
        setCheckedUser: (value: UserInfo[]) => {
          this.state.checkedUser = value;
        },
        setShowUserModal: (value: boolean) => {
          this.state.isShowUserModal = value;
        },
        setCurrentFile: (file: EncooFile | null) => {
          this.state.currentFile = file;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        file: (): FileItem => {
          const byId = this._entityContainer.state.byId;
          const id = this._id;
          const children = this.state.children;
          const loadedChildren = this.state.loadedChildren;

          const file = byId[id] ?? {
            id,
            nodeName: "",
            directory: "",
            fullName: "",
            contentType: "",
            size: 0,
            fileType: "Directory",
            createdAt: "",
            createdByName: "",
          };
          const childrenItems = children.map(
            (id: string) => this.getContainer(FileItemUIModel, id).getters.file
          );

          return {
            ...file,
            children: childrenItems,
            loadedChildren,
          };
        },
        parentId: () =>
          this._entityContainer.state.byId[this._id]?.parentId ?? null,
        fullName: () =>
          this._entityContainer.state.byId[this._id]?.fullName ?? "",
        permission: (): PermissionMode | null =>
          this._entityContainer.state.byId[this._id]?.permission ?? null,
        rootDirctoryPermission: (): PermissionMode | null => {
          const rootId = this.getContainer(FileUIModel).state.rootId;

          if (!rootId) {
            return null;
          }
          const rootChildren: string[] = this.getContainer(
            FileItemUIModel,
            rootId
          ).state.children;

          const file = this.getters.file;
          const rootName = file.directory.split("/")[0];

          const foundChild = rootChildren.find((child) => {
            const file = this.getContainer(FileItemUIModel, child).getters.file;

            return file.nodeName === rootName && file.fileType === "Directory";
          });

          if (!foundChild) {
            return null;
          }
          return (
            this.getContainer(FileItemUIModel, foundChild).getters.file
              .permission ?? null
          );
        },
      };
    }
    public effects() {
      return {
        ...super.effects(),
        requestChildrenFiles: async (payload: {
          isRoot?: boolean;
          force?: boolean;
        }) => {
          const { isRoot, force } = payload;

          const loadedChildren = this.state.loadedChildren;
          const fullName = isRoot ? "" : this.getters.fullName;

          if (!loadedChildren || force) {
            const files = await this._helperContainer.actions.getAllChildren.dispatch(
              { fullName, parentId: this._id }
            );
            const children = files.map((file) => file.id);
            await this.actions.setChildren.dispatch(children);
            await this.actions.setLoadedChildren.dispatch(true);
          }
        },
        createChildDirectory: async (directoryName: string) => {
          const fullName = this.getters.fullName;
          const file = await this._helperContainer.actions.createDirectory.dispatch(
            {
              parentId: this._id,
              fullName,
              name: directoryName,
            }
          );

          await this.actions.requestChildrenFiles.dispatch({ force: true });

          return file;
        },
        delete: async () => {
          const backupFullName = this.getters.fullName;
          const backupParentId = this.getters.parentId;

          await this._helperContainer.actions.delete.dispatch({
            id: this._id,
            fullName: this.getters.fullName,
          });

          if (backupParentId) {
            const parentContainer = this.getContainer(
              FileItemUIModel,
              backupParentId
            );
            await parentContainer.actions.setChildren.dispatch(
              parentContainer.state.children.filter((id) => this._id !== id)
            );

            const routerContainer = this.getContainer(RouterModel);

            const params = routerContainer.getters.currentRouteInfo
              .params as Record<string, string>;
            const { fullName } = params;

            if (fullName && fullName.startsWith(backupFullName)) {
              await routerContainer.actions.navigateByRouteInfo.dispatch({
                type: "file",
                params: {
                  fullName: parentContainer.getters.fullName,
                },
              });
            }
          }
        },
        getFilePermission: async () => {
          const filePermission = await this._helperContainer.actions.getFilePermission.dispatch(
            this._id
          );
          await this.actions.setCheckedUser.dispatch(
            filePermission.userPermissions
          );
          await this.actions.setFilePermission.dispatch(
            filePermission as FilePermissData
          );
        },
        updateFilePermission: async (payload: {
          permissionId: string;
          filePermissionData: FilePermissionUpdateData;
        }) => {
          const { permissionId, filePermissionData } = payload;
          await this._helperContainer.actions.updateFilePermission.dispatch({
            id: permissionId,
            filePermissionData,
          });
        },
      };
    }
  },
  {
    isDynamic: true,
    isLazy: true,
  }
);
