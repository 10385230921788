import { createModel } from "nyax";
import { EncooFileUploadCallbackInfo } from "src/models/file";
import { ModelBase } from "src/store/ModelBase";
import { FileEntityModel } from "src/store/models/entity/file/entity";
import { FileHelperModel } from "src/store/models/entity/file/helper";
import { FileItemUIModel } from "src/store/models/ui/file/fileItem";
import { UploadingUIModel } from "src/store/models/ui/file/uploading";
import { newGuid } from "src/utils/uuid";

export const FileUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(FileEntityModel);
    }
    private get _helperContainer() {
      return this.getContainer(FileHelperModel);
    }

    public initialState() {
      return {
        rootId: null as string | null,
        currentId: null as string | null,
        uploadingInfo: null as EncooFileUploadCallbackInfo | null,
        permissionSettingFileId: null as string | null,
        isShowFilePermission: false,
      };
    }

    public reducers() {
      return {
        setRootId: (rootId: string) => {
          this.state.rootId = rootId;
        },
        setCurrentId: (id: string | null) => {
          this.state.currentId = id;
        },
        setShowFilePermisson: (value: boolean) => {
          this.state.isShowFilePermission = value;
        },
        setPermissionSettingFileId: (id: string | null) => {
          this.state.permissionSettingFileId = id;
        },
        setUploadingInfo: (info: EncooFileUploadCallbackInfo | null) => {
          this.state.uploadingInfo = info;
        },
      };
    }

    public selectors() {
      return {
        files: () => {
          const rootId = this.state.rootId;
          return rootId
            ? this.getContainer(FileItemUIModel, rootId).getters.file.children
            : undefined;
        },
        currentFile: () => {
          const currentId = this.state.currentId;
          return currentId
            ? this.getContainer(FileItemUIModel, currentId).getters.file
            : null;
        },
      };
    }

    public effects() {
      return {
        ...super.effects(),
        initializeRequest: async () => {
          const rootId = newGuid();
          await this.actions.setRootId.dispatch(rootId);
          await this.getContainer(
            FileItemUIModel,
            rootId
          ).actions.requestChildrenFiles.dispatch({ isRoot: true });
        },
        requestChildren: async (payload: {
          parentId: string;
          force?: boolean;
        }) => {
          const { parentId, force } = payload;

          await this.getContainer(
            FileItemUIModel,
            parentId
          ).actions.requestChildrenFiles.dispatch({ isRoot: false, force });
        },
        createDirectory: async (payload: {
          parentId?: string;
          directoryName: string;
        }) => {
          const { parentId, directoryName } = payload;
          const newParentId = parentId ?? this.state.rootId;
          if (newParentId) {
            return await this.getContainer(
              FileItemUIModel,
              newParentId
            ).actions.createChildDirectory.dispatch(directoryName);
          }
        },
        delete: async (id: string) => {
          await this.getContainer(FileItemUIModel, id).actions.delete.dispatch(
            {}
          );
        },
        updateCurrentIdByFullName: async (fullName: string | null) => {
          if (fullName) {
            const file = await this._helperContainer.actions.getFileByFullName.dispatch(
              { fullName }
            );
            await this.actions.setCurrentId.dispatch(file.id);
            await this.getContainer(
              FileItemUIModel,
              file.id
            ).actions.requestChildrenFiles.dispatch({});
          } else {
            await this.actions.setCurrentId.dispatch(null);
          }
        },
        upload: async (payload: { parentId: string; file: File }) => {
          const { parentId, file } = payload;
          const parentContainer = this.getContainer(FileItemUIModel, parentId);

          await this.dependencies.serviceClient.file.uploadFile(
            parentContainer.getters.fullName,
            file,
            async (info) => {
              await this.getContainer(
                UploadingUIModel
              ).actions.updateUploadCallbackInfo.dispatch(info);
            }
          );
          await this.getContainer(
            FileItemUIModel,
            parentId
          ).actions.requestChildrenFiles.dispatch({ force: true });
        },
      };
    }
  }
);
