/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { UserTenantInfo } from "@encoo-web/encoo-lib/types";
import { Badge, Tooltip } from "antd";
import { memoize } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Icon from "src/components/Icon";
import Modal from "src/components/Modal";
import CreateUserTenant from "src/containers/tenant/CreateUserTenant";
import UserTenantList from "src/containers/tenant/UserTenantList";
import { ThemeContext } from "src/contexts";
import {
  useFormatMessage,
  useGetContainer,
  useTheme,
  useThemeSet,
} from "src/hooks";
import messageIds from "src/locales/messageIds";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export default React.memo(function TenantComponent() {
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const themeSet = useThemeSet();

  const getContainer = useGetContainer();
  const loginUserUIModel = getContainer(LoginUserUIModel);
  const userTenantUIContainer = getContainer(UserTenantUIModel);

  const tenantMenuModalVisible = useSelector(
    () => userTenantUIContainer.state.tenantMenuModalVisible
  );

  // TODO暂时隐藏
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isShowTenants, setShowTenants] = useState(false);

  const selectedUserTenant = useSelector(
    () => loginUserUIModel.getters.selectedUserTenant
  );

  const selectTenant = useMemo(
    () =>
      memoize((item: UserTenantInfo) => {
        return () => {
          userTenantUIContainer.actions.switchTenant.dispatch({
            id: item.tenantId,
          });
        };
      }),
    [userTenantUIContainer.actions.switchTenant]
  );

  const onTenantMenuModalVisible = useCallback(
    (modalType) => {
      userTenantUIContainer.actions.setTenantMenuModalVisible.dispatch(
        modalType
      );
    },
    [userTenantUIContainer.actions.setTenantMenuModalVisible]
  );

  const tenantItemRender = useCallback(
    (item: UserTenantInfo) => {
      return (
        <Tooltip title={item.tenantName}>
          <div
            key={item.tenantId}
            onClick={selectTenant(item)}
            css={css`
              display: flex;
              align-items: center;
              padding: 3px 10px;
              font-size: 12px;
              color: ${theme.bodySubtext};
              cursor: pointer;
              &:hover {
                background-color: ${theme.lightPrimaryColor};
              }
            `}
          >
            <Badge
              status="default"
              text={item.tenantName}
              css={css`
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              `}
            />
            {item.tenantId === selectedUserTenant?.tenantId && (
              <Icon
                name="icon-check"
                css={css`
                  font-size: 8px;
                  color: ${theme.primaryColor};
                `}
              />
            )}
          </div>
        </Tooltip>
      );
    },
    [
      selectTenant,
      selectedUserTenant,
      theme.bodySubtext,
      theme.lightPrimaryColor,
      theme.primaryColor,
    ]
  );

  const goTenantEntrySelect = useCallback(() => {
    window.location.href = `${window._settings.consoleV4CompanyEntryUrl}`;
  }, []);

  return (
    <div
      // TODO隐藏
      // onMouseEnter={() => setShowTenants(true)}
      // onMouseLeave={() => setShowTenants(false)}
      onClick={goTenantEntrySelect}
      css={css`
        position: relative;
        height: 42px;
        &:hover {
          background-color: ${theme.lightPrimaryColor};
        }
      `}
    >
      <p
        css={css`
          display: flex;
          align-items: center;
          padding: 0 22px;
          line-height: 42px;
        `}
      >
        <Icon
          name="icon-tanent"
          css={css`
            margin-right: 12px;
          `}
        />
        {formatMessage(messageIds.user.switchTenant)}
        <Icon
          name="icon-next-page"
          css={css`
            margin-left: 10px;
            font-size: 10px;
          `}
        />
      </p>
      {isShowTenants && (
        <div
          css={css`
            position: absolute;
            top: 0px;
            left: -140px;
            width: 140px;
            padding: 10px 0 5px 0;
            background: ${theme.bodyBackground};
            box-shadow: 0px 2px 4px 0px ${theme.bodyFrameShadowColor};
          `}
        >
          <UserTenantList tenantItemRender={tenantItemRender} />
          {/* TODO暂时隐藏此功能，需求有问题 */}
          {/* <Divider
            css={css`
              margin-top: 10px;
              margin-bottom: 5px;
            `}
          />
          <div
            css={css`
              text-align: center;
            `}
          >
            <Button onClick={onTenantMenuModalVisible} type="link">
              {formatMessage(messageIds.user.createTenant)}
            </Button>
          </div> */}
        </div>
      )}
      <ThemeContext.Provider value={themeSet.main}>
        <Modal
          onCancel={() => onTenantMenuModalVisible(null)}
          visible={!!tenantMenuModalVisible}
          footer={""}
        >
          <CreateUserTenant
            onCloseModal={() => onTenantMenuModalVisible(null)}
          />
        </Modal>
      </ThemeContext.Provider>
    </div>
  );
});
