import {
  Workflow,
  WorkflowCreationData,
  WorkflowUpdateData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { WorkflowEntityModel } from "src/store/models/entity/workflow/entity";
import { WorkflowListModel } from "src/store/models/entity/workflow/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const WorkflowHelperModel = createModel(
  class extends createHelperModel<Workflow>({
    setItems: (getContainer, items) =>
      getContainer(WorkflowEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) => getContainer(WorkflowEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(WorkflowEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(WorkflowListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(WorkflowEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),

        readByGroupIds: async (payload: {
          groupIds: string[];
          force?: boolean;
        }) => {
          const { groupIds, force } = payload;
          return await this._readByParentIds({
            parentIds: groupIds,
            getAllAction: (resourceGroupId) => {
              return this.dependencies.serviceClient.workflow.getAll(
                resourceGroupId
              );
            },
            getEntityParentId: (entity: Workflow) => entity.resourceGroupId,
            force,
          });
        },
        getById: async (payload: {
          resourceGroupId: string;
          workflowId: string;
          force?: boolean;
        }) => {
          const { resourceGroupId, workflowId, force } = payload;
          return await this._readById({
            id: workflowId,
            getByIdAction: async () => {
              const item = await this.dependencies.serviceClient.workflow.getById(
                resourceGroupId,
                workflowId
              );
              return item;
            },
            force,
          });
        },
        create: async (payload: {
          resourceGroupId: string;
          params: WorkflowCreationData;
        }) => {
          const { resourceGroupId, params } = payload;
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.workflow.create(
                resourceGroupId,
                params
              ),
          });
        },
        update: async (params: {
          resourceGroupId: string;
          workflowId: string;
          payload: WorkflowUpdateData;
        }) => {
          const { resourceGroupId, workflowId, payload } = params;
          return await this._update({
            id: workflowId,
            updateAction: () =>
              this.dependencies.serviceClient.workflow.update(
                resourceGroupId,
                workflowId,
                payload
              ),
          });
        },
        delete: async (payload: {
          resourceGroupId: string;
          workflowId: string;
        }) => {
          const { resourceGroupId, workflowId } = payload;
          return await this._delete({
            id: workflowId,
            deleteAction: () =>
              this.dependencies.serviceClient.workflow.delete(
                resourceGroupId,
                workflowId
              ),
          });
        },
        batchBindRobot: async (params: {
          resourceGroupId: string;
          workflowId: string;
          payload: {
            robotIds: Array<string>;
          };
        }) => {
          const { resourceGroupId, workflowId, payload } = params;
          const associtedIds = await this.dependencies.serviceClient.workflow.batchBindRobot(
            resourceGroupId,
            workflowId,
            payload
          );
          return associtedIds;
        },
        getRobots: async (payload: {
          resourceGroupId: string;
          workflowId: string;
        }) => {
          const { resourceGroupId, workflowId } = payload;
          const associtedRobots = await this.dependencies.serviceClient.workflow.getAllRobots(
            resourceGroupId,
            workflowId
          );
          return associtedRobots;
        },
        unbindRobot: async (params: {
          resourceGroupId: string;
          workflowId: string;
          robotId: string;
        }) => {
          const { resourceGroupId, workflowId, robotId } = params;
          const isUnbind = await this.dependencies.serviceClient.workflow.undinbRobot(
            resourceGroupId,
            workflowId,
            robotId
          );
          return isUnbind;
        },
      };
    }
  }
);
