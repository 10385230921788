export default {
  robot: {
    entityTypeName: "ロボット",
    robotName: "ロボット名",
    introduction:
      "ロボット管理は、専用ロボットの接続を作成および管理すると同時に、ロボットのライセンスステータスを管理できます。",
    resourceGroupIntroduction:
        "リソースグループロボットは、主にリソースグループ専用ロボットの接続を作成および管理するために使用され、同時にリソースグループロボットのライセンスステータスを管理できます。",
    sharedIntroduction:
        "共有ロボットは、主にテナント共有ロボットの接続を作成および管理するために使用され、同時に共有ロボットのライセンスステータスを管理できます。",
    lastTime: "前回のオンライン時間",
    addRobot: "ロボットを追加",
    connectStatusName: "接続ステータス",
    licenseStatusName: "ライセンスのステータス",
    licenseStatus: {
      unlicensed: "ライセンスされていない",
      serverLicensed: "ライセンスされた",
    },
    status: {
      all: "すべて",
      free: "暇",
      busy: "ビジー",
      noResponse: "応答なし",
      break: "切断",
      connect: "接続",
      unLicensed: "ライセンスされていない",
    },
    consoleManagement: {
      managed: "ホスト済み",
      unManaged: "未ホスト",
    },
    consoleManagementName: "ホストステータス",
    resource: {
      title: "グループ移動",
      current: "現在のリソースグループ",
      target: "ターゲットリソースグループ",
    },
    detail: {
      title: "ロボットの詳細",
      bindUser: "バインドしたドメイン/ユーザー名",
      name: "ロボット名",
      status: "ロボットステータス",
      code: "ロボットの接続文字列",
      tag: "ロボットタグ",
      remark: "ロボットの説明",
      type: "ロボットのタイプ",
      time: "前回接続した時間",
      workflow: "関連するプロセス展開",
      queue: "関連するキュー",
    },
    validate: {
      name: "ロボット名",
      status: "ロボットのステータス",
      code: "ロボットの接続文字列",
    },
    placeholder: {
      tags: "タグを入力し、Enterキーを押して確認してください",
      type: "ユーザーロボット",
    },
    info: {
      bindConfirm: "{entityTypeName}を{name}にバインドしますか?",
      unBindCofnirm: "{name}{entityTypeName}を削除しますか?",
    },
    tips: {
      copy: "コピーが成功しました",
      create: {
        code: "ロボットの接続文字列",
        content: "ロボットが正常に作成されました。ロボットクライアントをアクティベートするために、アクティベーション接続文字列をコピーしてください",
        mark: "ロボットの接続文字列は、ロボット詳細ページで確認できます",
      },
      unBind:
          "バインドが解除されると、クライアントロボットはプロセスを実行できなくなります。 本当にロボットバインドを解除しますか？",
      bindDelete: "バインドされたライセンスがあるので、削除できません",
      bindFail: {
        title: "バインド解除に失敗しました",
        content: "ロボットには、実行されるのを待っているタスクと実行されているタスクがあります",
      },
    },
    scope: {
      resourceGroup: "リソースグループロボット",
      shared: "共有ロボット",
    },
    listName: "{name}名",
    robotScope: "ロボットのレベル",
    tab: {
      setting: "設定",
      report: "レポート",
    },
    config: "基本情報設定",
  },
};
