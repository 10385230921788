import { ResourceGroup, ResourceGroupCreationData } from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { ResourceGroupEntityModel } from "src/store/models/entity/resourceGroup/entity";
import { ResourceGroupListModel } from "src/store/models/entity/resourceGroup/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const ResourceGroupHelperModel = createModel(
  class extends createHelperModel<ResourceGroup>({
    setItems: (getContainer, items) =>
      getContainer(ResourceGroupEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(ResourceGroupEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(ResourceGroupEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(ResourceGroupListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(ResourceGroupEntityModel),
      };
    }
    public effects() {
      return {
        ...super.effects(),
        readAll: async (payload: { force?: boolean }) => {
          const { force } = payload;
          this._readByParentIds({
            parentIds: [""],
            getAllAction: () => {
              return this.dependencies.serviceClient.resourceGroup.getAll();
            },
            getEntityParentId: (entity: ResourceGroup) => entity.id,
            force,
          });
        },
        create: async (payload: {
          resourceGroup: ResourceGroupCreationData;
        }) => {
          const { resourceGroup } = payload;
          return this._create({
            createAction: () => {
              return this.dependencies.serviceClient.resourceGroup.create(
                resourceGroup
              );
            },
          });
        },
        edit: async (payload: {
          resourceGroup: ResourceGroup;
          record: Record<string, unknown>;
        }) => {
          const { resourceGroup, record } = payload;
          return this._update({
            id: resourceGroup.id,
            updateAction: () => {
              return this.dependencies.serviceClient.resourceGroup.update(
                resourceGroup,
                record
              );
            },
          });
        },
        delete: async (payload: { resourceGroupId: string }) => {
          const { resourceGroupId } = payload;
          return this._delete({
            id: resourceGroupId,
            deleteAction: () => {
              return this.dependencies.serviceClient.resourceGroup.delete(
                resourceGroupId
              );
            },
          });
        },
      };
    }
  }
);
