import antdZhCN from "antd/es/locale/zh_CN";
import { LocaleConfig } from "src/locales";
import zhHansMessages from "src/locales/zhHans.messages";
import { flattenMessages } from "src/utils/locale";

const zhHans: LocaleConfig = {
  locale: "zh-Hans",
  messages: flattenMessages(zhHansMessages),

  antdLocale: antdZhCN,
};

export default zhHans;
