/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import React from "react";
import FileUploadingComponent from "src/containers/layout/components/FileUploadingComponent";
import LoadingContainer from "src/containers/loading/Loading";
import { ThemeContext } from "src/contexts";
import { useTheme, useThemeSet } from "src/hooks";

const AppMainInternal = React.memo(function AppMainInternal(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const theme = useTheme();

  return (
    <main
      css={css`
        display: flex;
        flex-grow: 1;
        min-width: 800px;
        min-height: 200px;
        position: relative;
        background-color: ${theme.bodyBackground};
        overflow: auto;
      `}
    >
      <LoadingContainer />
      {children}
      <FileUploadingComponent />
    </main>
  );
});

export default React.memo(function AppMain(props: {
  children?: React.ReactNode;
}) {
  const { children } = props;

  const themeSet = useThemeSet();

  return (
    <ThemeContext.Provider value={themeSet.main}>
      <AppMainInternal>{children}</AppMainInternal>
    </ThemeContext.Provider>
  );
});
