import { createModel } from "nyax";
import {
  appRouteDefinitions,
  AppRouteDefinitions,
  AppRouteInfo,
} from "src/routes";
import { ModelBase } from "src/store/ModelBase";
import { RouterModel } from "src/store/models/router";
import { ResourceGroupModel } from "src/store/models/ui/resourceGroup";

export const SidebarModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        isMenuCollapsed: false,
      };
    }

    public reducers() {
      return {
        setMenuCollapsed: (isMenuCollapsed: boolean) => {
          this.state.isMenuCollapsed = isMenuCollapsed;
        },
      };
    }

    public effects() {
      return {
        navigate: async (selectedKey: string | null) => {
          const resourceGroupContainer = this.getContainer(ResourceGroupModel);
          const selectedResourceGroupId =
            resourceGroupContainer.state.selectedResourceGroupId;
          let routeType: keyof AppRouteDefinitions;

          if (selectedKey && selectedKey in appRouteDefinitions) {
            routeType = selectedKey as keyof AppRouteDefinitions;
          } else {
            routeType = "404";
          }

          const defaultParams = appRouteDefinitions[routeType].defaultParams;
          let params = {};
          if (Object.keys(defaultParams).includes("resourceGroupId")) {
            params = {
              resourceGroupId: selectedResourceGroupId,
            };
          }
          await this.getContainer(
            RouterModel
          ).actions.navigateByRouteInfo.dispatch({
            type: routeType,
            params,
          } as AppRouteInfo);
        },
      };
    }
  }
);
