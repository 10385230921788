import { User, UserStatus } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { UserEntityModel } from "src/store/models/entity/user/entity";

type DrawerType = "detail" | "edit";

export const UserUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(UserEntityModel);
    }

    public initialState() {
      return {
        // Single selection
        selectedUser: {} as User,
        isMultiSelectionDisabled: true,
        selectedIds: [] as string[],
        selectedStatus: "all",
        isDetail: false,
        type: "detail" as DrawerType,
        createUserVisible: false,
        batchAddUserModalVisible: false,
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setSelectedUser: (value: User) => {
          this.state.selectedUser = value;
        },
        setDetail: (value: boolean) => {
          this.state.isDetail = value;
        },
        setType: (value: DrawerType) => {
          this.state.type = value;
        },
        setMultiSelectionDisabled: (value: boolean) => {
          this.state.isMultiSelectionDisabled = value;
        },
        setSelectedIds: (value: string[]) => {
          this.state.selectedIds = value;
        },
        setSelectedStatus: (value: string) => {
          this.state.selectedStatus = value;
        },
        setCreateUserVisible: (value: boolean) => {
          this.state.createUserVisible = value;
        },
        setBatchAddUserModalVisible: (value: boolean) => {
          this.state.batchAddUserModalVisible = value;
        },
      };
    }
    public effects() {
      return {
        initialState: async () => {
          await this.actions.setDetail.dispatch(false);
          await this.actions.setSelectedIds.dispatch([]);
        },
      };
    }
    public selectors() {
      return {
        users: createSelector(
          () => this._entityContainer.getters.items,
          () => this.state.selectedStatus,
          (items, selectedStatus) => {
            switch (selectedStatus) {
              case "enabled":
                return items.filter(
                  (item) => item.status === UserStatus.Active
                );
              case "disabled":
                return items.filter(
                  (item) => item.status === UserStatus.Banned
                );
              case "all":
                return items;
            }
          }
        ),
      };
    }
  }
);
