/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetContainer, useTheme } from "src/hooks";
import { LoadingUIModel } from "src/store/models/ui/loading/loading";

export default React.memo(function LoadingContainer() {
  const theme = useTheme();

  const getContainer = useGetContainer();

  const loadingUIModel = getContainer(LoadingUIModel);

  const isShowLoading = useSelector(() => loadingUIModel.state.isShowLoading);

  useEffect(() => {
    loadingUIModel.actions.registerLoading.dispatch({});
  }, [loadingUIModel.actions.registerLoading]);

  return (
    <div
      css={css`
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100%;
        display: ${isShowLoading ? "block" : "none"};
        text-align: center;
        vertical-align: middle;
        &:after {
          content: "";
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          display: inline-block;
          padding: 20px;
          border-radius: 0.6em;
          vertical-align: middle;
          box-shadow: 0 4px 12px ${theme.shadowColor};
          background-color: ${theme.bodyBackground};
        `}
      >
        <Spin size="default" />
      </div>
    </div>
  );
});
