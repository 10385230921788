export default {
  apps: {
    name: "Application Name",
    introduction: "The Mini Program management is mainly used to browse the Mini Programs to be audited and add or remove the Mini Programs in a unified manner. It supports Mini-Program version management and details view.",
    workbench: "Application",
    currentVersion: "Current Enabled Version",
    status: {
      all: "All status",
      enabled: "Enable",
      disabled: "Disable",
    },
    currentStatus: {
      enabled: "Enabled",
      disabled: "Disabled",
    },
    version: "Version Number",
    changeVersion: "Switch this version",
    uploadDateTime: "Upload Time",
    creater: "Developer",
    device: "Range of Applications",
    mobile: "Mobile",
    pc: "PC",
    try: "Trial",
    moreVersion: "View more versions",
    workflowName: "Flow Deployment Name",
    packageVersion: "Flow Package Version Number",
    appVersionsTitle: "Version List",
    currentSign: "Current",
    upgradeTip: "You are not using the latest version. Click to upgrade to {lastAvailableVersion} version.",
    upgradeConfirm: "Do you want to upgrade to {lastAvailableVersion} version?",
    upgradeBatchConfirm: "Are you sure you want to upgrade?",
    updateAppCurrentVersionConfirm: "Are you sure you want to switch the current application to this version?",
    deleteAppVersionMessage: "This version is in use and cannot be deleted. Please switch to other versions to delete.",
  },
};
