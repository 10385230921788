import { UserResourceGroup } from "@encoo-web/encoo-lib";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";
import { LoginUserUIModel } from "src/store/models/ui/user/loginUser";

export const ResourceGroupModel = createModel(
  class extends ModelBase {
    private get _loginUserUIModel() {
      return this.getContainer(LoginUserUIModel);
    }

    public selectors() {
      return {
        userResourceGroup: (): UserResourceGroup[] | null =>
          this._loginUserUIModel.state.loggedinUser?.resourceGroups ?? null,
        defaultResourceGroup: createSelector(
          (): string | null =>
            this._loginUserUIModel.state.loggedinUser?.defaultResourceGroupId ??
            null,
          (): UserResourceGroup[] | null =>
            this.getters.userResourceGroup ?? null,
          (
            defaultResourceGroupId,
            resourceGroups
          ): UserResourceGroup | null => {
            return (
              resourceGroups?.find(
                (item) => item.id === defaultResourceGroupId
              ) ?? null
            );
          }
        ),
      };
    }

    public initialState() {
      return {
        selectedResourceGroupId: "",
      };
    }

    public reducers() {
      return {
        setSelectedResourceGroupId: (value: string) => {
          this.state.selectedResourceGroupId = value;
        },
      };
    }

    public effects() {
      return {
        initializeRequest: async () => {
          const basicUserProfile = this._loginUserUIModel.state
            .basicUserProfile;
          let user = null;
          if (!basicUserProfile) {
            user = await this._loginUserUIModel.actions.login.dispatch({});
          }

          if (basicUserProfile || user) {
            await this.getContainer(
              UserTenantUIModel
            ).actions.initSelectedTenant.dispatch({});
          }
        },
      };
    }
  }
);
