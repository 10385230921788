import { createModel, createSelector } from "nyax";
import { ModelBase } from "./ModelBase";
import { SharedModelContext } from "./_shared";

export function createItemsReadWriteModel<
  TItem extends object,
  TDependencies = {}
>(options?: {
  getItemId: (item: TItem) => string;
  setItems: (
    context: SharedModelContext<TDependencies>,
    items: Array<TItem | string>
  ) => Promise<void>;
}) {
  return createModel(
    class extends ModelBase {
      public initialState() {
        return {
          currentTimestampById: {} as Record<string, number | undefined>,
          expiredTimestampById: {} as Record<string, number | undefined>,
          readingTimestampById: {} as Record<string, number | null | undefined>,
          writingTimestampById: {} as Record<string, number | null | undefined>,
        };
      }

      public selectors() {
        return {
          getItemCurrentTimestamp: createSelector(() => (id: string) => {
            return this.state.currentTimestampById[id] ?? 0;
          }),
          getItemExpiredTimestamp: createSelector(() => (id: string) => {
            return this.state.expiredTimestampById[id] ?? 0;
          }),
          getItemReadingTimestamp: createSelector(() => (id: string) => {
            return this.state.readingTimestampById[id] ?? null;
          }),
          getItemWritingTimestamp: createSelector(() => (id: string) => {
            return this.state.writingTimestampById[id] ?? null;
          }),
          getItemExpired: createSelector(() => (id: string) => {
            return (
              this.getters.getItemExpiredTimestamp(id) >=
              this.getters.getItemCurrentTimestamp(id)
            );
          }),
          getItemReading: createSelector(() => (id: string) => {
            return this.getters.getItemReadingTimestamp(id) != null;
          }),
          getItemWriting: createSelector(() => (id: string) => {
            return this.getters.getItemWritingTimestamp(id) != null;
          }),
        };
      }

      public reducers() {
        return {
          setItemsCurrentTimestamp: (payload: {
            ids: string[];
            timestamp: number;
          }) => {
            payload.ids.forEach((id) => {
              this.state.currentTimestampById[id] = payload.timestamp;
            });
          },
          setItemsExpiredTimestamp: (payload: {
            ids: string[];
            timestamp: number;
          }) => {
            payload.ids.forEach((id) => {
              this.state.expiredTimestampById[id] = payload.timestamp;
            });
          },
          setItemsReadingTimestamp: (payload: {
            ids: string[];
            timestamp: number | null;
          }) => {
            payload.ids.forEach((id) => {
              this.state.readingTimestampById[id] = payload.timestamp;
            });
          },
          setItemsWritingTimestamp: (payload: {
            ids: string[];
            timestamp: number | null;
          }) => {
            payload.ids.forEach((id) => {
              this.state.writingTimestampById[id] = payload.timestamp;
            });
          },
        };
      }

      public effects() {
        return {
          beginRead: async (payload: { ids: string[]; force?: boolean }) => {
            const timestamp = Date.now();

            const { ids, force } = payload;

            const toReadIds = force
              ? ids
              : ids.filter((id) => this.getters.getItemExpired(id));

            await this.actions.setItemsReadingTimestamp.dispatch({
              ids: toReadIds,
              timestamp,
            });

            return {
              ids: toReadIds,
              timestamp,
            };
          },
          endRead: async (payload: {
            timestamp: number;
            items: Array<TItem | string>;
          }) => {
            const { timestamp } = payload;

            const timestampIds = Object.entries(this.state.readingTimestampById)
              .filter((e) => e[1] === timestamp)
              .map((e) => e[0]);

            try {
              const ids: string[] = [];
              const items: Array<TItem | string> = [];

              payload.items.forEach((item) => {
                let id: string;
                if (typeof item === "string") {
                  id = item;
                } else if (options) {
                  id = options.getItemId(item);
                } else {
                  throw new Error("NotSupported");
                }

                if (timestamp > this.getters.getItemCurrentTimestamp(id)) {
                  ids.push(id);
                  items.push(item);
                }
              });

              if (options) {
                await options.setItems(
                  (this as unknown) as SharedModelContext<TDependencies>,
                  items
                );
              }

              await this.actions.setItemsCurrentTimestamp.dispatch({
                ids,
                timestamp,
              });

              return {
                ids,
                items,
                timestamp,
              };
            } finally {
              await this.actions.setItemsReadingTimestamp.dispatch({
                ids: timestampIds,
                timestamp: null,
              });
            }
          },
          beginWrite: async (payload: { ids: string[] }) => {
            const timestamp = Date.now();

            const { ids } = payload;

            if (ids.some((id) => this.getters.getItemWriting(id))) {
              throw new Error("Failed to write items: item is writing");
            }

            await this.actions.setItemsWritingTimestamp.dispatch({
              ids,
              timestamp,
            });

            return {
              ids,
              timestamp,
            };
          },
          endWrite: async (payload: {
            timestamp: number;
            items: Array<TItem | string>;
          }) => {
            const { timestamp } = payload;

            const timestampIds = Object.entries(this.state.writingTimestampById)
              .filter((e) => e[1] === timestamp)
              .map((e) => e[0]);

            try {
              const ids: string[] = [];
              const items: Array<TItem | string> = [];

              payload.items.forEach((item) => {
                let id: string;
                if (typeof item === "string") {
                  id = item;
                } else if (options) {
                  id = options.getItemId(item);
                } else {
                  throw new Error("NotSupported");
                }

                if (timestamp > this.getters.getItemCurrentTimestamp(id)) {
                  ids.push(id);
                  items.push(item);
                }
              });

              if (options) {
                await options.setItems(
                  (this as unknown) as SharedModelContext<TDependencies>,
                  items
                );
              }

              await this.actions.setItemsCurrentTimestamp.dispatch({
                ids,
                timestamp,
              });

              return {
                ids,
                items,
                timestamp,
              };
            } finally {
              await this.actions.setItemsWritingTimestamp.dispatch({
                ids: timestampIds,
                timestamp: null,
              });
            }
          },
          markExpired: async (payload: { ids: string[] }) => {
            const timestamp = Date.now();

            const { ids } = payload;

            await this.actions.setItemsExpiredTimestamp.dispatch({
              ids,
              timestamp,
            });

            return {
              ids,
              timestamp,
            };
          },
        };
      }
    }
  );
}
