import { Role } from "@encoo-web/encoo-lib/types";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";
import { RoleEntityModel } from "src/store/models/entity/role/entity";

export const RoleListUIModel = createModel(
  class extends ModelBase {
    private get _entityContainer() {
      return this.getContainer(RoleEntityModel);
    }
    public initialState() {
      return {
        multiSelectionDisabled: true,
        selectedIds: [] as string[],
        keyWord: "",
      };
    }
    public reducers() {
      return {
        ...super.reducers(),
        setMultiSelectionDisabled: (value: boolean) => {
          this.state.multiSelectionDisabled = value;
        },
        setSelectedIds: (value: string[]) => {
          this.state.selectedIds = value;
        },
        setkeyWord: (value: string) => {
          this.state.keyWord = value;
        },
      };
    }
    public selectors() {
      return {
        displayData: createSelector(
          () => this._entityContainer.getters.items,
          () => this.state.keyWord,
          (items, keyWord) =>
            items.filter((item: Role) => {
              if (!"".concat(keyWord).trim()) return true;
              return item.name.indexOf(keyWord) > -1;
            })
        ),
      };
    }
  }
);
