import _ from "lodash";
import moment from "moment";
import { createModel, createSelector } from "nyax";
import { ModelBase } from "src/store/ModelBase";

export type TimeType = "minute" | "hour" | "day" | "week" | "month" | "cron";

const transformStrToTime = (time?: string) =>
  time ? moment(time, "HH:mm:ss") : undefined;

export const initTimeInfo = {
  minute: undefined as undefined | number,
  hour: {
    hour: undefined as undefined | number,
    minute: undefined as undefined | number,
  },
  day: { time: undefined as moment.Moment | undefined, timeString: "" },
  week: {
    week: [] as string[],
    timeObject: {
      time: undefined as moment.Moment | undefined,
      timeString: "",
    },
  },
  month: {
    month: undefined as undefined | number,
    day: undefined as undefined | number,
    timeObject: {
      time: undefined as moment.Moment | undefined,
      timeString: "",
    },
  },
  cron: "",
};

export const defaultSelectedTimeType: TimeType = "cron";

export const cronReg: { [K in Exclude<TimeType, "cron">]: RegExp } = {
  // minuteRegString `0 0/9 * * * ?`;
  minute: /^[0]\s[0]\/[0-5]?[0-9]\s\*\s\*\s\*\s\?$/,
  // hourRegString =  "0 7 0/5 * * ?";
  hour: /^[0]\s[0-5]?[0-9]\s[0]\/[0-2]?[0-9]\s\*\s\*\s\?$/,
  //dayRegString = 5 24 2 1/1 * ?;
  day: /^[0-5]?[0-9]\s[0-5]?[0-9]\s[0-2]?[0-9]\s[1]\/[1]\s\*\s\?$/,
  //weekRegString = 13 23 16 ? * 3,4
  week: /^[0-5]?[0-9]\s[0-5]?[0-9]\s[0-2]?[0-9]\s\?\s\*\s[1-7,]+$/,
  //monthRegString = "07 26 17 8 1/2 ?"
  month: /^[0-5]?[0-9]\s[0-5]?[0-9]\s[0-2]?[0-9]\s[0-3]?[0-9]\s[1]\/[0-1]?[0-9]\s\?$/,
};

export type TimeInfoDefinition = typeof initTimeInfo;
export type TimeInfo = { [K in TimeType]: TimeInfoDefinition[K] };
export type TimeInfoItem = Partial<TimeInfo>;
export type TimeKeyAndInfo = {
  cronTimeType: TimeType;
  timeInfoItem: TimeInfoItem;
};

export const CronExpressModel = createModel(
  class extends ModelBase {
    public initialState() {
      return {
        selectedTimeType: defaultSelectedTimeType as TimeType,
        timeInfo: _.cloneDeep(initTimeInfo) as TimeInfo,
        isRightCronFields: false,
      };
    }

    public selectors() {
      return {
        cronExpression: createSelector(
          () => this.state.selectedTimeType,
          () => this.state.timeInfo,
          (cronTimeType: TimeType, cronTimeInfo: TimeInfo) =>
            this.getCronExpressByTimeInfo(cronTimeType, cronTimeInfo)
        ),
      };
    }

    public reducers() {
      return {
        setSelectedTimeType: (value: TimeType) => {
          this.state.selectedTimeType = value;
        },
        updateTimeInfo: (value: Partial<TimeInfo>) => {
          this.state.timeInfo = { ...this.state.timeInfo, ...value };
        },
        validateCronFields: () => {
          const selectedTimeType = this.state.selectedTimeType;
          const cronExpression = this.getters.cronExpression;
          if (selectedTimeType === "cron") {
            this.state.isRightCronFields = !!cronExpression;
          } else {
            this.state.isRightCronFields = cronReg[selectedTimeType].test(
              cronExpression || ""
            );
          }
        },
      };
    }

    public effects() {
      return {
        setTimeInfoItemByCron: async (cronExpression: string) => {
          const item = this.getTimeInfoItemByCron(cronExpression);
          await this.actions.updateTimeInfo.dispatch(item.timeInfoItem);
          await this.actions.setSelectedTimeType.dispatch(item.cronTimeType);
        },
      };
    }

    private getCronExpressByTimeInfo(
      cronTimeType: TimeType,
      cronTimeInfo: TimeInfo
    ) {
      let cronExpression = "";
      switch (cronTimeType) {
        case "minute":
          cronExpression = `0 0/${cronTimeInfo.minute} * * * ?`;
          break;
        case "hour": {
          const { hour, minute } = cronTimeInfo.hour;
          cronExpression = `0 ${minute} 0/${hour} * * ?`;
          break;
        }
        case "day": {
          const timeArr = cronTimeInfo.day.timeString.split(":");
          const timeByDay = timeArr[2] + " " + timeArr[1] + " " + timeArr[0];
          cronExpression = `${timeByDay} 1/1 * ?`;
          break;
        }
        case "week": {
          const { week, timeObject } = cronTimeInfo.week;
          const timeArr = timeObject.timeString.split(":");
          const time = timeArr[2] + " " + timeArr[1] + " " + timeArr[0];
          let weekCron = "";
          week.forEach((item) => {
            weekCron = weekCron + item + ",";
          });
          cronExpression = `${time} ? * ${weekCron.slice(0, -1)}`;
          break;
        }
        case "month": {
          const { month, day, timeObject } = cronTimeInfo.month;
          const timeArr = timeObject.timeString.split(":");
          const timeCorn = timeArr[2] + " " + timeArr[1] + " " + timeArr[0];
          cronExpression = `${timeCorn} ${day} 1/${month} ?`;
          break;
        }
        case "cron":
          cronExpression = cronTimeInfo.cron;
          break;
        default:
          return;
      }
      return cronExpression;
    }

    private getTimeInfoItemByCron(cronExpression: string): TimeKeyAndInfo {
      const cronTimeType = this.getInitTimeType(cronExpression);
      let timeInfoItem = {} as TimeInfoItem;
      switch (cronTimeType) {
        case "minute": {
          const minute = cronExpression.split(" ")[1].substring(2);
          timeInfoItem = { minute: minute ? Number(minute) : undefined };
          break;
        }
        case "hour": {
          const hour = cronExpression.split(" ")[2].substring(2);
          const minute = cronExpression.split(" ")[1];
          timeInfoItem = {
            hour: {
              hour: hour ? Number(hour) : undefined,
              minute: minute ? Number(minute) : undefined,
            },
          };
          break;
        }
        case "day": {
          const timeArr = cronExpression.split(" ");
          const time = timeArr[2] + ":" + timeArr[1] + ":" + timeArr[0];
          timeInfoItem = {
            day: { time: transformStrToTime(time), timeString: time },
          };
          break;
        }
        case "week": {
          const timeArr = cronExpression.split(" ");
          const time = timeArr[2] + ":" + timeArr[1] + ":" + timeArr[0];
          const week = timeArr[5].split(",");
          timeInfoItem = {
            week: {
              week,
              timeObject: { time: transformStrToTime(time), timeString: time },
            },
          };
          break;
        }
        case "month": {
          const monthDataArr = cronExpression.split(" ");
          const time =
            monthDataArr[2] + ":" + monthDataArr[1] + ":" + monthDataArr[0];
          const month = monthDataArr[4].substring(2);
          const day = monthDataArr[3];
          timeInfoItem = {
            month: {
              month: month ? Number(month) : undefined,
              day: day ? Number(day) : undefined,
              timeObject: { time: transformStrToTime(time), timeString: time },
            },
          };
          break;
        }
        case "cron":
          timeInfoItem = { cron: cronExpression };
          break;
        default:
          timeInfoItem = { cron: cronExpression };
      }
      return { cronTimeType, timeInfoItem };
    }

    private getInitTimeType(cronExpression: string): TimeType {
      const timeTypeInfo = Object.entries(cronReg).find(([, reg]) =>
        reg.test(cronExpression)
      );
      return (timeTypeInfo?.[0] as TimeType) ?? defaultSelectedTimeType;
    }
  }
);
