export default {
  job: {
    lastExecuteTime: "前回の実行時間",
    terminate: "終了",
    introduction:
        "タスクレコードページには、現在のリソースグループ下にあるすべてのプロセスタスクの実行状況を示し、プロセス実行の詳細とログの表示もサポートしています",
    jobNumber: "タスク番号",
    executableTarget: "実行ターゲット",
    targetName: "ターゲット名",
    view: "詳細",
    status: {
      queued: "待つ",
      allocated: "割り当て済み",
      running: "実行中",
      failed: "失敗",
      succeeded: "成功",
      cancelled: "キャンセル",
      cancelling: "キャンセル中",
    },
    executeOrder: "タスク優先度",
    deployAssignment: "プロセス展開の代入",
    executionAssignment: "プロセス実行の代入",
    adjustPriority: "優先度を調整",
    priorityCount: "優先度値",
    defaultCount: "タスク優先度のデフォルト値は2000",
    updatePriorityCountTip:
        "タスク優先度の範囲は、0が最低で、5000が最高です。タスクの優先度が高ければ高いほど、優先に実行されます",
    priorityValidateTips: "0と5000の間の数字を入力してください。",
    lastRobotName: "最後に実行されたロボット",
  },
};
