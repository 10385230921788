export default {
  job: {
    lastExecuteTime: "上次执行时间",
    terminate: "取消",
    execute: "执行",
    message: "选择流程数目为0",
    messagePriority: "可批量调整状态为等待的任务优先级！",
    messageExecute: "可批量执行状态为成功、失败和取消的任务！",
    messageTerminate: "可批量取消状态为等待、已分配和运行中的任务!",
    introduction:
      "任务记录页面展示当前资源组下所有流程任务的执行状态，支持查看流程执行详情及日志详情。",
    jobNumber: "任务编号",
    executableTarget: "执行目标",
    targetName: "目标名称",
    view: "查看详情",
    status: {
      queued: "等待",
      allocated: "已分配",
      running: "运行",
      failed: "失败",
      succeeded: "成功",
      cancelled: "取消",
      cancelling: "正在取消",
    },
    executeOrder: "任务优先级",
    deployAssignment: "流程部署赋值",
    executionAssignment: "流程执行赋值",
    adjustPriority: "调整优先级",
    operateErrorMessage: "提示",
    tipAll: "共批量处理",
    tipAllTask: "个任务",
    tipSuccess: "其中成功  ",
    tipSuccessUnit: "  个",
    tipFail: "失败  ",
    tipMessage: "失败信息如下：",
    priorityCount: "优先级值",
    defaultCount: "任务优先级默认2000",
    updatePriorityCountTip:
      "任务优先级最低为0，最高为5000，优先级越高任务将越优先执行",
    priorityValidateTips: "请输入0-5000之间的数字",
    lastRobotName: "最新执行机器人",
  },
};
