/** @jsx jsx */
import { jsx } from "@emotion/core";
import css from "@emotion/css/macro";
import { Alert as AntAlert } from "antd";
import { AlertProps as AntAlertProps } from "antd/lib/alert";
import React, { useMemo } from "react";
import { useTheme } from "src/hooks";

interface AlertProps extends AntAlertProps {
  className?: string;
}

export default React.memo(function Alert(props: AlertProps) {
  const { className, type = "info", ...restProps } = props;
  const theme = useTheme();
  const typeStyle = useMemo(() => {
    switch (type) {
      case "success":
        return css`
          color: ${theme.succuessText};
        `;
      case "info":
        return css`
          color: ${theme.infoText};
        `;
      case "warning":
        return css`
          color: ${theme.warningText};
        `;
      case "error":
        return css`
          color: ${theme.errorText};
        `;
    }
  }, [
    theme.errorText,
    theme.infoText,
    theme.succuessText,
    theme.warningText,
    type,
  ]);
  return (
    <AntAlert
      css={css`
        .ant-alert-message {
          ${typeStyle};
        }
      `}
      className={className}
      type={type}
      {...restProps}
    ></AntAlert>
  );
});
