import messageIds from "src/locales/messageIds";
import {
  AppRouteType,
  createMenuDefinition,
  MenuDefinition,
  MenuExpandedDefinition
} from "src/routes/_shared";

export const appMenuDefinitions = [
  createMenuDefinition({
    name: messageIds.menu.dashboard.name,
    icon: "icon-menu-dashboard",
    children: [
      createMenuDefinition({
        name: messageIds.menu.dashboard.overallMonitor.name,
        routeType: "dashboard",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.robotStatusStatistics.name,
        routeType: "robotStatusStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.queueStatistics.name,
        routeType: "queueStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.robotRunStatistics.name,
        routeType: "robotRunStatistics",
      }),
      createMenuDefinition({
        name: messageIds.menu.dashboard.userFlowStatistics.name,
        routeType: "userFlowStatistics",
      }),
    ],
  }),
  createMenuDefinition({
    name: messageIds.menu.rpa.name,
    icon: "icon-menu-rpa",
    children: [
      createMenuDefinition({
        routeType: "package",
        name: messageIds.menu.rpa.package.name,
        children: [
          createMenuDefinition({
            routeType: "packageDetail",
            name: messageIds.menu.rpa.package.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "workflow",
        name: messageIds.menu.rpa.workflow.name,
        children: [
          createMenuDefinition({
            routeType: "workflowDetail",
            name: messageIds.menu.rpa.workflow.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "robot",
        name: messageIds.menu.rpa.robot.name,
        children: [
          createMenuDefinition({
            routeType: "robotDetail",
            name: messageIds.menu.rpa.queue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "queue",
        name: messageIds.menu.rpa.queue.name,
        children: [
          createMenuDefinition({
            routeType: "queueDetail",
            name: messageIds.menu.rpa.queue.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
      createMenuDefinition({
        routeType: "job",
        name: messageIds.menu.rpa.log.name,
        children: [
          createMenuDefinition({
            routeType: "jobDetail",
            name: messageIds.menu.rpa.log.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
          createMenuDefinition({
            routeType: "runInstanceLog",
            name: messageIds.menu.rpa.log.name,
            hiddenInMenu: true,
            hiddenInBreadcrumb: true,
          }),
        ],
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.apps.name,
    routeType: "app",
    icon: "icon-menu-apps",

    children: [
      createMenuDefinition({
        routeType: "appDetail",
        name: messageIds.menu.apps.name,
        hiddenInMenu: true,
        hiddenInBreadcrumb: true,
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.understanding.name,
    routeType: "template",
    icon: "icon-menu-understanding",

    children: [
      createMenuDefinition({
        routeType: "templateEditor",
        name: messageIds.menu.understanding.editor.name,
        hiddenInMenu: true,
      }),
      createMenuDefinition({
        routeType: "templateTester",
        name: messageIds.menu.understanding.tester.name,
        hiddenInMenu: true,
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.data.name,
    icon: "icon-menu-data",
    children: [
      createMenuDefinition({
        routeType: "asset",
        name: messageIds.menu.data.asset.name,
      }),
      // TODO:未完成，不在本次迭代中
      // createMenuDefinition({
      //   routeType: "dataTable",
      //   name: messageIds.menu.data.dataTable.name,
      //   children: [
      //     createMenuDefinition({
      //       routeType: "dataTableDetail",
      //       name: messageIds.menu.data.dataTable.name,
      //       hiddenInMenu: true,
      //       hiddenInBreadcrumb: true,
      //     }),
      //   ],
      // }),
      createMenuDefinition({
        routeType: "file",
        name: messageIds.menu.data.file.name,
      }),
    ],
  }),

  createMenuDefinition({
    name: messageIds.menu.setting.name,
    icon: "icon-menu-settings",
    children: [
      createMenuDefinition({
        routeType: "user",
        name: messageIds.menu.setting.user.name,
      }),
      createMenuDefinition({
        routeType: "role",
        name: messageIds.menu.setting.role.name,
      }),
      createMenuDefinition({
        routeType: "resourcegroupDetail",
        name: messageIds.menu.setting.resourcegroup.name,
        // children: [
        //   createMenuDefinition({
        //     routeType: "resourcegroupDetail",
        //     name: messageIds.menu.setting.resourcegroup.name,
        //     hiddenInMenu: true,
        //     hiddenInBreadcrumb: true,
        //   }),
        // ],
      }),
      createMenuDefinition({
        routeType: "auditlog",
        name: messageIds.menu.setting.auditlog.name,
      }),
      createMenuDefinition({
        routeType: "license",
        name: messageIds.menu.setting.license.name,
      }),
      createMenuDefinition({
        routeType: "systemSetting",
        name: messageIds.menu.setting.systemSetting.name,
      }),
    ],
  }),
];

export const noLicenseImportedMenuDefinitions = [
  createMenuDefinition({
    name: messageIds.menu.setting.name,
    icon: "icon-menu-settings",
    children: [
      createMenuDefinition({
        routeType: "license",
        name: messageIds.menu.setting.license.name,
      }),
    ],
  }),
];

const expandMenu = function (
  menus: MenuDefinition[],
  parentKey?: string
): Record<string | AppRouteType, MenuExpandedDefinition> {
  let menuInfo: Record<string | AppRouteType, MenuExpandedDefinition> = {};
  for (const menu of menus) {
    const key = menu.routeType ?? menu.name;
    menuInfo[key] = {
      ...menu,
      parentKey,
    };

    if (menu.children) {
      const childrenMenuInfo = expandMenu(menu.children, key);
      menuInfo = {
        ...menuInfo,
        ...childrenMenuInfo,
      };
    }
  }

  return menuInfo;
};

export const APP_MENUS = expandMenu(appMenuDefinitions);
