export default {
  resourceGroup: {
    title: "Resource Group Name",
    introduction: "Resource group management is mainly used to define each resource group. It can assign different roles with different permissions to each user under different resource groups to achieve fine-grained access control for users.",
    entityTypeName: "Resource Group",
    name: "Resource Group Name",
    addToResourceGroup: "Add",
    user: "Resource Group User",
    allResourceGroup: "",
  },
};
