import { createModel } from "nyax";
import { EnvironmentEdition } from "src/models/environment";
import { ModelBase } from "src/store/ModelBase";
import { LicenseBindingsHelperModel } from "src/store/models/entity/licenseBindings/helper";
import { RouterModel } from "src/store/models/router";

export const EnvironmentModel = createModel(
  class extends ModelBase {
    private get _licenseBindingsHelper() {
      return this.getContainer(LicenseBindingsHelperModel);
    }

    public initialState() {
      return {
        environmentEdition: null as EnvironmentEdition | null,
        environmentUniqueCode: null as string | null,
        environmentVersion: null as string | null,
        hasLicenseAuthorization: false,
      };
    }

    public reducers() {
      return {
        setEnvironmentEdition: (value: EnvironmentEdition | null) => {
          this.state.environmentEdition = value;
        },
        setEnvironmentUniqueCode: (value: string | null) => {
          this.state.environmentUniqueCode = value;
        },
        setEnvironmentVersion: (value: string | null) => {
          this.state.environmentVersion = value;
        },
        setHasLicenseAuthorization: (value: boolean) => {
          this.state.hasLicenseAuthorization = value;
        },
      };
    }

    public effects() {
      return {
        initEnvironmentDeploy: async () => {
          await this.actions.updateEnvironmentSystemInfo.dispatch({});
          if (this.state.environmentEdition === "Private") {
            await this.actions.updateEnvironmentUniqueCode.dispatch({});
            await this.actions.initPrivateLicenseBinded.dispatch({});
          } else {
            await this.actions.setHasLicenseAuthorization.dispatch(true);
          }
        },
        updateEnvironmentSystemInfo: async () => {
          const environmentSystemInfo = await this.dependencies.serviceClient.environment.getSystemInfo();
          await this.actions.setEnvironmentEdition.dispatch(
            environmentSystemInfo.edition
          );
          await this.actions.setEnvironmentVersion.dispatch(
            environmentSystemInfo.version
          );
        },
        updateEnvironmentUniqueCode: async () => {
          const environmentUniqueCode = await this.dependencies.serviceClient.environment.getUniqueCode();
          await this.actions.setEnvironmentUniqueCode.dispatch(
            environmentUniqueCode
          );
        },
        initPrivateLicenseBinded: async () => {
          if (this.state.environmentUniqueCode) {
            try {
              await this._licenseBindingsHelper.actions.getAllLicenseBindings.dispatch(
                {
                  productName: "Console",
                  sku: "Enterprise",
                  boundToId: this.state.environmentUniqueCode,
                  binded: true,
                }
              );
              await this.actions.setHasLicenseAuthorization.dispatch(true);
            } catch (err) {
              if (err?.response) {
                const { status } = err?.response;
                if (status === 404) {
                  err.ishandled = true;
                }
              }
              if (err.response.data.code === "ResoureNotBinded") {
                await this.navigateToLicense();
              }
            }
          }
        },
      };
    }

    private async navigateToLicense() {
      await this.getContainer(RouterModel).actions.navigateByRouteInfo.dispatch(
        {
          type: "license",
          params: {},
        }
      );
    }
  }
);
