import {
  DataTable,
  DataTableCreationData,
  DataTableUpdateData,
} from "@encoo-web/encoo-lib";
import { createModel } from "nyax";
import { DataTableEntityModel } from "src/store/models/entity/dataTable/entity";
import { DataTableListModel } from "src/store/models/entity/dataTable/list";
import { createHelperModel } from "src/store/models/entity/_shared";

export const DataTableHelperModel = createModel(
  class extends createHelperModel<DataTable>({
    setItems: (getContainer, items) =>
      getContainer(DataTableEntityModel).actions.setItems.dispatch(items),
    getItems: (getContainer) =>
      getContainer(DataTableEntityModel).getters.items,
    getItem: (getContainer, id) =>
      getContainer(DataTableEntityModel).state.byId[id],
    refreshList: (getContainer) =>
      getContainer(DataTableListModel).actions.refresh.dispatch({}),
  }) {
    public selectors() {
      return {
        ...super.selectors(),
        entity: () => this.getContainer(DataTableEntityModel),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        getById: async (payload: { dataTableId: string; force?: boolean }) => {
          const { dataTableId, force } = payload;
          return await this._readById({
            id: dataTableId,
            getByIdAction: async () => {
              const item = await this.dependencies.serviceClient.dataTable.getById(
                dataTableId
              );
              return item;
            },
            force,
          });
        },
        create: async (payload: DataTableCreationData) => {
          return await this._create({
            createAction: () =>
              this.dependencies.serviceClient.dataTable.create(payload),
          });
        },
        update: async (params: {
          dataTableId: string;
          payload: DataTableUpdateData;
        }) => {
          const { dataTableId, payload } = params;
          return await this._update({
            id: dataTableId,
            updateAction: () =>
              this.dependencies.serviceClient.dataTable.update(
                dataTableId,
                payload
              ),
          });
        },
        delete: async (payload: { dataTableId: string }) => {
          const { dataTableId } = payload;
          return await this._delete({
            id: dataTableId,
            deleteAction: () =>
              this.dependencies.serviceClient.dataTable.delete(dataTableId),
          });
        },
      };
    }
  }
);
