/** @jsx jsx */
import { jsx } from "@emotion/core";
import { UserTenantInfo } from "@encoo-web/encoo-lib/types";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useGetContainer } from "src/hooks";
import { UserTenantUIModel } from "src/store/models/ui/tenant/userTenant";

export default React.memo(function UserTenantList(props: {
  tenantItemRender: (item: UserTenantInfo) => ReactNode;
}) {
  const getContainer = useGetContainer();
  const userTenantUIContainer = getContainer(UserTenantUIModel);

  const userTenants = useSelector(
    () => userTenantUIContainer.state.userTenants
  );
  const { tenantItemRender } = props;

  return <div>{userTenants?.map((item) => tenantItemRender(item))}</div>;
});
